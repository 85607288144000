/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Notifications.css';
import NotificationList from './NotficationList';
import { Link } from 'react-router-dom';

class NotificationDropDown extends Component {
  state = {
    // notifications: dummyData,
    notificationPage: '',
  };

  componentDidMount() {
    this.notifcationDropDownCheck();
  }

  componentWillUnmount() {
    // update last check prop of user
    const { user, userActions } = this.props;
    userActions.updateNotificationsCheck(user);
  }

  componentDidUpdate(prevProps) {
    const { notifications, notificationsActions } = this.props;
    const receivedNewNotification =
      notifications.unreadCount > 0 && prevProps.unreadCount === 0;
    if (receivedNewNotification) {
      notificationsActions.updateUnreadCount();
    }
  }

  notifcationDropDownCheck = () => {
    const { notificationsActions } = this.props;
    if (window.location.pathname === '/Notifications' && !this.props.dropDown) {
      // update notifications count
      this.setState({
        notificationPage: true,
      });
    } else {
      this.setState({
        notificationPage: false,
      });
    }
    notificationsActions.updateUnreadCount();
  };

  render() {
    const { notifications, connections } = this.props;
    const { notificationPage } = this.state;
    const notificationsList = notificationPage
      ? notifications.list
      : notifications.list.slice(0, 10);
    return (
      <div>
        <div
          className={notificationPage ? 'notification' : 'notification-drop'}
        >
          {notificationsList.map((notification) => (
            <NotificationList
              notificationPage={this.state.notifcationPage}
              notification={notification}
              connections={connections}
            />
          ))}
        </div>
        <div className="view-notifications">
          <Link to="/Notifications">
            {this.props.dropDown && <h2>View all notifications</h2>}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  user: state.user,
  connections: state.connections,
});

export default connect(mapStateToProps)(NotificationDropDown);
