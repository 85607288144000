import {
  FETCH_FAVORITES,
  FETCH_FAVORITES_SUCCESS,
  FETCH_FAVORITES_FAILED,
  UPDATE_FAVORITES,
} from './types';
import { fetchFavorites } from '../services';

export const getFavorites = (userId, businessId) => async(dispatch) => {
  dispatch({ type: FETCH_FAVORITES });
  fetchFavorites(userId, businessId)
    .then((favoritesIds) => {
      dispatch({ type: FETCH_FAVORITES_SUCCESS, payload: favoritesIds });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FAVORITES_FAILED, payload: err });
    });
};

export const updateFavorites = (favorites) => async(dispatch) => {
  dispatch({
    type: UPDATE_FAVORITES,
    payload: { favorites },
  });
};
