import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Icon,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import validator from 'validator';
import { toast } from 'react-toastify';
import './Referral.css';
import SentReferralsTable from './SentReferralsTable';
import Graphic from './Referral-Graphic.png';
import FilterSVG from './FilterSVG';
import { OutsideClickDetect, Loading } from '../../components';
import {
  getReferrals,
  getProfileByCategory,
  sendReferrals,
  socketServices,
  redeemReferral,
  deleteReferral,
} from '../../services';
import {
  makeReferralsList,
  delayedFunc,
  referralCounts,
  filterReferrals,
} from '../../util';

const ReferralPage = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [sentReferrals, setSentReferrals] = useState([]);
  const [email, setEmail] = useState('');
  const [emailObj, setEmailObj] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [sendingState, setSendingState] = useState('Send');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [redeemedReferral, setRedeemedReferral] = useState({});
  const [redeemedIndex, setRedeemedIndex] = useState(0);
  const [viewingCategories, setViewingCategories] = useState({
    redeemed: false,
    fulfilled: false,
    pending: false,
    expired: false,
  });
  const [refCounts, setRefCounts] = useState({
    pending: 0,
    fulfilled: 0,
    expired: 0,
    redeemed: 0,
  });
  const emails = Object.keys(emailObj);
  const { user, userActions } = props;

  const notifySuccess = (message) =>
    toast.success(message, {
      className: 'Referral-Toast-Success',
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = (message) =>
    toast.error(message, {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const sendEmail = () => {
    if (emails.length !== 0) {
      setEmailError(false);
      setSendingState('Sending...');
      const referrals = makeReferralsList(user, emails);
      sendReferrals(referrals)
        .then(({ newReferrals }) => {
          newReferrals.forEach((referral, i) => {
            delayedFunc(i, () => {
              if (referral.success) {
                notifySuccess(
                  ` ✔️ Invite sent successfully to ${referral.inviteEmail} `,
                );
              } else {
                notifyError(
                  ` x There was an error sending your referral to ${referral.inviteEmail}`,
                );
              }
            });
          });
          setSentReferrals([...newReferrals, ...sentReferrals]);
          setSendingState('Send');
          setEmailObj({});
        })
        .catch(() => {
          setEmailError(true);
          setEmailErrorText(
            'There was an error sending your referrals. Please contact support if error persists.',
          );
        });
    }
  };

  const addEmail = () => {
    const validEmail = validator.isEmail(email);
    const tempEmailObj = { ...emailObj };
    if (validEmail && !tempEmailObj[email]) {
      const alreadySentReferral = sentReferrals.find(
        (referral) => referral.inviteEmail === email,
      );
      if (!alreadySentReferral) {
        getProfileByCategory({ field: 'email', value: email, type: 'users' })
          .then(({ user }) => {
            if (!user) {
              tempEmailObj[email] = true;
              setEmailObj({ ...tempEmailObj });
              setEmail('');
              setEmailError(false);
              setEmailErrorText('');
            } else {
              setEmailError(true);
              setEmailErrorText(
                'There is already a user under this email address',
              );
            }
          })
          .catch(() => {
            setEmailError(true);
            setEmailErrorText(
              'There was an issue validating this email. Please contact support if error persists.',
            );
          });
      } else {
        setEmailError(true);
        setEmailErrorText(
          'You have already sent a referral to this email address',
        );
      }
    } else {
      const errorMessage = !validEmail
        ? 'Not a valid email address'
        : 'This email is already added';
      setEmailError(true);
      setEmailErrorText(errorMessage);
    }
  };

  const deleteEmail = (currentEmail) => {
    const tempEmailObj = { ...emailObj };
    delete tempEmailObj[currentEmail];
    setEmailObj({ ...tempEmailObj });
  };

  const handleInput = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    setEmailErrorText('');
  };

  const freeMonths = () => {
    if (user.rewardTokens) {
      return Math.floor(user.rewardTokens / 5);
    }

    return 0;
  };

  const redeem = (referral, id) => {
    const { userActions } = props;
    redeemReferral(referral)
      .then(({ redeemed }) => {
        if (user.rewardTokens) {
          user.rewardTokens += 1;
        } else {
          user.rewardTokens = 1;
        }

        setRedeemedReferral(redeemed);
        setRedeemedIndex(id);
        userActions.updateUserRedeemCount(user);
      })
      .catch(() => {
        notifyError(
          'There was a problem redeeming this referral. Please contact support if error persists',
        );
      });
  };

  const deleteReferralFunction = (referralId, id) => {
    deleteReferral(referralId)
      .then(() => {
        const updatedReferrals = [...sentReferrals];
        updatedReferrals.splice(id, 1);
        setSentReferrals([...updatedReferrals]);
      })
      .catch(() => {
        notifyError(
          'Error removing referral. Contact support if error persists',
        );
      });
  };

  const updateViewingCategories = (d) => {
    const newViewingCategories = { ...viewingCategories };
    newViewingCategories[d.value] = d.checked;
    setViewingCategories({ ...newViewingCategories });
    setOpenDropdown(false);
  };

  useEffect(() => {
    const fetchReferrals = () => {
      getReferrals(user._id)
        .then(({ referrals }) => {
          const counts = referralCounts(referrals);
          setRefCounts(counts);
          setSentReferrals([...referrals]);
          setLoaded(true);
        })
        .catch(() => {
          setEmailError(true);
          setEmailErrorText(
            'There was an issue loading the page. Please try refreshing and contact support if this error persits.' // eslint-disable-line
          );
          setLoaded(true);
        });
    };
    fetchReferrals();
    return () => {
      socketServices.removeReferralPageListeners();
      const newUser = { ...user };
      delete newUser.redeeming;
      userActions.setUser(newUser);
    };
  }, []);

  useEffect(() => {
    socketServices.removeReferralPageListeners();
    socketServices.listenReferralPage(sentReferrals, setSentReferrals);
    const newCounts = referralCounts(sentReferrals);
    setRefCounts(newCounts);
  }, [sentReferrals]);

  useEffect(() => {
    if (user.redeeming === false) {
      if (user.redeemingError) {
        redeemedReferral.status = 'fulfilled';
        redeemReferral(redeemedReferral);
        setRedeemedReferral({});
        notifyError(
          ` x There was an error redeeming your referral to ${redeemedReferral.inviteEmail}`,
        );
      } else {
        const updatedReferrals = [...sentReferrals];
        updatedReferrals[redeemedIndex] = redeemedReferral;
        setSentReferrals([...updatedReferrals]);
        notifySuccess('Referral Redeemed!');
      }
    }
  }, [user.redeeming]);

  const visibleReferrals = filterReferrals(sentReferrals, viewingCategories);

  return (
    <div style={{ marginBottom: '5%' }}>
      {loaded ? (
        <div
          className={
            sentReferrals
              ? 'referral-container-referalls'
              : 'referral-container'
          }
        >
          <div
            className={
              sentReferrals ? 'referral-content-referalls' : 'referral-content'
            }
          >
            <img src={Graphic} alt="graphic" />
            <h1 className="extra-month">
              Gain an extra month free with every 5 friends you invite.
            </h1>
            <p className="referral-text">
              Rhiz uses your network to grow your net worth. Combine lead
              generation and referrals for fast and trustworthy connections.
            </p>
            <Form>
              <Input
                error={emailError}
                name="Email"
                value={email}
                placeholder="Email address(es)"
                onChange={handleInput}
              />

              <Button onClick={addEmail} type="submit">
                Add
              </Button>
            </Form>
            {emailError && (
              <p className="referral-error-text">{emailErrorText}</p>
            )}

            <div className="referral-emails">
              {emails.map((email) => (
                <div className="referral-email">
                  <p>{email}</p>
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteEmail(email)}
                    onKeyDown={() => deleteEmail(email)}
                    role="presentation"
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
            {emails.length !== 0 && (
              <Button onClick={sendEmail} type="submit">
                {sendingState}
              </Button>
            )}
          </div>

          {sentReferrals && (
            <div className="Sent-Referrals-Container">
              <div className="Sent-Referrals-Horizontal-Line"></div>
              <div className="Sent-Referrals">
                <div className="Sent-Referrals-Text">
                  <h1>
                    {props.user.firstName}, here are your referral earnings so
                    far.
                  </h1>
                  <p>
                    Free months earned will be applied to your account when we
                    launch paid Rhiz subscriptions.
                  </p>
                </div>
                <div className="Sent-Referrals-Earned-Status-Container">
                  <div className="Sent-Referrals-Earned">
                    <div className="Sent-Referrals-Earned-Referrals">
                      <p>{user.rewardTokens ? user.rewardTokens : 0}</p>
                      <p>Referrals</p>
                    </div>
                    <div className="Sent-Referrals-Line"></div>
                    <div className="Sent-Referrals-Earned-Earned">
                      <p>{freeMonths()} free months </p>
                      <p>Earned</p>
                    </div>
                  </div>
                  <OutsideClickDetect
                    outsideClick={() => setOpenDropdown(false)}
                  >
                    <div
                      onClick={() => setOpenDropdown(!openDropdown)}
                      onKeyDown={() => setOpenDropdown(!openDropdown)}
                      className="Sent-Referrals-Status"
                    >
                      <FilterSVG />
                      <p>View Status(es):</p>
                      <Dropdown
                        closeOnBlur
                        open={openDropdown}
                        icon={
                          <Icon
                            color="black"
                            name={openDropdown ? 'angle up' : 'angle down'}
                            link
                            size="large"
                            onClick={() => setOpenDropdown(!openDropdown)}
                          />
                        }
                      >
                        <Dropdown.Menu className="Sent-Referrals-Dropdown">
                          <Dropdown.Item>
                            <Checkbox
                              onClick={(d) => {
                                updateViewingCategories(d);
                                setOpenDropdown(true);
                              }}
                              value="redeemed"
                              label={
                                <label>
                                  <Icon
                                    color="grey"
                                    name="circle"
                                    size="small"
                                  />
                                  Redeemed ({refCounts.redeemed})
                                </label>
                              }
                            />
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Checkbox
                              value="fulfilled"
                              onClick={(e, d) => {
                                updateViewingCategories(d);
                                setOpenDropdown(true);
                              }}
                              label={
                                <label>
                                  <Icon
                                    color="green"
                                    name="circle"
                                    size="small"
                                  />
                                  Fulfilled ({refCounts.fulfilled})
                                </label>
                              }
                            />
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Checkbox
                              onClick={(e, d) => {
                                updateViewingCategories(d);
                                setOpenDropdown(true);
                              }}
                              value="pending"
                              label={
                                <label>
                                  <Icon
                                    color="yellow"
                                    name="circle"
                                    size="small"
                                  />
                                  Pending ({refCounts.pending})
                                </label>
                              }
                            />
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Checkbox
                              onClick={(e, d) => {
                                updateViewingCategories(d);
                                setOpenDropdown(true);
                              }}
                              value="expired"
                              label={
                                <label>
                                  <Icon
                                    color="red"
                                    name="circle"
                                    size="small"
                                  />
                                  Expired ({refCounts.expired})
                                </label>
                              }
                            />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </OutsideClickDetect>
                </div>
                <SentReferralsTable
                  deleteReferralFunction={deleteReferralFunction}
                  redeem={redeem}
                  visibleReferrals={visibleReferrals}
                  Referrals={sentReferrals}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(ReferralPage);
