/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

export default class ChangePassword extends Component {
  state = {
    currentPassword: '',
    newPassword: '',
    isBlocking: false,
    message: '',
    showMessage: false,
    success: false,
    updating: false,
  };

  componentDidMount = () => {
    const { location } = this.props;

    if (location.pathname === '/reset_password') {
      this.setState({
        isBlocking: true,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { user } = this.props;
    const updatePasswordAttempted =
      (user.checkingPassword || user.checkingPassword === false) &&
      user.changePasswordMessage !== prevState.message;
    if (updatePasswordAttempted) {
      const newPassword =
        !user.checkingPasword && user.changePasswordSuccess
          ? ''
          : this.state.newPassword;
      const currentPassword =
        !user.checkingPasword && user.changePasswordSuccess
          ? ''
          : this.state.currentPassword;
      const success = user.changePasswordSuccess
        ? user.changePasswordSuccess
        : user.checkingPassword;
      const isBlocking = user.changePasswordSuccess
        ? !user.changePasswordSuccess
        : true;
      const message = user.changePasswordMessage;

      this.setState({
        message,
        isBlocking,
        success,
        newPassword,
        currentPassword,
      });
    }
  };

  componentWillUnmount = () => {
    const { isBlocking } = this.state;
    const { userActions, location } = this.props;
    if (isBlocking) {
      userActions.logoutUser();
    } else if (!isBlocking && location.pathname === '/reset_password') {
      window.href = '/';
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showMessage: false,
      success: false,
      // message: '',
      isBlocking: true,
    });
  };

  blockingHandler = (e) => {
    this.setState({
      isBlocking: e.target.value !== this.state.isBlocking,
    });
  };

  onSubmit = async(e) => {
    e.preventDefault();
    const { resetPassword, reset, changePassword } = this.props;
    const { currentPassword, newPassword } = this.state;
    const formFilled =
      currentPassword.trim().length > 0 && newPassword.trim().length > 0;

    if (formFilled) {
      if (!resetPassword) {
        // case for change password
        this.setState(
          {
            showMessage: true,
            message: '',
            updating: true,
          },
          () => changePassword(currentPassword, newPassword),
        );
      } else {
        // case for reset password
        const passwordsMatch = currentPassword === newPassword;
        if (passwordsMatch) {
          // need to make sure both fields have the same value typed in
          this.setState(
            {
              showMessage: true,
              message: '',
              updating: true,
            },
            () => reset(newPassword),
          );
        } else {
          this.setState({
            showMessage: true,
            // isBlocking: false,
            message: 'Please make sure that both fields match.',
            success: false,
          });
        }
      }
    } else {
      // case for hitting save without filling both fields
      this.setState({
        showMessage: true,
        message: 'Please make sure that both fields are filled out.',
        success: false,
      });
    }
  };

  goToHomePage = () => {
    window.location.href = '/';
  };

  render() {
    const { isBlocking, showMessage, message, success } = this.state;
    const { resetPassword, user, userActions, location } = this.props;

    return (
      <div className="profile-form">
        <div id="change-password-title">Change Password</div>
        <div id="change-password-warn">
          **New password must be between 8 to 16 characters and must contain at
          least 1 uppercase, 1 lowercase, 1 number, and 1 special character**
        </div>
        <form
          onChange={(e) => this.blockingHandler(e)}
          id="change-password-form"
          onSubmit={(e) => this.onSubmit(e)}
        >
          <div>
            <label htmlFor="current-password">
              {!resetPassword ? 'CURRENT PASSWORD' : 'NEW PASSWORD'}
            </label>
            <input
              type="password"
              name="currentPassword"
              placeholder={!resetPassword ? 'Current password' : 'New password'}
              onChange={(e) => this.handleChange(e)}
              id="current-password"
              value={this.state.currentPassword}
              required
            />
          </div>
          <div>
            <label htmlFor="new-password">
              {!resetPassword ? 'NEW PASSWORD' : 'RETYPE PASSWORD'}
            </label>
            <input
              type="password"
              name="newPassword"
              placeholder={!resetPassword ? 'New password' : 'Retype password'}
              onChange={(e) => this.handleChange(e)}
              id="new-password"
              value={this.state.newPassword}
              required
            />
          </div>
        </form>
        {showMessage &&
          (!success ? (
            <div
              style={{
                color: 'red',
                paddingBottom: '5px',
                fontSize: '1.1em',
                marginTop: '2%',
              }}
            >
              {message}
            </div>
          ) : (
            <div
              style={{
                color: '#00BE6E',
                paddingBottom: '5px',
                fontSize: '1.25em',
                marginTop: '2%',
              }}
            >
              {message}
            </div>
          ))}
        <Prompt
          when={isBlocking}
          message="If you leave before saving, your changes will be lost and you will stay logged out." // eslint-disable-line
          setConfirmCallback={() => userActions.logoutUser()}
        />
        {success && !user.checkingPassword && location.pathname === '/reset_password' ? (
          <button id="password-save-button" onClick={this.goToHomePage}>
            Back To Homepage
          </button>
        ) : (
          <button id="password-save-button" onClick={(e) => this.onSubmit(e)}>
            Update Password
          </button>
        )}
      </div>
    );
  }
}
