/* eslint-disable no-invalid-this */
import React, { Component } from 'react';

// Components
import ProfileProgressBar from './ProfileProgressBar';
import BreakLine from '../../BusinessProfile/subcomponents/BreakLine';

// styled components
import { Container, InputLabels, BusinessDetailsInput } from '../Styles';

export default class Verification extends Component {
  state = {
    facebook: '',
    bbb: '',
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { handleChange } = this;
    return (
      <Container style={{ marginTop: '-6%' }}>
        <ProfileProgressBar progress={this.props.progress} />
        <InputLabels style={{ marginTop: '1.5%' }}>FACEBOOK PAGE</InputLabels>
        <BusinessDetailsInput
          placeholder="Facebook Page"
          name="facebook"
          onChange={handleChange}
        />
        <BreakLine color="#999" margin="100%" />
        <InputLabels style={{ marginTop: '1.5%' }}>
          BBB Verification
        </InputLabels>
        <BusinessDetailsInput
          placeholder="BBB Verification"
          name="bbb"
          onChange={handleChange}
        />
        <BreakLine color="#999" margin="100%" />
      </Container>
    );
  }
}
