/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import Ratings from 'react-ratings-declarative';
import { Link } from 'react-router-dom';
import './MyReviewsPage.css';

export default class BusinessesReviewedView extends Component {
  state = {
    messagedPeople: [],
    fullReview: false,
    recommendation: '',
  };

  componentDidMount() {
    this.buttonCheck();
  }

  toggle = () => {
    this.setState((prevstate) => ({
      fullReview: !prevstate.fullReview,
    }));
  };

  buttonCheck = () => {
    if (this.props.review.recommendation === 'Yes') {
      this.setState({
        yes: true,
      });
    } else if (this.props.review.recommendation === 'No') {
      this.setState({
        no: true,
      });
    } else if (this.props.review.recommendation === 'Neutral') {
      this.setState({
        neutral: true,
      });
    }
  };

  render() {
    const {
      img,
      businessName,
      work,
      service,
      communication,
      price,
      review,
    } = this.props.review;
    return (
      <div className="review-list">
        <img className="review-picture" src={img} alt="review pic" />
        <div className="review-flex-right">
          <div className="review-text">
            <div className="review-title-date-flex">
              <h5>{businessName}</h5>
              <p>06/20/10</p>
            </div>

            <p>{review}</p>

            {this.state.fullReview ? (
              <p
                className="close-review"
                onClick={this.toggle}
                onKeyDown={this.toggle}
                role="presentation"
              >
                Close review
              </p>
            ) : (
              <p
                className="view-review"
                onClick={this.toggle}
                onKeyDown={this.toggle}
                role="presentation"
              >
                View full review
              </p>
            )}

            {this.state.fullReview && (
              <div>
                <div className="star-flex">
                  <h5>Quality of work</h5>
                  <Ratings
                    rating={work}
                    widgetRatedColors="#07BA62"
                    widgetSpacings="0px"
                  >
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                  </Ratings>
                </div>
                <div className="star-flex">
                  <h5>Service</h5>
                  <Ratings
                    rating={service}
                    widgetRatedColors="#07BA62"
                    widgetSpacings="1px"
                  >
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                  </Ratings>
                </div>
                <div className="star-flex">
                  <h5>Communication</h5>
                  <Ratings
                    rating={communication}
                    widgetRatedColors="#07BA62"
                    widgetSpacings="1px"
                  >
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                  </Ratings>
                </div>
                <div className="star-flex">
                  <h5>Price</h5>
                  <Ratings
                    rating={price}
                    widgetRatedColors="#07BA62"
                    widgetSpacings="1px"
                  >
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                    <Ratings.Widget
                      widgetHoverColor="#07BA62"
                      widgetDimension="17px"
                    />
                  </Ratings>
                </div>
                <div className="recommend-choice">
                  <h5>Would you recommend this service provider?</h5>
                  <div className="recommend-choice-flex">
                    <div
                      className={
                        this.state.yes
                          ? ' recommend-button-true'
                          : 'recommend-button-false'
                      }
                    >
                      <p>Yes</p>
                    </div>
                    <div
                      className={
                        this.state.neutral
                          ? ' recommend-button-true'
                          : 'recommend-button-false'
                      }
                    >
                      <p>Neutral</p>
                    </div>
                    <div
                      className={
                        this.state.no
                          ? ' recommend-button-true'
                          : 'recommend-button-false'
                      }
                    >
                      <p>No</p>
                    </div>
                  </div>
                  <div className="Edit-Reviews-flex">
                    <Link to="/EditReviewBusinessForm">
                      <h5 className="Edit-Reviews-Link">Edit Review</h5>
                    </Link>
                    <h5 className="Delete-Reviews-Link">Delete</h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
