export default {
  user: {
    favorites: {},
  },
  business: {},
  connections: {
    all: [],
    personal: [],
    business: [],
    received: [],
    sent: [],
    declined: [],
    archived: {
      personal: [],
      business: [],
    },
    selectedConnection: {
      // refers to chatroom that user is at
      connection: {}, // actual connection with all info including type, users involved, lastMessage
      userProfile: {}, // the profile of the user, either user or business prop
      otherProfile: {}, // profile of other party in connection, either consumer or business
      category: '', // tracking if looking if chat is personal or business for the user
    },
    unreadPersonalMessagesCount: 0,
    unreadBusinessMessagesCount: 0,
  },
  dreamTeamRequests: {
    dreamTeam: [],
    requestsAccepted: [],
    requestsPendingReceived: [],
    requestsPendingSent: [],
  },
  notifications: {
    unreadCount: 0,
    list: [],
  },
  favorites: {
    favoriteBusinesses: new Map(),
    favoritedBy: new Map(),
  },
  sockets: {
    users: {},
    businesses: {},
  },
};
