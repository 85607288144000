import React from 'react';
import { Image } from 'semantic-ui-react';

// styles
import { Container, Text } from '../BusinessProfileStyles';

const BackgroundBanner = (props) => (
  <Container
    className="business-banner"
    style={{
      backgroundImage: `url(${props.banner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      marginBottom: '5%',
    }}
  >
    <Container style={{ height: '100%', position: 'relative' }}>
      <Image
        src={props.profilePic}
        className="business-photo"
        style={{
          position: 'absolute',
          top: '64%',
          boxShadow:
            '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        }}
      />
      <Container style={{ margin: 'auto', textAlign: 'center' }}>
        <Text
          className="banner-title"
          style={{ fontWeight: 'bold', color: 'white', lineHeight: 'normal' }}
        >
          {props.name}
        </Text>
        <Text
          className="banner-category"
          style={{ fontWeight: 'bold', color: 'white', lineHeight: 'normal' }}
        >
          {props.description}
        </Text>
      </Container>
    </Container>
  </Container>
);

export default BackgroundBanner;
