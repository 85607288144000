import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import * as Icon from 'react-feather'; // eslint-disable-line

export default class MarkedComplete extends Component {
  render() {
    return (
      <div className="Quote-Form-Container">
        <div className="Marked-complete">
          <p
            onClick={this.props.backToConverastionComplete}
            onKeyDown={this.props.backToConverastionComplete}
            role="presentation"
          >
            Back to conversation
          </p>
          <div className="Marked-complete-flex">
            <Icon.CheckCircle size="60" color={'#21BA45'} />
            <h3>Blades of Glory marked this job as complete .</h3>
            <Button onClick={this.props.quoteRecievedView} color="green">
              Yep! Confirm and proceed to payment page
            </Button>
            <h4>Not so fast-contest this</h4>
          </div>
        </div>
      </div>
    );
  }
}
