/* eslint-disable max-len */
import tier1 from '../../assets/images/fern-tier-1.png';
import tier2 from '../../assets/images/fern-tier-2.png';
import tier3 from '../../assets/images/fern-tier-3.png';
import tier4 from '../../assets/images/fern-tier-4.png';

const SubsciptionList = [
  {
    tierType: 'Free-Time',
    price: {
      monthly: 0.0,
      yearly: 0.0,
    },
    features: [
      {
        name: 'Dream Team feature',
        included: true,
        message:
          'Create a powerful referral network by featuring 5 fellow businesses on your profile, helping potential customers.',
      },
      {
        name: 'Business networking',
        included: true,
      },
      {
        name: ' In-app-messages',
        included: true,
      },
      {
        name: 'Resource packages',
        included: true,
        message:
          'Get access to special videos, webinars, PDFs and more to help your business thrive.',
      },
      {
        name: 'Verification badge',
        included: false,
        message:
          ' Create immediate trust with potential customers through a BBB verification badge displayed on your business profile.',
      },
      // {
      //     name: 'Stripe / Shopify payments',
      //     included: false,
      // },
      {
        name: 'Rhiz score',
        included: false,
        message:
          ' A 0-100 score generated by our Rhiz algorithm based on a weighted system of customer feedback.',
      },
      // {
      //     name: '3 Months Off Gusto HR/Payroll',
      //     included: false,
      // },
      {
        name: 'Custom URL',
        included: false,
        message:
          '    Customize your business profile link to display at Rhizmarket.com/YourNameHere, making it even easier for customers to find and refer you.',
      },
      {
        name: 'On-demand scheduling',
        included: false,
        message:
          ' Manage your availability and accept bookings directly on Rhiz.',
      },
    ],
    img: tier1,
  },
  {
    tierType: 'Part-Time',
    price: {
      monthly: 39.0,
      yearly: 33.25,
    },
    features: [
      {
        name: 'Dream Team feature',
        included: true,
        message:
          'Create a powerful referral network by featuring 5 fello businesses on your profile, helping potential customers.',
      },
      {
        name: 'Business networking',
        included: true,
      },
      {
        name: ' In-app-messages',
        included: true,
      },
      {
        name: 'Resource packages',
        included: true,
        message:
          'Get access to special videos, webinars, PDFs and more to help your business thrive.',
      },
      {
        name: 'Verification badge',
        included: true,
        message:
          ' Create immediate trust with potential customers through a BBB verification badge displayed on your business profile.',
      },
      // {
      //     name: 'Stripe / Shopify payments',
      //     included: true,
      // },
      {
        name: 'Rhiz score',
        included: false,
        message:
          ' A 0-100 score generated by our Rhiz algorithm based on a weighted system of customer feedback.',
      },
      // {
      //     name: '3 Months Off Gusto HR/Payroll',
      //     included: false,
      // },
      {
        name: 'Custom URL',
        included: false,
        message:
          '    Customize your business profile link to display at Rhizmarket.com/YourNameHere, making it even easier for customers to find and refer you.',
      },
      {
        name: 'On-demand scheduling',
        included: false,
        message:
          ' Manage your availability and accept bookings directly on Rhiz.',
      },
    ],
    img: tier2,
  },
  {
    tierType: 'Full-Time',
    price: {
      monthly: 79.0,
      yearly: 62.42,
    },
    features: [
      {
        name: 'Dream Team feature',
        included: true,
        message:
          'Create a powerful referral network by featuring 5 fellow businesses on your profile, helping potential customers.',
      },
      {
        name: 'Business networking',
        included: true,
      },
      {
        name: ' In-app-messages',
        included: true,
      },
      {
        name: 'Resource packages',
        included: true,
        message:
          'Get access to special videos, webinars, PDFs and more to help your business thrive.',
      },
      {
        name: 'Verification badge',
        included: true,
        message:
          ' Create immediate trust with potential customers through a BBB verification badge displayed on your business profile.',
      },
      // {
      //     name: 'Stripe / Shopify payments',
      //     included: true,
      // },
      {
        name: 'Rhiz score',
        included: true,
        message:
          ' A 0-100 score generated by our Rhiz algorithm based on a weighted system of customer feedback.',
      },
      // {
      //     name: '3 Months Off Gusto HR/Payroll',
      //     included: true,
      // },
      {
        name: 'Custom URL',
        included: false,
        message:
          '    Customize your business profile link to display at Rhizmarket.com/YourNameHere, making it even easier for customers to find and refer you.',
      },
      {
        name: 'On-demand scheduling',
        included: false,
        message:
          ' Manage your availability and accept bookings directly on Rhiz.',
      },
    ],
    img: tier3,
  },
  {
    tierType: 'Big-Time',
    price: {
      monthly: 99.0,
      yearly: 84.15,
    },
    features: [
      {
        name: 'Dream Team feature',
        included: true,
        message:
          'Create a powerful referral network by featuring 5 fellow businesses on your profile, helping potential customers.',
      },
      {
        name: 'Business networking',
        included: true,
      },
      {
        name: ' In-app-messages',
        included: true,
      },
      {
        name: 'Resource packages',
        included: true,
        message:
          'Get access to special videos, webinars, PDFs and more to help your business thrive.',
      },
      {
        name: 'Verification badge',
        included: true,
        message:
          ' Create immediate trust with potential customers through a BBB verification badge displayed on your business profile.',
      },
      // {
      //     name: 'Stripe / Shopify payments',
      //     included: true,
      // },
      {
        name: 'Rhiz score',
        included: true,
        message:
          ' A 0-100 score generated by our Rhiz algorithm based on a weighted system of customer feedback.',
      },
      // {
      //     name: '3 Months Off Gusto HR/Payroll',
      //     included: true,
      // },
      {
        name: 'Custom URL',
        included: true,
        message:
          '    Customize your business profile link to display at Rhizmarket.com/YourNameHere, making it even easier for customers to find and refer you.',
      },
      {
        name: 'On-demand scheduling',
        included: true,
        message:
          ' Manage your availability and accept bookings directly on Rhiz.',
      },
    ],
    img: tier4,
  },
];

export default SubsciptionList;
