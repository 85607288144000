/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './InboxPage.css';
import InboxList from './InboxList';
import ConnectRequest from './ConnectRequests';
import { Loading } from '../../components';
import { isMobile } from 'react-device-detect';

class InboxPage extends Component {
  state = {
    unreadMessagesBusiness: '1',
    unreadMessagesPersonal: '2',
    listCategory: this.props.business._id ? 'business' : 'personal',
    viewMessageFlag: false,
    declineMessageFlag: false,
    pendingCategory: this.props.business._id ? 'received' : 'sent',
    pending: this.props.business._id ? 'received' : 'sent',
    loaded: true,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  setListCategory = (category) => {
    const { listCategory } = this.state;
    const changingCategory = listCategory !== category;
    if (changingCategory) {
      this.setState({
        listCategory: category,
      });
    }
  };

  // receivedSent = (pending) => {
  //   this.setState({
  //     pending
  //   })
  // }

  messageCategorySelection = (pendingCategory) => {
    this.setState({
      pendingCategory,
    });
  };

  determineProfiles = (connection) => {
    const { pendingCategory, user, business } = this.props;
    let otherProfile;
    let userProfile;
    if (pendingCategory === 'sent') {
      otherProfile = connection.users[connection.businessId];
      userProfile = user;
    } else if (pendingCategory === 'received') {
      otherProfile = connection.users[connection.consumerId];
      userProfile = business;
    } else {
      otherProfile =
        user._id === connection.consumerId
          ? connection.users[connection.businessId]
          : connection.users[connection.consumerId];
      userProfile = user._id === connection.consumerId ? user : business;
    }
    return [otherProfile, userProfile];
  };

  businessCheckRender() {
    const { business, connections } = this.props;
    const { loaded, listCategory, pendingCategory } = this.state;

    return (
      <div>
        {loaded ? (
          <div className="Inbox-Connect-Flex">
            <div className="Inbox-Container">
              {business._id ? (
                <div className="Inbox-tab">
                  <div
                    onClick={() => this.setListCategory('business')}
                    onKeyDown={() => this.setListCategory('business')}
                    className={
                      listCategory === 'business' ? 'True-tab' : 'False-tab'
                    }
                  >
                    <h4>BUSINESS</h4>
                    {connections.unreadBusinessMessagesCount > 0 && (
                      <p
                        className={
                          connections.unreadBusinessMessagesCount > 0
                            ? ' new-messages'
                            : ''
                        }
                      >
                        {connections.unreadBusinessMessagesCount}
                      </p>
                    )}
                  </div>
                  <div
                    onClick={() => this.setListCategory('personal')}
                    onKeyDown={() => this.setListCategory('personal')}
                    className={
                      listCategory === 'personal' ? 'True-tab' : 'False-tab'
                    }
                  >
                    <h4>PERSONAL</h4>
                    {connections.unreadPersonalMessagesCount > 0 && (
                      <p
                        className={
                          connections.unreadPersonalMessagesCount > 0
                            ? ' new-messages'
                            : ''
                        }
                      >
                        {connections.unreadPersonalMessagesCount}
                      </p>
                    )}
                  </div>
                  {isMobile && (
                    <div
                      onClick={() => this.setListCategory('connect requests')}
                      onKeyDown={() => this.setListCategory('connect requests')}
                      className={
                        listCategory === 'connect requests'
                          ? 'True-tab'
                          : 'False-tab'
                      }
                    >
                      <h4>CONNECT</h4>
                      {!!business._id && connections.received.length > 0 && (
                        <p className={'new-messages'}>
                          {connections.received.length}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="Inbox-tab">
                  <div
                    onClick={() => this.setListCategory('personal')}
                    onKeyDown={() => this.setListCategory('personal')}
                    className={
                      listCategory === 'personal'
                        ? 'True-tab-no-business'
                        : 'False-tab-no-business'
                    }
                  >
                    <h4>PERSONAL</h4>
                    {connections.unreadPersonalMessagesCount > 0 && (
                      <p
                        className={
                          connections.unreadPersonalMessagesCount > 0
                            ? ' new-messages'
                            : ''
                        }
                      >
                        {connections.unreadPersonalMessagesCount}
                      </p>
                    )}
                  </div>
                  {isMobile && (
                    <div
                      onClick={() => this.setListCategory('connect requests')}
                      onKeyDown={() => this.setListCategory('connect requests')}
                      className={
                        listCategory === 'connect requests'
                          ? 'True-tab-no-business'
                          : 'False-tab-no-business'
                      }
                    >
                      <h4>CONNECT</h4>
                      {!!business._id && connections.received.length > 0 && (
                        <p className={'new-messages'}>
                          {connections.received.length}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}

              {(listCategory === 'business' || listCategory === 'personal') && (
                <div className="message-list-container">
                  {connections[listCategory].length ? (
                    connections[listCategory].map((connection) => (
                      <InboxList
                        {...this.props}
                        connection={connection}
                        category={listCategory}
                      />
                    ))
                  ) : (
                    <div>
                      {listCategory === 'business' && (
                        <p className="no-message-business">
                          No messages yet! Once you start connecting with
                          potential customers, your conversations will appear
                          here.
                        </p>
                      )}
                      {listCategory === 'personal' && (
                        <p>
                          No messages yet! Once you start connecting with
                          businesses, your conversations will appear here.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
              {listCategory === 'connect requests' && (
                <div className="message-list-container">
                  {business.id ? (
                    <div className="pending-outgoing-declined">
                      <h4
                        onClick={() => {
                          this.messageCategorySelection('received');
                        }}
                        onKeyDown={() => {
                          this.messageCategorySelection('received');
                        }}
                        className={
                          pendingCategory === 'received'
                            ? 'True-connect'
                            : 'False-connect'
                        }
                        role="presentation"
                      >
                        Received
                      </h4>
                      <h4
                        onClick={() => {
                          this.messageCategorySelection('sent');
                        }}
                        onKeyDown={() => {
                          this.messageCategorySelection('sent');
                        }}
                        className={
                          pendingCategory === 'sent'
                            ? 'True-connect'
                            : 'False-connect'
                        }
                        role="presentation"
                      >
                        Pending
                      </h4>
                      <h4
                        onClick={() =>
                          this.messageCategorySelection('declined')
                        }
                        onKeyDown={() =>
                          this.messageCategorySelection('declined')
                        }
                        className={
                          pendingCategory === 'declined'
                            ? 'True-connect'
                            : 'False-connect'
                        }
                        role="presentation"
                      >
                        Declined
                      </h4>
                    </div>
                  ) : (
                    <div className="pending-outgoing-declined">
                      <h4
                        onClick={() =>
                          this.messageCategorySelection('received')
                        }
                        onKeyDown={() =>
                          this.messageCategorySelection('received')
                        }
                        className={
                          pendingCategory === 'received'
                            ? 'True-connect-no-business'
                            : 'False-connect-no-business'
                        }
                        role="presentation"
                      >
                        Received
                      </h4>
                      <h4
                        onClick={() => this.messageCategorySelection('sent')}
                        onKeyDown={() => this.messageCategorySelection('sent')}
                        className={
                          pendingCategory === 'sent'
                            ? 'True-connect-no-business'
                            : 'False-connect-no-business'
                        }
                        role="presentation"
                      >
                        Pending
                      </h4>
                    </div>
                  )}

                  <div className="connect-requests">
                    {connections[pendingCategory].length ? (
                      connections[pendingCategory].map((connection) => {
                        const profiles = this.determineProfiles(connection);
                        return (
                          <ConnectRequest
                            {...this.props}
                            otherProfile={profiles[0]}
                            userProfile={profiles[1]}
                            category={pendingCategory}
                            connection={connection}
                          />
                        );
                      })
                    ) : (
                      <div className="no-connect-requests-text">
                        <p>All Caught Up!</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="connect-container desktop">
              <div className="Connect-number-flex">
                <h4 className="Connect-Tab">CONNECT REQUESTS</h4>

                {!!business._id && connections.received.length > 0 && (
                  <p className={'new-messages'}>
                    {connections.received.length}
                  </p>
                )}
              </div>
              <div className="pending-outgoing-declined">
                {!!business._id && (
                  <h4
                    onClick={() => this.messageCategorySelection('received')}
                    onKeyDown={() => this.messageCategorySelection('received')}
                    className={
                      pendingCategory === 'received'
                        ? 'True-connect'
                        : 'False-connect'
                    }
                    role="presentation"
                  >
                    Received
                  </h4>
                )}
                <h4
                  onClick={() => this.messageCategorySelection('sent')}
                  onKeyDown={() => this.messageCategorySelection('sent')}
                  className={
                    pendingCategory === 'sent'
                      ? 'True-connect'
                      : 'False-connect'
                  }
                  role="presentation"
                >
                  Pending
                </h4>
                <h4
                  onClick={() => this.messageCategorySelection('declined')}
                  onKeyDown={() => this.messageCategorySelection('declined')}
                  className={
                    pendingCategory === 'declined'
                      ? 'True-connect'
                      : 'False-connect'
                  }
                  role="presentation"
                >
                  Declined
                </h4>
              </div>

              <div className="connect-requests">
                {connections[pendingCategory].length ? (
                  connections[pendingCategory].map((connection) => {
                    const profiles = this.determineProfiles(connection);
                    return (
                      <ConnectRequest
                        {...this.props}
                        otherProfile={profiles[0]}
                        userProfile={profiles[1]}
                        category={pendingCategory}
                        connection={connection}
                      />
                    );
                  })
                ) : (
                  <div className="no-connect-requests-text">
                    <p>All Caught Up!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }

  render() {
    return this.businessCheckRender();
  }
}

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(withRouter(InboxPage));
