/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'semantic-ui-react';
import { loginUser } from '../../../actions/userActions';
import { connect } from 'react-redux';
import { renderInput, email, required } from '../utils/formValidations';

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    validLogin: true,
    message: '',
  };

  renderForm = () => {
    const { handleInput } = this;

    return (
      <div>
        <Field
          name="email"
          component={renderInput}
          type="email"
          placeholder="Email"
          validate={[email, required]}
          onChange={handleInput}
        />
        <Field
          name="password"
          component={renderInput}
          type="password"
          placeholder="Password"
          validate={[required]}
          onChange={handleInput}
        />
      </div>
    );
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    // const { loginUser } = this.props.userActions;
    this.props.dispatch(loginUser(email, password));
  };

  render() {
    const { pristine, user, submitting } = this.props;
    const { submit, renderForm } = this;
    return (
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{renderForm()}</div>
        {user.loginMessage && (
          <div
            style={{
              color: `${!user.checking ? 'red' : '#808080'}`,
              paddingBottom: '5px',
            }}
          >
            {user.loginMessage}
          </div>
        )}
        <Button
          positive
          type="submit"
          disabled={pristine || submitting}
          fluid
          onClick={submit}
        >
          Login
        </Button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

// eslint-disable-next-line
LoginForm = reduxForm({
  form: 'login', // a unique identifier for this form
})(LoginForm);

export default connect(mapStateToProps)(LoginForm);
