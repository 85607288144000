/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import './MyReviewsPage.css';

export default class ReviewsAboutMeView extends Component {
  state = {
    messagedPeople: [],
    fullReview: false,
    recommendation: '',
  };

  componentDidMount() {
    this.buttonCheck();
  }

  toggle = () => {
    this.setState((prevstate) => ({
      fullReview: !prevstate.fullReview,
    }));
  };

  buttonCheck = () => {
    if (this.props.review.recommendation === 'Yes') {
      this.setState({
        yes: true,
      });
    } else if (this.props.review.recommendation === 'No') {
      this.setState({
        no: true,
      });
    } else if (this.props.review.recommendation === 'Neutral') {
      this.setState({
        neutral: true,
      });
    }
  };

  render() {
    const { img, businessName, review } = this.props.review;
    return (
      <div className="review-list">
        <img className="review-picture" src={img} alt="review pic" />
        <div className="review-flex-right">
          <div className="review-text">
            <div className="review-title-date-flex">
              <h5>{businessName}</h5>
              <p>06/20/10</p>
            </div>

            <p>{review}</p>

            {this.state.fullReview ? (
              <p
                className="close-review"
                onClick={this.toggle}
                onKeyDown={this.toggle}
                role="presentation"
              >
                Close review
              </p>
            ) : (
              <p
                className="view-review"
                onClick={this.toggle}
                onKeyDown={this.toggle}
                role="presentation"
              >
                View full review
              </p>
            )}

            {this.state.fullReview && (
              <div className="recommend-choice">
                <h5>Would you recommend this service provider?</h5>
                <div className="recommend-choice-flex">
                  <div
                    className={
                      this.state.yes
                        ? ' recommend-button-true'
                        : 'recommend-button-false'
                    }
                  >
                    <p>Yes</p>
                  </div>
                  <div
                    className={
                      this.state.neutral
                        ? ' recommend-button-true'
                        : 'recommend-button-false'
                    }
                  >
                    <p>Neutral</p>
                  </div>
                  <div
                    className={
                      this.state.no
                        ? ' recommend-button-true'
                        : 'recommend-button-false'
                    }
                  >
                    <p>No</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
