import {
  FETCH_DREAM_TEAM_REQUESTS,
  FETCH_DREAM_TEAM_REQUESTS_SUCCESS,
  FETCH_DREAM_TEAM_REQUESTS_FAILED,
  DREAM_TEAM_SEND_REQUEST,
  DREAM_TEAM_SEND_REQUEST_SUCCESS,
  DREAM_TEAM_SEND_REQUEST_FAILED,
  DREAM_TEAM_RESPONSE,
  DREAM_TEAM_RESPONSE_SUCCESS,
  DREAM_TEAM_RESPONSE_FAILED,
  UPDATE_DREAM_TEAM,
  UPDATE_DREAM_TEAM_SUCCESS,
  UPDATE_DREAM_TEAM_FAILED,
  REVOKE_APPROVED_DREAM_TEAM,
  REVOKE_APPROVED_DREAM_TEAM_FAILED,
  REVOKE_APPROVED_DREAM_TEAM_SUCCESS,
  CANCEL_DREAM_TEAM_REQUEST,
  CANCEL_DREAM_TEAM_REQUEST_SUCCESS,
  CANCEL_DREAM_TEAM_REQUEST_FAILED,
  LOGOUT_DONE,
  SET_DREAM_TEAM_REQUESTS,
  CLEAR_DREAM_TEAM_REQUESTS,
} from '../actions/types';

const dreamTeamRequests = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DREAM_TEAM_REQUESTS:
      return {
        ...state,
        fetchingRequests: true,
      };
    case FETCH_DREAM_TEAM_REQUESTS_SUCCESS:
      return {
        ...action.payload.requests,
        success: true,
        fetchingRequests: false,
      };
    case FETCH_DREAM_TEAM_REQUESTS_FAILED:
      return {
        ...state,
        fetchingRequests: false,
      };
    case DREAM_TEAM_SEND_REQUEST:
      return {
        ...state,
        success: true,
        message: 'Sending...',
        sendingRequest: true,
      };
    case DREAM_TEAM_SEND_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        success: true,
        message: 'Request Sent!',
        sendingRequest: false,
      };
    case DREAM_TEAM_SEND_REQUEST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        success: false,
        sendingRequest: false,
      };
    case DREAM_TEAM_RESPONSE:
      return {
        ...state,
        sendingResponse: true,
      };
    case DREAM_TEAM_RESPONSE_SUCCESS:
      return {
        ...action.payload.requests,
        sendingResponse: false,
      };
    case DREAM_TEAM_RESPONSE_FAILED:
      return {
        ...action.payload.message,
        sendingResponse: false,
      };
    case UPDATE_DREAM_TEAM:
      return {
        ...state,
        updatingDreamTeam: true,
      };
    case UPDATE_DREAM_TEAM_SUCCESS:
      return {
        ...action.payload.requests,
        updatingDreamTeam: false,
      };
    case UPDATE_DREAM_TEAM_FAILED:
      return {
        ...state,
        message: action.payload.message,
        updatingDreamTeam: false,
      };
    case REVOKE_APPROVED_DREAM_TEAM:
      return {
        ...state,
        revoking: true,
      };
    case REVOKE_APPROVED_DREAM_TEAM_SUCCESS:
      return {
        ...action.payload.requests,
        revoking: false,
      };
    case REVOKE_APPROVED_DREAM_TEAM_FAILED:
      return {
        ...action.payload.message,
        revoking: false,
      };
    case CANCEL_DREAM_TEAM_REQUEST:
      return {
        ...state,
        canceling: true,
      };
    case CANCEL_DREAM_TEAM_REQUEST_SUCCESS:
      return {
        ...action.payload.requests,
        canceling: false,
      };
    case CANCEL_DREAM_TEAM_REQUEST_FAILED:
      return {
        ...action.payload.message,
        canceling: false,
      };
    case SET_DREAM_TEAM_REQUESTS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_DREAM_TEAM_REQUESTS:
      return {
        dreamTeam: [],
        requestsAccepted: [],
        requestsPendingReceived: [],
        requestsPendingSent: [],
      };
    case LOGOUT_DONE:
      return {
        dreamTeam: [],
        requestsAccepted: [],
        requestsPendingReceived: [],
        requestsPendingSent: [],
      };
    default:
      return state;
  }
};

export default dreamTeamRequests;
