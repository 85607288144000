/* eslint-disable  no-use-before-define */
import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_DONE,
  UPDATE_USER,
  UPDATE_FAILED,
  UPDATE_SUCCESS,
  UPDATE_BUSINESS_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILED,
  UPDATE_USER_FAVORITES,
  UPDATE_USER_FAVORITES_SUCCESS,
  UPDATE_USER_FAVORITES_FAILED,
  UPDATE_USER_NOTIFICATIONS_CHECK,
  UPDATE_USER_NOTIFICATIONS_CHECK_SUCCESS,
  UPDATE_USER_NOTIFICATIONS_CHECK_FAILED,
  UPDATE_REDEEM_COUNT,
  UPDATE_REDEEM_COUNT_SUCCESS,
  UPDATE_REDEEM_COUNT_FAILED,
  SET_USER,
  UPDATE_USER_PHOTO,
  UPDATE_USER_PHOTO_SUCCESS,
  UPDATE_USER_PHOTO_FAILED,
} from './types';
import { getHeaderOptions, getRefreshToken } from '../util';
import {
  notificationsCheck,
  updateUserPhoto,
  fetchCurrentUser,
  loginAttempt,
  verifyUserService,
  editUserService,
  changePasswordService,
  resetPasswordService,
  loginFromConnectionEmailService,
  loginFromRestPasswordEmailService,
  updateUserService,
} from '../services';

export const fetchUser = () => async(dispatch) => {
  dispatch({ type: FETCH_USER });
  const refreshToken = await getRefreshToken();
  const fetchUserResults = await fetchCurrentUser(refreshToken);

  if (fetchUserResults.success) {
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: {
        accessToken: fetchUserResults.accessToken,
        user: fetchUserResults.user,
        refreshToken: fetchUserResults.refreshToken,
      },
    });
  } else {
    dispatch({ type: FETCH_USER_FAILED, payload: {} });
  }
};

export const loginUser = (email, password) => async(dispatch) => {
  dispatch({ type: LOGIN_STARTED });
  const loginResults = await loginAttempt(email, password);
  const actionType = loginResults.success ? LOGIN_SUCCESS : LOGIN_FAILED;
  dispatch({ type: actionType, payload: loginResults });
};

export const logoutUser = () => async(dispatch) => {
  dispatch({ type: LOGOUT });

  dispatch({ type: LOGOUT_DONE });
};

export const verifyUser = (userId) => async(dispatch) => {
  dispatch({ type: VERIFY_USER });
  const res = await verifyUserService(userId);
  const actionType = res.success ? VERIFY_USER_SUCCESS : VERIFY_USER_FAILED;
  const payload = res;

  dispatch({ type: actionType, payload });
};

export const updateUser = (id, updates) => async(dispatch) => {
  dispatch({ type: UPDATE_USER });
  const options = await getHeaderOptions();
  const res = await editUserService(id, updates, options);
  const actionType = res.success ? UPDATE_SUCCESS : UPDATE_FAILED;
  dispatch({ type: actionType, payload: res });
  if (res.user.business) {
    const user = {
      _id: id,
      firstName: updates.firstName,
      lastName: updates.lastName,
      profilePic: updates.profilePic,
    };
    dispatch({
      type: UPDATE_BUSINESS_SUCCESS,
      payload: { business: { user } },
    });
  }
};

export const changePassword = (id, original, newPassword) => async(
  dispatch,
) => {
  dispatch({ type: CHANGE_PASSWORD });
  const options = await getHeaderOptions();
  const res = await changePasswordService(id, original, newPassword, options);

  if (res.success) {
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: res });
  } else {
    dispatch({ type: CHANGE_PASSWORD_FAILED, payload: res });
  }
};

export const resetPassword = (id, newPassword) => async(dispatch) => {
  dispatch({ type: CHANGE_PASSWORD });
  const res = await resetPasswordService(id, newPassword);

  if (res.success) {
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: res });
  } else {
    dispatch({ type: CHANGE_PASSWORD_FAILED, payload: res });
  }
};

export const updateUserFavorites = (updatedUser, success, message) => async(
  dispatch,
) => {
  dispatch({ type: UPDATE_USER_FAVORITES });
  if (success) {
    dispatch({ type: UPDATE_USER_FAVORITES_SUCCESS, payload: updatedUser });
  } else {
    dispatch({ type: UPDATE_USER_FAVORITES_FAILED, payload: { message } });
  }
};

export const loginUserFromConnectionEmail = (_id) => async(dispatch) => {
  dispatch({ type: FETCH_USER });
  const res = await loginFromConnectionEmailService(_id);

  const actionType = res.success ? FETCH_USER_SUCCESS : LOGIN_FAILED;
  dispatch({ type: actionType, payload: res });
};

export const loginUserFromResetPasswordEmail = (_id) => async(dispatch) => {
  dispatch({ type: FETCH_USER });
  const res = await loginFromRestPasswordEmailService(_id);

  const actionType = res.success ? FETCH_USER_SUCCESS : LOGIN_FAILED;
  dispatch({ type: actionType, payload: res });
};

export const updateNotificationsCheck = (user) => async(dispatch) => {
  dispatch({ type: UPDATE_USER_NOTIFICATIONS_CHECK });
  const options = await getHeaderOptions();
  const results = await notificationsCheck(user, options);

  if (results.success) {
    dispatch(updateNotificationsCheckSuccess(results.user));
  } else {
    dispatch(updateNotificationsCheckFailed());
  }
};

const updateNotificationsCheckSuccess = (user) => async(dispatch) =>
  dispatch({
    type: UPDATE_USER_NOTIFICATIONS_CHECK_SUCCESS,
    payload: user,
  });

const updateNotificationsCheckFailed = () => async(dispatch) =>
  dispatch({
    type: UPDATE_USER_NOTIFICATIONS_CHECK_FAILED,
  });

export const updateUserRedeemCount = (updatedUser) => async(dispatch) => {
  dispatch({ type: UPDATE_REDEEM_COUNT });
  const options = await getHeaderOptions();
  const res = await updateUserService(updatedUser, options);

  if (res.success) {
    dispatch(updateRedeemCountSuccess(res.user));
  } else {
    dispatch(updateRedeemCountFailed());
  }
};

const updateRedeemCountSuccess = (user) => async(dispatch) =>
  dispatch({
    type: UPDATE_REDEEM_COUNT_SUCCESS,
    payload: { user },
  });

const updateRedeemCountFailed = () => async(dispatch) =>
  dispatch({
    type: UPDATE_REDEEM_COUNT_FAILED,
  });

export const setUser = (user) => async(dispatch) => {
  dispatch({
    type: SET_USER,
    payload: { user },
  });
};

export const updateUserPhotoAction = (userId, photoUpdates) => async(
  dispatch,
) => {
  dispatch({ type: UPDATE_USER_PHOTO });
  const options = await getHeaderOptions();
  const updatePhotoResults = await updateUserPhoto(
    userId,
    photoUpdates,
    options,
  );

  if (updatePhotoResults.success) {
    dispatch(updateUserPhotoActionSuccess(updatePhotoResults.user));
  } else {
    dispatch(updateUserPhotoActionFailed(updatePhotoResults.message));
  }
};

const updateUserPhotoActionSuccess = (user) => async(dispatch) =>
  dispatch({
    type: UPDATE_USER_PHOTO_SUCCESS,
    payload: { user },
  });

const updateUserPhotoActionFailed = (err) => async(dispatch) =>
  dispatch({
    type: UPDATE_USER_PHOTO_FAILED,
    payload: { err },
  });
