/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import DreamTeamMember from './DreamTeamMember';
import SearchResultsContainer from './SearchResultsContainer';
import BusinessesApproved from './BusinessesApproved/BusinessesApproved';
import AwaitingApproval from './AwaitingApproval/AwaitingApproval';
import PendingRequests from './PendingRequests/PendingRequests';
import EmptyResults from './Empty';
import { Loading } from '../../../components';
import OutsideClickDetect from '../../../components/OutsideClickDetect';

// styled components
import {
  Container,
  BoldText,
  RegText,
  InputLabels,
  BusinessDetailsInput,
} from '../Styles';

// services
import {
  searchBusinessByName,
  removeUserFromRevokedBusinessDT,
  socketServices,
} from '../../../services';

// util
import { notificationConstructor } from '../../../util';
class DreamTeam extends Component {
  state = {
    team: this.props.dreamTeamRequests.dreamTeam,
    teamLimitReached: this.props.dreamTeamRequests.dreamTeam
      ? this.props.dreamTeamRequests.dreamTeam.length === 5
      : false,
    searchResults: [],
    searchAttempt: false,
    popUpMessage: '',
    showMessage: false,
    loaded: true,
    searchTerm: '',
    success: true,
    requestToId: null,
  };

  componentDidMount = () => {
    socketServices.listenDtPage(this);
  };

  componentWillUnmount = () => {
    socketServices.removeDtListeners();
  };

  searchForBusiness = (event) => {
    const searchTerm = event.target.value;
    this.setState(
      {
        searchTerm,
      },
      () => {
        if (searchTerm.trim().length > 0) {
          searchBusinessByName(searchTerm)
            .then((results) => {
              this.setState({
                searchResults: results.businesses,
                searchAttempt: true,
                showMessage: false,
                popUpMessage: '',
                requestToId: null,
                success: true,
              });
            })
            .catch(() => {
              this.setState({
                searchResults: [],
                searchAttempt: false,
                showErrorMessage: true,
                errorMessage:
                  'There was an error searching for businesses. Please contact support if this error persists.', // eslint-disable-line
              });
            });
        } else {
          this.setState({
            searchResults: [],
            searchAttempt: false,
            showMessage: false,
            popUpMessage: '',
            requestToId: null,
            success: true,
          });
        }
      },
    );
  };

  clickAwayFromSearchResults = () => {
    this.setState({
      searchResults: [],
      searchAttempt: false,
      showMessage: false,
      popUpMessage: '',
      requestToId: null,
    });
  };

  // remove business from dream team and back into approved
  removeTeamMember = (chosenBusiness, requestByUser, request) => {
    // this confirmation will eventually change, but easy for now
    if (
      // eslint-disable-next-line
      window.confirm(
        'Are you sure you want to remove this business from your Dream Team',
      )
    ) {
      const { business, businessActions } = this.props;
      const businessType = requestByUser ? 'requester' : 'requested';
      if (requestByUser) {
        request.inRequestByDreamTeam = false;
      } else {
        request.inRequestToDreamTeam = false;
      }

      delete business.dreamTeam[chosenBusiness._id];

      const notification = notificationConstructor(
        { ...business, type: 'business' },
        { chosenBusiness, type: 'business' },
        'dream team remove',
      );
      businessActions.updateBusiness(
        business._id,
        { dreamTeam: business.dreamTeam },
        business.username,
      );
      socketServices.emitDtRemove(
        request,
        business,
        businessType,
        notification,
      );
    }
  };

  // moving one of the approved into your dream team (same basic process as removal)
  addTeamMember = (chosenBusiness, requestByUser, request) => {
    if (!this.state.teamLimitReached) {
      const { business } = this.props;
      const businessType = requestByUser ? 'requester' : 'requested';
      business.dreamTeam = business.dreamTeam || {};
      business.dreamTeam[chosenBusiness._id] = {
        _id: chosenBusiness._id,
        name: chosenBusiness.name,
        profilePic: chosenBusiness.profilePic,
        username: chosenBusiness.username,
      };

      if (requestByUser) {
        request.inRequestByDreamTeam = true;
      } else {
        request.inRequestToDreamTeam = true;
      }
      const notification = notificationConstructor(
        { ...business, type: 'business' },
        { chosenBusiness, type: 'business' },
        'dream team add',
      );
      socketServices.emitDtAdd(request, business, businessType, notification);
    } else {
      /* eslint-disable-next-line */
      alert(
        'You have reached the max Dream Team size, please remove one member before adding a new one.' // eslint-disable-line
      );
    }
  };

  // sending request to business
  sendRequest = (requestedBusiness) => {
    const { business } = this.props;
    const request = {
      requestById: business._id,
      requestToId: requestedBusiness._id,
      businesses: {
        [business._id]: {
          _id: business._id,
          name: business.name,
          username: business.username,
          profilePic: business.profilePic,
        },
        [requestedBusiness._id]: {
          _id: requestedBusiness._id,
          name: requestedBusiness.name,
          username: requestedBusiness.username,
          profilePic: requestedBusiness.profilePic,
        },
      },
    };
    this.setState(
      {
        showMessage: true,
      },
      () => {
        const notification = notificationConstructor(
          { ...business, type: 'business' },
          { ...requestedBusiness, type: 'business' },
          'dream team request',
        );
        socketServices.emitDtRequest(request, notification, this);
      },
    );
  };

  // accepting or delcining request
  respondRequest = (request, response) => {
    const updatedRequest = {
      ...request,
      status: response,
    };
    let notificationType = 'dream team decline';
    if (response === 'approved') {
      updatedRequest.inRequestedDreamTeam = false;
      updatedRequest.inRequesterDreamTeam = false;
      notificationType = 'dream team accept';
    }
    const notification = notificationConstructor(
      { ...this.props.business, type: 'business' },
      { ...request.businesses[request.requestById], type: 'business' },
      notificationType,
    );
    socketServices.emitDtRequestResponse(updatedRequest, notification);
  };

  revokeApproval = async(request) => {
    if (
      // eslint-disable-next-line
      window.confirm(
        'Are you sure you want to revoke your Dream Team approval to this business?',
      )
    ) {
      const { business } = this.props;
      const { businesses, requestById, requestToId } = request;
      const requestByUser = business._id === request.requestById;
      const businessType = requestByUser ? 'requester' : 'requested';
      const revokedBusiness = requestByUser
        ? businesses[requestToId]
        : businesses[requestById];
      const inRevokedBusinessDreamTeam =
        (requestByUser && request.inRequestedDreamTeam) ||
        (!requestByUser && request.inRequesterDreamTeam);
      request.status = 'denied';
      request.inRequesterDreamTeam = false;
      request.inRequestedDreamTeam = false;
      if (inRevokedBusinessDreamTeam) {
        await removeUserFromRevokedBusinessDT(
          business._id,
          revokedBusiness._id,
        );
      }
      const notification = notificationConstructor(
        { ...business, type: 'business' },
        { ...revokedBusiness, type: 'business' },
        'dream team revoke',
      );
      socketServices.emitDtRevoke(request, businessType, notification);
    }
  };

  // canceling a pending request that user sent (deletes the request on backend)
  cancelRequest = (request) => {
    const notification = notificationConstructor(
      { ...this.props.business, type: 'business' },
      { ...request.businesses[request.requestToId], type: 'business' },
      'dream team cancel',
    );
    socketServices.emitDtRequestCancel(request, notification);
  };

  goToProfile = (business) => {
    const { history } = this.props;
    history.push({
      pathname: `/business/${business.name}`,
      state: {
        business,
      },
    });
  };

  render() {
    const {
      searchTerm,
      searchResults,
      searchAttempt,
      showMessage,
      popUpMessage,
      loaded,
      success,
      requestToId,
    } = this.state;
    const { dreamTeamRequests, business } = this.props;

    return (
      <div>
        {loaded ? (
          <Container style={{ marginTop: '-6%' }}>
            <BoldText
              className="dream-team-header"
              style={{ color: '#999', marginBottom: '3.5%' }}
            >
              MY DREAM TEAM
            </BoldText>
            <RegText style={{ color: '#999', marginBottom: '3%' }}>
              Choose up to 5 other businesses you work with to feature on your
              business profile.
            </RegText>
            <Container style={{ marginBottom: '3%' }}>
              {/* Dream Team Members Displayed Here */}
              {dreamTeamRequests.dreamTeam.length > 0 ? (
                dreamTeamRequests.dreamTeam.map((teamMemberRequest) => {
                  const {
                    businesses,
                    requestById,
                    requestToId,
                  } = teamMemberRequest;
                  const requestByUser = business._id === requestById;
                  const businessData = requestByUser
                    ? businesses[requestToId]
                    : businesses[requestById];
                  return (
                    <DreamTeamMember
                      business={businessData}
                      requestByUser={requestByUser}
                      request={teamMemberRequest}
                      remove={this.removeTeamMember}
                      goToProfile={this.goToProfile}
                    />
                  );
                })
              ) : (
                <div className="empty-dt">
                  No members yet. Start searching for businesses to add below!
                </div>
              )}
            </Container>
            <InputLabels>ADD A BUSINESS</InputLabels>
            {/* Search Input Here */}
            <OutsideClickDetect outsideClick={this.clickAwayFromSearchResults}>
              <BusinessDetailsInput
                placeholder="Business Name"
                name="business"
                value={searchTerm}
                onChange={this.searchForBusiness}
                onFocus={this.searchForBusiness}
              />
              <hr
                style={{
                  borderWidth: '0.05em',
                  marginTop: '1em',
                  marginBottom: '1em',
                  height: '1px',
                  background: '#DCDCDC',
                }}
              />
              {/* Search results will be mapped here */}
              {searchAttempt &&
                (searchResults.length ? (
                  <SearchResultsContainer
                    results={searchResults}
                    sendRequest={this.sendRequest}
                    userBusinessId={business._id}
                    showMessage={showMessage}
                    popUpMessage={popUpMessage}
                    requestToId={requestToId}
                    success={success}
                  />
                ) : (
                  <EmptyResults text={'No results found'} />
                ))}
            </OutsideClickDetect>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                marginTop: '5%',
              }}
            >
              <section
                className="approved-businesses"
                style={{ marginBottom: '3em' }}
              >
                <BoldText style={{ marginBottom: '0.5em', color: '#808080' }}>
                  BUSINESSES YOU&apos;VE APPROVED
                </BoldText>
                <RegText style={{ color: '#C0C0C0', marginBottom: '8%' }}>
                  You are approved to feature these businesses on your profile.
                </RegText>
                {/* Displaying approved businesses here */}
                {dreamTeamRequests.requestsAccepted.length ? (
                  dreamTeamRequests.requestsAccepted.map((request) => {
                    const { businesses, requestToId, requestById } = request;
                    const currentlyFeaturingYou =
                      business._id === request.requestById
                        ? request.inRequestToDreamTeam
                        : request.inRequestByDreamTeam;
                    const businessData =
                      business._id === requestById
                        ? businesses[requestToId]
                        : businesses[requestById];
                    const requestByUser = business._id === requestById;
                    return (
                      <BusinessesApproved
                        business={businessData}
                        currentlyFeaturingYou={currentlyFeaturingYou}
                        revoke={this.revokeApproval}
                        addToTeam={this.addTeamMember}
                        requestByUser={requestByUser}
                        request={request}
                        goToProfile={this.goToProfile}
                      />
                    );
                  })
                ) : (
                  <EmptyResults
                    style={{ marginTop: '5%' }}
                    text={'No Approved Businesses Left. Look Up More to Add!'}
                  />
                )}
              </section>
              <section className="awaiting-approval">
                <BoldText style={{ color: '#00BE6E', marginBottom: '1em' }}>
                  REQUESTS AWAITING YOUR APPROVAL
                </BoldText>
                {/* Requests awaiting user's approval here */}
                {dreamTeamRequests.requestsPendingReceived.length ? (
                  dreamTeamRequests.requestsPendingReceived.map((request) => {
                    const { requestById, businesses } = request;
                    return (
                      <AwaitingApproval
                        request={request}
                        respondRequest={this.respondRequest}
                        goToProfile={this.goToProfile}
                        business={businesses[requestById]}
                      />
                    );
                  })
                ) : (
                  <EmptyResults text={'All Caught Up Here!'} />
                )}
              </section>
              <section
                className="pending-requests"
                style={{ marginBottom: '5%' }}
              >
                <BoldText style={{ color: 'silver', marginBottom: '1em' }}>
                  YOUR PENDING REQUESTS
                </BoldText>
                {/* Pending requests sent by user here */}
                {dreamTeamRequests.requestsPendingSent.length ? (
                  dreamTeamRequests.requestsPendingSent.map((request) => {
                    const { requestToId } = request;
                    const requestTo = request.businesses[requestToId];
                    return (
                      <PendingRequests
                        request={request}
                        cancel={this.cancelRequest}
                        business={requestTo}
                        goToProfile={this.goToProfile}
                      />
                    );
                  })
                ) : (
                  <EmptyResults text={'No Requests Pending Right Now!'} />
                )}
              </section>
            </div>
          </Container>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  dreamTeamRequests: state.dreamTeamRequests,
});

export default connect(mapStateToProps)(DreamTeam);
