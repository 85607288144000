/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, TextArea, Modal, Icon } from 'semantic-ui-react';
import dateFnsFormat from 'date-fns/format';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import ScrollToBottom from 'react-scroll-to-bottom';
import 'react-day-picker/lib/style.css';

import { socketServices } from '../../../../services';
import { notificationConstructor, getDate, formatDate } from '../../../../util';

class QuoteForm extends Component {
  state = {
    quoteInfo: { ...this.props.quotes.saved },
    items: this.props.quotes.saved.items
      ? [...this.props.quotes.saved.items]
      : [],
    date: this.props.quotes.saved.date
      ? this.props.quotes.saved.date
      : dateFnsFormat(new Date(), 'MM-dd-yyyy'),
    titleError: false,
    dateError: false,
    taskError: false,
    RateError: false,
    hoursError: false,
    priceError: false,
    quantityError: false,
    discountError: false,
    termsError: false,
    message: '',
    showMessage: false,
    editsMade: false,
    success: true,
    itemEmptyError: false,
    tempItems: [],
    modalState: false,
    addItemError: false,
  };

  changeDateHandler = (date) => {
    const newDate = getDate(date, 'MMDDYYYY');
    this.setState({
      date: newDate,
      showMessage: false,
      editsMade: true,
    });
  };

  notifySuccess = (message) =>
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyError = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  componentDidMount = () => {
    socketServices.listenQuoteForm(this);
    this.itemPropCheck();
  };

  componentWillUnmount = () => {
    socketServices.removeQuoteFormListeners();
  };

  itemPropCheck = () => {
    // this explains what the whole variable is being used for
    const quoteMissingItems = !this.props.quotes.saved.items;
    if (quoteMissingItems) {
      this.setState({
        items: [
          {
            id: Math.random(),
            item: '',
            price: 0,
            itemTotal: 0,
            quantity: 0,
            itemEmptyError: false,
          },
        ],
      });
    } else {
      this.setState({
        items: [...this.props.quotes.saved.items],
      });
    }
  };

  // componentDidUpdate = () => {
  //   console.log('quote form update props: ', this.props)
  //   const { connections } = this.props;
  //   const { message } = this.state;
  //   if (connections.updateQuoteMessage && (message !== connections.updateQuoteMessage)) {
  //     this.setState({
  //       message: connections.updateQuoteMessage
  //     });
  //   }
  // };

  // changes itme empty error property to true or false for the inputs
  emptyItemError = () => {
    const { items } = this.state;
    const temp = JSON.parse(JSON.stringify(items));
    for (let i = 0; i < temp.length; i++) {
      // eslint-disable-next-line
      const itemNameEmpty = temp[i].item.trim().length === 0; // tells why you're checking what item name is, also use trim in case of spaces
      if (itemNameEmpty) {
        temp[i].itemEmptyError = true;
      } else {
        temp[i].itemEmptyError = false;
      }
    }
    this.setState({
      items: temp,
    });
  };

  // Checks for an empty inputs in array
  emptyItemCheck = () => {
    const { items } = this.state;
    let itemInputEmpty = false;
    for (let i = 0; i < items.length; i++) {
      const itemNameEmpty = items[i].item.trim().length === 0;
      if (itemNameEmpty) {
        itemInputEmpty = true;
        this.setState({
          addItemError: true,
        });
        break;
      }
    }
    return itemInputEmpty;
  };

  closeModal = () => {
    const { tempItems, items } = this.state;
    if (JSON.stringify(tempItems) === JSON.stringify(items)) {
      this.setState({
        modalState: false,
        items: this.state.tempItems,
        addItemError: false,
      });
    } else {
      this.setState({
        modalState: false,
        items: this.state.tempItems,
        addItemError: false,
      });
      this.notifyError('Changes Dismissed');
    }
  };

  openModal = () => {
    const tempItems = JSON.parse(JSON.stringify(this.state.items));
    this.setState({
      modalState: true,
      addItemError: false,
      tempItems,
    });
  };

  saveItem = () => {
    // eslint-disable-next-line
    const hasEmptyItems = this.emptyItemCheck(); // this one is not as needed but just more examples of descriptive var names
    if (hasEmptyItems) {
      this.emptyItemError();
    } else {
      this.setState(
        {
          modalState: false,
        },
        () => {
          this.notifySuccess(
            'Items Updated! Remeber to hit save or send to customer',
          );
          this.updateItemtotal();
          this.emptyItemError();
        },
      );
    }
  };

  addItem = () => {
    const hasEmptyItems = this.emptyItemCheck();
    if (hasEmptyItems) {
      this.emptyItemError();
    } else {
      this.emptyItemError();
      this.setState((prevState) => ({
        items: [
          ...prevState.items,
          {
            id: Math.random(),
            item: '',
            price: 0,
            itemTotal: 0,
            quantity: 0,
            itemEmptyError: false,
          },
        ],
        editsMade: true,
        showMessage: false,
        addItemError: false,
      }));
    }
  };

  deleteItem = (id) => {
    const { items } = this.state;
    const tempItems = JSON.parse(JSON.stringify(items));
    const filteredItems = tempItems.filter((items) => items.id !== id);
    this.setState({
      items: filteredItems,
      editsMade: true,
      showMessage: false,
      addItemError: false,
    });
  };

  formSubmit = (status) => {
    const { editsMade, quoteInfo, items, date } = this.state;
    // const valid = this.validate();
    const valid = true;
    if ((editsMade && valid) || status === 'sent') {
      const {
        connections: { selectedConnection },
        connectionsActions,
        business,
        quotes,
      } = this.props;
      const connection = { ...selectedConnection.connection };
      const currentChat = `${connection.type}_${connection._id}`;
      quoteInfo.connection = {
        _id: connection._id,
        room: currentChat,
      };
      quoteInfo.byBusinessId = business._id;
      quoteInfo.forConsumerId = selectedConnection.connection.consumerId;
      quoteInfo.items = items;
      quoteInfo.itemSum = quoteInfo.itemSum || 0;
      quoteInfo.date = getDate(date, 'MMDDYYYY');
      quoteInfo.status = status;
      quoteInfo.read = false;
      quoteInfo.discount = 0;
      quoteInfo.createdOn = Date.now();

      const savedQuote = { ...quoteInfo };
      savedQuote.status = 'saved';
      const sentQuote = { ...savedQuote };
      let message = {};
      if (status === 'sent') {
        sentQuote.status = 'pending';
        message = {
          sender: {
            _id: business._id,
            username: business.username,
            type: 'business',
          },
          receiver: {
            _id: connection.consumerId,
            username: connection.users[connection.consumerId].username,
            type: 'consumer',
          },
          text: `${business.name} just sent you a quote! Hit the "View Quote" button to see it.`,
          timestamp: Date.now(),
          connection: {
            _id: connection._id,
            type: 'B2C',
          },
        };
        connection.lastMessage = message;
      }
      this.setState(
        {
          showMessage: true,
          editsMade: false,
          message: status === 'sent' ? 'Sending...' : 'Saving...',
        },
        async() => {
          let notification = {};
          if (status === 'sent') {
            const updatedSelectedConnection = { ...selectedConnection };
            updatedSelectedConnection.connection = { ...connection };
            await connectionsActions.setSelectedConnection(
              updatedSelectedConnection,
            );
            notification = notificationConstructor(
              { ...business, type: 'business' },
              { ...selectedConnection.otherProfile, type: 'consumer' },
              'quote sent',
              { _id: connection._id, type: connection.type },
            );
          }
          const createNewSentQuote =
            status === 'sent' &&
            (!quotes.sent._id || quotes.sent.status !== 'pending');
          const createNewSavedQuote = !quotes.saved._id;
          if (!createNewSentQuote && status === 'sent') {
            sentQuote._id = quotes.sent._id;
          } else {
            delete sentQuote._id;
          }
          socketServices.emitQuote({
            savedQuote,
            sentQuote,
            currentChat,
            status,
            notification,
            connection,
            createNewSentQuote,
            createNewSavedQuote,
          });
        },
      );
    } else {
      this.setState({
        errorText: 'These fields cannot be empty',
      });
    }
  };

  textInput = (e) => {
    const { quoteInfo } = this.state;
    this.setState({
      quoteInfo: {
        ...quoteInfo,
        [e.target.name]: e.target.value,
      },
      editsMade: true,
      message: '',
      showMessage: false,
      addItemError: false,
    });
  };

  // items
  itemInput = (e, idx) => {
    const { items } = this.state;
    const updatedItems = [...items];
    updatedItems[idx].item = e.target.value;
    this.setState({
      items: updatedItems,
      editsMade: true,
      message: '',
      showMessage: false,
      addItemError: false,
    });
  };

  handleQuantityChange = (e, idx) => {
    const { items } = this.state;
    const updatedItems = [...items];
    updatedItems[idx].quantity = e.target.value;
    updatedItems[idx].itemTotal =
      e.target.value * (updatedItems[idx].price || 0);
    this.setState({
      items: updatedItems,
      editsMade: true,
      message: '',
      showMessage: false,
    });
  };

  handlePriceChange = (e, idx) => {
    const { items } = this.state;
    const updatedItems = [...items];
    updatedItems[idx].price = e.target.value;
    updatedItems[idx].itemTotal =
      e.target.value * (updatedItems[idx].quantity || 0);
    this.setState({
      items: updatedItems,
      editsMade: true,
      showMessage: false,
    });
  };
  // items

  handleRateChange = (e) => {
    const updatedQuote = { ...this.state.quoteInfo };
    updatedQuote.rate = e.target.value;
    updatedQuote.taskTotal = updatedQuote.rate * updatedQuote.hours;
    if (e.target.validity.valid) {
      this.setState(
        () => ({
          quoteInfo: { ...updatedQuote },
          editsMade: true,
          message: '',
          showMessage: false,
        }),
        () => {
          this.updateSubtotal();
          this.updateTotal();
        },
      );
    }
  };

  handleHoursChange = (e) => {
    const updatedQuote = { ...this.state.quoteInfo };
    updatedQuote.hours = e.target.value;
    updatedQuote.taskTotal = updatedQuote.rate * updatedQuote.hours;
    this.setState(
      () => ({
        quoteInfo: { ...updatedQuote },
        editsMade: true,
        message: '',
        showMessage: false,
      }),
      () => {
        this.updateSubtotal();
        this.updateTotal();
      },
    );
  };

  handleDiscountChange = (e) => {
    const { quoteInfo } = this.state;
    const discount = e.target.value;
    this.setState(
      () => ({
        quoteInfo: {
          ...quoteInfo,
          discount,
        },
        editsMade: true,
        message: '',
        showMessage: false,
      }),
      () => {
        this.updateSubtotal();
        this.updateTotal();
      },
    );
  };

  updateTotal = () => {
    const updatedQuote = { ...this.state.quoteInfo };
    updatedQuote.total =
      (this.state.subTotal || 0) - (updatedQuote.discount || 0);
    this.setState({
      quoteInfo: { ...updatedQuote },
    });
  };

  updateItemtotal = () => {
    const { items, quoteInfo } = this.state;
    const updatedQuote = { ...quoteInfo };
    let itemSum = 0;
    for (let i = 0; i < items.length; i++) {
      itemSum += items[i].itemTotal;
    }
    updatedQuote.itemSum = itemSum;
    this.setState(
      () => ({
        quoteInfo: updatedQuote,
      }),
      () => {
        this.updateSubtotal();
        this.updateTotal();
      },
    );
  };

  updateSubtotal = () => {
    const { quoteInfo } = this.state;
    const subTotal = (quoteInfo.itemSum || 0) + (quoteInfo.taskTotal || 0);
    quoteInfo.subTotal = subTotal;
    this.setState({
      quoteInfo,
    });
  };

  itemLength = () => {
    const { items } = this.state;
    let counter = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i].item.trim().length > 0) {
        counter++;
      }
    }
    if (counter === 1) {
      return counter + ' Item ';
    }

    return counter + ' Items ';
  };

  // validate = () => {
  //   const errorList = {
  //     titleError: !this.state.quoteInfo.title.length,
  //     dateError: !this.state.quoteInfo.date.length,
  //     hoursError: !this.state.quoteInfo.hours.length,
  //     rateError: !this.state.quoteInfo.rate.length,
  //     taskError: !this.state.quoteInfo.task.length,
  //     itemError: !this.state.quoteInfo.item.length,
  //     priceError: !this.state.quoteInfo.price.length,
  //     termsError: !this.state.quoteInfo.termsNotes.length,
  //   }

  //   const isValid = Object.values(errorList).every((error) => !error);
  //   !isValid && this.setState({
  //     ...errorList
  //   })

  //   return isValid;
  // };

  render() {
    const { quoteInfo, addItemError } = this.state;
    return (
      <div className="messages-right">
        <div className="Quote-Form">
          <Link
            to={{
              pathname: '/Messages',
              state: {
                ...this.props.connections.selectedConnection,
              },
            }}
          >
            <p>Back to conversation</p>
          </Link>
          <h1>Send a quote</h1>
          <Form id="Quote-form">
            <Form.Group>
              <Form.Input
                label="Title (optional)"
                name="title"
                error={this.state.titleError}
                value={quoteInfo.title}
                onChange={this.textInput}
                width={9}
              />
              <p className="Messages-Date-Picker-Label">Date</p>
              <DayPickerInput
                onDayChange={this.changeDateHandler}
                formatDate={formatDate}
                format="MM-dd-yyyy"
                placeholder={Date.now()}
                value={this.state.date}
                // parseDate={parseDate}
                dayPickerProps={{
                  disabledDays: {
                    before: new Date(),
                  },
                }}
                style={{
                  marginTop: '23px',
                  width: '36%',
                  marginLeft: '-19px',
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Task"
                name="task"
                error={this.state.taskError}
                value={quoteInfo.task}
                onChange={this.textInput}
                width={6}
              />
              <Form.Input
                label="Rate"
                name="rate"
                type="number"
                error={this.state.rateError}
                value={quoteInfo.rate}
                onChange={this.handleRateChange}
                width={3}
              />
              <Form.Input
                label="Hours"
                name="hours"
                type="number"
                error={this.state.hoursError}
                value={quoteInfo.hours}
                onChange={this.handleHoursChange}
                width={3}
              />
              <Form.Input
                readOnly
                label="Total"
                value={!quoteInfo.taskTotal ? '' : `$${quoteInfo.taskTotal}`}
                width={3}
              />
            </Form.Group>

            <div className="Items-itemsubtotal">
              <Button onClick={this.openModal}> {this.itemLength()}+</Button>
              <Modal
                open={this.state.modalState}
                closeOnDimmerClick={false}
                className="Modal-Items"
              >
                <Icon onClick={this.closeModal} name="close" />
                <Modal.Content>
                  <Modal.Description>
                    <Form id="Quote-Items">
                      <ScrollToBottom className={'item-container'}>
                        {this.state.items.map((item, idx) => (
                          <Form.Group key={item.id}>
                            <Form.Input
                              label={`Item ${idx + 1}`}
                              placeholder={
                                item.itemEmptyError ? "Can't be empty" : ''
                              }
                              name="item"
                              error={item.itemEmptyError}
                              value={item.item}
                              onChange={(e) => this.itemInput(e, idx)}
                              width={6}
                            />
                            <Form.Input
                              label="Price"
                              type="number"
                              name="price"
                              placeholder={0}
                              value={item.price > 0 ? item.price : ''}
                              onChange={(e) => this.handlePriceChange(e, idx)}
                              width={3}
                            />
                            <Form.Input
                              label="Quantity"
                              type="number"
                              name="quantity"
                              placeholder={0}
                              value={item.quantity > 0 ? item.quantity : ''}
                              onChange={(e) =>
                                this.handleQuantityChange(e, idx)
                              }
                              width={3}
                            />
                            <Form.Input
                              readonly
                              label="Total"
                              value={`$${item.itemTotal}`}
                              width={3}
                            />
                            {!(idx === 0) && (
                              <p
                                className="delete-item"
                                onClick={() => this.deleteItem(item.id)}
                                onKeyDown={() => this.deleteItem(item.id)}
                                role="presentation"
                              >
                                x
                              </p>
                            )}
                            {!(idx === 0) && (
                              <Button
                                className="delete-item-mobile"
                                onClick={() => this.deleteItem(item.id)}
                              >
                                Delete
                              </Button>
                            )}
                          </Form.Group>
                        ))}
                      </ScrollToBottom>
                    </Form>
                    <div>
                      <Button className="add-item" onClick={this.addItem}>
                        +
                      </Button>
                      {addItemError && (
                        <p className="item-error">
                          Please fill out all fields before adding a new item.
                        </p>
                      )}
                      <Button
                        className="cancel-items"
                        onClick={this.closeModal}
                      >
                        Cancel
                      </Button>
                      <Button className="save-items" onClick={this.saveItem}>
                        Save
                      </Button>
                    </div>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              <Form.Input
                readOnly
                label="Item Total"
                placeholder={0}
                value={!quoteInfo.itemSum ? '' : `$${quoteInfo.itemSum}`}
              />
            </div>
            <Form.Group className="Subtotal">
              <Form.Input
                readOnly
                label="Sub Total"
                placeholder={0}
                value={!quoteInfo.subTotal ? '' : `$${quoteInfo.subTotal}`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                className="Quote-textarea"
                control={TextArea}
                error={this.state.termsError}
                name="termsNotes"
                value={quoteInfo.termsNotes}
                onChange={this.textInput}
                label="Terms & Notes"
                width={15}
              />
            </Form.Group>
          </Form>

          <div className="Send-Customer">
            <Button onClick={() => this.formSubmit('sent')}>
              Send to customer
            </Button>
            <Button onClick={() => this.formSubmit('saved')} color="grey">
              Save Draft
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(withRouter(QuoteForm));
