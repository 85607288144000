import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_COUNT,
} from './types';
import { getNotifications } from '../services/apiRequests';
import { unReadNotifCount, sortNotificationsByTimestamp } from '../util';

export const fetchNotifications = (user) => async(dispatch) => {
  dispatch({ type: FETCH_NOTIFICATIONS });
  getNotifications(user._id, user.business?._id)
    .then((notifications) => {
      const unreadCount = unReadNotifCount(
        notifications,
        user.lastNotificationsCheck,
      );
      const list = sortNotificationsByTimestamp(notifications);
      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: { list, unreadCount },
      });
    })
    .catch(() => {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILED });
    });
};

export const setNotifications = (notifications) => async(dispatch) => {
  dispatch({ type: SET_NOTIFICATIONS, payload: { notifications } });
};

export const updateUnreadCount = () => async(dispatch) => {
  dispatch({ type: CLEAR_NOTIFICATIONS_COUNT });
};
