import moment from 'moment';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';

// gives you todays date
export const getDate = (date, format) => {
  let today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  today =
    format === 'MMDDYYYY'
      ? mm + '-' + dd + '-' + yyyy
      : yyyy + '-' + mm + '-' + dd;
  return today;
};

export const convertDate = (date, format) => {
  let today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  today =
    format === 'MMDDYYYY'
      ? mm + '-' + dd + '-' + yyyy
      : yyyy + '-' + mm + '-' + dd;
  return today;
};

export const convertDateSlash = (date, format) => {
  let today = new Date(date);
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  today =
    format === 'MMDDYYYY'
      ? mm + '/' + dd + '/' + yyyy
      : yyyy + '/' + mm + '/' + dd;
  return today;
};

export const getDateAndTime = () =>
  moment(new Date(), 'YYYY-MM-DD HH:mm').toDate();

export const sortByTimestamp = (list) =>
  list.sort(
    (a, b) => b.lastMessage.timestamp - a.lastMessage.timestamp,
  );

export const sortNotificationsByTimestamp = (notifications) =>
  notifications.sort(
    (a, b) => b.timestamp - a.timestamp,
  );

export const timeagoFormatter = (value, unit) => {
  if (unit === 'second' && value < 15) return 'just now';
  if (unit === 'second') return 'few seconds ago';
  if (unit === 'minute')
    return `${value} ${value === 1 ? 'minute' : 'minutes'} ago`;
  return `${value} ${value === 1 ? unit : unit + 's'} ago`;
};

export const timeagoFormatterMobile = (value, unit) =>
  `${value}${unit[0].toUpperCase()} ago`;

export const formatDate = (date, format, locale) =>
  dateFnsFormat(date, format, { locale });

export const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};
