/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import SortAndTags from './SortAndTags';
import { HorizontalCard } from '../../components';
import NoSearch from './No-Search-Results.jpg';

export default class ListingsList extends Component {
  state = {
    results: [],
    tags: [],
  };

  componentDidMount() {
    this.handleResults();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tags !== prevProps.tags) {
      this.setState({
        results: this.props.results,
        tags: this.props.tags,
      });
    }
  }

  clearSearch = () => {
    this.setState({
      results: [],
      tags: [],
    });
  };

  deleteTagHandler = (tagName) => {
    this.setState({
      tags: this.state.tags.filter((tag) => tag !== tagName),
    });
  };

  handleResults = () => {
    this.setState({
      results: this.props.results,
      tags: this.props.tags,
    });
  };

  render() {
    const { tags, results } = this.state;
    const length = results.length;

    return (
      <div className="sort-tags" id="search-results">
        <SortAndTags
          length={length}
          tags={tags}
          deleteTagHandler={this.deleteTagHandler}
          clearSearch={this.clearSearch}
        />
        <div className={results.length ? 'listings-list' : ''}>
          {results.length ? (
            results.map((business, index) => (
              <HorizontalCard
                openModal={this.props.openModal}
                openSignUp={this.props.openSignUp}
                user={this.props.user}
                index={index}
                businessCard={business}
                {...this.props}
              />
            ))
          ) : (
            <div className="no-listing">
              <img src={NoSearch} alt="no search" />
              <h2>No results found</h2>
              <p>
                Looks like there&apos;s nothing here. Try wider search terms or
                neighboring zipcodes
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
