import React from 'react';
import TimeAgo from 'react-timeago';
import { timeagoFormatter } from '../../util';

const NotificationPopup = ({ notification, notificationMessage }) => (
  <div>
    <section style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={notification.notificationFrom.profilePic}
        style={{ width: '60px', marginRight: '1em' }}
        alt="notification from profile pic"
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              marginRight: '3px',
              fontSize: '14px',
              marginLeft: '0px',
            }}
          >
            {notification.notificationFrom.name}
          </span>
          <p style={{ color: 'black', fontSize: '14px', display: 'contents' }}>
            {notificationMessage}
          </p>
        </div>
      </div>
      <TimeAgo
        date={notification.timestamp}
        formatter={timeagoFormatter}
        minPeriod={30}
        style={{
          marginLeft: '7px',
          border: 'none',
          backgroundColor: '#fff',
          color: '#999999',
          position: 'absolute',
          top: '6px',
          right: '25px',
          fontSize: '9px',
        }}
      />
      {/* <button style={{border:'none', backgroundColor: '#fff',
      marginLeft: '5em', color: '#DCDCDC'}}>Today</button> */}
    </section>
  </div>
);

export default NotificationPopup;
