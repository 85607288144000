/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { socketServices } from '../../services';
import { notificationConstructor } from '../../util';

export default class Star extends Component {
  state = {
    favorite: this.props.favorite,
  };

  componentDidMount = () => {
    const { businessViewed, favorites } = this.props;
    this.setState({
      favorite: favorites.favoriteBusinesses.get(businessViewed._id),
    });
  };

  componentDidUpdate = (prevProps) => {
    const { businessViewed, favorites } = this.props;
    const newBusiness = businessViewed._id !== prevProps.businessViewed._id;
    if (newBusiness) {
      this.setState({
        favorite: favorites.favoriteBusinesses.get(businessViewed._id),
      });
    }
  };

  favoriteUnfavorite = () => {
    const { user, businessViewed } = this.props;
    const { favorite } = this.state;
    let notification;
    if (favorite) {
      notification = notificationConstructor(
        { ...user, type: 'consumer' },
        { ...businessViewed, type: 'business' },
        'unfavorited',
      );
      socketServices.emitRemoveFromFavorites(
        businessViewed,
        notification,
        this,
      );
    } else {
      notification = notificationConstructor(
        { ...user, type: 'consumer' },
        { ...businessViewed, type: 'business' },
        'favorited',
      );
      socketServices.emitAddToFavorites(businessViewed, notification, this);
    }
  };

  render() {
    return (
      <svg
        onClick={this.favoriteUnfavorite}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        height="30px"
        viewBox="0 0 24 24"
        aria-labelledby="starIconTitle"
        stroke={this.state.favorite ? 'none' : 'black'}
        strokeWidth="1"
        strokeLinecap="square"
        strokeLinejoin="miter"
        fill={this.state.favorite ? '#ffcc00' : 'white'}
        color="#000"
      >
        <title id="starIconTitle">Star</title>
        {/* eslint-disable-next-line max-len */}
        <polygon points="12 17.844 6.183 20.902 7.294 14.425 2.588 9.838 9.092 8.893 12 3 14.908 8.893 21.412 9.838 16.706 14.425 17.817 20.902" />
      </svg>
    );
  }
}
