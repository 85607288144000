/* eslint-disable no-invalid-this */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Tag = ({ tag, deleteTagHandler }) => (
  <div className="tag">
    <section className="tag-section">
      {tag}
      <FontAwesomeIcon
        onClick={deleteTagHandler.bind(this, tag)}
        id="x-icon"
        icon={faTimes}
        style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer' }}
      />
    </section>
  </div>
);

export default Tag;
