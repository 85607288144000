import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { businessActions } from '../../actions';
import Modal from './Modal';
import ToggleSwitch from './ToggleSwitch';
import SearchBar from '../Home/SearchBar';
import { ParallaxImage } from '../../components';
import './subscription.css';
import validator from 'validator';
import SubsciptionList from './SubsciptionList';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Button } from 'semantic-ui-react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const Title = styled.h2`
  font-size: 2em;
`;

const Container = styled.div`
  margin: 0;
  padding: 0;
`;

const PricingInfoContainerMobile = styled.div`
  ul {
    list-style: none;
    border-bottom: 1px;
    width: 75% hr {
      width: 85%;
      position: relative;
      left: 1.7em;
      height: 1px;
      border: none;
      background-color: #c8c8c8;
      margin: 0.7em 0;
    }
    li {
      padding: 10px 0px;
      color: #a5a5a5;
      font-weight: bold;
    }
  }
  button {
    padding: 10px 25px;
    background-color: #00bf6f;
    color: #fff;
    border: none;
    border-radius: 3px;
    margin-top: 1.5em;
  }
`;

const PricingInfoContainer = styled.div`
  margin: 5em 5em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ul {
    list-style: none;
    border-bottom: 1px;
    width: 75% hr {
      width: 85%;
      position: relative;
      left: 1.7em;
      height: 1px;
      border: none;
      background-color: #c8c8c8;
      margin: 0.7em 0;
    }
    li {
      padding: 10px 0px;
      color: #a5a5a5;
      font-weight: bold;
    }
  }
  button {
    padding: 10px 25px;
    background-color: #00bf6f;
    color: #fff;
    border: none;
    border-radius: 3px;
    margin-top: 1.5em;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5em;
`;

const Box = styled.div`
  padding: 20px 15px;
  border: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 1px 1px 5px 1px rgba(166, 166, 166, 0.2);
  -moz-box-shadow: 1px 1px 5px 1px rgba(166, 166, 166, 0.2);
  box-shadow: 1px 1px 5px 1px rgba(166, 166, 166, 0.2);
`;

const Price = styled.h1`
  @media (max-width: 768px) {
    font-size: 1.5em;
    text-align: center;
  }
  font-size: 3.5em;
  margin-top: 0;
  letter-spacing: 2px;
  sub {
    font-size: 0.3em;
    bottom: 0.1em;
    font-weight: bold;
  }
  sup {
    font-size: 0.3em;
    top: -1.6em;
    font-weight: bold;
  }
`;

const ImageContainer = styled.img`
  width: 40%;
  height: auto;
  margin-bottom: 2rem;
`;

const SubscriptionPageHeadlineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f6f8f9;
  padding: 0 0 10em 0;
  @media (max-width: 768px) {
    padding: 5em 2em;
  }
  p:nth-child(1) {
    color: #00bf6f;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 2em;
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-top: 0px;
      font-size: 1.5em;
    }
  }
  p {
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 16px;
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
`;

const PaymentPlanToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5em;
`;

// Browser
const EnterpriseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3em 5em 0 5em;
  border-bottom: 1px;
`;

const EntTitle = styled.h2`
  font-size: 3em;
  margin-right: 1em;
  margin-bottom: 0;
`;

const EntPrice = styled.h4`
  font-size: 2em;
  margin: 0 2em 0 0;
  color: #7a7a7a;
`;

const EntButton = styled.button`
  background-color: #00be6e;
  color: white;
  border: none;
  padding: .78571429em 1.5em .78571429em;
  border-radius: 4px;
`;

// Mobile
const EnterpriseMobileContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 2em auto;
  border-bottom: 1px;
`;

const EntMobileTitle = styled.h2`
  font-size: 1.5em;
  margin-right: 1em;
  margin-bottom: 0;
`;

const EntMobilePrice = styled.h4`
  font-size: 1.25em;
  margin: 0 2em 0 0;
  color: #7a7a7a;
`;

const EntMobileButton = styled.button`
  background-color: #00be6e;
  color: white;
  border: none;
  padding: .78571429em 1.5em .78571429em;
  border-radius: 4px;
`;

const Subscription = (props) => {
  const [modal, setModal] = useState(false);
  // const [billing, setBilling] = useState(true);
  const [subOption, setOption] = useState('free tier');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [createError, setCreateError] = useState('');
  const { user, business, businessActions, openSignUp } = props;

  useEffect(() => {
    window.scrollTo(0, 650);
  }, []);

  useEffect(() => {
    if (business.createBusinessSuccess) {
      window.location = '/edit_business';
    }
  }, [business.createBusinessSuccess]);

  useEffect(() => {
    const messageIsString =
      props.business.addingBusinessMessage &&
      props.business.addingBusinessMessage.length > 0;
    messageIsString && setCreateError(props.business.addingBusinessMessage);
  }, [props.business.addingBusinessMessage]);

  const subscribeClick = (tier) => {
    setOption(tier);
    setModal(true);
  };

  const handleChange = (text, setFunc) => {
    setFunc(text);
    setNameError('');
    setEmailError('');
    setCreateError('');
  };

  const checkForm = () => {
    const isValidEmail = validator.isEmail(email);
    const isValidName = name.length > 2; // minimum character count for now
    if (isValidEmail && isValidName) {
      return true;
    }
    !isValidEmail && setEmailError('Please enter a valid email.');
    !isValidName && setNameError('Please enter a name.');
    return false;
  };

  const subscribeBusiness = (e) => {
    e.preventDefault();
    const subscription = 'free tier';
    const validNameAndEmail = checkForm();
    if (validNameAndEmail) {
      const createInfo = {
        user: {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          profilePic: user.profilePic,
        },
        name,
        email,
        subscription,
      };

      businessActions.addBusiness(createInfo, user);
    }
  };

  // const toggleBilling = (e) => {
  //   e.preventDefault();
  //   setBilling(!billing);
  // };

  const modalContent = () => {
    const userLoggedIn = user._id;
    const buttonStyle = {
      // using for now, could not get classname in subcription.css to apply
      padding: '10px 25px',
      backgroundColor: '#00BF6F',
      color: '#fff',
      border: 'none',
      borderRadius: '3px',
      marginTop: '1em',
      fontSize: '16px',
    };
    if (userLoggedIn) {
      const userHasBusiness = user.business;
      switch (subOption) {
        case 'Free-Time':
          if (userHasBusiness) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  Your Business Is Already Added
                </h1>
                <h2 style={{ textAlign: 'center' }}>
                  Option to Add Multiple Businesses Coming Soon!
                </h2>
                <section style={{ position: 'absolute', bottom: 20 }}>
                  <button
                    style={buttonStyle}
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Close
                  </button>
                </section>
              </div>
            );
          }
          return (
            <div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '10%',
                  flexDirection: 'column',
                }}
              >
                <form id="business-sub-form">
                  <h3 style={{ textAlign: 'center', marginTop: '3%' }}>
                    Please enter the Name and Email for your Business
                  </h3>
                  <div className="business-name">
                    <label htmlFor="business-name">
                      BUSINESS NAME
                      <span style={{ color: 'red', marginLeft: '1%' }}>
                        {nameError}
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Business Name"
                      name="name"
                      value={name}
                      onChange={(e) => handleChange(e.target.value, setName)}
                      id="business-name"
                    />
                  </div>
                  <div className="email">
                    <label htmlFor="email">
                      BUSINESS EMAIL
                      <span style={{ color: 'red', marginLeft: '1%' }}>
                        {emailError}
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Business Email"
                      name="email"
                      value={email}
                      onChange={(e) => handleChange(e.target.value, setEmail)}
                      id="email"
                    />
                  </div>
                  <section className="business-sign-buttons">
                    <button
                      className="create-button"
                      style={{ ...buttonStyle, marginRight: '1em' }}
                      positive
                      onClick={(e) => subscribeBusiness(e)}
                    >
                      Create
                    </button>
                    <button
                      className="create-button"
                      style={buttonStyle}
                      positive
                      onClick={() => setModal(false)}
                    >
                      Cancel
                    </button>
                  </section>
                </form>
              </div>
              {createError.length > 0 && (
                <p
                  style={{
                    color: 'red',
                    paddingBottom: '5px',
                    fontSize: '16px',
                  }}
                >
                  {createError}
                </p>
              )}
            </div>
          );

        default: {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h1 style={{ textAlign: 'center' }}>Coming Soon!</h1>
              <section style={{ position: 'absolute', bottom: 20 }}>
                <button
                  style={buttonStyle}
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </button>
              </section>
            </div>
          );
        }
      }
    } else {
      setModal(!modal);
      openSignUp();
    }
  };

  return (
    <Container>
      <ParallaxImage
        bgImage={
          'https://res.cloudinary.com/rhiz/image/upload/b_rgb:000,o_60,c_fill,g_center,h_601,w_1920,y_0/v1558071139/Home%20Page/Search_Background_-_50_Black_Overlay_kwe1nc.jpg'
        }
        height={{ height: '225px' }}
        title="Let's get started! Just choose your category, zip code, and urgency"
        alt="parallaximg"
        search={<SearchBar />}
      />

      <SubscriptionPageHeadlineContainer>
        <p>OUR RHIZ PROMISE</p>
        <Price>Pay nothing until you get your first customer.</Price>
        {!props.user.verified && (
          <p>
            Rhiz is always free for consumers. To create a consumer account,
            <span
              onClick={props.openSignUp}
              onKeyDown={props.openSignUp}
              style={{
                marginLeft: '5px',
                cursor: 'pointer',
                color: 'gray',
                fontSize: `${isMobile ? '16px' : '20px'}`,
                fontWeight: 'bold',
              }}
            >
              click here⮕
            </span>
          </p>
        )}
      </SubscriptionPageHeadlineContainer>
      <BrowserView>
        <EnterpriseContainer>
            <EntTitle>Enterprise</EntTitle>
            <EntPrice>$399/mo</EntPrice>
              <EntButton>
                <Link to='/contact'
                style={{ color: 'white', fontSize: '1rem', fontWeight: '700'}}>
                  Contact Sales
                </Link>
              </EntButton>
        </EnterpriseContainer>
        <PricingInfoContainer>
          {SubsciptionList.map((list) => (
            <div className="Pricing-Containter">
              <Box>
                <ImageContainer src={list.img} />

                <TitleContainer>
                  <Title>{list.tierType}</Title>
                </TitleContainer>

                <div className={'subscription-' + list.tierType}>
                  {list.tierType === 'Free-Time' ? (
                    <p style={{ fontSize: '30px', fontWeight: 'bold' }}>Free</p>
                  ) : (
                    <PaymentPlanToggleContainer>
                      <ToggleSwitch />
                    </PaymentPlanToggleContainer>
                  )}
                </div>

                <ul>
                  {list.features.map((feature) => (
                    <li>
                      <FontAwesomeIcon
                        id={feature.included ? 'check-mark' : 'xIcon'}
                        icon={faCheck}
                        style={{ fontSize: '14px', marginRight: '10px' }}
                      />
                      {feature.name}
                      {feature.message && (
                        <div className="popover__wrapper">
                          <FontAwesomeIcon
                            id="question-circle"
                            icon={faQuestionCircle}
                            style={{ fontSize: '14px', marginLeft: '10px' }}
                          />
                          <div className="popover__content">
                            <p className="popover__message">
                              {feature.message}
                            </p>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                <Button onClick={() => subscribeClick(list.tierType)}>
                  Subscribe
                </Button>
              </Box>
            </div>
          ))}
        </PricingInfoContainer>
      </BrowserView>
      <MobileView>
      <EnterpriseMobileContainer>
            <EntMobileTitle>Enterprise</EntMobileTitle>
            <EntMobilePrice>$399/mo</EntMobilePrice>
              <EntMobileButton>
                <Link to='/contact'
                style={{ color: 'white', fontSize: '1rem', fontWeight: '700'}}>
                  Contact Sales
                </Link>
              </EntMobileButton>
        </EnterpriseMobileContainer>
        <PricingInfoContainerMobile>
          <Carousel centered arrows>
            {SubsciptionList.map((list) => (
              <div>
                <Box>
                  <ImageContainer src={list.img} />
                  <TitleContainer>
                    <Title>{list.tierType}</Title>
                  </TitleContainer>

                  <div className={'subscription-' + list.tierType}>
                    {list.tierType === 'Free-Time' ? (
                      <p style={{ fontSize: '30px', fontWeight: 'bold' }}>
                        Free
                      </p>
                    ) : (
                      <PaymentPlanToggleContainer>
                        <ToggleSwitch />
                      </PaymentPlanToggleContainer>
                    )}
                  </div>
                  <ul>
                    {list.features.map((feature) => (
                      <li>
                        <FontAwesomeIcon
                          id={feature.included ? 'check-mark' : 'xIcon'}
                          icon={faCheck}
                          style={{ fontSize: '14px', marginRight: '10px' }}
                        />
                        {feature.name}
                        {feature.message && (
                          <div className="popover__wrapper">
                            <FontAwesomeIcon
                              id="question-circle"
                              icon={faQuestionCircle}
                              style={{ fontSize: '14px', marginLeft: '10px' }}
                            />
                            <div className="popover__content">
                              <p className="popover__message">
                                {feature.message}
                              </p>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  <button onClick={() => subscribeClick(list.tierType)}>
                    Subscribe
                  </button>
                </Box>
              </div>
            ))}
          </Carousel>
        </PricingInfoContainerMobile>
      </MobileView>
      <Modal onClose={() => setModal(!modal)} show={modal}>
        {modal && modalContent()}
      </Modal>
    </Container>
  );
};

const mapStateToProps = ({ business }) => ({
  business,
});

const mapDispatchToProps = (dispatch) => ({
  businessActions: bindActionCreators(businessActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Subscription));
