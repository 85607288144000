import React from 'react';

const BreakLine = ({ color, width, margin, height = 1 }) => (
  <hr
    style={{
      color,
      backgroundColor: color,
      height,
      width,
      marginRight: margin,
    }}
  />
);

export default BreakLine;
