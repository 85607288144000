import React, { Component } from 'react';
import Form from './Form';

export default class EditProfile extends Component {
  render() {
    return (
      <div className="profile-form">
        <Form
          onSubmit={this.props.onSubmit}
          user={this.props.user}
          updateProfilePic={this.props.updateProfilePic}
        />
      </div>
    );
  }
}
