/* eslint-disable max-len */
export const businessBlades = {
  name: 'Blades of Glory Landscaping',
  topLine: 'Blades of Glory Landscaping',
  secondLine: 'Sustainable Landscaping',
  mainCategory: 'Sustainable Landscaping',
  about:
    'For the past two decades, Blades of Glory has provided both commercial and residential property owners in Los Angeles with the highest quality sustainable landscaping services. With extensive resources, our highly trained staff work passionately to provide professional outcomes with a personal touch.',
  subCategories: ['Palapas', 'Patio Accouterments', 'Style'],
  price: 3,
  rhizScore: 90,
  user: {
    firstName: 'Alessandra',
    lastName: 'McLane',
    title: 'Business Owner',
    profilePic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460084/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Business_Owner_-_Alessandra_sepe6z.jpg',
  },
  src:
    'https://res.cloudinary.com/rhiz/image/upload/c_crop,g_center,h_3707,w_3707/v1557459323/Home%20Page/Blades_of_Glory_Thumb_dmsann.jpg',
  address: '1351 3rd St., Santa Monica, 90291',
  street: '1351 3rd St.',
  city: 'Santa Monica',
  state: 'California',
  zip: '90291',
  location: 'Santa Monica, California',
  reviewCount: 185,
  headerPic:
    'https://res.cloudinary.com/rhiz/image/upload/v1559088219/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Thumb_cagrij.jpg',
  profilePic:
    'https://res.cloudinary.com/rhiz/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1557460077/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/BoG_fn8uip.png',
  mutualFriends: 7,
  totalFriends: 1856,
  _id: 1,
};

export const reviewsBlades = [
  {
    name: 'Kevin Snyder',
    src:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1557460128/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Kevin_Snyder_x4er66.jpg',
    review: 'Blades of Glory Landscaping is a step above the rest!',
    datePosted: '11 days ago',
  },
  {
    name: 'Cait Smith',
    src:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1557460144/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Cait_Smith_bxp8ow.jpg',
    review: 'LOVE LOVE LOVE!\n Great communication and workmanship',
    datePosted: '14 days ago',
  },
  {
    name: 'Tim Sanches',
    src:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1557460134/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Tim_Sanches_pshpcn.jpg',
    review:
      'Great company to work with. Fred was attentive to my budget and design considerations. Found them on Rhiz and what a breeze the whole process was.',
    change: 5,
    datePosted: '2 weeks ago',
  },
  {
    name: 'Shirley Washburn',
    src:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1557460135/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Shirley_Washburn_i9fpmf.jpg',
    review:
      'I liked them. Had a small project done, though it was not cheap! May recommend to a friend.',
    datePosted: '1 month ago',
  },
];

export const worksWithBlades = [
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460103/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Works_With_1_w8agct.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460110/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Works_With_2_rrokz7.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460110/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Works_With_3_bl7muc.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460123/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Works_With_4_rmxebj.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460119/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Works_With_5_wwao05.jpg',
  },
];

export const instaPicsBlades = [
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460158/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_1_kerszf.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460154/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_2_vtuqfx.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460154/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_3_rrjlhm.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460159/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_4_iyicmd.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460164/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_5_ediz0a.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460163/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_6_pikgjs.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460283/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_7_mnkybp.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460148/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_8_vigykn.jpg',
  },
  {
    pic:
      'https://res.cloudinary.com/rhiz/image/upload/v1557460150/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Instagram_9_xuyg7u.jpg',
  },
];
