import React from 'react';

const BusinessImages = ({ mainImage }) => (
  <div className="horizontal-card-image-container">
    <img id="main-image" src={mainImage} alt="profile-img" />
    {/* <section class="sub-image-section">
        <img id="sub-image-one" src={subImages[3].pic}/>
        <img id="sub-image-two" src={subImages[4].pic}/>
        <img id="sub-image-three" src={subImages[5].pic}/>
      </section> */}
  </div>
);

export default BusinessImages;
