import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import '@brainhubeu/react-carousel/lib/style.css';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import './learningPage.css';
import { learningSectionsInfo } from './constants';
import LearningSection from './LearningSection';
import LearningCard from './LearningCard';
import branding from '../../assets/images/learningPage/branding.png';
import Breakline from './Breakline';

const LearningPage = (props) => {
  const { user, openSignUp } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserView>
        <div className='learning-page-container'>
          <div className='learning-page-top'>
            <section>
              <div className='learning-page-section-wrapper'>
                <h1 className='learning-page-title'>Learning</h1>
                <p className='learning-page-text'>
                  Much more to come as we grow together, but, for now, please
                  use as many of these resources as you need.
                </p>
              </div>
            </section>
          </div>

          <div className='learning-page-mid'>
            {learningSectionsInfo.map((section) => (
              <LearningSection
                icon={section.icon}
                title={section.title}
              >
                {section.cards.map((card) => (
                  <LearningCard
                    img={card.img}
                    name={card.name}
                    desc={card.desc}
                    link={card.link}
                  />
                ))}
              </LearningSection>
            ))}
            <div className='learning-page-section'>
              <div className='learning-page-section-wrapper-left'>
                <div className='landing-page-image-wrapper' id='desktop'>
                  <img className='icon-img' src={branding} alt='icon' />
                </div>

                <div className='learning-page-section-title title-b'>
                  <div className='branding'>
                    <h3>Branding / Uniform</h3>
                  </div>
                  <div className='line-box'>
                    <Breakline height='60px' width='40px' />
                  </div>
                </div>
              </div>
              <div className='learning-page-section-wrapper-right'>
                <div className='learning-page-section-text'>
                  <h5>Wait.</h5>
                  <p>
                    It’s tempting to do this right away to carve your spot, but
                    wait for feedback before you go all out. You likely won’t
                    need 1000 business cards and will have to give away shirts
                    if you order early online based on minimums. Trust us. It’s
                    a common mistake.
                    <br />
                    <br />
                    Your biggest asset early on that will carry with you
                    is...YOU. Your branding will represent your craft and
                    reputation, not the other way around. Get a few clients,
                    then order locally!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='learning-page-bot'>
            <div className='learning-page-bot-wrapper'>
              <h2>
                Always free for consumers, always free trials for business.
              </h2>
              <p>Try Rhiz today and discover a better business marketplace.</p>
              <div className='learning-page-bot-button'>
                {!user._id ? (
                  <Button id='botBtn' size='huge' onClick={openSignUp} positive>
                    Sign Up Now
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: `/user/${user.firstName}_${user.lastName}`,
                      state: {
                        user,
                      },
                    }}
                  >
                    <Button size='huge' positive>
                      View My Profile
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className='learning-page-container'>
          <div className='learning-page-top'>
            <section>
              <div className='learning-page-section-wrapper'>
                <h1 className='learning-page-title'>Learning</h1>
                <p className='learning-page-text'>
                  Rhiz uses your network to grow your net worth. Combine lead
                  generation and referrals for fast and trustworthy connections.
                </p>
              </div>
            </section>
          </div>

          <div className='learning-page-mid'>
          {learningSectionsInfo.map((section) => (
              <LearningSection
                icon={section.icon}
                title={section.title}
              >
                {section.cards.map((card) => (
                  <LearningCard
                    img={card.img}
                    name={card.name}
                    desc={card.desc}
                    link={card.link}
                  />
                ))}
              </LearningSection>
            ))}
            <div className='learning-page-section'>
              <div className='learning-page-section-wrapper-left mobile'>
                <div className='title'>
                  <div className='title-top brand'>
                    <div className='landing-page-image-wrapper'>
                      <img className='icon-img' src={branding} alt='icon' />
                    </div>

                    <div className='learning-page-section-title uniform'>
                      <h3>Branding / Uniform</h3>
                    </div>
                  </div>
                  <div className='title-bot'>
                    <div className='line-container'>
                      <div className='line-mob'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='learning-page-section-wrapper-right'>
                <div className='learning-page-section-text'>
                  <h5>Wait.</h5>
                  <p>
                    It’s tempting to do this right away to carve your spot, but
                    wait for feedback before you go all out. You likely won’t
                    need 1000 business cards and will have to give away shirts
                    if you order early online based on minimums. Trust us. It’s
                    a common mistake.
                    <br />
                    <br />
                    Your biggest asset early on that will carry with you
                    is...YOU. Your branding will represent your craft and
                    reputation, not the other way around. Get a few clients,
                    then order locally!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='learning-page-bot'>
            <div className='learning-page-bot-wrapper'>
              <h2>
                Always free for consumers, always free trials for business.
              </h2>
              <p>Try Rhiz today and discover a better business marketplace.</p>
              <div className='learning-page-bot-button'>
                {!user._id ? (
                  <Button id='botBtn' size='huge' onClick={openSignUp} positive>
                    Sign Up Now
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: `/user/${user.firstName}_${user.lastName}`,
                      state: {
                        user,
                      },
                    }}
                  >
                    <Button size='huge' positive>
                      View My Profile
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
};

export default LearningPage;
