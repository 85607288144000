export const makeReferralsList = (user, emails) =>
  emails.map((inviteEmail) => ({
    inviteEmail,
    referredByUserId: user._id,
    referredByName: `${user.firstName} ${user.lastName}`,
  }));

export const referralCounts = (referrals) => {
  const counts = {
    pending: 0,
    expired: 0,
    redeemed: 0,
    fulfilled: 0,
  };

  referrals.forEach(({ status }) => {
    counts[status] += 1;
  });

  return counts;
};

export const filterReferrals = (referrals, categoryList = {}) => {
  let result = true;

  for (const i in categoryList) {
    if (categoryList[i] === true) {
      result = false;
      break;
    }
  }
  if (result) {
    return referrals;
  }

  const filteredRefferalsList = referrals.filter(
    (referral) => categoryList[referral.status],
  );

  return filteredRefferalsList;
};

export const changeReferralStatus = (status, referral, id) => ({ ...referral, [id]: { status } });
