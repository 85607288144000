/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Input, Icon, Button } from 'semantic-ui-react';
import { Redirect } from 'react-router';

// services
import { searchBusinessByCatAndZip } from '../../services';

// dummy data
import {
  reviewsBlades,
  worksWithBlades,
  instaPicsBlades,
} from '../BusinessProfile/BusinessInfo/BladesOfGlory';

class SearchBar extends Component {
  state = {
    category: '',
    zipCode: '',
    query: '',
    results: [],
    tags: [],
    search: false,
  };

  deleteTagHandler = (tagName) => {
    this.setState({
      tags: this.state.tags.filter((tag) => tag !== tagName),
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { category, zipCode } = this.state;
    const tags = [category, zipCode];
    const query = `?q=${category}&${zipCode}`;

    searchBusinessByCatAndZip(category, zipCode).then((businesses) => {
      const newResults = businesses.results.map((business) => [
        business,
        reviewsBlades,
        worksWithBlades,
        instaPicsBlades,
      ]);
      this.setState({
        results: [...newResults],
        tags,
        query,
        search: true,
      });
    });
  };
  render() {
    const { category, zipCode, results, tags, search } = this.state;

    return (
      <div>
        <form
          className="home-search-bar"
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <Input
            className="search-input-term"
            placeholder="Enter Category"
            name="category"
            onChange={(e) => {
              this.handleChange(e);
            }}
            value={category}
            style={{ width: '50%' }}
            required
          />
          <Input
            className="search-input-location"
            placeholder="ZIP"
            label={
              <Button icon className="search button" id="searchButton">
                <Icon name="search" />
              </Button>
            }
            labelPosition="right"
            name="zipCode"
            value={zipCode}
            onChange={(e) => {
              this.handleChange(e);
            }}
            style={{ width: '50%' }}
            required
          />
        </form>

        {search && (category.length > 0) & (zipCode.length > 0) && (
          <Redirect
            push
            to={{
              pathname: '/search',
              search: this.state.query,
              state: { results, tags },
            }}
          />
        )}
      </div>
    );
  }
}

export default SearchBar;
