/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Route, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import isEqual from 'lodash.isequal';
import { Drawer } from 'antd';
import { Card } from 'semantic-ui-react';
import MessagedPeople from './MessagedPeople';
import MessagesUserContent from './MessagesUserContent';
import MessagesUserMessages from './MessagesUserMessages';

import './Messages.css';
import QuoteForm from './common/Quote/QuoteForm';
import QuoteFromView from './common/Quote/QuoteFromView';
import QuoteRecieved from './Alerts/QuoteRecieved';
import MarkedComplete from './Alerts/MarkedComplete';
import BusinessReview from '../Review/business/BusinessReview';
import EditBusinessReview from '../Review/business/EditBusinessReview';
import EditConsumerReview from '../Review/consumer/EditConsumerReview';
import ConsumerReview from '../Review/consumer/ConsumerReview';
import ConsumerReviewView from '../Review/consumer/ConsumerReviewView';
import EditReviewBusinessForm from '../Review/business/EditReviewBusinessForm';
import EditReviewConsumerForm from '../Review/consumer/EditReviewConsumerForm';
import { Loading } from '../../components';
import {
  socketServices,
  getQuotes,
  fetchMessages,
  updateConnection,
} from '../../services';
import { notificationConstructor } from '../../util';

class Messages extends Component {
  state = {
    consumerProfile: false,
    QuoteRecieved: false,
    approvedDate: '',
    markedCompleteFlag: false,
    completeDate: '',
    paid: '',
    quoteSentDate: '',
    expiredDate: '',
    denyDate: '',
    pending: false,
    busninessReviewSent: false,
    reviewSent: false,
    listCategory: this.props.location.state
      ? this.props.location.state.category
        ? this.props.location.state.category
        : this.props.connections.selectedConnection.category
      : this.props.connections.selectedConnection.category,
    loaded: false,
    visible: false,
    quotes: {},
    messages: [],
    currentChat: '',
  };

  componentDidMount() {
    const stateUpdate = { loaded: true };
    const {
      location,
      connections,
      connectionsActions,
      business,
      user,
    } = this.props;
    const { selectedConnection } = connections;
    const connectionFromLocationState =
      location.state && !!location.state.connection;
    const connectionFromProps =
      selectedConnection.connection && !!selectedConnection.connection._id;
    let currentChat;
    if (connectionFromLocationState) {
      currentChat = location.state.connection;
      stateUpdate.category = location.state.category;
      connectionsActions.setSelectedConnection(location.state);
      // emit chat enter
      socketServices.emitChatEnter(
        `${location.state.connection.type}_${location.state.connection._id}`,
        selectedConnection,
      );
    } else if (!connectionFromProps) {
      // if just go /Messages need to grab the first convo
      const userHasBusiness = !!business._id;
      stateUpdate.category = userHasBusiness ? 'business' : 'personal';
      currentChat = connections[stateUpdate.category][0];
      const userProfile = userHasBusiness ? business : user;
      let otherProfile;
      if (userHasBusiness) {
        otherProfile = currentChat[currentChat.consumerId];
      } else {
        const chatIsB2C = currentChat.type === 'B2C';
        if (chatIsB2C) {
          otherProfile = currentChat[currentChat.businessId];
        } else {
          otherProfile =
            currentChat.user1_id === user._id
              ? currentChat.user2_id
              : currentChat.user1_id;
        }
      }
      connectionsActions.setSelectedConnection({
        connection: currentChat,
        category: 'business',
        userProfile,
        otherProfile,
      });
      // emit chat enter
      socketServices.emitChatEnter(
        `${currentChat.type}_${currentChat._id}`,
        selectedConnection,
      );
    } else {
      currentChat = selectedConnection.connection;
      stateUpdate.category = selectedConnection.category;
    }

    this.setMessagesState(stateUpdate, currentChat);
    // this.setState({
    //   ...stateUpdate
    // });
  }

  componentDidUpdate = () => {
    const {
      location,
      connections: { selectedConnection },
      connectionsActions,
    } = this.props;
    const newConnection =
      location.state &&
      !!location.state.connection &&
      (selectedConnection.connection._id !== location.state.connection._id ||
        location.state.connection.type !== selectedConnection.connection.type);
    if (newConnection) {
      // emit chat left
      socketServices.emitChatLeft(
        `${location.state.connection.type}_${location.state.connection._id}`,
      );
      connectionsActions.setSelectedConnection(location.state);
      // emit chat enter
      socketServices.emitChatEnter(
        `${selectedConnection.connection.type}_${selectedConnection.connection._id}`,
        selectedConnection,
      );
      this.setMessagesState({}, location.state.connection);
      this.onClose();
    }
  };

  componentWillUnmount = () => {
    socketServices.removeMessageListeners();
  };

  setMessagesState = async(state, connection) => {
    const quotes =
      connection.type === 'B2C'
        ? await getQuotes(connection._id, state.category)
        : { sent: {}, saved: {}, rejected: [] };
    // fetch messages
    const messages = await fetchMessages(connection._id, connection.type);
    Array.isArray(messages) &&
      messages.length === 0 &&
      messages.push(connection.lastMessage);
    socketServices.listenMessage(this);
    this.setState({
      quotes,
      messages: messages || [],
      ...state,
    });
  };

  // only call when user is consumer in B2C
  quoteRecievedCheck() {
    const {
      connections: { selectedConnection },
    } = this.props;
    const quoteReceived = !!selectedConnection.quote.sent.read;
    const quoteNotRead = !selectedConnection.quote.sent.read;
    if (quoteReceived && quoteNotRead) {
      this.setState({
        sendQuote: true,
        QuoteRecieved: true,
      });
    }
  }

  quoteCompleteCheck() {
    if (this.state.markedCompleteFlag && !this.state.consumerProfile) {
      this.setState({
        sendQuote: true,
      });
    }
  }

  quoteRecievedView = () => {
    this.setState({
      sendQuote: true,
      QuoteRecieved: false,
    });
  };

  backToConversationQuote = async() => {
    const {
      connections: { selectedConnection },
      connectionsActions,
    } = this.props;
    const newConnection = { ...selectedConnection };
    newConnection.quote.sent.read = true;
    // const markRead = await connectionsActions.markQuoteRead(selectedConnection);
    // connectionsActions.setSelectedConnection(newConnection);
    connectionsActions.updateConnections(
      newConnection,
      selectedConnection.category,
      false,
    );
  };

  backToConversationComplete = () => {
    this.setState({
      sendQuote: false,
      markedCompleteFlag: false,
    });
  };

  // add mess
  sendMessage = async(message, updateCountColumn, currentChat, connection) => {
    const {
      connectionsActions,
      connections: { selectedConnection },
    } = this.props;
    const updatedSelectedConnection = { ...selectedConnection };
    updatedSelectedConnection.connection = connection;
    // const update = await connectionsActions.updateLastMessage(message, updateCountColumn,
    // connection.type, updatedSelectedConnection.category, connection._id)
    connectionsActions.setSelectedConnection(updatedSelectedConnection);
    socketServices.emitMessage(
      message,
      currentChat,
      connection,
      updateCountColumn,
    );
  };

  sendQuote = (message, currentChat, connection) => {
    const {
      userProfile,
      otherProfile,
    } = this.props.connections.selectedConnection;
    this.sendMessage(message, currentChat, connection);
    const notificationType =
      connection.quote.sent.status === 'sent' ? 'quote update' : 'quote sent';
    const notification = notificationConstructor(
      userProfile,
      otherProfile,
      notificationType,
    );
    socketServices.emitQuote(connection, notification);
  };

  messageCategorySelection = (listCategory) => {
    this.setState({
      listCategory,
    });
  };

  checkForDifferenceInQuote = (prevPropQuote, newPropQuote) =>
    isEqual(prevPropQuote, newPropQuote);

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  updateReadCount = async(updatedSelected, updateDb, callUpdateSelect) => {
    const { user, connections, connectionsActions } = this.props;
    const updatedConnections = { ...connections };

    let needUpdate = false;
    const countUpdate = {};
    if (updatedSelected.category === 'business') {
      needUpdate = updatedSelected.connection.unreadMessagesBusiness > 0;
      updatedConnections.unreadBusinessMessagesCount -=
        updatedSelected.connection.unreadMessagesBusiness;
      countUpdate.unreadMessagesBusiness = 0;
    } else if (updatedSelected.connection.type === 'B2C') {
      needUpdate = updatedSelected.connection.unreadMessagesConsumer > 0;
      updatedConnections.unreadPersonalMessagesCount -=
        updatedSelected.connection.unreadMessagesConsumer;
      countUpdate.unreadMessagesConsumer = 0;
    } else if (user._id === updatedSelected.connection.user1_id) {
      needUpdate = updatedSelected.connection.unreadMessagesUser1 > 0;
      updatedConnections.unreadPersonalMessagesCount -=
        updatedSelected.connection.unreadMessagesUser1;
      countUpdate.unreadMessagesUser1 = 0;
    } else {
      needUpdate = updatedSelected.connection.unreadMessagesUser2 > 0;
      updatedConnections.unreadPersonalMessagesCount -=
        updatedSelected.connection.unreadMessagesUser2;
      countUpdate.unreadMessagesUser2 = 0;
    }
    updatedConnections.selectedConnection.connection = callUpdateSelect
      ? { ...updatedSelected.connection, ...countUpdate }
      : { ...updatedConnections.selectedConnection.connection };
    const success =
      (updateDb &&
        (await updateConnection(
          updatedSelected.connection._id,
          countUpdate,
          updatedSelected.connection.type,
        ))) ||
      !updateDb;
    if (needUpdate) {
      const selectedIndex = updatedConnections[
        updatedSelected.category
      ].findIndex(
        (connection) =>
          connection._id === updatedSelected.connection._id &&
          connection.type === updatedSelected.connection.type,
      );
      updatedConnections[updatedSelected.category][selectedIndex] =
        updatedConnections.selectedConnection.connection;
      connectionsActions.updateConnectionsFromRequest(
        updatedConnections,
        '',
        true,
      );
      success && connectionsActions.setSelectedConnection(updatedSelected);
    } else if (!this.state.loaded) {
      this.getMessages(
        updatedConnections.selectedConnection.connection._id,
        updatedConnections.selectedConnection.connection.type,
        updatedConnections.selectedConnection.connection,
      );
    }
  };

  render() {
    const { user, connections, business } = this.props;
    const { listCategory, loaded, quotes, messages } = this.state;

    return (
      <div className="tab-messages-flex">
        {loaded ? (
          <div>
            <Drawer
              placement={'left'}
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <p className="back-inbox">
                <Link to="/InboxPage">← back to inbox</Link>
              </p>
              <div className="Inbox-tab-messages-mobile">
                <div>
                  {business._id && (
                    <div
                      onClick={() => this.messageCategorySelection('business')}
                      onKeyDown={() =>
                        this.messageCategorySelection('business')
                      }
                      className={
                        listCategory === 'business'
                          ? 'True-tab-messages-mobile'
                          : 'False-tab-messages-mobile'
                      }
                      role="presentation"
                    >
                      <h4>BUSINESS</h4>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => this.messageCategorySelection('personal')}
                  onKeyDown={() => this.messageCategorySelection('personal')}
                  className={
                    listCategory === 'personal'
                      ? 'True-tab-messages-mobile'
                      : 'False-tab-messages-mobile'
                  }
                  role="presentation"
                >
                  <h4>PERSONAL</h4>
                </div>
              </div>
              <div id="mobile-card">
                <Card>
                  <Card.Content>
                    {connections[listCategory].map((connection) => {
                      let otherProfile1;
                      if (connection.type === 'B2C') {
                        otherProfile1 =
                          listCategory === 'business'
                            ? connection.users[connection.consumerId]
                            : connection.users[connection.businessId];
                      } else {
                        otherProfile1 =
                          user._id === connection.user1_id
                            ? connection.users[connection.user2_id]
                            : connection.users[connection.user1_id];
                      }
                      return (
                        <div className="Messaged-PeopleList-Containter">
                          <MessagedPeople
                            {...this.props}
                            connection={connection}
                            otherProfile={otherProfile1}
                            category={listCategory}
                          />
                        </div>
                      );
                    })}
                  </Card.Content>
                </Card>
              </div>
            </Drawer>

            <div className="Inbox-tab-messages desktop">
              <div>
                {business._id && (
                  <div
                    onClick={() => this.messageCategorySelection('business')}
                    onKeyDown={() => this.messageCategorySelection('business')}
                    className={
                      listCategory === 'business'
                        ? 'True-tab-messages'
                        : 'False-tab-messages'
                    }
                    role="presentation"
                  >
                    <h4>BUSINESS</h4>
                    {connections.unreadBusinessMessagesCount > 0 && (
                      <p className={'new-messages-messages'}>
                        {connections.unreadBusinessMessagesCount}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div
                onClick={() => this.messageCategorySelection('personal')}
                onKeyDown={() => this.messageCategorySelection('personal')}
                className={
                  listCategory === 'personal'
                    ? 'True-tab-messages'
                    : 'False-tab-messages'
                }
                role="presentation"
              >
                <h4>PERSONAL</h4>
                {connections.unreadPersonalMessagesCount > 0 && (
                  <p className={'new-messages-messages'}>
                    {connections.unreadPersonalMessagesCount}
                  </p>
                )}
              </div>
            </div>
            <div className="Messages-Container">
              <Card className="desktop">
                <Card.Content>
                  {connections[listCategory].map((connection) => {
                    let otherProfile1;
                    if (connection.type === 'B2C') {
                      otherProfile1 =
                        listCategory === 'business'
                          ? connection.users[connection.consumerId]
                          : connection.users[connection.businessId];
                    } else {
                      otherProfile1 =
                        user._id === connection.user1_id
                          ? connection.users[connection.user2_id]
                          : connection.users[connection.user1_id];
                    }
                    return (
                      <div className="Messaged-PeopleList-Containter">
                        <MessagedPeople
                          {...this.props}
                          connection={connection}
                          otherProfile={otherProfile1}
                          category={listCategory}
                        />
                      </div>
                    );
                  })}
                </Card.Content>
              </Card>
              <div className="Messages-User desktop">
                <MessagesUserContent
                  {...this.props}
                  approvedDate={this.state.approvedDate}
                  completeDate={this.state.completeDate}
                  consumerProfile={this.state.consumerProfile}
                  paid={this.state.paid}
                  quoteSentDate={this.state.quoteSentDate}
                  expiredDate={this.state.expiredDate}
                  denyDate={this.state.denyDate}
                  pending={this.state.pending}
                  busninessReviewSent={this.state.busninessReviewSent}
                  reviewSent={this.state.reviewSent}
                  quotes={quotes}
                />
              </div>

              <div className="message-container-mobile">
                {/* <Router>
                <Switch> */}
                <Route
                  path="/Messages"
                  exact
                  render={() => (
                    <MessagesUserMessages
                      approvedDate={this.state.approvedDate}
                      completeDate={this.state.completeDate}
                      consumerProfile={this.state.consumerProfile}
                      paid={this.state.paid}
                      quoteSentDate={this.state.quoteSentDate}
                      expiredDate={this.state.expiredDate}
                      denyDate={this.state.denyDate}
                      pending={this.state.pending}
                      busninessReviewSent={this.state.busninessReviewSent}
                      reviewSent={this.state.reviewSent}
                      {...this.props}
                      messages={messages}
                      quotes={quotes}
                      send={this.sendMessage}
                      showDrawer={this.showDrawer}
                    />
                  )}
                />
                <Route
                  path="/QuoteForm"
                  render={() => (
                    <QuoteForm
                      {...this.props}
                      sendMessage={this.sendMessage}
                      sendQuote={this.sendQuote}
                      quotes={{ ...quotes }}
                    />
                  )}
                />
                <Route
                  path="/QuoteView"
                  render={() => (
                    <QuoteFromView
                      {...this.props}
                      sendMessage={this.sendMessage}
                      rejectedQuotes={quotes.rejected}
                      quotes={{ ...quotes }}
                    />
                  )}
                />
                <Route
                  path="/ConsumerReviewForm"
                  render={() => <ConsumerReview {...this.props} />}
                />
                <Route
                  path="/BusinessReviewForm"
                  render={() => <BusinessReview {...this.props} />}
                />
                <Route
                  path="/EditConsumerReview"
                  render={() => <EditConsumerReview {...this.props} />}
                />
                <Route
                  path="/EditBusinessReview"
                  render={() => <EditBusinessReview {...this.props} />}
                />
                <Route
                  path="/EditReviewBusinessForm"
                  render={() => <EditReviewBusinessForm {...this.props} />}
                />
                <Route
                  path="/EditReviewConsumerForm"
                  render={() => <EditReviewConsumerForm {...this.props} />}
                />
                <Route
                  path="/ConsumerReviewView"
                  render={() => <ConsumerReviewView {...this.props} />}
                />
                {/* </Switch>

              </Router> */}
              </div>

              {!this.state.consumerProfile && (
                <div>
                  {this.state.markedCompleteFlag ? (
                    <Route
                      path="/Messages"
                      render={() => (
                        <MarkedComplete
                          backToConverastionComplete={
                            this.backToConverastionComplete
                          }
                          {...this.props}
                        />
                      )}
                    />
                  ) : (
                    <div>
                      {this.state.QuoteRecieved && (
                        <Route
                          path="/Messages"
                          render={() => (
                            <QuoteRecieved
                              {...this.props}
                              backToConversation={this.backToConversationQuote}
                            />
                          )}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(withRouter(Messages));
