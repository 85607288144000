import React from 'react';
import { Button } from 'semantic-ui-react';
import { ParallaxImage } from '../../components';
import SearchBar from './SearchBar';
import './home.css';
import { Link } from 'react-router-dom';

// assests
import { welcomePic } from './images';

const Home = (props) => {
  const { user, openSignUp } = props;

  return (
    <div>
      <ParallaxImage
        bgImage={
          'https://res.cloudinary.com/rhiz/image/upload/b_rgb:000,o_45,c_crop,g_center,h_590,w_1920/v1558071139/Home%20Page/Search_Background_-_50_Black_Overlay_kwe1nc.jpg'
        }
        height={{ height: '8rem' }}
        title="Word of mouth is now online. Find the right business here."
        alt="parallaximg"
        search={<SearchBar {...props} />}
      />
      <section>
        <div className="home-page-section">
          <div className="home-page-section-wrapper">
            <div className="home-page-section-welcome-left">
              <h1 className="home-page-section-welcome">
                Welcome to your new business community.
              </h1>
              <div className="home-page-section-welcome-left-container">
                <div className="home-feature">Smarter Searching</div>
                <div className="home-feature-description">
                  <p>
                    Don’t waste time asking for recommendations. Search on Rhiz
                    instantly, and SEE who your friends like.
                  </p>
                </div>
                <div className="home-feature">Build your Dream Team</div>
                <div className="home-feature-description">
                  <p>Create a powerful referral network in just 5 clicks.</p>
                </div>
                <div className="home-feature">Trusted Leads</div>
                <div className="home-feature-description">
                  <p>
                    Customers find YOU and contact when ready. You engage if you
                    want to, with our messaging system for record keeping.
                  </p>
                </div>
                <div className="home-feature">Growth Support</div>
                <div className="home-feature-description">
                  <p>
                    Because we come from small businesses, we know there’s a lot
                    to learn. Use our resources to help start or keep growing.
                    We’re here for you!
                  </p>
                </div>
              </div>
            </div>
            <div className="home-page-section-welcome-right">
              <img src={welcomePic.src} alt="welcome" />
            </div>
            <div className="business-pricing-cta">
              <Link to="/subscriptions">
                <Button size="huge" positive>
                  See Business Pricing
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="home-page-section-three-wrapper">
        <div className="home-page-section">
          <div>
            <img alt="city-scene" src="https://res.cloudinary.com/dqxnksmgd/image/upload/v1583264985/rhiz/city-scene.png" />
          </div>
          <div>
            <h1 className="home-page-section-three">
              Always free for consumers, always free trials for businesses.
            </h1>
            <div className="consumer-blurb">
              <p>
                Don&apos;t have a business, just want to find someone trustworthy
                quickly?
              </p>
              <p>
                Rhiz combines the trust of a &lsquo;seeking recommendations&rsquo; post with
                the speed of search. Word of mouth is where trust is at, but it
                often comes at the expense of time. Not anymore! Search your
                community here to keep trust and time-saving together at last.
              </p>
              <p>
                Message businesses, track progress, and even pay invoices all in
                one place. Focus on your project, your family, and your goals.
                We&apos;ll assemble the rest.
              </p>
            </div>
            {!user._id ? (
              <Button size="huge" onClick={openSignUp} positive>
                Sign Up Now
              </Button>
            ) : (
              <Link
                to={{
                  pathname: `/user/${user.firstName}_${user.lastName}`,
                  state: {
                    user,
                  },
                }}
              >
                <Button size="huge" positive>
                  View My Profile
                </Button>
              </Link>
            )}
          </div>
        </div>
      </section>
      <div className="home-page-section">
        <section>
          <div className="home-page-section-wrapper">
            <h1 className="home-page-section-one-left">
              Inspired by struggles, driven to help
              <div className="home-page-section-one-line-break"></div>
            </h1>
            <p className="home-page-section-one-right">
              Rhīz is designed to bring consumers and businesses together as
              simply and quickly as possible. After our own founder&apos;s experience
              launching a landscaping business, we saw what it takes to succeed
              and grow a small business, and what doesn&apos;t work. We are dedicated
              to helping others pursue their dreams of starting and growing
              their business, while curating for consumers a fast and
              trustworthy source for their services needs.
            </p>
          </div>
        </section>
        <section className="home-page-section-two-wrapper">
          <div className="home-page-section-two">
            <img alt="town" src="https://res.cloudinary.com/dqxnksmgd/image/upload/v1583170921/rhiz/Town.png" />
            <h1>Why</h1>
            <p>
              Too many people live their lives in quiet frustration, afraid to
              take the risk of starting a business. We&apos;re here for those who
              want to &ldquo;Rhīz&rdquo; above their fears. We combine many resources for
              learning with real & local community to help.
            </p>
          </div>
          <div className="home-page-section-two">
            <img alt="knowledge" src="https://res.cloudinary.com/dqxnksmgd/image/upload/v1583170921/rhiz/Knowledge.png" />
            <h1>Who</h1>
            <p>
              Rhīz is for consumers who need a fast, trustworthy business search
              and for aspiring and growing businesses that need reliable leads
              and networking.
            </p>
          </div>
          <div className="home-page-section-two">
            <img alt="updates" src="https://res.cloudinary.com/dqxnksmgd/image/upload/v1583170921/rhiz/Updates.png" />
            <h1>What</h1>
            <p>
              Rhīz is a simplified all-in-one marketplace providing instant
              search, business networking, and secure messaging and payments for
              a complete solution.
            </p>
          </div>
          <div className="home-page-section-two">
            <img alt="add-friends" src="https://res.cloudinary.com/dqxnksmgd/image/upload/v1583170921/rhiz/Add_Friends.png" />
            <h1>How</h1>
            <p>
              We combine your existing social channels to save you from creating
              yet another profile. Rhīz brings word of mouth online, leaving you
              with more time (and businesses with more money!)
            </p>
          </div>
        </section>
      </div>

      <section>
        <div className="home-page-wrapper what-is-rhiz home-page-section">
          <img alt="what-is-rhiz" src="https://res.cloudinary.com/dqxnksmgd/image/upload/v1583172373/rhiz/What_is_Rhiz_Graphic.png" />
        </div>
        <div className="what-is-rhiz home-page-section">
          <h1>Rhiz as in rhizome</h1>
          <h2>Sounds like &ldquo;rise&rdquo;</h2>
          <p>
            A rhizome is a fast growing root-like network. This nifty plant
            structure visualizes the kind of autonomous and deeply
            interconnected business community we&apos;re building with Rhiz.
          </p>
          {!user._id ? (
            <Button size="huge" onClick={openSignUp} positive>
              Sign Up For Free
            </Button>
          ) : (
            <Link
              to={{
                pathname: `/user/${user.firstName}_${user.lastName}`,
                state: {
                  user,
                },
              }}
            >
              <Button size="huge" positive>
                View My Profile
              </Button>
            </Link>
          )}
        </div>
      </section>

      <section className="intro-wrapper">
        <div className="home-page-section">
          <div className="featured-categories">
            <h1>Featured Categories</h1>
            <div className="feature-category-wrapper">
              <div className="featured-category" id="masonry"></div>
              <span className="category-name">Masonry</span>
            </div>
            <div className="feature-category-wrapper">
              <div
                className="featured-category"
                id="sustainable-landscaping"
              ></div>
              <span className="category-name">Sustainable Landscaping</span>
            </div>
            <div className="feature-category-wrapper">
              <div className="featured-category" id="electrical"></div>
              <span className="category-name">Electrical</span>
            </div>
            <div className="feature-category-wrapper">
              <div className="featured-category" id="custom-pools"></div>
              <span className="category-name">Custom Pools</span>
            </div>
          </div>
        </div>
      </section>

      <section className="home-page-section-five-wrapper">
        <div className="home-page-section">
          <div className="home-page-section-five-header-div-center">
            <h1>Big Questions</h1>
            <p>Asked by our early subscriber businesses</p>
            <div className="home-page-section-five-line-break"></div>
          </div>

          <div className="home-page-section-five-div-left">
            <h1>How are you different from other marketplaces I&apos;ve tried?</h1>
            <p>
              We were designed from a small business owner&apos;s perspective,
              meaning this was MADE for you.
            </p>
            <p>
              We take out the risk and annoyance. There are no &ldquo;leads&rdquo; you have
              to waste your money on. Consumers can always search for free,
              without submitting personal information, clients find you on their
              time and YOU get to choose if/when to connect.
            </p>
          </div>

          <div className="home-page-section-five-div-right">
            <h1>When do I pay?</h1>
            <p>
              You can start a FREE trial at anytime. We know you&apos;re busy, so we
              let you connect with 1 free customer on your time. Whether it
              takes you 2 days or 2 months, Congrats! We hope you use our
              learning resources in the meantime.
            </p>
            <p>
              At the end of the 1 customer trial, you should have reason to
              believe your business can either get off to a great start or
              continue to grow, at which point you can choose a tier that best
              fits your goals and needs.
            </p>
          </div>

          <div className="home-page-section-five-div-left">
            <h1>
              What is the business networking feature about? How does it work?
            </h1>
            <p>
              Referrals are great and we want to encourage businesses to work
              together as a community. Our &ldquo;Works With&rdquo; networking feature
              allows you to network passively and in a way consumers can see.
            </p>
            <p>
              This means...no more &ldquo;referrals&rdquo;. You get discovered by networking
              with a click of a button, saving you time and effort, so you can
              focus on work or spending time with loved ones. Let your good will
              and friendlines work for you.
            </p>
          </div>

          <div className="home-page-section-five-div-right">
            <h1>What if I&apos;m not getting contacted for my business?</h1>
            <p>
              If you&apos;re just starting and have yet to pay, we suggest you try
              something else. Did you fill out your top 5 Dream Team yet? Make
              sure you&apos;ve set up your network. Also make sure you&apos;ve selected
              your search categories broadly enough to show up on relevant
              search results
            </p>
            <p>
              If you&apos;ve already paid and are STILL not getting customers, we can
              put your account on hold for $5.99 after 2 months of inactivity.
              This way your business will still show up in search results but
              will save you money. Unlike competitors you may have tried, we&apos;re
              value-first and want to help. Remember, we&apos;re here for YOU!
            </p>
          </div>
        </div>
      </section>

      <section className="home-page-section-six-wrapper rhiz-green">
        <div className="home-page-section">
          <div>
            <h1 className="home-page-section-six">Ready to get started?</h1>
            {!user._id ? (
              <Button size="huge" onClick={openSignUp} positive>
                Sign Up For Free
              </Button>
            ) : (
              <Link
                to={{
                  pathname: `/user/${user.firstName}_${user.lastName}`,
                  state: {
                    user,
                  },
                }}
              >
                <Button size="huge" positive>
                  View My Profile
                </Button>
              </Link>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
