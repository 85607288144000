import { api, POST, PUT } from './apiRequests';

export const fetchCurrentUser = (refreshToken) =>
  api({
    path: 'auth/current_user',
    method: POST,
    headers: { authorization: refreshToken },
    body: {},
  });

export const loginAttempt = (email, password) =>
  api({
    path: 'auth/login',
    method: POST,
    body: { email, password },
  });

export const signupUser = (newUser) =>
  api({
    path: 'users',
    method: POST,
    body: { newUser },
  });

export const verifyUserService = (userId) =>
  api({
    path: 'users/verify',
    method: PUT,
    body: { userId },
  });

export const updateUserService = (updatedProfile, headerOptions) =>
  api({
    path: 'users/update',
    method: PUT,
    headers: { ...headerOptions },
    body: { updatedProfile },
  });

export const editUserService = (id, updates, headerOptions) =>
  api({
    path: 'users',
    method: PUT,
    headers: { ...headerOptions },
    body: { id, updates },
  });

export const updateUserPhoto = (userId, photoUpdates, headerOptions) =>
  api({
    path: `users/photo/${userId}`,
    method: PUT,
    headers: { ...headerOptions },
    body: photoUpdates,
  });

export const sendResetPasswordEmail = (email) =>
  api({
    path: 'auth/reset_password',
    method: POST,
    body: { email },
  });

export const notificationsCheck = (user, headerOptions) =>
  api({
    path: 'users/notificationsCheck',
    method: PUT,
    headers: { ...headerOptions },
    body: { user },
  });

export const changePasswordService = (
  id,
  original,
  newPassword,
  headerOptions,
) =>
  api({
    path: 'users/updatePassword',
    method: PUT,
    headers: { ...headerOptions },
    body: { id, original, newPassword },
  });

export const resetPasswordService = (id, newPassword) =>
  api({
    path: 'users/resetPassword',
    method: PUT,
    body: { id, newPassword },
  });

export const loginFromConnectionEmailService = (_id) =>
  api({
    path: 'users/connectionEmail',
    method: POST,
    body: { _id },
  });

export const loginFromRestPasswordEmailService = (_id) =>
  api({
    path: 'auth/reset_login',
    method: POST,
    body: { _id },
  });
