import React, { Component } from 'react';
import { Container, Item, Icon, Label } from 'semantic-ui-react';
import TeamProfiles from './TeamProfiles';
import './Team.css';

class Team extends Component {
  renderProfiles = () => {
    const teamProfiles = TeamProfiles.map((profile) => (
      <Item className="team-member">
        <img className="headshot" alt={profile.alt} src={profile.src} />
        <Item.Content>
          <Item.Header as="h2" className="team-member-name">
            {profile.name}
          </Item.Header>
          <Item.Meta>
            <span className="team-member-position">{profile.position}</span>
          </Item.Meta>
          <Item.Description>
            <p className="team-member-position">{profile.bio}</p>
          </Item.Description>
          <Item.Extra>
            <Label color="linkedin" target="_blank" href={profile.linkedin}>
              <Icon name="linkedin" size="large" />
            </Label>
            {profile.twitter && (
              <Label color="twitter" target="_blank" href={profile.twitter}>
                <Icon name="twitter" size="large" />
              </Label>
            )}
            {profile.instagram && (
              <Label color="instagram" target="_blank" href={profile.instagram}>
                <Icon name="instagram" size="large" />
              </Label>
            )}
          </Item.Extra>
        </Item.Content>
      </Item>
    ));
    return teamProfiles;
  };

  render() {
    return (
      <div>
        <Container
          className="team-page"
          style={{ paddingTop: '15px', paddingBottom: '15px' }}
        >
          <div>
            <h1>About us</h1>
            <p>
              Based in Northern Michigan, Los Angeles, and Iceland, our team
              represents a range of experiences and skills - from small business
              owners, to immigrants, to Hollywood producers - while uniting on
              the common goal of advancing trust and transparency in the small
              business world, whether you&apos;re a homeowner, seasoned veteran
              entrepreneur, or you just want to test an idea within a supportive
              community.
            </p>
            <p>
              Read more about our mission{' '}
              <a
                href={
                  'https://medium.com/@RhizAboveIt/what-is-rh%C4%ABz-1964986dde0f'
                }
                target="_blank"
                rel='noreferrer'
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                here
              </a>
            </p>
          </div>
          <h1>Meet the Rhiz team</h1>
          <Item.Group>{this.renderProfiles()}</Item.Group>
        </Container>
      </div>
    );
  }
}

export default Team;
