// this feature is for showing the pricepoint of companies
// row of dollar sign icons, out of 5

// Dependencies
import React from 'react';
import { Icon } from 'semantic-ui-react';

// Styled Components
import { Row, Dollar } from './StyledComponents';

const dollars = [0, 1, 2, 3, 4];
const PricePoint = ({ price, size, style }) => (
    <Row className="pricepoint" style={style}>
      {dollars.map((dollar) => {
        if (dollar < price) {
          return (
            <Dollar>
              <Icon name="dollar" size={size} key={dollar} />
            </Dollar>
          );
        }
        return (
          <Dollar>
            <Icon name="dollar sign" size={size} key={dollar} />
          </Dollar>
        );
      })}
    </Row>
  );

export default PricePoint;
