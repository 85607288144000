const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const getItem = (key) => async() =>
  await window.localStorage.getItem(key);

export const setItem = (key) => async(val) =>
  await window.localStorage.setItem(key, val);

export const removeItem = (key) => async() =>
  await window.localStorage.removeItem(key);

export const getRefreshToken = () => getItem(REFRESH_TOKEN)();
export const setRefreshToken = (token) => setItem(REFRESH_TOKEN)(token);
export const removeRefreshToken = () => removeItem(REFRESH_TOKEN)();

export const getFromLocalStorage = (key) => getItem(key)();
export const setToLocalStorage = (key, val) => setItem(key)(val);
export const removeFromLocalStorage = (key) => removeItem(key)();
