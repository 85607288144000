import React from 'react';
import { Input } from 'semantic-ui-react';
import validator from 'validator';

export const required = (value) => (value ? undefined : 'Required');
export const email = (value) =>
  value && !validator.isEmail(value) ? 'Invalid email address' : undefined;
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength25 = maxLength(25);
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength2 = minLength(2);
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;
export const passwordFormatCheck = (val) =>
  val &&
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(val)
    ? undefined
    : 'Must follow proper password format.';

export const renderInput = ({
  input,
  type,
  placeholder,
  meta: { touched, error, warning }, // eslint-disable-line
}) => (
  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
    <Input
      fluid
      {...input}
      required={true}
      placeholder={placeholder}
      type={type}
    />
  </div>
);
