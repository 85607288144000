import React from 'react';
import { Image } from 'semantic-ui-react';

// components
// import VertLine from './VertLine';

// styles
import {
  Container,
  Row,
  PhotoText,
  DeleteButton,
  UploadLabel,
  UploadInput,
} from '../Styles';

// Component for BusinessProfile page that renders the profile pic,
// banner picture and business title at top of screen
const ProfilePictures = (props) => {
  // test pictures for component, props coming soon
  const banner =
    'https://res.cloudinary.com/rhiz/image/upload/v1559088219/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/Blades_of_Glory_-_Thumb_cagrij.jpg';
  const profilePic =
    'https://res.cloudinary.com/rhiz/image/upload/v1574201715/Default/R_Light_Grey_Background_1_tjrldc.png';
  return (
    <div className="business-banner" style={{ marginTop: '3%' }}>
      <Container
        style={{
          alignItems: 'center',
          backgroundImage: `url(${props.header || banner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }}
      >
        <Image
          src={props.profilePic || profilePic}
          style={{
            width: '17%',
            position: 'absolute',
            left: '8%',
            bottom: '-10%',
            boxShadow:
              '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
          }}
        />
        <Row style={{ margin: '10% 0' }}>
          <Container style={{ alignItems: 'center' }}>
            <PhotoText
              name="topLine"
              style={{
                fontSize: '22px',
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
              }}
            >
              {props.firstLine || 'Business Name'}
            </PhotoText>
            <PhotoText
              name="secondLine"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
              }}
            >
              {props.secondLine || 'Business Category'}
            </PhotoText>
          </Container>
        </Row>
      </Container>
      <Container style={{ margin: '5% 0', width: '75%' }}>
        <Row>
          <Container style={{ marginRight: '36%' }}>
            <div>PROFILE PHOTO</div>
            <Row>
              <DeleteButton
                name="profilephoto"
                onClick={props.deleteProfilePic}
              >
                Delete
              </DeleteButton>
              <span style={{ padding: '0 4px' }}>or</span>
              <UploadLabel for="profile-img-upload">Upload</UploadLabel>
              <UploadInput
                id="profile-img-upload"
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(e) => props.addProfilePic(e)}
              ></UploadInput>
            </Row>
          </Container>
          <Container>
            <div>HEADER PHOTO</div>
            <Row>
              <DeleteButton name="profilephoto" onClick={props.deleteHeaderPic}>
                Delete
              </DeleteButton>
              <span style={{ padding: '0 4px' }}>or</span>
              <UploadLabel for="header-img-upload">Upload</UploadLabel>
              <UploadInput
                id="header-img-upload"
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={(e) => props.addHeaderPic(e)}
              ></UploadInput>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
};

export default ProfilePictures;
