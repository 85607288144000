import React from 'react';

const Bell = ({ width, height }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    aria-labelledby="bellIconTitle"
    stroke="#C6C6C6"
    strokeWidth="1.5"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#C6C6C6"
  >
    {/* eslint-disable max-len */}
    <path
      strokeLinejoin="round"
      d="M10.5,4.5 C12.1666667,4.5 13.8333333,4.5 15.5,4.5 C17.5,4.5 18.8333333,3.83333333 19.5,2.5 L19.5,18.5 C18.8333333,17.1666667 17.5,16.5 15.5,16.5 C13.8333333,16.5 12.1666667,16.5 10.5,16.5 L10.5,16.5 C7.1862915,16.5 4.5,13.8137085 4.5,10.5 L4.5,10.5 C4.5,7.1862915 7.1862915,4.5 10.5,4.5 Z"
      transform="rotate(90 12 10.5)"
    />
    <path
      d="M11,21 C12.1045695,21 13,20.1045695 13,19 C13,17.8954305 12.1045695,17 11,17"
      transform="rotate(90 12 19)"
    />
  </svg>
);

export default Bell;
