/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import validator from 'validator';
import { Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderInput, email, required } from '../utils/formValidations';

class RetrievePasswordForm extends Component {
  state = {
    email: '',
    emailSent: false,
    validEmail: true,
  };

  renderForm = () => {
    const { handleInput } = this;

    return (
      <div>
        <Field
          name="email"
          component={renderInput}
          type="email"
          placeholder="Email associated with account"
          validate={[email, required]}
          onChange={handleInput}
        />
      </div>
    );
  };

  send = (event) => {
    event.preventDefault();
    // validate email
    const { email } = this.state;
    const emailIsValid = validator.isEmail(email);
    if (emailIsValid) {
      this.setState(
        {
          validEmail: true,
        },
        () => {
          this.props.sendEmail(this.state.email);
        },
      );
    } else {
      this.setState({
        validEmail: false,
      });
    }
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      pristine,
      submitting,
      message,
      emailSent,
      existingAccount,
    } = this.props;
    const { send } = this;
    const { validEmail } = this.state;

    return (
      <div>
        {!emailSent ? (
          <form
            onSubmit={send}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {this.renderForm()}
            {!validEmail && (
              <div style={{ color: 'red', paddingBottom: '5px' }}>
                Please enter a valid email
              </div>
            )}
            {!existingAccount && (
              <div style={{ color: 'red', paddingBottom: '5px' }}>
                {message}
              </div>
            )}
            <Button
              positive
              type="submit"
              disabled={pristine || submitting}
              fluid
            >
              Send password reset link
            </Button>
          </form>
        ) : (
          <div>Please check your email for instructions</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

// eslint-disable-next-line
RetrievePasswordForm = reduxForm({
  form: 'retrieve-password', // a unique identifier for this form
})(RetrievePasswordForm);

export default connect(mapStateToProps)(RetrievePasswordForm);
