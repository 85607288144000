import React from 'react';

const Breakline = (props) => {
  const { height, width } = props;
  return (
    <svg height={height} width={width}>
      <g fill='none' stroke='black' strokeWidth='6'>
        <path strokeLinecap='square' d='M5 60 l215 0' />
      </g>
    </svg>
  );
};

export default Breakline;
