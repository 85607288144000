import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Route from 'react-router-dom/Route';
import FormContainer from './FormContainer';
import ChangePassword from './ChangePassword';
import ConsumerProfile from '../ConsumerProfile/ConsumerProfile';

const ProfileSidebar = (props) => {
  const { resetPassword } = props;
  return (
    <div className="profile-page-container">
      {!resetPassword && (
        <nav className="side-nav-bar desktop">
          <div style={{ marginBottom: '4%' }}>Edit profile</div>
          <div
            className="sidebar-nav-link"
            activeClassName="sidebar-nav-active"
            activeStyle={{ color: '#00BE6E' }}
            style={{ cursor: 'pointer' }}
            onClick={props.viewProfile}
            onKeyDown={props.viewProfile}
            role="presentation"
          >
            View profile
          </div>
          <hr
            style={{
              color: 'black',
              backgroundColor: 'black',
              height: '2px',
              width: '20%',
              marginBottom: '1em',
            }}
          />
          {/* <div style={{color: '#00BE6E'}}>Basic info</div> */}
          <NavLink
            to="/edit_profile"
            className="sidebar-nav-link"
            activeClassName="sidebar-nav-active"
            activeStyle={{ color: '#00BE6E' }}
          >
            Basic info
          </NavLink>
          <NavLink
            to="/change_password"
            className="sidebar-nav-link"
            activeClassName="sidebar-nav-active"
            activeStyle={{ color: '#00BE6E' }}
          >
            Change password
          </NavLink>
        </nav>
      )}
      <Route
        path="/edit_profile"
        render={() => (
          <FormContainer
            user={props.user}
            updateMessage={props.updateMessage}
            onSubmit={props.onSubmit}
            updateProfilePic={props.updateProfilePic}
          />
        )}
      />
      <Route
        path="/change_password"
        render={() => (
          <ChangePassword
            {...props}
            changePassword={props.changePassword}
            errorMessage={props.changePasswordMessage}
          />
        )}
      />
      <Route
        path="/reset_password"
        render={() => (
          <ChangePassword
            {...props}
            changePassword={props.changePassword}
            reset={props.reset}
            errorMessage={props.changePasswordMessage}
            resetPassword={props.resetPassword}
          />
        )}
      />
      <Route
        path={`/user/${props.user.username}`}
        render={() => <ConsumerProfile user={props.user} />}
      />
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(ProfileSidebar);
