import React from 'react';
import { Table } from 'semantic-ui-react';
import SentReferralList from './ReferralItem';

const SentReferralsTable = (props) => {
  const { visibleReferrals } = props;
  return (
    <Table className="Referrals-Table" basic="very" striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Date Sent</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {visibleReferrals.map((referral, idx) => (
          <SentReferralList
            key={referral._id}
            idx={idx}
            deleteReferralFunction={props.deleteReferralFunction}
            redeem={props.redeem}
            referral={referral}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default SentReferralsTable;
