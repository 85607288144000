/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import Pencil from './common/Pencil';

import { fetchProfileById } from '../../services';
import { convertDate, getDate } from '../../util';

class Messages extends Component {
  state = {
    firstName: 'Francis',
    lastName: 'Crozier',
    businessName: 'Blades of Glory',
    modal: false,
    pathname: '',
    state: {},
    loaded: false,
  };

  componentDidMount = () => {
    const {
      connections: { selectedConnection },
    } = this.props;
    let profileType = 'users';
    let pathname = `/user/${selectedConnection.otherProfile.name}`;
    if (selectedConnection.connection.type === 'B2C') {
      profileType =
        selectedConnection.category === 'business' ? 'users' : 'businesses';
      if (selectedConnection.category === 'personal') {
        pathname = `/business/${selectedConnection.otherProfile.name}`;
      }
    }
    this.setProfile(selectedConnection.otherProfile, profileType, pathname);
  };

  componentDidUpdate = (prevProps) => {
    const {
      connections: { selectedConnection },
    } = this.props;
    const newConnection =
      prevProps.connections.selectedConnection.connection._id !==
        selectedConnection.connection._id ||
      prevProps.connections.selectedConnection.connection.type !==
        selectedConnection.connection.type;
    const newCategory =
      prevProps.connections.selectedConnection.category !==
      selectedConnection.category;
    if (newConnection || newCategory) {
      let profileType = 'users';
      let pathname = `/user/${selectedConnection.otherProfile.name}`;
      if (selectedConnection.connection.type === 'B2C') {
        profileType =
          selectedConnection.category === 'business' ? 'users' : 'businesses';
        if (selectedConnection.category === 'personal') {
          pathname = `/business/${selectedConnection.otherProfile.name}`;
        }
      }
      this.setProfile(selectedConnection.otherProfile, profileType, pathname);
    }
  };

  checkForDifferenceInQuote = (prevPropQuote, newPropQuote) =>
    isEqual(prevPropQuote, newPropQuote);

  setProfile = (otherProfile, type, pathname) => {
    fetchProfileById(otherProfile._id, type)
      .then((profileInfo) => {
        this.setState({
          profileInfo,
          state: profileInfo.dreamTeam
            ? { business: profileInfo }
            : { user: profileInfo },
          pathname,
          loaded: true,
        });
      })
      .catch((err) => {
        console.error('error fetching profile: ', err);
      });
  };

  render() {
    const {
      connections: { selectedConnection },
      quotes,
    } = this.props;
    const { profileInfo, pathname, state, loaded } = this.state;
    const quoteHasBeenSent =
      selectedConnection.connection.type === 'B2C' &&
      Object.keys(quotes.sent).length > 0;
    const quoteHasBeenSaved =
      selectedConnection.connection.type === 'B2C' &&
      Object.keys(quotes.saved).length > 0;
    const buttonText =
      !quoteHasBeenSent && quoteHasBeenSaved
        ? 'View/Edit Quote'
        : !quoteHasBeenSaved && !quoteHasBeenSent
        ? 'Write Quote'
        : 'Write New Quote';

    return (
      <div>
        {loaded && (
          <div>
            <div>
              {selectedConnection.otherProfile.profilePic ? (
                <img
                  src={selectedConnection.otherProfile.profilePic}
                  style={{ width: '5em', height: '5em' }}
                  alt="selected connection other profile pic"
                />
              ) : (
                <img
                  src="https://react.semantic-ui.com/images/avatar/small/jenny.jpg"
                  alt="jenny"
                />
              )}
            </div>
            <div>
              <div className="User-viewProfile">
                {selectedConnection.connection ? (
                  <h4>{selectedConnection.otherProfile.name}</h4>
                ) : (
                  <h4>Francis Crozier</h4>
                )}
                <p>{profileInfo.city}</p>
                <Link
                  className="View-Profile"
                  to={{
                    pathname,
                    state,
                  }}
                >
                  View Profile
                </Link>
              </div>

              {selectedConnection.category === 'personal' &&
                selectedConnection.connection.type === 'C2C' && (
                  <div>
                    <div
                      className="Message-flex"
                      style={{ marginBottom: '3%' }}
                    >
                      <h4>Connected:</h4>
                      <p>{convertDate(
                          selectedConnection.connection.connected,
                          'MMDDYYYY',
                        )}</p>
                    </div>
                    <div className="Message-horizontal-line"></div>

                    <div className="delete-report">
                      <Link to="/InboxPage">
                        <p>Delete conversation</p>
                      </Link>
                      <p>Block or report</p>
                    </div>
                  </div>
                )}

              {selectedConnection.connection.type === 'B2C' && (
                <div>
                  <div className="Message-flex">
                    <h4>Urgency:</h4>
                    <p>{selectedConnection.connection.urgency}</p>
                  </div>
                  <div className="Message-flex">
                    <h4>Start date:</h4>
                    <p>{selectedConnection.connection.startDate}</p>
                  </div>
                  <div className="Message-flex">
                    <h4>Connected:</h4>
                    <p>
                      {convertDate(selectedConnection.connection.connected)}
                    </p>
                  </div>
                  {quoteHasBeenSent && (
                    <div>
                      <div className="Message-flex">
                        <h4>Last Quote sent:</h4>
                        <p>{getDate(quotes.sent.modifiedOn, 'MMDDYYYY')}</p>
                      </div>
                      <div className="Message-flex">
                        <h4>Quote Status:</h4>
                        <p className={quotes.sent.status}>
                          {quotes.sent.status.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  )}
                  {this.props.approvedDate.length > 0 && (
                    <div className="Message-flex-approved">
                      <h4>Approved:</h4>
                      <p>{this.props.approvedDate}</p>
                    </div>
                  )}
                  {this.props.completeDate.length > 0 && (
                    <div className="Message-flex-complete">
                      <h4>Completed:</h4>
                      <p>{this.props.completeDate}</p>
                    </div>
                  )}
                  {this.props.denyDate.length > 0 && (
                    <div className="Message-flex-deny">
                      <h4>Turned Down:</h4>
                      <p>{this.props.quoteSentDate}</p>
                    </div>
                  )}
                  {this.props.expiredDate.length > 0 && (
                    <div className="Message-flex-expired">
                      <h4>Expired:</h4>
                      <p>{this.state.expiredDate}</p>
                    </div>
                  )}
                </div>
              )}

              {selectedConnection.category === 'personal' &&
                selectedConnection.connection.type === 'B2C' && (
                  <div>
                    <div>
                      <div className="Send-Quote">
                        {quoteHasBeenSent && (
                          <Link
                            to={{
                              pathname: '/QuoteView',
                              state: {
                                ...selectedConnection,
                                viewType: 'sent',
                                viewer: 'consumer',
                              },
                            }}
                          >
                            <div className="view-quote-button">
                              <p>View Quote</p>
                            </div>
                          </Link>
                        )}
                      </div>
                      {quotes.declined.length > 0 && (
                        <Link
                          to={{
                            pathname: '/QuoteView',
                            state: {
                              ...selectedConnection,
                              viewType: 'rejected',
                              viewer: 'consumer',
                            },
                          }}
                        >
                          <div
                            className="view-quote-button"
                            style={{ marginTop: '5%' }}
                          >
                            <p>Declined Quotes</p>
                          </div>
                        </Link>
                      )}

                      {this.props.paid.length > 0 && (
                        <button className="paid-button ">
                          <p>Paid</p>
                        </button>
                      )}

                      {this.props.completeDate.length > 0 &&
                        !this.props.paid.length > 0 && (
                          <div className="payment-outstanding">
                            <h4>PAYMENT OUTSTANDING</h4>
                          </div>
                        )}

                      {this.props.reviewSent && (
                        <div>
                          <div className="view-edit">
                            <h4>You left a review:</h4>
                            <Link to="/EditConsumerReview">
                              <p>View & edit</p>
                            </Link>
                          </div>
                        </div>
                      )}

                      {this.props.paid.length > 0 && !this.props.reviewSent && (
                        <div>
                          <Link to="/ConsumerReviewForm">
                            <div className="write-review-flex">
                              <h4 className="write-review" color="green">
                                Write a review
                              </h4>
                              <Pencil width="20px" height="20px" />
                            </div>
                          </Link>
                        </div>
                      )}

                      {this.props.busninessReviewSent && (
                        <div className="view-business-review">
                          <h4>This business reviewed you:</h4>
                          <Link to="/ConsumerReviewView">
                            <p>View</p>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {selectedConnection.category === 'business' && (
                <div>
                  <div>
                    <div className="Send-Quote">
                      <div>
                        {quoteHasBeenSent && (
                          <Link
                            to={{
                              pathname: '/QuoteView',
                              state: {
                                ...selectedConnection,
                                viewType: 'sent',
                                viewer: 'business',
                              },
                            }}
                          >
                            <div
                              className="view-quote-button"
                              onClick={this.props.SendQuote}
                              onKeyDown={this.props.SendQuote}
                            >
                              <p>View Sent Quote</p>
                            </div>
                          </Link>
                        )}

                        <Link
                          to={{
                            pathname: '/QuoteForm',
                            state: {
                              ...selectedConnection,
                            },
                          }}
                        >
                          <div
                            className={
                              quoteHasBeenSent
                                ? 'view-quote-button-sandwiched'
                                : 'view-quote-button'
                            }
                          >
                            <p style={{ color: 'white' }}>{buttonText}</p>
                          </div>
                        </Link>

                        {quotes.rejected.length > 0 && (
                          <Link
                            to={{
                              pathname: '/QuoteView',
                              state: {
                                ...selectedConnection,
                                viewType: 'rejected',
                                viewer: 'business',
                              },
                            }}
                          >
                            <div
                              className="view-quote-button"
                              style={{ marginTop: '3%' }}
                            >
                              <p>Previous Quotes</p>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>

                    {this.props.pending && (
                      <div className="pending">
                        <h4>Complete - pending customer approval</h4>
                      </div>
                    )}
                    {!this.props.pending &&
                      !this.props.paid.length > 0 &&
                      this.props.approvedDate.length > 0 &&
                      !this.props.completeDate.length > 0 && (
                        <div className="approved-button">
                          <h4>Mark job complete</h4>
                        </div>
                      )}

                    {this.props.paid.length > 0 && (
                      <button className="paid-button ">
                        <p>Paid</p>
                      </button>
                    )}
                    {this.props.completeDate.length > 0 &&
                      !this.props.paid.length > 0 && (
                        <div className="payment-outstanding">
                          <h4>PAYMENT OUTSTANDING</h4>
                        </div>
                      )}

                    {this.props.reviewSent && (
                      <div>
                        <div className="view-edit">
                          <h4>You left a review:</h4>
                          <Link to="/EditBusinessReview">
                            <p>View & edit</p>
                          </Link>
                        </div>
                      </div>
                    )}

                    {this.props.paid.length > 0 && !this.props.reviewSent && (
                      <div>
                        <Link to="/BusinessReviewForm">
                          <div className="write-review-flex">
                            <h4 className="write-review" color="green">
                              Write a review
                            </h4>
                            <Pencil width="20px" height="20px" />
                          </div>
                        </Link>
                      </div>
                    )}

                    {this.props.busninessReviewSent && (
                      <div className="view-business-review">
                        <h4>This business reviewed you:</h4>
                        <Link to="/ConsumerReviewView">
                          <p>View</p>
                        </Link>
                      </div>
                    )}

                    <div className="view-business-review">
                      <Link to="/MyReviewsPage/MyBusinessReviews">
                        <h4>Read customer&apos;s review</h4>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(Messages);
