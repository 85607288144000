import React from 'react';
import './Modal.css';

const BackDrop = () => (

  <div className="backdrop">

  </div>

);

export default BackDrop;
