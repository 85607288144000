import React from 'react';

export default function Inbox() {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="inboxAltIconTitle"
      stroke="#00bf6f"
      strokeWidth=".65"
      strokeLinecap="square"
      strokeLinejoin="miter"
      fill="none"
      color="#00bf6f"
    >
      <title id="inboxAltIconTitle">Inbox</title>
      <path d="M3 13H9V16H15V13H21" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13L5 4H19L21 13V20H3V13Z"
      />
      <path d="M7 7H17" /> <path d="M6.5 10H17.5" />
    </svg>
  );
}
