import React, { useEffect, useState } from 'react';
import { Embed } from 'semantic-ui-react';
import { getGoogleMapKey } from '../../../services/apiRequests';

const formatAddress = (address) => (address.split(', ').join(' ').split(' ').join('+'));

const BusinessMapContainer = ({ address }) => {
  const [mapKey, setMapKey] = useState('');

  useEffect(() => {
    getGoogleMapKey()
      .then(({ data }) => {
        setMapKey(data);
      })
      .catch((err) => {
        console.error('error: ', err);
      });
  }, []);


  const formattedAddress = formatAddress(address);
  const googleUrl = `https://www.google.com/maps/embed/v1/place?key=${mapKey}&q=${formattedAddress}`;

  return (
    <div className="business-map">
      <Embed active url={googleUrl} />
    </div>
  );
};

export default BusinessMapContainer;
