import React, { useState, useEffect } from 'react';
import Router from './Router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationPopup from './views/Notifications/NotificationPopup';
import Loading from './components/Loader';
import {
  userActions,
  businessActions,
  connectionsActions,
  dreamTeamActions,
  favoritesActions,
  notificationsActions,
  socketsActions,
} from './actions';
import { socketServices } from './services';
import { notificationText, getSearchQueryProp } from './util';
import { useRefresh } from './hooks';

const App = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [fetchingOthers, setFetchingOthers] = useState(false);
  const {
    user,
    userActions,
    business,
    businessActions,
    favorites,
    favoritesActions,
    connections,
    connectionsActions,
    notifications,
    notificationsActions,
    dreamTeamRequests,
    dreamTeamActions,
    history,
    location,
  } = props;
  const refresh = useRefresh(history, location);

  const fetchAllInfo = () => {
    if (location.pathname !== '/reset_password') {
      if (user.business) {
        businessActions.fetchBusiness(user.business._id);
        dreamTeamActions.fetchDreamTeamRequests(user.business._id);
      }

      connectionsActions.fetchConnections(user);
      favoritesActions.getFavorites(user._id, user.business?._id);
      notificationsActions.fetchNotifications(user);
      setFetchingOthers(true);
    }
  };

  useEffect(() => {
    const checkIfUserLoggedIn = () => {
      const id = getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'id',
      });
      const businessEmail = getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'businessEmail',
      });
      const newUserId = getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'newUserId',
      });
      const userIdQueryParam = getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'userId',
      });

      if (id && businessEmail && location.pathname === '/InboxPage') {
        userActions.loginUserFromConnectionEmail(id);
      } else if (location.pathname === '/reset_password' && userIdQueryParam) {
        userActions.loginUserFromResetPasswordEmail(userIdQueryParam);
      } else if (newUserId) {
        userActions.verifyUser(newUserId);
      } else {
        userActions.fetchUser();
      }
    };

    checkIfUserLoggedIn();

    return () => socketServices.removeListeners();
  }, []);

  useEffect(() => {
    if (user.fetching === false) {
      if (user._id && !loaded && location.pathname !== '/reset_password') {
        setLoggedIn(true);

        fetchAllInfo();
      } else {
        setLoaded(true);
      }
    }
  }, [user.fetching]);

  useEffect(() => {
    if (user.loggedOut === false && loaded) {
      setLoaded(false);
      setLoggedIn(true);
      fetchAllInfo();
      refresh();
    }
  }, [user.loggedOut]);

  useEffect(() => {
    const notificationAlert = (notification) => {
      const notificationMessage = notificationText(notification);
      toast(
        <NotificationPopup
          notification={notification}
          notificationMessage={notificationMessage}
        />,
      );
    };

    const allFetchingFinished =
      !business.fetchBusiness &&
      !notifications.fetchingNotifications &&
      !favorites.fetchingFavorites &&
      !connections.fetchingConnections &&
      !dreamTeamRequests.fetchingRequests;
    if (allFetchingFinished && fetchingOthers) {
      setFetchingOthers(false);
      setLoaded(true);
      socketServices.emitUserConnect(user);
      socketServices.appComponentListeners(props, notificationAlert);
    }
  }, [
    business.fetchBusiness,
    notifications.fetchingNotifications,
    favorites.fetchingFavorites,
    connections.fetchingConnections,
    dreamTeamRequests.fetchingRequests,
  ]);

  return (
    <div>
      {loaded ? (
        <Switch>
          <Route
            path="/"
            render={() => (
              <Router
                user={user}
                userActions={userActions}
                business={business}
                businessActions={businessActions}
                connections={connections}
                connectionsActions={connectionsActions}
                dreamTeamRequests={dreamTeamRequests}
                dreamTeamActions={dreamTeamActions}
                notifications={notifications}
                notificationsActions={notificationsActions}
                favorites={favorites}
                favoritesActions={favoritesActions}
                history={history}
                location={location}
                loggedIn={loggedIn}
              />
            )}
          />
        </Switch>
      ) : (
        <Loading />
      )}
      <ToastContainer autoClose={5000} position={toast.POSITION.BOTTOM_RIGHT} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  business: state.business,
  dreamTeamRequests: state.dreamTeamRequests,
  connections: state.connections,
  notifications: state.notifications,
  favorites: state.favorites,
  sockets: state.sockets,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
  businessActions: bindActionCreators(businessActions, dispatch),
  dreamTeamActions: bindActionCreators(dreamTeamActions, dispatch),
  connectionsActions: bindActionCreators(connectionsActions, dispatch),
  notificationsActions: bindActionCreators(notificationsActions, dispatch),
  favoritesActions: bindActionCreators(favoritesActions, dispatch),
  socketsActions: bindActionCreators(socketsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
