import React from 'react';
import { Image } from 'semantic-ui-react';
// import { connect } from 'react-redux';
// import tier1 from '../../../assets/images/fern-tier-1.png';
// import tier2 from '../../../assets/images/fern-tier-2.png';
import tier3 from '../../../assets/images/fern-tier-3.png';
// import tier4 from '../../../assets/images/fern-tier-4.png';

const SubscriptionModal = (props) => (
  <div id="SubscriptionModal" className="subscription-modal">
    <div className="image-container">
      <Image src={tier3}></Image>
    </div>
    <div className="subscription-type">
      <h3>You are on the Full-Time subscription.</h3>
    </div>
    <div className="view-or-switch">
      <button className="btn" onClick={props.viewSubscription}>
        View or Change Subscription
      </button>
    </div>
    <div className="billing-info-container">
      <p>$79/month billed monthly</p>
      <p>Renews Sunday, August 4th, 2019</p>
      <p>
        Billing card XXXX-XXXX-1234 <button className="btn">Update</button>
      </p>
    </div>
    <div>
      <button className="btn cancel">Cancel subscription</button>
    </div>
  </div>
);

export default SubscriptionModal;
