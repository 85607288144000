/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Button } from 'semantic-ui-react';
import { Prompt } from 'react-router';
import ProfilePictures from './ProfilePictures';
import { states, mainCats, subCatSuggestions } from '../constants';
import { toast } from 'react-toastify';

// styled components
import { Container } from '../Styles';
import '../Styles/editBusiness.css';

class BusinessDetails extends Component {
  state = {
    name: this.props.business.name || '',
    email: this.props.business.email || '',
    mainCategory: this.props.business.mainCategory || '',
    subCategories: this.props.business.subCategories || [],
    about: this.props.business.about || '',
    street: this.props.business.street || '',
    city: this.props.business.city || '',
    zip: this.props.business.zip || '',
    description: this.props.business.description || '',
    profileImgFile: '',
    profilePic: this.props.business.profilePic || null,
    prevProfilePic_id: this.props.business.prevProfilePic_id || null,
    prevHeaderPic_id: this.props.business.prevHeaderPic_id || null,
    profilePicChanged: false,
    headerImgFile: '',
    headerPic: this.props.business.headerPic || null,
    headerPicChanged: false,
    topLine: this.props.business.topLine || '',
    secondLine: this.props.business.secondLine || '',
    editsMade: false,
    mainCategories: mainCats,
    state: this.props.business.state || '',
    saveConfirmation: '',
    showMessage: false,
    updating: false,
    isBlocking: false,
    chooseSubCategory: false,
    subCatSuggestions,
    subCatTextValue: '',
    website: this.props.business.website || '',
    isValidUrl: true,
  };

  componentDidUpdate = (prevProps) => {
    const { business } = this.props;
    const { saveConfirmation, showMessage, updating } = this.state;

    if (
      business.message &&
      saveConfirmation !== business.message &&
      showMessage
    ) {
      if (!updating && business.profilePic !== prevProps.business.profilePic) {
        this.setState({
          saveConfirmation: business.message,
          profilePic: business.profilePic,
        });
      } else {
        this.setState({
          saveConfirmation: business.message,
          updating: false,
        });
      }
    }
  };

  blockingHandler = (e) => {
    this.setState({
      isBlocking: e.target.value !== this.state.isBlocking,
    });
  };

  notifySuccess = (message) =>
    toast.success(message, {
      className: 'Referral-Toast-Success-Edit-Changes',
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyError = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  handleProfileImageChange = (e) => {
    e.preventDefault();
    const { business, businessActions } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      const photoUpdate = {
        newPhoto: reader.result,
        prevPhotoId: business.prevProfilePic_id,
        folderName: 'Business/profile_pic',
        photoType: 'profilePic',
      };
      businessActions.updatePhotoAction(business._id, photoUpdate);
    };
    file && reader.readAsDataURL(file);
  };

  deleteProfileImgHandler = () => {
    const { business, businessActions } = this.props;
    const profilePicIsAlreadyNull = business.profilePic === null;
    if (!profilePicIsAlreadyNull) {
      const photoUpdate = {
        newPhoto: null,
        prevPhotoId: business.prevProfilePic_id,
        folderName: 'Business/profile_pic',
        photoType: 'profilePic',
      };

      businessActions.updatePhotoAction(business._id, photoUpdate);
    }
  };

  handleHeaderImageChange = (e) => {
    e.preventDefault();
    const { business, businessActions } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      const photoUpdate = {
        newPhoto: reader.result,
        prevPhotoId: business.prevHeaderPic_id,
        folderName: 'Business/header_pic',
        photoType: 'headerPic',
      };
      businessActions.updatePhotoAction(business._id, photoUpdate);
    };
    file && reader.readAsDataURL(file);
  };

  deleteHeaderImgHandler = () => {
    const { business, businessActions } = this.props;
    const headerIsAlreadyNull = business.headerPic === null;
    if (!headerIsAlreadyNull) {
      const photoUpdate = {
        newPhoto: null,
        prevPhotoId: business.prevHeaderPic_id,
        folderName: 'Business/header_pic',
        photoType: 'headerPic',
      };

      businessActions.updatePhotoAction(business._id, photoUpdate);
    }
  };

  // for any input fields
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      editsMade: true,
      showMessage: false,
      saveConfirmation: '',
      isBlocking: true,
    });
  };

  handleWebsiteChange = (event) => {
    const { value } = event.target;
    const isValidUrl = value.trim().length === 0 || getIsValidUrl(value);

    this.setState({
      website: value,
      isValidUrl,
      showMessage: false,
      saveConfirmation: '',
      isBlocking: true,
      editsMade: true,
    });
  }

  categorySelect = (event, data) => {
    if (event.target.textContent === '') {
      return true;
    }

    this.setState({
      mainCategory: data.value,
      editsMade: true,
      showMessage: false,
      saveConfirmation: '',
      chooseSubCategory: true,
      subCategories: [],
      isBlocking: true,
    });
  };

  subCategorySelect = (event, data) => {
    const isNotAlreadySelected =
      this.state.subCategories.indexOf(event.target.textContent) === -1;
    if (event.target.textContent === '') {
      this.setState({
        subCategories: data.value,
        editsMade: true,
        showMessage: false,
        saveConfirmation: '',
        isBlocking: true,
      });
    } else if (this.state.subCategories.length < 3 && isNotAlreadySelected) {
      const sub = this.state.subCategories.slice();
      sub.push(event.target.textContent);
      this.setState({
        subCategories: sub,
        editsMade: true,
        showMessage: false,
        saveConfirmation: '',
        isBlocking: true,
      });
    }
  };

  subCategoryTypeInput = (event) => {
    const subCatLimitReached = this.state.subCategories.length === 3;
    if (!subCatLimitReached) {
      this.setState({
        subCatTextValue: {
          text: event.target.value,
          value: event.target.value,
        },
      });
    }
  };

  addCustomSubCatsToSuggested = () => {
    const { subCategories, mainCategory } = this.state;
    const mainCategoryChosen =
      mainCategory.length &&
      !!subCatSuggestions[mainCategory] &&
      Array.isArray(subCatSuggestions[mainCategory].dropdown);

    if (mainCategoryChosen) {
      subCategories.forEach((subcategory) => {
        const subCatIsCustom = !subCatSuggestions[mainCategory].values[
          subcategory
        ];
        if (subCatIsCustom) {
          const newSubDropdown = { value: subcategory, text: subcategory };
          subCatSuggestions[mainCategory].dropdown.push(newSubDropdown);
          subCatSuggestions[mainCategory].values[subcategory] = true;
        }
      });
      return subCatSuggestions[mainCategory].dropdown;
    }
    return [];
  };

  stateSelect = (event) => {
    this.setState({
      state: event.target.textContent,
      editsMade: true,
      showMessage: false,
      saveConfirmation: '',
      isBlocking: true,
    });
  };

  handleFocus = (e) => {
    e.target.setAttribute('autocomplete', 'nope');
  };

  submitChanges = (event) => {
    event.preventDefault();
    const { business, businessActions } = this.props;
    const { editsMade } = this.state;
    if (editsMade) {
      const { _id } = business;
      const nameChanged = this.state.name !== business.name;
      const websiteChanged = this.state.website !== business.website;
      const updates = {
        ...business,
        name: this.state.name,
        username: `${this.state.name.split(' ').join('_')}_${_id}`,
        mainCategory: this.state.mainCategory,
        subCategories: this.state.subCategories,
        about: this.state.about,
        street: this.state.street,
        city: this.state.city,
        zip: this.state.zip,
        state: this.state.state,
        description: this.state.description,
        website: this.state.website?.trim(),
      };

      this.setState(
        {
          showMessage: true,
          updating: true,
        },
        async() => {
          businessActions.editBusiness(_id, updates, nameChanged, websiteChanged);

          // business.success ? this.notifySuccess(this.state.saveConfirmation) :
          // this.notifyError(this.state.saveConfirmation)

          this.setState({
            isBlocking: false,
            editsMade: false,
            profilePicChanged: false,
            headerPicChanged: false,
          });
        },
      );
    }
  };

  render() {
    const {
      mainCategory,
      state,
      mainCategories,
      subCategories,
      name,
      about,
      street,
      city,
      zip,
      saveConfirmation,
      isBlocking,
      subCatTextValue,
      website,
      editsMade,
      isValidUrl,
    } = this.state;
    const {
      handleChange,
      submitChanges,
      categorySelect,
      handleHeaderImageChange,
      handleProfileImageChange,
      handleWebsiteChange,
      deleteHeaderImgHandler,
      deleteProfileImgHandler,
    } = this;
    const { business } = this.props;
    const subCatDropdown = this.addCustomSubCatsToSuggested();

    return (
      <Container id="BusinessDetails">
        <ProfilePictures
          profilePic={business.profilePic}
          header={business.headerPic}
          firstLine={name}
          secondLine={mainCategory}
          addProfilePic={handleProfileImageChange}
          deleteProfilePic={deleteProfileImgHandler}
          addHeaderPic={handleHeaderImageChange}
          deleteHeaderPic={deleteHeaderImgHandler}
          handleInput={handleChange}
        />
        <form
          id="edit-business-form"
          onChange={this.blockingHandler}
          onSubmit={(e) => this.onSubmit(e)}
        >
          <div className="business-name">
            <label htmlFor="business-name">BUSINESS NAME</label>
            <input
              type="text"
              placeholder="Business Name"
              name="name"
              value={name}
              onChange={handleChange}
              id="business-name"
            />
          </div>
          {/* <div className="email">
            <label for="email">BUSINESS EMAIL</label>
            <input type="text" placeholder='Business Email' name='email'
              value={email} onChange={handleChange} id="email" />
          </div> */}
          <div className="category">
            <label htmlFor="email">MAIN CATEGORY</label>
            <Dropdown
              placeholder="Choose a main category for your business"
              fluid
              search
              onFocus={this.handleFocus}
              onChange={categorySelect}
              options={mainCategories}
              value={mainCategory}
              style={{
                width: '100%',
                background: 'transparent',
                borderBottom: '1px solid gray',
                padding: '1em',
              }}
            />
          </div>
          <div id="sub-category">
            <label htmlFor="sub-category">
              SUBCATEGORIES (Choose up to three. Use our suggested categories or
              enter your own.)
            </label>
            <Dropdown
              placeholder="Choose your subcatory/subcatories"
              fluid
              search
              multiple
              selection
              onFocus={this.handleFocus}
              onChange={this.subCategorySelect}
              onSearchChange={this.subCategoryTypeInput}
              options={[subCatTextValue, ...subCatDropdown]}
              value={subCategories}
              style={{
                width: '100%',
                background: 'transparent',
                borderBottom: '1px solid gray',
                padding: '1em',
              }}
            />
          </div>
          <div className="street">
            <label htmlFor="street">STREET</label>
            <input
              type="text"
              name="street"
              placeholder="Street"
              value={street}
              onChange={handleChange}
              id="street"
            />
          </div>
          <div className="city">
            <label htmlFor="city">CITY</label>
            <input
              type="text"
              name="city"
              placeholder="City"
              value={city}
              onChange={handleChange}
              id="city"
            />
          </div>
          <div className="state">
            <label htmlFor="state">STATE</label>
            <Dropdown
              placeholder="Select a state"
              fluid
              search
              onChange={this.stateSelect}
              value={state}
              style={{
                width: '100%',
                background: 'transparent',
                borderBottom: '1px solid gray',
                padding: '.88em',
              }}
              options={states}
            />
          </div>
          <div className="zip">
            <label htmlFor="zipCode">ZIP CODE</label>
            <input
              type="text"
              placeholder="Zip"
              name="zip"
              value={zip}
              onChange={handleChange}
              id="zip"
            />
          </div>
          <div className="about">
            <label htmlFor="about">ABOUT</label>
            <textarea
              type="text"
              placeholder="Share a little about your business"
              name="about"
              value={about}
              onChange={handleChange}
              id="about"
              rows="5"
              cols="50"
            />
          </div>
          <div>
            <label htmlFor="website">WEBSITE</label>
            <input
              type="text"
              placeholder="https://example.com"
              name="website"
              value={website}
              onChange={handleWebsiteChange}
              id="business-website"
            />
            {!isValidUrl && (
              <div style={{ color: '#F61C04' }}>Website is not valid.</div>
            )}
          </div>
        </form>

        {/* <Row> */}
        <Button
          className="save-changes button"
          positive
          disabled={!editsMade || !isValidUrl}
          onClick={(e) => submitChanges(e, this.state)}
        >
          Save Changes
        </Button>
        {saveConfirmation.length > 0 &&
          (!business.success ? (
            <div style={{ color: 'red', fontSize: '1.1em', marginTop: '2%' }}>
              {saveConfirmation}
            </div>
          ) : (
            <div
              style={{ color: '#00BE6E', fontSize: '1.25em', marginTop: '2%' }}
            >
              {saveConfirmation}
            </div>
          ))}
        {/* <Button positive style={{ width: '12%', marginBottom: '15%' }}
            onClick={this.enableFormEditing}>
            Edit
        </Button>  */}
        {/* </Row> */}

        <Prompt
          when={isBlocking}
          message="If you leave before saving, your changes will be lost."
        />
      </Container>
    );
  }
}

const urlRegex = new RegExp('(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
function getIsValidUrl(value) {
  return urlRegex.test(value);
}

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps)(BusinessDetails);
