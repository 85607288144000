/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import ProfileSidebar from './ProfileSidebar';
import './editProfile.css';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal, Button } from 'semantic-ui-react';
import ReferralWelcome from './ReferralWelcome.png';
import { getSearchQueryProp } from '../../util';
import '../../components/register-login/modalForms.css';

class EditProfile extends Component {
  state = {
    welcomeModal: false,
    user: this.props.user,
    loaded: false,
    resetPasswordBlock: false,
  };

  componentDidMount = async() => {
    // accounting for if coming to this page from verification email on initial sign up
    const { user, location } = this.props;
    const newUserId = getSearchQueryProp({
      queryString: location.search,
      queryProperty: 'newUserId',
    });
    if (newUserId) {
      this.setState({
        welcomeModal: true,
        user,
      });
    } else {
      const resetPasswordBlock = !!getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'userId',
      });
      this.setState({
        loaded: true,
        resetPasswordBlock,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { user } = this.props;
    const userChanged = user._id !== prevProps.user._id && !user.verifyingUser;
    if (userChanged) {
      this.setState({
        user,
        loaded: true,
      });
    }
  };

  onSubmit = (fields) => {
    const { user, userActions } = this.props;
    const updates = {
      ...user,
      ...fields,
      username: `${(fields.firstName + fields.lastName).split(' ').join('_')}_${
        user._id
      }`,
    };
    userActions.updateUser(user._id, updates);
  };

  updateProfilePic = (newPhoto) => {
    const { user, userActions } = this.props;
    const picUpdateInfo = {
      newPhoto,
      prevPhotoId: user.prevProfilePic_id,
      folderName: 'user_profile_pics',
      photoType: 'profilePic',
    };

    userActions.updateUserPhotoAction(user._id, picUpdateInfo);
  };

  changePassword = (original, newPassword) => {
    this.props.userActions.changePassword(
      this.props.user._id,
      original,
      newPassword,
    );
  };

  reset = (newPassword) => {
    this.props.userActions.resetPassword(this.props.user._id, newPassword);
  };

  viewProfile = (event) => {
    event.preventDefault();
    this.props.history.push({
      pathname: `/user/${this.props.user.username}`,
      state: { user: this.props.user },
    });
  };

  goToRefferal = (e) => {
    e.preventDefault();
    this.props.history.push('/referrals');
  };

  render() {
    const { welcomeModal, resetPasswordBlock } = this.state;

    return (
      <div>
        <section className="mobile" id="mobile-options-edit">
          <span className="active link">
            <NavLink to="/edit_profile">Basic info</NavLink>
          </span>
          <span className="link">
            <NavLink to="/change_password">Change password</NavLink>{' '}
          </span>

          <span
            className="link"
            onClick={this.viewProfile}
            onKeyDown={this.viewProfile}
            role="presentation"
          >
            View profile
          </span>
        </section>
        <Modal
          className="Referral-Sucess-Modal loginModal"
          open={welcomeModal}
          onClose={() => this.setState({ welcomeModal: false })}
          closeIcon
        >
          <Modal.Content className="ReferralSucess">
            <img src={ReferralWelcome} centered alt="referral welcome" />
            <h1> Gain an extra month free with every 5 friends you invite.</h1>
            <p>
              Click the button below to send invites now, or else access the
              Referrals page any time from the dropdown menu in the top right
              corner.
            </p>
            <Button type="submit" onClick={this.goToRefferal}>
              {' '}
              Invite friends
            </Button>
          </Modal.Content>
        </Modal>
        <ProfileSidebar
          {...this.props}
          user={this.state.user}
          updateMessage={this.props.user.updateUserMessage}
          onSubmit={this.onSubmit}
          changePassword={this.changePassword}
          changePasswordMessage={this.props.user.changePasswordMessage}
          viewProfile={this.viewProfile}
          resetPassword={resetPasswordBlock}
          reset={this.reset}
          resetPasswordBlock={resetPasswordBlock}
          unblockReset={() => this.setState({ resetPasswordBlock: false })}
          updateProfilePic={this.updateProfilePic}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(withRouter(EditProfile));
