/* eslint-disable max-len */
// Section Icons
import Inspiration from '../../assets/images/learningPage/inspiration.png';
import Design from '../../assets/images/learningPage/design.png';
import Legal from '../../assets/images/learningPage/legal.png';
import HR from '../../assets/images/learningPage/hr.png';
import Payment from '../../assets/images/learningPage/payment.png';
// import Branding from '../../assets/images/learningPage/branding.png';

// Inspiration Section Images
import Omar from '../../assets/images/learningPage/omar.png';
import Gary from '../../assets/images/learningPage/gary.png';
import Lewis from '../../assets/images/learningPage/lewis.png';
import Impact from '../../assets/images/learningPage/impact.png';
import Rhiz from '../../assets/images/learningPage/rhiz.png';

// Design Section Images
import Canva from '../../assets/images/learningPage/canva.png';
import Slide from '../../assets/images/learningPage/slide.png';

// Legal Section Images
import NAHB from '../../assets/images/learningPage/nahb.png';
import LegalZoom from '../../assets/images/learningPage/legalzoom.png';

// HR Section Images
import Gusto from '../../assets/images/learningPage/gusto.png';
import Bambee from '../../assets/images/learningPage/bambee.png';

// Payment Section Images
import Square from '../../assets/images/learningPage/square.png';
import Wave from '../../assets/images/learningPage/wave.png';
import Wrap from '../../assets/images/learningPage/wrap.png';

export const learningSectionsInfo = [
  {
    icon: Inspiration,
    title: 'Inspiration',
    cards: [
      {
        name: 'Omar Elattar',
        desc: 'The Passionate Few podcast',
        img: Omar,
        link: 'https://www.omarelattar.com/',
      },
      {
        name: 'Gary Vaynerchuk',
        desc: 'Business insights and more',
        img: Gary,
        link: 'https://www.garyvaynerchuk.com/',
      },
      {
        name: 'Lewis Howes',
        desc: 'School of Greatness podcast',
        img: Lewis,
        link: 'https://lewishowes.com/',
      },
      {
        name: 'Impact Theory',
        desc: 'The "why" behind inspirational leaders',
        img: Impact,
        link: 'https://impacttheory.com/',
      },
      {
        name: 'Rhiz Above It',
        desc: 'Our podcast on the people behind businesses',
        img: Rhiz,
        link: 'https://www.rhizaboveit.com/',
      },
    ],
  },
  {
    icon: Design,
    title: 'Design',
    cards: [
      {
        name: 'Canva',
        desc: 'A free tool to create logos, social media images, etc',
        img: Canva,
        link: 'https://www.canva.com/',
      },
      {
        name: 'Slidebean',
        desc: 'Pitch deck creator to help tell your story',
        img: Slide,
        link: 'https://slidebean.com/',
      },
    ],
  },
  {
    icon: Legal,
    title: 'Extra',
    cards: [
      {
        name: 'Home Builders Association',
        desc: 'Building Homes, Enriching Communities, Changing Lives',
        img: NAHB,
        link: 'https://www.nahb.org/',
      },
      {
        name: 'LegalZoom',
        desc: 'Incorporation and beyond',
        img: LegalZoom,
        link: 'https://www.legalzoom.com/',
      },
    ],
  },
  {
    icon: HR,
    title: 'HR, etc',
    cards: [
      {
        name: 'Gusto',
        desc: 'Payroll and benefits software',
        img: Gusto,
        link: 'https://gusto.com/',
      },
      {
        name: 'Bambee',
        desc: 'Your dedicated HR manager',
        img: Bambee,
        link: 'https://www.bambee.com/',
      },
    ],
  },
  {
    icon: Payment,
    title: 'Payment',
    cards: [
      {
        name: 'Square',
        desc: 'Take cards right on the job sight',
        img: Square,
        link: 'https://squareup.com/us/en',
      },
      {
        name: 'Wave',
        desc: 'Free/discounted invoicing',
        img: Wave,
        link: 'https://www.waveapps.com/',
      },
      {
        name: 'Wrapify',
        desc: 'Worried about wasted site visits? Get paid to drive there.',
        img: Wrap,
        link: 'https://wrapify.com/',
      },
    ],
  },
];
