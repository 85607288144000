/* eslint-disable no-invalid-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Radio, TextArea, Button } from 'semantic-ui-react';
import validator from 'validator';
import { sendContactEmail } from '../../services';
import './Contact.css';

export default class Contact extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    message: '',
    firstNameError: false,
    lastNameError: false,
    radioError: false,
    emailError: false,
    messageError: false,
    emailSent: false,
    emailSentMessage:
      'Your email has been sent to our team! Click below to return to the home page.',
  };

  componentDidMount = () => {
    window.scroll(0, 0);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      emailSent: false,
    });
  };

  handleRadioChange = (e, { value }) => {
    this.setState({
      type: value,
      emailSent: false,
    });
  };

  sendEmail = (e) => {
    e.preventDefault();
    const error = this.validation();
    const { firstName, lastName, email, type, message } = this.state;
    if (!error) {
      const name = `${firstName} ${lastName}`;
      sendContactEmail(name, email, type, message).then(() => {
        this.setState({
          emailSent: true,
        });
      });
    }
  };

  validation = () => {
    let error = false;
    const { type, email, firstName, lastName, message } = this.state;
    const isValidEmail = validator.isEmail(email);
    if (!firstName.length) {
      error = true;
      this.setState({
        firstNameError: true,
      });
    } else {
      this.setState({
        firstNameError: false,
      });
    }
    if (!lastName.length) {
      error = true;
      this.setState({
        lastNameError: true,
      });
    } else {
      this.setState({
        lastNameError: false,
      });
    }
    if (!isValidEmail) {
      error = true;
      this.setState({
        emailError: true,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
    if (!type.length) {
      error = true;
      this.setState({
        radioError: true,
      });
    } else {
      this.setState({
        radioError: false,
      });
    }
    if (!message.length) {
      error = true;
      this.setState({
        messageError: true,
      });
    } else {
      this.setState({
        messageError: false,
      });
    }

    return error;
  };

  render() {
    const {
      type,
      email,
      firstName,
      lastName,
      message,
      radioError,
      firstNameError,
      lastNameError,
      messageError,
      emailError,
      emailSent,
      emailSentMessage,
    } = this.state;
    return (
      <div className="Contact-container">
        <div className="Contact-form">
          <h1>Contact Rhiz</h1>
          <div className="Contact-line" />
          <Form onSubmit={this.sendEmail}>
            <Form.Field className="Contact-margin" required>
              <label htmlFor="">Name</label>
              <Form.Group>
                <Form.Input
                  error={firstNameError}
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                  width={4}
                  placeholder="First Name"
                />
                <Form.Input
                  error={lastNameError}
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  placeholder="Last Name"
                  width={4}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field className="Contact-margin" required>
              <label htmlFor="email">Email</label>
              <Form.Input
                className="contact-email"
                error={emailError}
                name="email"
                value={email}
                onChange={this.handleChange}
                placeholder="johndoe@email.com"
              />
            </Form.Field>
            <Form.Field required>
              <label>Type</label>
            </Form.Field>
            <Form.Field
              error={radioError}
              className="Contact-radio-margin"
              control={Radio}
              label="Seeking Businesses"
              value="Seeking Businesses"
              checked={type === 'Seeking Businesses'}
              onChange={this.handleRadioChange}
            />
            <Form.Field
              error={radioError}
              className="Contact-radio-margin"
              control={Radio}
              label="Aspiring Entrepreneur"
              value="Aspiring Entrepreneur"
              checked={type === 'Aspiring Entrepreneur'}
              onChange={this.handleRadioChange}
            />
            <Form.Field
              error={radioError}
              className="Contact-margin"
              control={Radio}
              label="Current Business Owner"
              value="Current Business Owner"
              checked={type === 'Current Business Owner'}
              onChange={this.handleRadioChange}
            />
            <Form.Field
              error={messageError}
              name="message"
              value={message}
              onChange={this.handleChange}
              className="Contact-margin contact-message"
              control={TextArea}
              label="Message"
            />
            {!emailSent ? (
              <Form.Field id="Contact-submit" control={Button}>
                Submit
              </Form.Field>
            ) : (
              <div>
                <p className="Contact-sent">{emailSentMessage}</p>
                <Link to="/">
                  <Button id="Contact-submit">Back To Homepage</Button>
                </Link>
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
