/* eslint-disable no-invalid-this */
import React, { Component } from 'react';

const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
  padding: 50,
  zIndex: 1,
};

const modalStyle = {
  backgroundColor: '#fff',
  borderRadius: 5,
  maxWidth: 500,
  minHeight: 300,
  margin: '0 auto',
  padding: 30,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '2em',
};

export default class Modal extends Component {
  constructer() {
    this.node = {};
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  setWrapperRef = (node) => {
    this.node = node;
  };

  handleClick = (e) => {
    if (this.props.show) {
      if (!this.node.contains(e.target)) {
        this.onClose(e);
      }
    }
  };

  onClose = (e) => {
    this.props.onClose && this.props.onClose(e);
  };

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div style={backdropStyle}>
        <section style={modalStyle} ref={(node) => this.setWrapperRef(node)}>
          {this.props.children}
        </section>
      </div>
    );
  }
}
