import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_COUNT,
  LOGOUT_DONE,
} from '../actions/types';

const notifications = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        fetchingNotifications: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetchingNotifications: false,
      };
    case FETCH_NOTIFICATIONS_FAILED:
      return {
        ...state,
        fetchErrorMessage:
          'Error fetching notifications. Please contact support if error persists.',
        fetchingNotifications: false,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload.notifications,
      };
    case CLEAR_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unreadCount: 0,
      };
    case LOGOUT_DONE:
      return {
        unreadCount: 0,
        list: [],
      };
    default:
      return {
        ...state,
      };
  }
};

export default notifications;
