// dependencies
import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Star, CommonModal } from '../../../components';
// import Instagram from '../../../assets/images/SocialLinksPng/instagram.png';
import 'react-day-picker/lib/style.css';
import { Link } from 'react-router-dom';

// components
import { Container, Row, Button, SquarePic, FernContainer } from '../BusinessProfileStyles';
import BreakLine from './BreakLine';

// assets
import WhatIsRhizGraphic from '../../../assets/images/WhatIsRhizGraphic.png';

import { formatDate } from '../../../util';

const Sidebar = (props) => {
  const [modal, setModal] = useState(false);
  const {
    score,
    urgency,
    changeUrgency,
    connectPress,
    date,
    getDate,
    favorite,
    user,
    viewedBusiness,
    openSignUp,
  } = props;
  return (
    <Container style={{ width: '487px' }} className="desktop business-sidebar">
      <Container style={{ marginBottom: '15%' }}>
        {user._id &&
          ((user.business?._id !== viewedBusiness._id) ||
            !user.business) && (
            <Row
              style={{
                fontSize: '23px',
                fontWeight: 'bold',
                marginBottom: '2.25%',
                marginLeft: '75%',
              }}
            >
              {' '}
              <span className="favorite-star">
                <Star
                  user={user}
                  businessViewed={viewedBusiness}
                  favorite={favorite}
                  {...props}
                />
              </span>
            </Row>
          )}
        <Row className="content-title">Business Rating</Row>
        <BreakLine color="#D3D3D3" width="80%" margin="25%" />
        <Row className="content">
          Rhiz Score
          <div
            style={{
              fontSize: '11px',
              marginLeft: '1%',
              marginTop: '-1%',
              color: '#999',
            }}
          >
            ?
          </div>
          <Row style={{ marginLeft: '45%', fontSize: '23px' }}>
            <div style={{ color: '#00be6e', fontSize: '23px' }}>{score}</div>
            /100
          </Row>
        </Row>
      </Container>
      <Container>
        <Row className="content-title">Contact Us</Row>
        <BreakLine color="#D3D3D3" width="80%" margin="25%" />
        <Row
          style={{
            fontSize: '16px',
            color: '#999',
            marginTop: '3%',
            marginBottom: '3%',
          }}
        >
          Start Date - Optional
        </Row>
        <DayPickerInput
          onDayChange={getDate}
          formatDate={formatDate}
          format="MM-dd-yyyy"
          placeholder={date}
          style={{
            width: '100%',
          }}
        />
        <Row
          style={{
            fontSize: '16px',
            color: '#999',
            marginBottom: '1.5%',
            marginTop: '2.5%',
          }}
        >
          URGENCY
        </Row>
        <Container style={{ marginBottom: '5%' }}>
          <form id="contact-radio">
            <div className="radio" style={{ marginBottom: '1%' }}>
              <label className="radio-option-container">
                <Row>
                  <input
                    type="radio"
                    value="Just Curious"
                    style={{ marginRight: '1.5%' }}
                    checked={urgency === 'Just Curious'}
                    onChange={changeUrgency}
                  />
                  <div style={{ fontSize: '16px' }}>Just Curious</div>
                  <span className="checkmark-connect"></span>
                </Row>
              </label>
            </div>
            <div style={{ marginBottom: '1%' }}>
              <label className="radio-option-container">
                <Row>
                  <input
                    type="radio"
                    value="Ready to Go"
                    style={{ marginRight: '1.5%' }}
                    checked={urgency === 'Ready to Go'}
                    onChange={changeUrgency}
                  />
                  <div style={{ fontSize: '16px' }}>Ready to Go</div>
                  <span className="checkmark-connect"></span>
                </Row>
              </label>
            </div>
            <div className="radio" style={{ marginBottom: '1%' }}>
              <label className="radio-option-container">
                <Row>
                  <input
                    type="radio"
                    value="Emergency"
                    style={{ marginRight: '1.5%' }}
                    checked={urgency === 'Emergency'}
                    onChange={changeUrgency}
                  />
                  <div style={{ fontSize: '16px' }}>Emergency</div>
                  <span className="checkmark-connect"></span>
                </Row>
              </label>
            </div>
          </form>
        </Container>

        <Button
          onClick={!user.verified ? openSignUp : connectPress}
          style={{ marginBottom: '20%' }}
        >
          Connect
        </Button>

        <BreakLine color="#D3D3D3" width="80%" margin="25%" />

        <FernContainer>
          <Link style={{ color: '#000000', display: 'flex', flexDirection: 'column' }} to={{
            pathname: '/referrals',
          }}>
            <SquarePic src={WhatIsRhizGraphic} />
            Grow Your Network
          </Link>

        </FernContainer>

          {/* Unused Connect To Instagram Component */}
        {/* {user.business && user.business._id === viewedBusiness._id && (
          <Row
            style={{
              fontSize: '23px',
              fontWeight: 'bold',
              marginBottom: '2.5%',
            }}
          >
            <div
              onClick={() => setModal(!modal)}
              onKeyDown={() => setModal(!modal)}
              className="connect-ig-business"
            >
              <img src={Instagram} alt="Instagram" />
              <h1>Connect Instagram</h1>
            </div>
          </Row>
        )} */}
        {/* <BreakLine color="#D3D3D3" width="80%" margin="25%" /> */}
        {/* <Row style={{ flexWrap: 'wrap', width: '100%', marginTop: '5%' }}>
          {instaPics.map((pic, i) => { return <InstaPic src={pic.pic} key={i} /> })}
        </Row> */}
      </Container>
      <CommonModal onClose={() => setModal(!modal)} show={modal}>
        Coming Soon
      </CommonModal>
    </Container>
  );
};

export default Sidebar;
