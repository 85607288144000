import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// styled components
import { Row, WorksWithPic, RegText } from '../Styles';

// component for dream team member
const DreamTeamMember = ({
  remove,
  business,
  requestByUser,
  request,
  goToProfile,
}) => (
  <Row className="dream-member">
    <WorksWithPic
      style={{ cursor: 'pointer' }}
      src={business.profilePic}
      onClick={() => goToProfile(business)}
    />
    <RegText style={{ marginTop: '1.5%', color: '#999', cursor: 'pointer' }}>
      {business.name}
    </RegText>
    <FontAwesomeIcon
      icon={faTimes}
      size="sm"
      id="add-business"
      style={{ cursor: 'pointer', marginLeft: '2%' }}
      onClick={() => remove(business, requestByUser, request)}
    />
  </Row>
);

export default DreamTeamMember;
