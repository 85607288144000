import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Breakline from './Breakline';

const LearningSection = ({ icon, title, children }) => (
  <>
    <BrowserView>
      <div className='learning-page-section'>
        <div className='learning-page-section-wrapper-left'>
          <div className='landing-page-image-wrapper' id='desktop'>
            <img className='icon-img' src={icon} alt='icon' />
          </div>
          <div className='learning-page-section-title'>
            <h3>{title}</h3>
            <div className='line-box'>
              <Breakline height='60px' width='40px' />
            </div>
          </div>
        </div>
        <div className='learning-page-section-wrapper-right'>
          {children}
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <div className='learning-page-section'>
        <div className='learning-page-section-wrapper-left mobile'>
          <div className='title'>
            <div className='title-top'>
              <div className='landing-page-image-wrapper'>
                <img className='icon-img' src={icon} alt='icon' />
              </div>

              <div className='learning-page-section-title'>
                <h3>{title}</h3>
              </div>
            </div>
            <div className='title-bot'>
              <div className='line-container'>
                <div className='line-mob'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='learning-page-section-wrapper-right'>
          <Carousel centered draggable dots infinite>
            {children}
          </Carousel>
        </div>
      </div>
    </MobileView>
  </>
);

export default LearningSection;
