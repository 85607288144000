// user action types
export const FETCH_USER = 'fetch_user';
export const FETCH_USER_SUCCESS = 'fetch_user_success';
export const FETCH_USER_FAILED = 'fetch_user_failed';
export const LOGIN_STARTED = 'login_started';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_SUCCESS = 'login_success';
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_FAILED = 'signup_failed';
export const SIGNUP_SUCCESS = 'signup_success';
export const LOGOUT = 'logout';
export const LOGOUT_DONE = 'logout_done';
export const UPDATE_USER = 'update_user';
export const UPDATE_SUCCESS = 'update_success';
export const UPDATE_FAILED = 'update_failed';
export const CHANGE_PASSWORD = 'change_password';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const CHANGE_PASSWORD_FAILED = 'change_password_failed';
export const VERIFY_USER = 'verify_user';
export const VERIFY_USER_SUCCESS = 'verify_user_success';
export const VERIFY_USER_FAILED = 'verify_user_failed';
export const UPDATE_USER_FAVORITES = 'update_user_favorites';
export const UPDATE_USER_FAVORITES_SUCCESS = 'update_user_favorites_success';
export const UPDATE_USER_FAVORITES_FAILED = 'update_user_favorites_failed';
export const UPDATE_USER_NOTIFICATIONS_CHECK =
  'update_user_notifications_check';
export const UPDATE_USER_NOTIFICATIONS_CHECK_SUCCESS =
  'update_user_notifications_check_success';
export const UPDATE_USER_NOTIFICATIONS_CHECK_FAILED =
  'update_user_notifications_check_failed';
export const SET_USER = 'set_user';
export const UPDATE_USER_PHOTO = 'update_user_photo';
export const UPDATE_USER_PHOTO_SUCCESS = 'update_user_photo_success';
export const UPDATE_USER_PHOTO_FAILED = 'update_user_photo_failed';
export const UPDATE_REDEEM_COUNT = 'update_redeem_count';
export const UPDATE_REDEEM_COUNT_SUCCESS = 'update_redeem_count_success';
export const UPDATE_REDEEM_COUNT_FAILED = 'update_redeem_count_failed';

// business action types
export const FETCH_BUSINESS = 'fetch_business';
export const FETCH_BUSINESS_SUCCESS = 'fetch_business_success';
export const FETCH_BUSINESS_FAILED = 'fetch_business_failed';
export const ADD_BUSINESS = 'add_business';
export const ADD_BUSINESS_SUCCESS = 'add_business_success';
export const ADD_BUSINESS_FAILED = 'add_business_failed';
export const UPDATE_BUSINESS = 'update_business';
export const UPDATE_BUSINESS_SUCCESS = 'update_business_success';
export const UPDATE_BUSINESS_FAILED = 'update_business_failed';
export const UPDATE_BUSINESS_PHOTO = 'update_business_photo';
export const UPDATE_BUSINESS_PHOTO_SUCCESS = 'update_business_photo_success';
export const UPDATE_BUSINESS_PHOTO_FAILED = 'update_business_photo_failed';
export const SET_BUSINESS = 'set_business';

// messages action types
export const FETCH_MESSAGES = 'fetch_messages';
export const FETCH_MESSAGES_SUCCESS = 'fetch_messages_success';
export const FETCH_MESSAGES_FAILED = 'fetch_messages_failed';

// connection action types (user to business owner)
export const FETCH_CONNECTIONS = 'fetch_connections';
export const FETCH_CONNECTIONS_SUCCESS = 'fetch_connections_success';
export const FETCH_CONNECTIONS_FAILED = 'fetch_connections_failed';
export const SEND_CONNECTION_REQUEST = 'send_connection_request';
export const SEND_CONNECTION_REQUEST_SUCCESS =
  'send_connection_request_success';
export const SEND_CONNECTION_REQUEST_FAILED = 'send_connection_request_failed';
export const UPDATE_LAST_MESSAGE = 'update_last_message';
export const UPDATE_LAST_MESSAGE_SUCCESS = 'update_last_message_success';
export const UPDATE_LAST_MESSAGE_FAILED = 'update_last_message_failed';
export const UPDATE_CONNECTION = 'update_connection';
export const UPDATE_CONNECTION_SUCCESS = 'update_connection_success';
export const UPDATE_CONNECTION_FAILED = 'update_connection_failed';
export const QUOTE_UPDATE = 'quote_update';
export const QUOTE_UPDATE_SUCCESS = 'quote_update_success';
export const QUOTE_UPDATE_FAILED = 'quote_update_failed';
export const RESPOND_CONNECT_REQUEST = 'respond_connect_request';
export const RESPOND_CONNECT_REQUEST_SUCCESS =
  'respond_connect_request_success';
export const RESPOND_CONNECT_REQUEST_FAILED = 'respond_connect_request_failed';
export const EDIT_PENDING_REQUEST = 'edit_pending_request';
export const EDIT_PENDING_REQUEST_SUCCESS = 'edit_pending_request_success';
export const EDIT_PENDING_REQUEST_FAILED = 'edit_pending_requests_failed';
export const DELETE_REQUEST = 'delete_request';
export const DELETE_REQUEST_SUCCESS = 'delete_request_success';
export const DELETE_REQUEST_FAILED = 'delete_request_failed';
export const CREATE_C2C_CONNECTION = 'create_c2c_connection';
export const CREATE_C2C_CONNECTION_SUCCESS = 'create_c2c_connection_success';
export const CREATE_C2C_CONNECTION_FAILED = 'create_c2c_connection_failed';
export const MARK_QUOTE_READ = 'mark_quote_read';
export const MARK_QUOTE_READ_SUCCESS = 'mark_quote_read_success';
export const MARK_QUOTE_READ_FAILED = 'mark_quote_read_failed';
export const UPDATE_CONNECTIONS_ORDER = 'update_connections_order';
export const SET_SELECTED_CONNECTION = 'set_selected_connection';
export const UPDATE_CONNECTIONS_REQUEST = 'update_connections_request';
export const UPDATE_CONNECTIONS_REQUEST_FAILED =
  'update_connections_request_failed';

// dreamTeam action types
export const FETCH_DREAM_TEAM_REQUESTS = 'fetch_dream_team';
export const FETCH_DREAM_TEAM_REQUESTS_SUCCESS = 'fetch_dream_team_success';
export const FETCH_DREAM_TEAM_REQUESTS_FAILED = 'fetch_dream_team_failed';
export const DREAM_TEAM_SEND_REQUEST = 'dream_team_send_request';
export const DREAM_TEAM_SEND_REQUEST_SUCCESS =
  'dream_team_send_request_success';
export const DREAM_TEAM_SEND_REQUEST_FAILED = 'dream_team_send_request_failed';
export const DREAM_TEAM_RESPONSE = 'dream_team_response';
export const DREAM_TEAM_RESPONSE_SUCCESS = 'dream_team_response_success';
export const DREAM_TEAM_RESPONSE_FAILED = 'dream_team_response_failed';
export const UPDATE_DREAM_TEAM = 'update_dream_team';
export const UPDATE_DREAM_TEAM_SUCCESS = 'update_dream_team_success';
export const UPDATE_DREAM_TEAM_FAILED = 'update_dream_team_failed';
export const REVOKE_APPROVED_DREAM_TEAM = 'revoke_approved_dream_team';
export const REVOKE_APPROVED_DREAM_TEAM_SUCCESS =
  'revoke_approved_dream_team_success';
export const REVOKE_APPROVED_DREAM_TEAM_FAILED =
  'revoke_approved_dream_team_failed';
export const CANCEL_DREAM_TEAM_REQUEST = 'cancel_dream_team_request';
export const CANCEL_DREAM_TEAM_REQUEST_SUCCESS =
  'cancel_dream_team_request_success';
export const CANCEL_DREAM_TEAM_REQUEST_FAILED =
  'cancel_dream_team_request_failed';
export const SET_DREAM_TEAM_REQUESTS = 'set_dream_team_requests';
export const CLEAR_DREAM_TEAM_REQUESTS = 'clear_dream_team_requests';

// notifications action types
export const FETCH_NOTIFICATIONS = 'fetch_notifications';
export const FETCH_NOTIFICATIONS_SUCCESS = 'fetch_notifications_success';
export const FETCH_NOTIFICATIONS_FAILED = 'fetch_notifications_failed';
export const SET_NOTIFICATIONS = 'set_notifications';
export const CLEAR_NOTIFICATIONS_COUNT = 'clear-notifications_count';

// favorites types
export const FETCH_FAVORITES = 'fetch_favorites';
export const FETCH_FAVORITES_SUCCESS = 'fetch_favorites_success';
export const FETCH_FAVORITES_FAILED = 'fetch_favorites_failed';
export const UPDATE_FAVORITES = 'update_favorites';

// socket types
export const SET_SOCKETS = 'set_sockets';
