import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';

import BusinessesReviewed from './BusinessesReviewed';
import './MyReviewsPage.css';
import ReviewsAboutMe from './ReviewsAboutMe';

export default class MyReviewsPageContainer extends Component {
  state = {
    messagedPeople: [],
  };

  headerBusinessCheck() {
    if (this.props.business._id) {
      return (
        <div className="reviews-header">
          <h4 style={{ color: '#00BE6E' }}>
            **Under Construction: Reviews Feature Coming Soon!**
          </h4>
          <h4>My Reviews</h4>
          <div className="reviews-tab">
            <NavLink
              to="/MyReviewsPage/BusinessesIReviewed"
              className="review-nav-link"
              activeClassName="review-nav-active"
              activeStyle={{ color: '#00BE6E' }}
            >
              Business reviews
            </NavLink>
            <NavLink
              to="/MyReviewsPage/ConsumersReviewed"
              className="review-nav-link"
              activeClassName="review-nav-active"
              activeStyle={{ color: '#00BE6E' }}
            >
              Consumer reviews
            </NavLink>
            <NavLink
              to="/MyReviewsPage/MyBusinessReviews"
              className="review-nav-link"
              activeClassName="review-nav-active"
              activeStyle={{ color: '#00BE6E' }}
            >
              Reviews about my business
            </NavLink>
            <NavLink
              to="/MyReviewsPage/ReviewsAboutMe"
              className="review-nav-link"
              activeClassName="review-nav-active"
              activeStyle={{ color: '#00BE6E' }}
            >
              Reviews about me
            </NavLink>
          </div>
        </div>
      );
    }

    return (
      <div className="reviews-header">
        <h4>My Reviews</h4>
        <div className="reviews-tab">
          <NavLink
            to="/MyReviewsPage/BusinessesIReviewed"
            className="review-nav-link"
            activeClassName="review-nav-active"
            activeStyle={{ color: '#00BE6E' }}
          >
            Businesses I&apos;ve reviewed
          </NavLink>
          <NavLink
            to="/MyReviewsPage/ReviewsAboutMe"
            className="review-nav-link"
            activeClassName="review-nav-active"
            activeStyle={{ color: '#00BE6E' }}
          >
            Reviews about me
          </NavLink>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="reviews-page-container">
        <div className="reviews-page">
          {this.headerBusinessCheck()}
          <div>
            <Route
              path="/MyReviewsPage/BusinessesIReviewed"
              render={() => <BusinessesReviewed {...this.props} />}
            />
            <Route
              path="/MyReviewsPage/ReviewsAboutMe"
              render={() => <ReviewsAboutMe {...this.props} />}
            />
            {/* eslint-disable */}
            {/* <Route path="/QuoteView" render={() => <QuoteFromView consumerProfile={this.state.consumerProfile} {...this.props} />} />
          <Route path="/ConsumerReviewForm" render={() => <ConsumerReview  {...this.props} />} />
          <Route path="/BusinessReviewForm" render={() => <BusinessReview  {...this.props} />} />
          <Route path="/EditConsumerReview" render={() => <EditConsumerReview  {...this.props} />} />
          <Route path="/EditBusinessReview" render={() => <EditBusinessReview  {...this.props} />} />
          <Route path="/EditReviewBusinessForm" render={() => <EditReviewBusinessForm  {...this.props} />} />
          <Route path="/EditReviewConsumerForm" render={() => <EditReviewConsumerForm  {...this.props} />} />
          <Route path="/ConsumerReviewView" render={() => <ConsumerReviewView   {...this.props} />} />  */}
            {/* eslint-enable */}
          </div>
        </div>
      </div>
    );
  }
}
