export const unreadMessagesCount = (list, category, user = null) => {
  let count = 0;
  for (let i = 0; i < list.length; i++) {
    if (
      category === 'business' &&
      typeof list[i].unreadMessagesBusiness === 'number'
    ) {
      count += list[i].unreadMessagesBusiness;
    } else if (
      list[i].type === 'B2C' &&
      typeof list[i].unreadMessagesConsumer === 'number'
    ) {
      count += list[i].unreadMessagesConsumer;
    } else if (
      user._id === list[i].user1_id &&
      typeof list[i].unreadMessagesUser1 === 'number'
    ) {
      count += list[i].unreadMessagesUser1;
    } else if (typeof list[i].unreadMessagesUser2 === 'number') {
      count += list[i].unreadMessagesUser2;
    }
  }
  return count;
};
