import BreezeThumb from '../../assets/images/bizThumbs/coolBreezeThumb.jpg';
import BelieveIt from '../../assets/images/consumerProfile/reviews/Believe It Or Knot.jpg';
import Garden from '../../assets/images/bizThumbs/gardenEvaThumb.jpg';
import AwesomeSauce from '../../assets/images/consumerProfile/reviews/AwesomeSauce.jpg';

export const reviews = [
  {
    name: 'Cool Breeze Dopeness',
    src: BreezeThumb,
    review:
      'Ms. McClane was great to work for and communicated well. Thank you',
    change: 12,
    datePosted: '11 days ago',
  },
  {
    name: 'Believe It Or Knot',
    src: BelieveIt,
    review:
      "Alessandra's eye for design helped speed up my process."
      + "She's fast to respond to messages, too.",
    change: 23,
    datePosted: '14 days ago',
  },
  {
    name: 'Garden of Eva',
    src: Garden,
    review:
      "Alessandra has a great eye for design, we're grateful to be partners.",
    change: 5,
    datePosted: '2 weeks ago',
  },
  {
    name: 'AwesomeSauce',
    src: AwesomeSauce,
    review:
      'The McClanes were a great catering client. Let me know if you need more help!',
    change: 5,
    datePosted: '1 month ago',
  },
];
