/* eslint-disable max-len */
const businessesReviewedDummyData = [
  {
    img:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1557460077/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/BoG_fn8uip.png',
    businessName: 'Blades of Glory',
    work: 4,
    service: 5,
    communication: 3,
    price: 3,
    recommendation: 'Yes',
    review:
      "I can't say enough good things about Blades of Glory. Great service, consistent communication , and unbeatable prices. My backyard has never looked better and all my neighbors are jealous. If you're looking for a landscaper, look not farther.",
  },
  {
    img:
      'https://res.cloudinary.com/rhiz/image/upload/c_thumb,w_200,g_face/v1559065260/Businesses%20%28profile%2C%20lists%2C%20search%29/Garden%20of%20Eva/GoE_h83gwf.png',
    businessName: 'Garden of Eva',
    work: 4,
    service: 5,
    communication: 3,
    price: 3,
    recommendation: 'Yes',
    review:
      "Garden of Eva was very thorough and attentive. I'm not the best on computers so when we connected, their workers were great about calling me directly",
  },
  {
    img:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1557460077/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/BoG_fn8uip.png',
    businessName: 'Blades of Glory',
    work: 4,
    service: 5,
    communication: 3,
    price: 3,
    recommendation: 'Yes',
    review:
      "Eric and his crew were one of the nicest I've met in a while. My yard was a beast but they did something magical and now the kids play outside all the time",
  },
];

export default businessesReviewedDummyData;
