import React, { Component } from 'react';

export default class Envelope extends Component {
  render() {
    return (
      <svg
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
        aria-labelledby="editIconTitle"
        stroke="#70D6A4"
        strokeWidth="1.8"
        strokeLinecap="square"
        strokeLinejoin="miter"
        fill="none"
        color="#70D6A4"
      >
        <title id="editIconTitle">Edit</title>
        {/* eslint-disable-next-line */}
        <path d="M18.4142136 4.41421356L19.5857864 5.58578644C20.366835 6.36683502 20.366835 7.63316498 19.5857864 8.41421356L8 20 4 20 4 16 15.5857864 4.41421356C16.366835 3.63316498 17.633165 3.63316498 18.4142136 4.41421356zM14 6L18 10" />
      </svg>
    );
  }
}
