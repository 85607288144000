import React from 'react';

const Checkmark = () => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="70px"
    height="70px"
    viewBox="0 0 24 24"
    aria-labelledby="circleOkIconTitle"
    stroke="#00be6e"
    strokeWidth="2"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#00be6e"
  >
    <title id="circleOkIconTitle">OK</title>
    <polyline points="7 13 10 16 17 9" />
    <circle cx="12" cy="12" r="10" />
  </svg>
);

export default Checkmark;
