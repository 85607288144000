/* eslint-disable no-invalid-this */
import React from 'react';
import RetrievePasswordForm from '../forms/RetrievePasswordForm';

// services
import { sendResetPasswordEmail } from '../../../services';

class RetrievePasswordModal extends React.Component {
  state = {
    emailSent: false,
    validEmail: true,
    existingAccount: true,
  };

  send = async(email) => {
    const result = await sendResetPasswordEmail(email);
    if (result.success) {
      this.setState({
        emailSent: true,
      });
    } else {
      this.setState({
        existingAccount: false,
        message: result.message,
      });
    }
  };

  render() {
    const { emailSent, existingAccount, message } = this.state;

    return (
      <div>
        {!emailSent ? <h1>Retrieve Password</h1> : <h1>Reset Link Sent!</h1>}
        {
          <RetrievePasswordForm
            emailSent={emailSent}
            message={message}
            existingAccount={existingAccount}
            sendEmail={this.send}
          />
        }
        <div
          className="links"
          onClick={this.props.openLogin}
          onKeyDown={this.props.openLogin}
        >
          Log in to your account
        </div>
      </div>
    );
  }
}

export default RetrievePasswordModal;
