import React from 'react';
import { RegText } from '../../Styles';

const AwaitingApproval = ({
  respondRequest,
  request,
  goToProfile,
  business,
}) => (
  <div>
    <section style={{ display: 'flex', alignItems: 'center' }}>
      {/* eslint-disable-next-line */}
      <img
        src={business.profilePic}
        alt="business profile pic"
        style={{ cursor: 'pointer', width: '40px', marginRight: '1em' }}
        onClick={() => goToProfile(business)}
        onKeyDown={() => goToProfile(business)}
      />
      <div>
        <RegText
          style={{
            cursor: 'pointer',
            marginBottom: '0.5em',
            maxWidth: '20em',
            wordWrap: 'break-word',
            color: '#C0C0C0',
            fontSize: '1.1em',
          }}
        >
          {business.name}
        </RegText>
        <div>
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
            }}
            onClick={() => respondRequest(request, 'approved')}
          >
            Accept
          </button>
          <button
            style={{ border: 'none', background: '#fff', color: '	#DCDCDC' }}
            onClick={() => respondRequest(request, 'denied')}
          >
            Decline
          </button>
        </div>
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <button
          style={{
            border: 'none',
            backgroundColor: '#fff',
            marginLeft: '5em',
            color: '#DCDCDC',
          }}
        >
          Today
        </button>
      </div>
    </section>
    <hr
      style={{
        borderWidth: '0.05em',
        marginTop: '1em',
        marginBottom: '1em',
        height: '1px',
        background: '#DCDCDC',
      }}
    />
  </div>
);

export default AwaitingApproval;
