import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import './components/common.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import buildStore from './Store/buildStore';

// build store and immediately check for logged in user.
const store = buildStore();
// store.dispatch(fetchUser())

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>
, document.getElementById('root'));
// registerServiceWorker();
unregisterServiceWorker();


