import React from 'react';
import './modal.css';

const BackDrop = () => (
  <div className="backdrop">

  </div>
);

export default BackDrop;
