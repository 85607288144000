import axios from 'axios';
import { getHeaderOptions } from '../util';
import {
  FETCH_DREAM_TEAM_REQUESTS,
  FETCH_DREAM_TEAM_REQUESTS_SUCCESS,
  FETCH_DREAM_TEAM_REQUESTS_FAILED,
  DREAM_TEAM_SEND_REQUEST,
  DREAM_TEAM_SEND_REQUEST_SUCCESS,
  DREAM_TEAM_SEND_REQUEST_FAILED,
  DREAM_TEAM_RESPONSE,
  DREAM_TEAM_RESPONSE_SUCCESS,
  UPDATE_DREAM_TEAM,
  UPDATE_DREAM_TEAM_SUCCESS,
  REVOKE_APPROVED_DREAM_TEAM,
  REVOKE_APPROVED_DREAM_TEAM_SUCCESS,
  CANCEL_DREAM_TEAM_REQUEST_SUCCESS,
  SET_DREAM_TEAM_REQUESTS,
  CLEAR_DREAM_TEAM_REQUESTS,
} from './types';

export const fetchDreamTeamRequests = (id) => async(dispatch) => {
  dispatch({ type: FETCH_DREAM_TEAM_REQUESTS });
  const options = await getHeaderOptions();

  axios
    .get(`/dreamTeam/requests?_id=${id}`, { headers: { ...options } })
    .then((res) => {
      const type = res.data.success
        ? FETCH_DREAM_TEAM_REQUESTS_SUCCESS
        : FETCH_DREAM_TEAM_REQUESTS_FAILED;
      dispatch({ type, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_DREAM_TEAM_REQUESTS_FAILED, payload: err.data });
    });
};

export const sendDreamTeamRequest = (
  dreamTeamRequests,
  success,
  message = '',
) => async(dispatch) => {
  dispatch({ type: DREAM_TEAM_SEND_REQUEST });
  if (success) {
    dispatch({
      type: DREAM_TEAM_SEND_REQUEST_SUCCESS,
      payload: dreamTeamRequests,
    });
  } else {
    dispatch({ type: DREAM_TEAM_SEND_REQUEST_FAILED, payload: { message } });
  }
};

export const respondDreamTeamRequest = (requests) => async(dispatch) => {
  dispatch({ type: DREAM_TEAM_RESPONSE });
  dispatch({ type: DREAM_TEAM_RESPONSE_SUCCESS, payload: { requests } });
};

export const revokeDreamTeamApproval = (requests) => async(dispatch) => {
  dispatch({ type: REVOKE_APPROVED_DREAM_TEAM });
  dispatch({ type: REVOKE_APPROVED_DREAM_TEAM_SUCCESS, payload: { requests } });
};

export const updateDreamTeam = (requests) => async(dispatch) => {
  dispatch({ type: UPDATE_DREAM_TEAM });
  dispatch({ type: UPDATE_DREAM_TEAM_SUCCESS, payload: { requests } });
};

export const cancelDreamTeamRequest = (requests) => async(dispatch) => {
  dispatch({ type: CANCEL_DREAM_TEAM_REQUEST_SUCCESS, payload: { requests } });
};

export const setDreamTeamRequests = (dtRequests) => async(dispatch) => {
  dispatch({ type: SET_DREAM_TEAM_REQUESTS, payload: dtRequests });
};

export const clearDreamTeamRequests = () => async(dispatch) => {
  dispatch({ type: CLEAR_DREAM_TEAM_REQUESTS });
};
