/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TimeAgo from 'react-timeago';
import { timeagoFormatter } from '../../util';

class InboxList extends Component {
  state = {
    otherProfile: {},
    userProfile: {},
  };

  componentDidMount() {
    const { category, connection } = this.props;
    this.setProfiles(connection, category);
  }

  componentDidUpdate(prevProps) {
    const { connection, category } = this.props;
    const differentConnection =
      prevProps.connection._id !== connection._id ||
      prevProps.connection.type !== connection.type;
    const differentCategory = prevProps.category !== category;
    if (differentCategory || differentConnection) {
      this.setProfiles(connection, category);
    }
  }

  setProfiles = (connection, category) => {
    const { user, business } = this.props;
    let otherProfile;
    let userProfile;
    if (connection.type === 'B2C') {
      otherProfile =
        category === 'business'
          ? connection.users[connection.consumerId]
          : connection.users[connection.businessId];
      userProfile = category === 'personal' ? user : business;
    } else {
      otherProfile =
        user._id === connection.user1_id
          ? connection.users[connection.user2_id]
          : connection.users[connection.user1_id];
      userProfile = user;
    }
    this.setState({
      otherProfile,
      userProfile,
    });
  };

  truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  determineMessageSender = (message) => {
    const { userProfile } = this.state;
    const messageHasSenderProp = !!message.sender;
    if (messageHasSenderProp) {
      return message.sender._id === userProfile._id;
    }
    return message.userId === userProfile._id;
  };

  render() {
    const { connection, category } = this.props;
    const { otherProfile, userProfile } = this.state;
    const unreadMessagesCount =
      category === 'business'
        ? connection.unreadMessagesBusiness
        : connection.type === 'B2C'
        ? connection.unreadMessagesConsumer
        : userProfile._id === connection.user1_id
        ? connection.unreadMessagesUser1
        : connection.unreadMessagesUser2;
    return (
      <Link
        to={{
          pathname: '/Messages',
          state: {
            connection,
            otherProfile,
            userProfile,
            category,
          },
        }}
      >
        <div
          className={
            connection.unreadMessages ? 'Inbox-message-unread' : 'Inbox-message'
          }
        >
          {otherProfile.profilePic && (
            <div>
              <img
                className="inbox-profile-picture"
                src={otherProfile.profilePic}
                alt="other profile pic"
              />
            </div>
          )}

          <div className="name-message-flex">
            <div className="name-date-flex">
              <h4>{otherProfile.name}</h4>
              <p>
                <TimeAgo
                  date={connection.lastMessage.timestamp}
                  formatter={timeagoFormatter}
                  minPeriod={30}
                  style={{
                    border: 'none',
                    marginLeft: '5em',
                    color: '#959595',
                  }}
                />
              </p>
            </div>
            <div className="text-number-flex">
              <h4>
                {`${
                  this.determineMessageSender(connection.lastMessage)
                    ? 'Me:  '
                    : ''
                }${this.truncateString(connection.lastMessage.text, 90)}`}
              </h4>
              {unreadMessagesCount > 0 && (
                <p className={unreadMessagesCount > 0 ? ' new-messages' : ''}>
                  {unreadMessagesCount}
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(InboxList);
