/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { HorizontalCard } from '../../components';
import NoFavorite from './0-Fern.png';
import {
  reviewsBlades,
  worksWithBlades,
  instaPicsBlades,
} from '../BusinessProfile/BusinessInfo/BladesOfGlory';
import { getBatchOfProfiles, fetchFavorites } from '../../services';
import './PersonalFavorites.css';
import '../SearchResults/searchResults.css';

class PersonalFavorites extends Component {
  state = {
    results: [],
    user: this.props.location.state ? this.props.location.state.user : {},
    favorites: [],
    loaded: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getFavoritesInfo();
  }

  componentDidUpdate = (prevState) => {
    const { favorites } = this.props;
    const { user } = this.props.location.state;
    const newUser = user._id !== prevState.user._id;
    const isCurrentUser = user._id === this.props.user._id;
    const favoritesChanged =
      isCurrentUser &&
      favorites.favoriteBusinesses.size !== this.state.favorites.length &&
      this.state.loaded;
    if (newUser || favoritesChanged) {
      this.setState(
        {
          user,
          loaded: false,
        },
        () => {
          this.getFavoritesInfo();
        },
      );
    }
  };

  getFavoritesInfo = async() => {
    const { favorites, user } = this.props;
    const isCurrentUser = this.state.user._id === user._id;
    const list = isCurrentUser
      ? favorites
      : await fetchFavorites(this.state.user._id, null);
    const businessIdList = isCurrentUser
      ? Array.from(list.favoriteBusinesses.keys())
      : list.businessIds;
    const businessProfiles = await getBatchOfProfiles(
      'businesses',
      JSON.stringify(businessIdList),
    );

    const finishedFavorites = this.resultsLoad(businessProfiles);
    this.setState({
      favorites: finishedFavorites,
      loaded: true,
    });
  };

  resultsLoad = (favorites) => {
    const newFavorites = Object.values(favorites);
    return newFavorites.map((business) => [
      business,
      reviewsBlades,
      worksWithBlades,
      instaPicsBlades,
    ]);
  };

  render() {
    const { favorites, loaded, user } = this.state;
    const { profilePic, firstName, lastName } = user;

    return (
      <div className="personal-favorites-container">
        <div className="personal-favorites-header">
          <img src={profilePic} alt="profile pic" />
          <h2>{loaded && `${firstName} ${lastName}'s Personal Favorites`}</h2>
          {/* <h5>
              I've worked with these businesses over the years, mostly for
              my home, but once also at work with Garden of Eva
          </h5> */}
        </div>
        <section className="personal-favorites-results">
          {loaded && (
            <div className={favorites.length ? 'listings-list' : ''}>
              {favorites.length ? (
                favorites.map((business) => (
                  <HorizontalCard
                    businessCard={business}
                    {...this.props}
                    fromFavorites={user._id === this.props.user._id}
                  />
                ))
              ) : (
                <div className="no-favorites">
                  <img src={NoFavorite} alt="no favorite" />
                  <h2>
                    {user.firstName} {user.lastName} doesn&apos;t have any
                    favorite businesses yet!&apos;
                  </h2>
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ favorites }) => ({
  favorites,
});

export default connect(mapStateToProps)(withRouter(PersonalFavorites));
