import {
  FETCH_FAVORITES,
  FETCH_FAVORITES_SUCCESS,
  FETCH_FAVORITES_FAILED,
  UPDATE_FAVORITES,
} from '../actions/types';

const favorites = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FAVORITES:
      return {
        ...state,
        fetchingFavorites: true,
      };
    case FETCH_FAVORITES_SUCCESS:
      return {
        ...state,
        favoriteBusinesses: new Map(action.payload.businessIds),
        favoritedBy: new Map(action.payload.userIds),
        fetchingFavorites: false,
      };
    case FETCH_FAVORITES_FAILED:
      return {
        ...state,
        fetchingFavorites: false,
        favoritesErrorMessage:
          'Error fetching favorites. Please contact support if error persists.',
      };
    case UPDATE_FAVORITES:
      return {
        ...state,
        ...action.payload.favorites,
      };
    default:
      return state;
  }
};

export default favorites;
