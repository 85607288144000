import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ratings from 'react-ratings-declarative';
import '../Reviews.css';

// const styles = {
//   getEmojiButton: {
//     border: 'none',
//     margin: 0,
//     cursor: 'pointer',
//   },
//   emojiPicker: {
//     position: 'absolute',
//     bottom: -194,
//     right: 83,
//     marginLeft: '200px',
//   },
// };

export default class EditReview extends Component {
  state = {};

  render() {
    return (
      <div className="messages-right">
        <div className="Review-Form">
          <Link to="/messages">
            <p className="Review-Form-back">Back to conversation</p>
          </Link>
          <h1>Your Review</h1>
          <h4 className="review-error-text">{this.state.errorText}</h4>
          <div className="star-flex">
            <h5>Quality of work</h5>
            <Ratings
              rating={4}
              widgetRatedColors="#07BA62"
              widgetSpacings="0px"
            >
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
            </Ratings>
          </div>
          <div className="star-flex">
            <h5>Service</h5>
            <Ratings
              rating={5}
              widgetRatedColors="#07BA62"
              widgetSpacings="1px"
            >
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
            </Ratings>
          </div>
          <div className="star-flex">
            <h5>Communication</h5>
            <Ratings
              rating={3}
              widgetRatedColors="#07BA62"
              widgetSpacings="1px"
            >
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
            </Ratings>
          </div>
          <div className="star-flex">
            <h5>Price</h5>
            <Ratings
              rating={3}
              widgetRatedColors="#07BA62"
              widgetSpacings="1px"
            >
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
              <Ratings.Widget
                widgetHoverColor="#07BA62"
                widgetDimension="17px"
              />
            </Ratings>
          </div>
          <div className="recommend-choice">
            <h5>Would you recommend this service provider?</h5>
            <div className="recommend-choice-flex">
              <div className="recommend-button-false">
                <p>Yes</p>
              </div>
              <div className="recommend-button-false">
                <p>Neutral</p>
              </div>
              <div className="recommend-button-true">
                <p>No</p>
              </div>
            </div>
          </div>
          <div className="review-type">
            <h5>Your Review</h5>
            <p>
              I can&apos;t say enough good things about Blades of Glory. Great
              service, consistent communication , and unbeatable prices. My
              backyard has never looked better and all my neighbors are jealous.
              If you&apos;re looking for a landscaper, look not farther.
            </p>
            <div className="Edit-Review-flex">
              <Link to="EditReviewBusinessForm">
                <p className="Edit-Review">EditReview</p>
              </Link>
              <h5>Delete</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
