/* eslint-disable no-invalid-this */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Button, Form, TextArea, Modal } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { notificationConstructor, getDate } from '../../../../util';
import { socketServices } from '../../../../services';

class QuoteFormConsumer extends Component {
  state = {
    quote:
      this.props.location.state && this.props.location.state.viewType === 'sent'
        ? { ...this.props.quotes.sent }
        : { ...this.props.quotes.rejected[0] },
    rejectedViewIndex: 0,
    message: '',
    showMessage: false,
    responded: false,
    showItems: false,
  };

  componentDidMount = () => {
    const {
      connections: {
        selectedConnection: { connection },
      },
      location,
    } = this.props;
    const viewingSentQuote =
      location.state && location.state.viewType === 'sent';
    if (viewingSentQuote) {
      this.markedReadCheck();
      const currentQuoteFormView = `quoteForm_${connection.type}_${connection._id}`;
      socketServices.emitEnterQuoteFormView(currentQuoteFormView);
    }
    socketServices.listenQuoteFormView(this);
  };

  componentDidUpdate = (prevProps) => {
    const {
      location: { state },
      quotes,
    } = this.props;
    const changedViewType =
      state.viewType !== prevProps.location.state.viewType;
    if (changedViewType) {
      const quote =
        state.viewType === 'sent' ? quotes.sent : quotes.rejected[0];
      const changedToRejectedView = state.viewType === 'rejected';
      if (changedToRejectedView) {
        const leftQuoteForm = `quoteForm_${prevProps.connections.selectedConnection.connection.type}_${prevProps.connections.selectedConnection.connection._id}`;
        socketServices.emitLeaveQuoteFormView(leftQuoteForm);
      }
      this.setState({
        quote,
        rejectedViewIndex: 0,
      });
    }
  };

  componentWillUnmount = () => {
    const {
      connections: {
        selectedConnection: { connection },
      },
    } = this.props;
    const leftQuoteForm = `quoteForm_${connection.type}_${connection._id}`;
    socketServices.emitLeaveQuoteFormView(leftQuoteForm);
    socketServices.removeQuoteFormViewListeners();
  };

  markedReadCheck = async() => {
    const {
      connections: { selectedConnection },
    } = this.props;
    const notMarkedRead = !selectedConnection.connection.quote.sent.read;
    if (notMarkedRead) {
      const { connectionsActions } = this.props;
      const updatedConnection = { ...selectedConnection };
      updatedConnection.connection.quote.sent.read = true;
      connectionsActions.markQuoteRead(updatedConnection);
    }
  };

  respondQuote = async(decision, quote) => {
    if (decision !== quote.status) {
      const {
        connections: { selectedConnection },
        connectionsActions,
        user,
      } = this.props;
      const updatedConnection = { ...selectedConnection };
      quote.status = decision;
      // const quoteDeclined = decision === 'declined';
      // const quoteRevoked = decision === 'pending';
      // updatedConnection.connection.quote.sent.status = decision;

      // if (quoteDeclined) { // only need to move the quote for a decline
      //   updatedConnection.connection.quote.rejected.push(updatedConnection.connection.quote.sent);
      // } else if (quoteRevoked) {
      //   updatedConnection.connection.quote.rejected.splice(0, 1);
      // }
      const text =
        decision === 'pending'
          ? `${user.firstName} ${user.lastName} revoked their decision.`
          : `${user.firstName} ${user.lastName} ${decision} your quote.`;
      const message = {
        sender: {
          _id: user._id,
          username: user.username,
          type: 'consumer',
        },
        receiver: {
          _id: updatedConnection.otherProfile._id,
          username: updatedConnection.otherProfile.username,
          type: 'business',
        },
        text,
        timestamp: Date.now(),
        connection: {
          _id: updatedConnection.connection._id,
          type: 'B2C',
        },
      };
      updatedConnection.connection.lastMessage = message;
      // updatedConnection.connection.unreadMessagesBusiness += 1;
      // const respondQuote = await connectionsActions.quoteUpdate(updatedConnection.connection._id, updatedConnection.connection.quote, decision, message);
      await connectionsActions.setSelectedConnection(updatedConnection);
      // connectionsActions.updateConnections(updatedConnection, updatedConnection.category, true);
      // this.props.sendMessage(message, `${updatedConnection.connection.type}_${updatedConnection.connection._id}`, updatedConnection.connection);
      const notificationType =
        decision === 'rejected' ? 'quote declined' : `quote ${decision}`;
      const notification = notificationConstructor(
        { ...user, type: 'consumer' },
        { ...selectedConnection.otherProfile, type: 'business' },
        notificationType,
        {
          _id: updatedConnection.connection._id,
          type: updatedConnection.connection.type,
        },
      );

      const currentChat = `${updatedConnection.connection.type}_${updatedConnection.connection._id}`;
      socketServices.emitQuoteRespond(
        quote,
        updatedConnection.connection,
        currentChat,
        decision,
        notification,
      );
    }
  };

  rejectedQuoteScroll = (newIndex) => {
    const { connections: selectedConnection } = this.props;
    this.setState({
      rejectedViewIndex: newIndex,
      quote: selectedConnection.quote.rejected[newIndex],
    });
  };

  switchModal = (quote) => {
    quote.items[0].item.trim().length > 0 &&
      this.setState((prevState) => ({
        showItems: !prevState.showItems,
      }));
  };

  itemLength = (quote) => {
    if (quote.items.length === 1) {
      return quote.items[0].item.trim().length > 0
        ? quote.items.length + ' Item '
        : '0 Items ';
    }

    return quote.items.length + ' Items ';
  };

  render() {
    const { viewer, viewType } = this.props.location.state;
    const { quote, showItems } = this.state;
    // const quote = viewType === 'sent' ? this.props.quotes.sent : this.props.quotes.rejected[rejectedViewIndex];
    return (
      <div className="messages-right">
        <div className="Quote-Form">
          <Link
            to={{
              pathname: '/Messages',
              state: {
                ...this.props.connections.selectedConnection,
              },
            }}
          >
            <p>Back to conversation</p>
          </Link>
          <h1 style={{ fontWeight: 'bold' }}>
            {viewType === 'rejected' ? 'Declined Quotes' : 'Quote'}
          </h1>
          {viewType === 'sent' ? (
            <h3>
              Status:{' '}
              {quote.status === 'sent' ? (
                <span className="pending">Pending Approval</span>
              ) : (
                <span className={quote.status}>
                  {quote.status.toUpperCase()}
                </span>
              )}
            </h3>
          ) : (
            <h3>
              Date Declined:{' '}
              <span className="pending">
                {getDate(quote.modifiedOn, 'MMDDYYYY')}
              </span>
            </h3>
          )}
          <Form id="Quote-form">
            <Form.Group>
              <Form.Input
                label="Title"
                name="title"
                value={quote.title}
                readOnly
                width={11}
              />
              <Form.Input label="Date" value={quote.date} readOnly width={4} />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly label="Task" value={quote.task} width={6} />
              <Form.Input
                readOnly
                label="Rate"
                value={`$${quote.rate}`}
                width={3}
              />
              <Form.Input
                readOnly
                label="Hours"
                value={quote.hours}
                width={3}
              />
              <Form.Input
                label="Total"
                value={`$${quote.taskTotal}`}
                width={3}
                readOnly
              />
            </Form.Group>
            <div className="Items-itemsubtotal">
              <Button onClick={() => this.switchModal(quote)}>
                {' '}
                {this.itemLength(quote)}
              </Button>
              <Modal
                closeIcon
                className="Modal-Items"
                open={showItems}
                onClose={() => this.switchModal(quote)}
                // trigger={<Button> {this.itemLength(quote)}</Button>}
              >
                <Modal.Content>
                  <Modal.Description>
                    <Form id="Quote-Items">
                      {quote.items.map((item, idx) => (
                        <Form.Group key={item.id}>
                          <Form.Input
                            label={`Item ${idx + 1}`}
                            error={this.state.itemError}
                            name="item"
                            value={item.item}
                            readOnly
                            width={6}
                          />
                          <Form.Input
                            label="Price"
                            type="number"
                            error={this.state.priceError}
                            name="price"
                            value={item.price}
                            readOnly
                            width={3}
                          />
                          <Form.Input
                            label="Quantity"
                            type="number"
                            error={this.state.quantityError}
                            name="quantity"
                            value={item.quantity}
                            readOnly
                            width={3}
                          />
                          <Form.Input
                            label="Total"
                            value={`$${item.itemTotal}`}
                            readOnly
                            width={3}
                          />
                        </Form.Group>
                      ))}
                    </Form>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              <Form.Input
                label="Item Total"
                value={`$${quote.itemSum}`}
                readOnly
              />
            </div>
            <Form.Group className="Subtotal">
              <Form.Input
                label="Subtotal"
                value={`$${quote.subTotal}`}
                readOnly
              />
            </Form.Group>

            <Form.Group>
              <Form.Field
                className="Quote-textarea"
                control={TextArea}
                value={quote.termsNotes}
                label="Terms & Notes"
                width={15}
                readOnly
              />
            </Form.Group>
          </Form>

          {viewer === 'consumer' &&
            viewType === 'sent' &&
            (quote.status === 'pending' ? (
              <div className="Send-Customer">
                <Button
                  onClick={() => this.respondQuote('approved', quote)}
                  color="green"
                >
                  Accept
                </Button>
                <h5
                  onClick={() => this.respondQuote('rejected', quote)}
                  onKeyDown={() => this.respondQuote('rejected', quote)}
                  role="presentation"
                >
                  I&apos;ll Pass
                </h5>
              </div>
            ) : (
              <div className="Send-Customer">
                <h5
                  onClick={() => this.respondQuote('pending', quote)}
                  onKeyDown={() => this.respondQuote('pending', quote)}
                  role="presentation"
                >
                  Revoke Decision
                </h5>
              </div>
            ))}
        </div>
        {this.state.responded && (
          <Redirect
            push
            to={{
              pathname: '/Messages',
              state: { ...this.props.connections.selectedConnection },
            }}
          />
        )}
      </div>
    );
  }
}

export default QuoteFormConsumer;
