import React from 'react';
import SearchBar from '../Home/SearchBar';
import { ParallaxImage } from '../../components';
import ListingsList from './ListingsList';
import './searchResults.css';

const SearchResults = (props) => {
  const { results, tags } = props.location.state;

  return (
    <div>
      <ParallaxImage
        bgImage={
          'https://res.cloudinary.com/rhiz/image/upload/b_rgb:000,o_60,c_fill,g_center,h_601,w_1920,y_0/v1558071139/Home%20Page/Search_Background_-_50_Black_Overlay_kwe1nc.jpg' // eslint-disable-line
        }
        height={{ height: '225px' }}
        title="Word of mouth is now online. Find the right business here."
        alt="parallaximg"
        search={<SearchBar />}
      />
      <ListingsList
        openModal={props.openModal}
        openSignUp={props.openSignUp}
        user={props.user}
        results={results}
        tags={tags}
        {...props}
      />
    </div>
  );
};

export default SearchResults;
