/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Container, Grid, Header, List } from 'semantic-ui-react';
import Modal from './comingSoonModal';
import facebook from '../assets/images/SocialLinksPng/facebook.png';
import twitter from '../assets/images/SocialLinksPng/twitter.png';
import instagram from '../assets/images/SocialLinksPng/instagram.png';

class Footer extends Component {
  state = {
    openModal: false,
  };

  // open coming soon modal for links that don't have pages to go to yet
  openCloseModal = (e) => {
    const { openModal } = this.state;
    e.preventDefault();
    this.setState({
      openModal: !openModal,
    });
  };

  render() {
    const { openModal } = this.state;

    return (
      <Container
        fluid
        style={{ paddingLeft: 0, paddingRight: 0, paddingTop: '1em' }}
      >
        <Segment inverted vertical style={{ padding: '2em 0em' }}>
          <Container>
            <Grid columns={3} divided inverted>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Link to='/'>
                    <Header inverted as='h1' content='Rhiz' />
                  </Link>
                  <Grid.Column>
                    <div className='Social-Links'>
                      <a
                        href='https://www.facebook.com/RhizMarket/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={facebook} alt='fbook' />
                      </a>
                      <a
                        href='https://twitter.com/rhizmarket'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={twitter} alt='twitter' />
                      </a>
                      <a
                        href='https://www.instagram.com/rhizmarket/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={instagram} alt='insta' />
                      </a>
                      {/* <a href='https://www.pinterest.com/rhizmarket/' target="_blank">
                        <img src={pinterest} />
                      </a>
                      <a href='https://www.linkedin.com/company/rhiz' target="_blank">
                        <img src={linkedin} />
                      </a> */}
                    </div>
                  </Grid.Column>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Company' />
                  <List link>
                    <List.Item>
                      <a href='/team'>Team</a>
                    </List.Item>
                    <List.Item>
                      <a href='https://app.termly.io/document/terms-of-use-for-online-marketplace/9bd161af-11c6-4ebd-a9b6-4c3f9240d76c'>
                        Terms of Service
                      </a>
                    </List.Item>
                    <List.Item>
                      <a href='https://app.termly.io/document/privacy-policy/619d97bb-088e-41cf-8041-b62f466fb464'>
                        Privacy Policy
                      </a>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Contact' />
                  <List link>
                    <List.Item>
                      {/* eslint-disable jsx-a11y/anchor-is-valid */}
                      <a
                        onClick={this.openCloseModal}
                        onKeyDown={this.openCloseModal}
                      >
                        FAQ
                      </a>
                    </List.Item>
                    <List.Item>
                      <Link to='/contact'>Contact</Link>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4'>
                    Business Tools
                  </Header>
                  <List link>
                    <List.Item>
                      <Link to='/subscriptions'>Pricing</Link>
                    </List.Item>
                    <List.Item>
                      <Link to='/learning'>Learning</Link>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='centered' style={{ color: 'white' }}>
                © Rhiz
              </Grid.Row>
            </Grid>
            <Modal onClose={this.openCloseModal} show={openModal}>
              Coming Soon
            </Modal>
          </Container>
        </Segment>
      </Container>
    );
  }
}

export default Footer;
