/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import './modal.css';
import { notificationConstructor } from '../../../util';
import { socketServices } from '../../../services';
import { SuccessModal } from '../../../components';
import PropTypes from 'prop-types';

const styles = {
  getEmojiButton: {
    border: 'none',
    margin: 0,
    cursor: 'pointer',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: -245,
    right: 0,
    marginLeft: '200px',
  },
};

export default class ConnectModal extends Component {
  state = {
    requestMessage: '',
    showMessage: false,
    success: false,
    attachment: null,
    showEmojis: false,
    modal: false,
  };

  constructor(props) {
    super(props);
    this.dateElRef = React.createRef();
    this.descriptionElRef = React.createRef();
  }

  componentDidUpdate = () => {
    const { connections } = this.props;
    const { requestMessage, showMessage } = this.state;
    if (
      connections.message &&
      requestMessage !== connections.message &&
      showMessage
    ) {
      this.setState({
        requestMessage: connections.message,
      });
    }
  };

  descriptionHandler = (e) => {
    this.setState({
      description: e.target.value,
      requestMessage: '',
      showMessage: false,
    });
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState({
      description: this.state.description + emoji,
      requestMessage: '',
      showMessage: false,
    });
  };

  showEmojis = () => {
    this.setState(
      {
        showEmojis: true,
      },
      () => document.addEventListener('click', this.closeMenu),
    );
  };

  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false,
        },
        () => document.removeEventListener('click', this.closeMenu),
      );
    }
  };
  // upload files
  attachmentUploadHandler = (e) => {
    this.setState({
      attachment: e.target.files[0],
      requestMessage: '',
      showMessage: false,
    });
  };

  sendConnectionRequest = (e) => {
    e.preventDefault();
    const { user, userProfile } = this.props;
    const description = this.descriptionElRef.current.value;
    const connection = {
      user1_id: user._id,
      user2_id: userProfile._id,
      users: {
        [user._id]: {
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          profilePic: user.profilePic,
        },
        [userProfile._id]: {
          _id: userProfile._id,
          name: `${userProfile.firstName} ${userProfile.lastName}`,
          profilePic: userProfile.profilePic,
        },
      },
      lastMessage: {
        text: description,
        sender: {
          _id: user._id,
          username: user.username,
          type: 'consumer',
        },
        receiver: {
          _id: userProfile._id,
          username: userProfile.username,
          type: 'consumer',
        },
        timestamp: Date.now(),
        connection: {
          type: 'C2C',
        },
      },
      type: 'C2C',
      unreadMessagesUser1: 0,
      unreadMessagesUser2: 1,
    };
    this.setState(
      {
        showMessage: true,
      },
      () => {
        const notification = notificationConstructor(
          { ...user, type: 'consumer' },
          { ...userProfile, type: 'consumer' },
          'c2cConnect started',
          { type: 'C2C' },
        );
        socketServices.emitC2CConnectRequest(
          connection,
          user._id,
          notification,
          this,
        );
      },
    );
  };

  render() {
    /* eslint-disable-next-line max-len */
    const placeholderMessage = `Say hello! Include a personal message to ${this.props.userProfile.firstName} along with your connect request.`;
    const { requestMessage } = this.state;
    const { connections, userProfile } = this.props;

    return (
      <div className="connect-modal">
        {this.state.modal ? (
          <SuccessModal closeModal={this.props.closeModalHandler} />
        ) : (
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              id="close-modal-button"
              style={{
                marginRight: '1em',
                marginTop: '1em',
                cursor: 'pointer',
              }}
              onClick={(e) => this.props.closeModalHandler(e)}
            />
            <form
              id="connect-modal-form"
              onSubmit={this.sendConnectionRequest}
            >
              <section className="connect-modal-left-C2C">
                <img
                  id="profile-pic-C2C"
                  src={userProfile.profilePic}
                  alt={userProfile.businessName}
                />
                <h4>
                  {userProfile.firstName} {userProfile.lastName}
                </h4>
                <div className="connection-type">
                  <p>Personal</p>
                </div>
              </section>

              <section className="connect-modal-right">
                <h1>Send a connect request</h1>
                <label htmlFor="story">Tell us your story: </label>
                <textarea
                  id="story"
                  ref={this.descriptionElRef}
                  row="5"
                  placeholder={placeholderMessage}
                  value={this.state.description}
                  onChange={(e) => this.descriptionHandler(e)}
                ></textarea>
                <section className="attachment-container">
                  <label
                    className="delete-upload-buttons"
                    htmlFor="attachment-upload"
                  >
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      id="attachment-icon"
                      style={{ marginRight: '1em' }}
                    />
                  </label>
                  <input
                    id="attachment-upload"
                    type="file"
                    onChange={(e) => this.attachmentUploadHandler(e)}
                  ></input>
                  {this.state.showEmojis ? (
                    <div>
                      <span
                        style={styles.emojiPicker}
                        ref={(el) => (this.emojiPicker = el)}
                      >
                        <Picker
                          onSelect={this.addEmoji}
                          emojiTooltip={true}
                        />
                      </span>
                      <span
                        onClick={this.showEmojis}
                        onKeyDown={this.showEmojis}
                        style={{ cursor: 'pointer' }}
                        role="presentation"
                      >
                        {String.fromCodePoint(0x1f60a)}
                      </span>
                    </div>
                  ) : (
                    <span
                      onClick={this.showEmojis}
                      onKeyDown={this.showEmojis}
                      style={{ cursor: 'pointer' }}
                      role="presentation"
                    >
                      {String.fromCodePoint(0x1f60a)}
                    </span>
                  )}
                </section>
                <button id="send-connect-button">Send Connect Request</button>
                {requestMessage.length > 0 &&
                  (connections.success === false ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '1.1em',
                        marginTop: '2%',
                      }}
                    >
                      {requestMessage}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: '#00BE6E',
                        fontSize: '1.25em',
                        marginTop: '2%',
                      }}
                    >
                      {requestMessage}
                    </div>
                  ))}
              </section>
            </form>
          </div>
        )}
      </div>
    );
  }
}

ConnectModal.propTypes = {
  connections: PropTypes.arrayOf({
    message: PropTypes.any,
  }),
  user: PropTypes.object,
  userProfile: PropTypes.object,
  closeModalHandler: PropTypes.any,
};
