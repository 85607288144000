/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Picker } from 'emoji-mart';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Attachment from './Attachment';
import './Modal.css';
import { getDate, formatDate, notificationConstructor } from '../../../util';
import { socketServices } from '../../../services';

const styles = {
  getEmojiButton: {
    border: 'none',
    margin: 0,
    cursor: 'pointer',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: -194,
    right: 83,
    marginLeft: '200px',
  },
};

export default class ViewMessageDeclineModal extends Component {
  state = {
    date: this.props.connection.date,
    selectedOption: '',
    choice: '',
    description: this.props.connection.description,
    zipcode: this.props.connection.zipcode,
    urgency: this.props.connection.urgency,
    attachments: this.props.connection.attachments || [],
    startDate: this.props.connection.startDate,
    // getDate(this.props.connection.startDate, 'YYYYMMDD'),
    showEmojis: false,
    editsMade: false,
    message: '',
    success: true,
    showMessage: '',
  };

  componentDidMount() {
    socketServices.listenConnectRequestEditConsumer(this);
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    socketServices.removeConnectRequestEditListeners();
  }

  handleOptionChange = (event) => {
    this.setState(
      {
        selectedOption: event.target.value,
        choice: event.target.value !== 'other' ? event.target.value : '',
        message: '',
        showMessage: false,
        success: true,
      },
      () => this.checkForChanges(),
    );
  };

  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false,
        },
        () => document.removeEventListener('click', this.closeMenu),
      );
    }
  };

  attachmentUploadHandler = (e) => {
    const { attachments } = this.state;
    const newAttachments = JSON.parse(JSON.stringify(attachments));
    const reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      file = {
        ...file,
        path: reader.result,
      };
      file && newAttachments.push(file);
      this.setState(
        {
          attachments: newAttachments,
          message: '',
          showMessage: false,
          success: true,
        },
        () => this.checkForChanges(),
      );
    };
    file && reader.readAsDataURL(file);
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState(
      {
        description: this.state.description + emoji,
        message: '',
        showMessage: false,
        success: true,
      },
      () => this.checkForChanges(),
    );
  };

  showEmojis = () => {
    this.setState(
      {
        showEmojis: true,
      },
      () => document.addEventListener('click', this.closeMenu),
    );
  };

  handleotherChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        message: '',
        showMessage: false,
        success: true,
      },
      this.checkForChanges(),
    );
  };

  changeDateHandler = (date) => {
    this.setState(
      {
        // startDate: e.target.value,
        startDate: getDate(date, 'MMDDYYYY'),
        message: '',
        showMessage: false,
        success: true,
      },
      () => this.checkForChanges(),
    );
  };

  changeUrgencyHandler = (e) => {
    this.setState(
      {
        selectedOption: e.target.value,
        message: '',
        showMessage: false,
        success: true,
      },
      () => this.checkForChanges(),
    );
  };

  respond = (decision) => {
    const { choice } = this.state;
    const reasonGiven = decision === 'accepted' || choice.length > 0;
    if (reasonGiven) {
      this.props.respondToRequest(decision, choice);
    } else {
      this.setState({
        message: 'Please provide a reason you are declining this job.',
        showMessage: true,
        success: false,
      });
    }
  };

  saveChanges = () => {
    const { user, otherProfile, connection } = this.props;
    const { description, urgency, startDate, attachments } = this.state;
    const updatedConnection = { ...connection };
    updatedConnection.attachments = attachments;
    updatedConnection.startDate = startDate;
    updatedConnection.date = Date.now();
    updatedConnection.description = description;
    updatedConnection.urgency = urgency;
    const notification = notificationConstructor(
      { ...user },
      { ...otherProfile, type: 'business' },
      'connect request edit',
    );
    socketServices.emitConnectRequestEdit(updatedConnection, notification);
    // connectionsActions.editPendingConnection(_id, updates, connections);
  };

  removeImage = (i) => {
    const { attachments } = this.state;
    // const attachments = this.state.attachments
    const newAttachments = JSON.parse(JSON.stringify(attachments));
    newAttachments.splice(i, 1);
    this.setState(
      {
        attachments: newAttachments,
        message: '',
        showMessage: false,
        success: true,
      },
      () => this.checkForChanges(),
    );
  };

  checkForChanges = () => {
    const {
      attachments,
      description,
      urgency,
      zipcode,
      startDate,
    } = this.props.connection;
    const editsMade =
      JSON.stringify(attachments) !== JSON.stringify(this.state.attachment) ||
      this.state.description !== description ||
      this.state.zipcode !== zipcode ||
      this.state.urgency !== urgency ||
      this.state.date !== getDate(startDate, 'YYYYMMDD');
    editsMade && this.setState({ editsMade });
  };

  render() {
    const {
      viewType,
      connection,
      category,
      otherProfile,
      closeModal,
      declineModal,
    } = this.props;
    const {
      startDate,
      description,
      urgency,
      zipcode,
      attachments,
    } = this.state;

    const { showMessage, message, editsMade, success } = this.state;
    return (
      <div className="view-message-modal">
        <h4
          className="message-close-modal"
          onClick={closeModal}
          onKeyDown={closeModal}
          role="presentation"
        >
          X
        </h4>
        <div className="view-message-flex">
          <div className="view-message-left">
            <img src={otherProfile.profilePic} alt="other profile pic" />
            <h2>{otherProfile.name}</h2>

            <div
              className={
                viewType === 'edit' && category === 'sent'
                  ? 'view-message-flex-data-sent'
                  : 'view-message-flex-data'
              }
            >
              {category === 'sent' && viewType === 'edit' ? (
                <div className="connect-zip">
                  <p>SERVICE AREA ZIP</p>
                  <input
                    type="text"
                    name="zipcode"
                    row="5"
                    value={zipcode}
                    onChange={this.handleotherChange}
                  />
                </div>
              ) : (
                <div className="view-flex-data">
                  <h4>Service zip:</h4>
                  <p>{zipcode}</p>
                </div>
              )}

              {viewType === 'edit' && category === 'sent' ? (
                <div>
                  {/* <label for="start-date">START DATE </label>
                  <input type="date" id="start-date" name='date'
                  value={startDate} onChange={this.handleotherChange} /> */}
                  <DayPickerInput
                    onDayChange={this.changeDateHandler}
                    formatDate={formatDate}
                    format="MM-dd-yyyy"
                    placeholder={this.state.startDate}
                    dayPickerProps={{
                      disabledDays: {
                        before: new Date(),
                      },
                    }}
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              ) : (
                <div className="view-flex-data">
                  <h4>Start date:</h4>
                  <p>{startDate}</p>
                </div>
              )}

              {viewType === 'edit' && category === 'sent' ? (
                <div className="connect-edit-urgency">
                  <h4>Urgency</h4>
                  <label className="radio-option-container">
                    <p>Just curious</p>
                    <input
                      type="radio"
                      id="just-curious"
                      name="urgency"
                      value="Just Curious"
                      className="connect-modal-radio-button"
                      checked={urgency === 'Just Curious'}
                      onChange={this.handleotherChange}
                    />
                    <span className="checkmark-connect"></span>
                  </label>

                  <label className="radio-option-container">
                    <p>Ready to Go</p>
                    <input
                      type="radio"
                      id="ready-to-go"
                      name="urgency"
                      value="Ready to Go"
                      className="connect-modal-radio-button"
                      checked={urgency === 'Ready to Go'}
                      onChange={this.handleotherChange}
                    />
                    <span className="checkmark-connect"></span>
                  </label>

                  <label className="radio-option-container">
                    <p>Emergency</p>
                    <input
                      type="radio"
                      id="emergency"
                      name="urgency"
                      value="Emergency"
                      className="connect-modal-radio-button"
                      checked={urgency === 'Emergency'}
                      onChange={this.handleotherChange}
                    />
                    <span className="checkmark-connect"></span>
                  </label>
                </div>
              ) : (
                <div className="view-flex-data">
                  <h4>Urgency:</h4>
                  <p>{urgency}</p>
                </div>
              )}
              {category === 'declined' && (
                <div className="view-flex-data">
                  <h4>Reason:</h4>
                  <p>&quot;{connection.reason}&quot;</p>
                </div>
              )}
              {viewType === 'review' && category === 'received' && (
                <div className="view-message-accept-pass">
                  <h4
                    onClick={() => this.respond('accepted')}
                    onKeyDown={() => this.respond('accepted')}
                    className="view-message-accept"
                    role="presentation"
                  >
                    ACCEPT
                  </h4>
                  <h4
                    onClick={declineModal}
                    onKeyDown={declineModal}
                    className="view-message-pass"
                    role="presentation"
                  >
                    PASS
                  </h4>
                </div>
              )}
            </div>
          </div>

          {viewType !== 'decline' && (
            <div className="view-message-right">
              <h1>Connect request</h1>
              {viewType === 'edit' && category === 'sent' ? (
                <div className="view-message-description">
                  <textarea
                    name="description"
                    value={this.state.description}
                    onChange={this.handleotherChange}
                  />
                  <section className="attachment-container">
                    <label
                      className="delete-upload-buttons"
                      htmlFor="attachment-upload"
                    >
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        id="attachment-icon"
                        style={{ marginRight: '1em' }}
                      />
                    </label>
                    <input
                      id="attachment-upload"
                      type="file"
                      onChange={(e) => this.attachmentUploadHandler(e)}
                    ></input>
                    {this.state.showEmojis ? (
                      <div>
                        <span
                          style={styles.emojiPicker}
                          ref={(el) => (this.emojiPicker = el)}
                        >
                          <Picker
                            onSelect={this.addEmoji}
                            emojiTooltip={true}
                          />
                        </span>
                        <span
                          onClick={this.showEmojis}
                          onKeyDown={this.showEmojis}
                          style={{ cursor: 'pointer' }}
                        >
                          {String.fromCodePoint(0x1f60a)}
                        </span>
                      </div>
                    ) : (
                      <span
                        onClick={this.showEmojis}
                        onKeyDown={this.showEmojis}
                        style={{ cursor: 'pointer' }}
                      >
                        {String.fromCodePoint(0x1f60a)}
                      </span>
                    )}
                  </section>
                </div>
              ) : (
                <div className="view-message-description">
                  <p>{description}</p>
                </div>
              )}

              {viewType === 'edit' && category === 'sent' && editsMade && (
                <div>
                  <div className="sent-save-description">
                    <Button
                      color="green"
                      onClick={() => this.saveChanges(connection._id)}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )}
              {showMessage && message.length && !success ? (
                <div
                  style={{ color: 'red', fontSize: '1.25em', marginTop: '2%' }}
                >
                  {message}
                </div>
              ) : (
                <div
                  style={{
                    color: '#00BE6E',
                    fontSize: '1.25em',
                    marginTop: '2%',
                  }}
                >
                  {message}
                </div>
              )}

              <div className="view-message-attachment">
                {attachments.map((image, index) => {
                  const imageDiv = <image src={image.path} />;
                  return (
                    <div style={{ display: 'flex', marginRight: '4%' }}>
                      <Attachment image={imageDiv} />
                      {viewType === 'edit' && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="sm"
                          id="add-business"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.removeImage(index)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {viewType === 'decline' && (
            <div className="view-message-right">
              <h1>Are you sure you want to decline.</h1>

              <div className="decline-header-text">
                <p>
                  The customer will be notified that you&apos;ve chosen to pass.
                  If you like, you can provide a little feedback as to why.
                </p>
              </div>

              <div className="decline-optional">
                <h3>OPTIONAL -</h3>
                <p>Let the customer know why you declined:</p>
              </div>

              <form className="decline-optional-radio">
                <label className="view-message-radio">
                  <p>
                    The project described is not a service my business provides
                  </p>
                  <input
                    className="decline-radio"
                    type="radio"
                    value="The project described is not a service my business provides"
                    checked={
                      this.state.selectedOption ===
                      'The project described is not a service my business provides'
                    }
                    onChange={this.handleOptionChange}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="view-message-radio">
                  <p>Too busy with other jobs</p>
                  <input
                    type="radio"
                    value="Too busy with other jobs"
                    checked={
                      this.state.selectedOption === 'Too busy with other jobs'
                    }
                    onChange={this.handleOptionChange}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="view-message-radio">
                  <p>Not interested in the job</p>
                  <input
                    type="radio"
                    value="Not interested in the job"
                    checked={
                      this.state.selectedOption === 'Not interested in the job'
                    }
                    onChange={this.handleOptionChange}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="view-message-radio">
                  <p>Other:</p>
                  <input
                    type="radio"
                    value="other"
                    checked={this.state.selectedOption === 'other'}
                    onChange={this.handleOptionChange}
                  />
                  <span className="checkmark"></span>
                </label>
              </form>

              {this.state.selectedOption !== 'other' ? (
                <textarea
                  placeholder="Reason"
                  className="decline-text"
                  value={this.state.choice}
                  name="choice"
                />
              ) : (
                <textarea
                  placeholder="Reason"
                  className="decline-text"
                  value={this.state.choice}
                  name="choice"
                  onChange={this.handleotherChange}
                />
              )}
              <div
                onClick={() => this.respond('declined', this.state.choice)}
                onKeyDown={() => this.respond('declined', this.state.choice)}
                className="decline-send"
              >
                <p>Decline</p>
              </div>
              {showMessage && message.length && !success ? (
                <div
                  style={{
                    color: 'red',
                    paddingBottom: '5px',
                    fontSize: '1.1em',
                  }}
                >
                  {message}
                </div>
              ) : (
                <div
                  style={{
                    color: '#00BE6E',
                    paddingBottom: '5px',
                    fontSize: '1.25em',
                  }}
                >
                  {message}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
