/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Image, Modal } from 'semantic-ui-react';
import SignUpModal from './signUpModal';
import LoginModal from './loginModal';
import RetrievePasswordModal from './RetrievePasswordModal';
import SubscriptionModal from './SubscriptionModal';
import '../modalForms.css';
// import Subscription from '../../Subscription/Subscription';

class ModalForms extends Component {
  state = {
    open: this.props.openModal,
    formType: this.props.formType,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
      this.setState({
        open: this.props.openModal,
        formType: this.props.formType,
      });
    }
  }

  openSignUpModal = () => {
    this.setState({
      open: true,
      formType: 'signUp',
    });
  };

  openLoginModal = () => {
    this.setState(
      {
        open: false,
        formType: 'login',
      },
      () => {
        this.setState({
          open: true,
        });
      },
    );
  };

  // openSubscriptionModal = (e) => {
  //   console.log('hey there subscription')
  //   this.setState({
  //     open: false,
  //     formType: 'subscription',
  //   }, () => {
  //     this.setState({
  //       open: true
  //     })
  //   })
  // }

  openRetrievePasswordModal = () => {
    this.setState(
      {
        open: false,
        formType: 'retrievePassword',
      },
      () => {
        this.setState({
          open: true,
        });
      },
    );
  };

  closeModals = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { open, formType } = this.state;
    const { location } = this.props;

    return (
      <div>
        <Modal
          size={'tiny'}
          open={open}
          onClose={this.closeModals}
          className="loginModal"
          closeIcon
        >
          <Modal.Content>
            {formType === 'signUp' ? (
              <div>
                <Image
                  size="small"
                  src="https://res.cloudinary.com/rhiz/image/upload/v1562133352/Company/r-curved-stem_c1fkbb.png"
                  centered
                />
                <SignUpModal
                  openLogin={this.openLoginModal}
                  location={location}
                />
              </div>
            ) : formType === 'login' ? (
              <div>
                <Image
                  size="small"
                  src="https://res.cloudinary.com/rhiz/image/upload/v1562133352/Company/r-curved-stem_c1fkbb.png"
                  centered
                />
                <LoginModal
                  openRetrievePasswordModal={this.openRetrievePasswordModal}
                  openSignUp={this.openSignUpModal}
                />
              </div>
            ) : formType === 'subscription' ? (
              <SubscriptionModal
                viewSubscription={this.props.viewSubscription}
              />
            ) : (
              // <SubscriptionModal openSubscriptionModal={this.openSubscriptionModal}/> :
              <div>
                <Image
                  size="small"
                  src="https://res.cloudinary.com/rhiz/image/upload/v1562133352/Company/r-curved-stem_c1fkbb.png"
                  centered
                />
                <RetrievePasswordModal openLogin={this.openLoginModal} />
              </div>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default ModalForms;
