/* eslint-disable import/first */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import user from './user';
import business from './business';
import notifications from './notifications';
import connections from './connections';
import dreamTeamRequests from './dreamTeam';
import favorites from './favorites';
import sockets from './sockets';

const rootReducer = combineReducers({
  user,
  business,
  connections,
  notifications,
  dreamTeamRequests,
  favorites,
  sockets,
  form: formReducer,
});

export default rootReducer;
