import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { convertDateSlash } from '../../util';

const ReferralItem = (props) => {
  const { referral } = props;

  const status = () => {
    if (referral.status === 'pending') {
      return 'yellow';
    } else if (referral.status === 'fulfilled') {
      return 'green';
    } else if (referral.status === 'redeemed') {
      return 'grey';
    } else if (referral.status === 'expired') {
      return 'red';
    }
  };

  const redeemFulfill = () => {
    const redeem = {
      createdOn: props.referral.createdOn,
      inviteEmail: props.referral.inviteEmail,
      modifiedOn: props.referral.modifiedOn,
      referredByName: props.referral.referredByName,
      referredByUserId: props.referral.referredByUserId,
      status: 'redeemed',
      _id: props.referral._id,
    };

    props.redeem(redeem, props.idx);
  };

  const deleteReferral = () => {
    props.deleteReferralFunction(props.referral._id, props.idx);
  };

  return (
    <Table.Row>
      <Table.Cell className="table-cell-mobile">Email:</Table.Cell>
      <Table.Cell>{referral.inviteEmail}</Table.Cell>
      <Table.Cell className="table-cell-mobile" style={{ fontWeight: 'bold' }}>
        Date Sent:
      </Table.Cell>
      <Table.Cell>
        {convertDateSlash(referral.createdOn, 'MMDDYYYY')}
      </Table.Cell>

      <Table.Cell className="table-cell-mobile" style={{ fontWeight: 'bold' }}>
        Status:
      </Table.Cell>
      <Table.Cell className="Referral-Table-Cell">
        <Icon color={status()} name="circle" size="small" />
        <p>{referral.status}</p>
        {referral.status === 'fulfilled' && (
          <p
            onClick={redeemFulfill}
            onKeyDown={redeemFulfill}
            className="Redeem-Referral-Button"
            role="presentation"
          >
            Redeem
          </p>
        )}
        {referral.status === 'expired' && (
          <p
            onClick={deleteReferral}
            onKeyDown={deleteReferral}
            className="Delete-Referral-Button"
            role="presentation"
          >
            Delete
          </p>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default ReferralItem;
