import React from 'react';
import { RegText } from '../../Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './businessessApproved.css';

const BusinessesApproved = ({
  currentlyFeaturingYou,
  revoke,
  addToTeam,
  business,
  requestByUser,
  request,
  goToProfile,
}) => (
  <div>
    <section style={{ display: 'flex', alignItems: 'center' }}>
      {/* eslint-disable-next-line */}
      <img
        src={business.profilePic}
        alt="business profile pic"
        style={{ cursor: 'pointer', width: '40px', marginRight: '1em' }}
        onClick={() => goToProfile(business)}
        onKeyDown={() => goToProfile(business)}
      />
      <div>
        <RegText
          style={{
            cursor: 'pointer',
            marginBottom: '0.5em',
            maxWidth: '20em',
            wordWrap: 'break-word',
            color: '#C0C0C0',
            fontSize: '1.1em',
          }}
          onClick={() => goToProfile(business)}
        >
          {business.name}
        </RegText>
        {currentlyFeaturingYou && (
          <RegText
            style={{
              marginBottom: '0',
              color: '#00BE6E',
              letterSpacing: '0.05em',
            }}
          >
            Currently featuring you
          </RegText>
        )}
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <FontAwesomeIcon
          icon={faPlus}
          size="sm"
          id="add-business"
          style={{ cursor: 'pointer' }}
          onClick={() => addToTeam(business, requestByUser, request)}
        />
        <button
          style={{
            border: 'none',
            backgroundColor: '#fff',
            marginLeft: '3em',
            color: '#DCDCDC',
          }}
          onClick={() => revoke(request)}
        >
          Revoke
        </button>
      </div>
    </section>
    <hr
      style={{
        borderWidth: '0.05em',
        marginTop: '1em',
        marginBottom: '1em',
        height: '1px',
        background: '#DCDCDC',
      }}
    />
  </div>
);

export default BusinessesApproved;
