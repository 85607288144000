import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
// import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import dateFnsFormat from 'date-fns/format';
import Star from './Star';
import ConnectModal from '../../views/BusinessProfile/ConnectModal/ConnectModal';
import Backdrop from '../../views/BusinessProfile/ConnectModal/Backdrop/Backdrop';

import { notificationConstructor } from '../../util';
import { socketServices } from '../../services';

const BusinessInfo = (props) => {
  const [connecting, setConnection] = useState(false);
  const [urgency, setUrgency] = useState('Just Curious');
  const [favorite, setFavorite] = useState(false);
  const { user, businessInfo, favorites, businessCard, business } = props;
  const dreamTeam = Object.values(businessInfo[0].dreamTeam);
  const price = businessInfo[0].price;


  useEffect(() => {
    const favoriteCheck = () => {
      const userLoggedIn = !user.loggedOut;
      if (userLoggedIn) {
        setFavorite(
          favorites.favoriteBusinesses.get(businessCard[0]._id),
        );
      } else {
        setFavorite(false);
      }
    };

    favoriteCheck();
  }, [businessCard, user.loggedOut, favorites.favoriteBusinesses]);

  const priceIcon = (price) => {
    const priceElement = (
      <FontAwesomeIcon
        size="xs"
        style={{ marginRight: '10px' }}
        icon={faDollarSign}
      />
    );
    switch (price) {
      case 1:
        return <span>{priceElement}</span>;
      case 2:
        return (
          <span>
            {priceElement}
            {priceElement}
          </span>
        );
      case 3:
        return (
          <span>
            {priceElement}
            {priceElement}
            {priceElement}
          </span>
        );
      default:
        return;
    }
  };

  const updateUrgency = (event) => {
    // get new selection
    setUrgency(event.target.value);
  };

  const closeModalHandler = () => {
    const isUserBusinessProfile =
      businessInfo[0]._id === business._id;
    if (!isUserBusinessProfile) {
      setConnection(!connecting);
    }
  };

  const sendRequest = (request) => {
    const notification = notificationConstructor(
      user,
      request.users[request.businessId],
      'connect request received',
    );
    request.date = Date.now();
    socketServices.emitConnectRequest(request, notification);
  };

  return (
    <div className="business-info-container">
      <section>
        <h1 id="business-info-flex">
          <Link
            to={{
              pathname: `/business/${businessInfo[0].username}`,
              state: {
                business: businessInfo[0],
              },
            }}
          >
            <span className="business-name">{businessInfo[0].name}</span>
          </Link>
          <span id="business-info-top">
            {priceIcon(price)}
            <span className="desktop">
              {businessInfo[0].price
                ? businessInfo[0].reviewCount
                : '0'}{' '}
              Reviews
            </span>
            <span className="desktop">
              <span id="rhiz-score">{businessInfo[0].rhizScore}</span>/100
            </span>
            {user._id &&
              (business?._id !== businessInfo[0]._id) && (
                <span className="favorite-star">
                  <Star
                    user={user}
                    businessViewed={businessInfo[0]}
                    favorite={favorite}
                    {...props}
                  />
                </span>
              )}
          </span>
        </h1>
      </section>

      <a
        href={`https://maps.google.com/?q=${businessInfo[0].street}+${
          businessInfo[0].city
        }+${businessInfo[0].state ? businessInfo[0].state : ''}`}
        target="_blank"
        rel="noreferrer"
      >
        <p className="business-address">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          {businessInfo[0].street || ''},{' '}
          {businessInfo[0].city || ''},{' '}
          {businessInfo[0].state || ''} {businessInfo[0].zip || ''}
        </p>
      </a>
      <ul className="business-tags">
        <li>{businessInfo[0].mainCategory}</li>
        {Array.isArray(businessInfo[0].subCategories) &&
          businessInfo[0].subCategories.map((tag, index) => (
            <li index={index} tag={tag}>
              {tag}
            </li>
          ))}
      </ul>
      <p id="business-about">{businessInfo[0].about}</p>
      <h2 id="works-with">Works With</h2>
      <section className="works-with-images-container">
        {Array.isArray(dreamTeam) && dreamTeam.length > 0 ? (
          dreamTeam.map((picture, index) => (
            <img
              className="works-with-image"
              index={index}
              src={picture.profilePic}
              alt=""
            />
          ))
        ) : (
          <h4>This business does not have any Dream Team Members yet</h4>
        )}
      </section>
      <section className="business-info-bottom">
        {/* eslint-disable max-len */}
        {/* <p className="desktop"><FontAwesomeIcon id="fb-icon" size='lg' style={{ marginRight: '5px' }} icon={faFacebookSquare} />{props.businessInfo[0].mutualFriends} of your Facebook friends and {props.businessInfo[0].totalFriends} people have liked {props.businessInfo[0].name}</p> */}
        {/* <p className="mobile business-facebook-likes "><FontAwesomeIcon id="fb-icon" size='lg' style={{ marginRight: '5px' }} icon={faFacebookSquare} />{props.businessInfo[0].totalFriends}likes</p> */}
        <span id="business-info-top-mobile">
          {priceIcon(price)}
          {/* <span className='mobile'>{props.businessInfo[0].price ? props.businessInfo[0].reviewCount : props.businessInfo[1].length} Reviews</span> */}
          {/* <span className='mobile'><span id="rhiz-score">{props.businessInfo[0].rhizScore}</span>/100</span> */}
        </span>
        <section className="buttons">
          <Link
            to={{
              pathname: `/business/${businessInfo[0].username}`,
              state: {
                business: businessInfo[0],
              },
            }}
          >
            <button id="view-profile-button">View Profile</button>
          </Link>
          <button
            onClick={
              !user.verified ? props.openSignUp : closeModalHandler
            }
            id="connect-button"
          >
            Connect
          </button>
        </section>
        {connecting && <Backdrop />}
        {connecting && (
          <ConnectModal
            businessReq={businessInfo[0]}
            closeModalHandler={closeModalHandler}
            date={dateFnsFormat(new Date(), 'MM-dd-yyyy')}
            urgency={urgency}
            updateUrgency={updateUrgency}
            sendRequest={sendRequest}
            email={businessInfo[0].email}
            {...props}
          />
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(BusinessInfo);
