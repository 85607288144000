/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import TimeAgo from 'react-timeago';
import Clip from './Clip';
import Backdrop from './ConnectRequestModal/Backdrop';
import ViewMessageDeclineModal from './ConnectRequestModal/VIewMessageDeclineModal';
import { timeagoFormatter, notificationConstructor } from '../../util';
import { socketServices } from '../../services';

class ConnectRequests extends Component {
  state = {
    viewModal: false,
    viewModalType: '',
    declineModal: false,
    deleteModal: false,
    otherProfile: {},
    userProfile: {},
    connection: this.props.connection || {},
  };

  viewMessage = (type) => {
    this.setState({
      viewModal: true,
      viewModalType: type,
    });
  };

  declineModalFunction = () => {
    this.setState({
      viewModal: true,
      viewModalType: 'decline',
    });
  };

  deleteModalFunction = () => {
    this.setState({
      deleteModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      viewModal: false,
      declineModal: false,
      deleteModal: false,
    });
  };

  cancelRequest = () => {
    const { connection, otherProfile, user } = this.props;
    const notification = notificationConstructor(
      user,
      { ...otherProfile, type: 'business' },
      'connect request cancel',
    );
    socketServices.emitCancelConnectRequest(connection, notification);
  };

  deleteRequest = () => {
    const { connection, userProfile } = this.props;
    socketServices.emitDeleteConnectRequest(connection, userProfile);
  };

  respondToRequest = async(response, reason) => {
    const { connection, business, otherProfile } = this.props;
    const updatedConnection = { ...connection };
    updatedConnection.status = response;
    updatedConnection.date = Date.now();
    let notification;
    if (response === 'accepted') {
      // updatedConnection.connected = getDate(new Date(), 'MMDDYYYY');
      updatedConnection.connected = Date.now();
      updatedConnection.lastMessage = {
        sender: {
          _id: connection.consumerId,
          username: connection.users[connection.consumerId].username,
          type: 'consumer',
        },
        receiver: {
          _id: business._id,
          username: business.username,
          type: 'business',
        },
        text: connection.description,
        timestamp: Date.now(),
        connection: {
          _id: connection._id,
          type: 'B2C',
        },
        unreadMessagesBusiness: 0,
        unreadMessagesConsumer: 0,
      };
      updatedConnection.quote = { sent: {}, saved: {}, rejected: [] };
      notification = notificationConstructor(
        { ...business, type: 'business' },
        { ...otherProfile, type: 'consumer' },
        'connect request accept',
        { _id: updatedConnection._id, type: updatedConnection.type },
      );
    } else {
      updatedConnection.reason = reason;
      notification = notificationConstructor(
        { ...business, type: 'business' },
        { ...otherProfile, type: 'consumer' },
        'connect request decline',
      );
    }

    socketServices.emitConnectRequestResponse(updatedConnection, notification);
    response !== 'accepted' && this.closeModal();
  };

  render() {
    const {
      category,
      connection,
      user,
      userProfile,
      otherProfile,
    } = this.props;
    const {
      zipcode,
      urgency,
      date,
      startDate,
      description,
      attachments,
    } = connection;
    const viewType =
      category === 'sent'
        ? 'edit'
        : category === 'declined'
        ? 'declined'
        : 'review';

    return (
      <div>
        <div>
          <div className="connect-request-box">
            <div className="connect-img-header-flex">
              <div>
                <img src={otherProfile.profilePic} alt="other profile pic" />
              </div>
              <div className="connect-name-time-type-flex">
                <div className="connect-name-time-flex">
                  <p>{otherProfile.name}</p>
                  <p className="connect-time-ago">
                    <TimeAgo
                      date={date}
                      minPeriod={30}
                      formatter={timeagoFormatter}
                      style={{
                        border: 'none',
                        color: '#959595',
                        marginLeft: '2em',
                      }}
                    />
                  </p>
                </div>
                <div className="connect-type">
                  {user._id === userProfile._id ? (
                    <p>Personal</p>
                  ) : (
                    <p>Business</p>
                  )}
                </div>
              </div>
            </div>

            <div className="connect-urgency-start-zip">
              <div className="connect-flex-data">
                <h4>Urgency:</h4>
                <p>{urgency}</p>
              </div>

              <div className="connect-flex-data">
                <h4>Start date:</h4>
                <p>{startDate}</p>
              </div>

              <div className="connect-flex-data">
                <h4>Zip Code:</h4>
                <p>{zipcode}</p>
              </div>
            </div>

            <div className="connect-desciption">
              <h4>{description}</h4>
            </div>

            <div className="connect-attachment">
              <div className="connect-clip">
                <Clip />
              </div>
              <p>
                ({attachments ? attachments.length : 0} attachment
                {attachments && attachments.length !== 1 ? 's' : ''})
              </p>
            </div>
            <div className="connect-view-decline">
              <h4
                onClick={() => this.viewMessage(viewType)}
                onKeyDown={() => this.viewMessage(viewType)}
                role="presentation"
              >
                {category === 'sent' ? 'View / Edit' : 'View Message'}
              </h4>
              <div className="connect-vertical-line"></div>
              <p
                onClick={
                  category === 'sent'
                    ? this.cancelRequest
                    : category === 'declined'
                    ? this.deleteRequest
                    : () => this.viewMessage('decline')
                }
                onKeyDown={
                  category === 'sent'
                    ? this.cancelRequest
                    : category === 'declined'
                    ? this.deleteRequest
                    : () => this.viewMessage('decline')
                }
                role="presentation"
              >
                {category === 'declined'
                  ? 'Delete'
                  : category === 'sent'
                  ? 'Cancel'
                  : 'Decline'}
              </p>
            </div>
          </div>
          <div>
            {this.state.viewModal && <Backdrop />}
            {this.state.viewModal && (
              <ViewMessageDeclineModal
                {...this.props}
                viewType={this.state.viewModalType}
                closeModal={this.closeModal}
                declineModal={this.declineModalFunction}
                respondToRequest={this.respondToRequest}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectRequests;
