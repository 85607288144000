export default [
  {
    name: 'firstName',
    component: 'input',
    type: 'text',
    placeholder: 'First Name',
  },
  {
    name: 'lastName',
    component: 'input',
    type: 'text',
    placeholder: 'Last Name',
  },
  {
    name: 'email',
    component: 'input',
    type: 'email',
    placeholder: 'Email',
  },
  {
    name: 'password',
    component: 'input',
    type: 'password',
    placeholder: 'Choose a Password',
  },
];
