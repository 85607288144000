/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Container, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import ParallaxConsumer from './ParallaxConsumer';
import Reviews from '../BusinessProfile/subcomponents/Reviews';
import { HorizontalCard, Loading } from '../../components';
import hero1 from '../../assets/images/consumerProfile/hero.jpg';
import Backdrop from './ConnectionRequest/Backdrop';
import ConnectionRequest from './ConnectionRequest/ConnectionRequest';
import { reviews } from './Reviews';
import { withRouter, Link } from 'react-router-dom';
import { fetchProfileById, fetchProfileByUsername,
  fetchFavorites, getBatchOfProfiles } from '../../services';
import { reviewsBlades, worksWithBlades,
  instaPicsBlades } from '../BusinessProfile/BusinessInfo/BladesOfGlory';
import './consumerProfile.css';
import PropTypes from 'prop-types';


class ConsumerProfile extends Component {

  state = {
    user: {},
    business: {},
    favorites: {},
    userBusiness: {},
    currentUserProfile: false,
    render: 1,
    viewModal: false,
    loaded: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setProfile();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { state } = this.props.location;
    const newUserProfile = state && state.user._id !== prevState.user._id;

    if (newUserProfile && this.state.loaded) {
      this.setState({
        loaded: false,
      }, async() => {
        await this.setProfile();
      });
    }
  }

  setProfile = async() => {
    const { user, location, match } = this.props;
    const newUserState = {
      user: {},
      business: {},
      favorites: [],
      currentUserProfile: false,
    };
    const thisIsCurrentUserProfile =
      (location.state && user._id === location.state.user._id)
      || match.params.user === user.username;
    if (thisIsCurrentUserProfile) {
      const { business, favorites } = this.props;
      newUserState.user = user;
      newUserState.favorites = await getBatchOfProfiles(
        'businesses',
        JSON.stringify(Array.from(favorites.favoriteBusinesses.keys())),
      );
      newUserState.currentUserProfile = true;
      const userHasBusiness = !!business._id;
      if (userHasBusiness) {
        newUserState.business = business;
        newUserState.userBusiness = [
          business,
          reviewsBlades,
          worksWithBlades,
          instaPicsBlades,
        ];
      }
    } else {
      const userInfo = location.state
        ? await fetchProfileById(location.state.user._id, 'users')
        : await fetchProfileByUsername(match.params.user, 'users');
      const idList = await fetchFavorites(userInfo._id, null);
      newUserState.user = userInfo;
      newUserState.favorites = await getBatchOfProfiles(
        'businesses',
        JSON.stringify(idList.businessIds),
      );

      if (userInfo.business) {
        const businessInfo = await fetchProfileById(
          userInfo.business._id,
          'businesses',
        );
        newUserState.business = businessInfo;
        newUserState.userBusiness = [
          businessInfo,
          reviewsBlades,
          worksWithBlades,
          instaPicsBlades,
        ];
      }
    }
    this.setState({
      ...newUserState,
      loaded: true,
      render: 2,
    });
  };


  // getFavoritesInfo = async() => {

  // }

  openModal = () => {
    this.setState({
      viewModal: true,
    });
  };

  closeModalHandler = () => {
    this.setState({
      viewModal: false,
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    const { user, userActions } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      const picUpdatesInfo = {
        newPhoto: reader.result,
        prevPhotoId: user.prevBannerPic_id,
        photoType: 'bannerPic',
        folderName: 'user_banner_pics',
      };

      userActions.updateUserPhotoAction(user._id, picUpdatesInfo);
    };
    reader.readAsDataURL(file);
  };

  deleteImageHandler = () => {
  if (window.confirm('Are you sure you wish' // eslint-disable-line no-alert
  + 'to delete this photo?')){
      const { user, userActions } = this.props;
      const picUpdatesInfo = {
        newPhoto: null,
        prevPhotoId: user.prevBannerPic_id,
        photoType: 'bannerPic',
        folderName: 'user_banner_pics',
      };

      userActions.updateUserPhotoAction(user._id, picUpdatesInfo);
    }
  };

  render() {
    const { user, userBusiness, business, loaded, favorites } = this.state;

    let bannerPicture =
      user._id === this.props.user._id
        ? this.props.user.bannerPic
        : user.bannerPic;
    if (bannerPicture === null) {
      bannerPicture = hero1;
    }

    return (
      <div className="profile-page">
        {loaded ? (
          <div
            style={{
              margin: '0 auto',
              maxWidth: '1600px',
              marginBottom: '10%',
            }}
          >
            <section className="parallax-section">
              <ParallaxConsumer
                bgImage={bannerPicture}
                height={{ height: '100px' }}
                title=""
                text=""
                alt="parallaximg"
              />
              {this.state.currentUserProfile && (
                <section className="parallax-dropdown">
                  <Dropdown
                    item
                    text="Update Cover Photo &nbsp;"
                    icon={'chevron down'}
                    id="dropdown-text"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <label htmlFor="img-upload">Select Photo</label>
                        <input
                          style={{ display: 'none' }}
                          id="img-upload"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => this.handleImageChange(e)}
                        ></input>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.deleteImageHandler}>
                        Delete Photo
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </section>
              )}
            </section>

            {!this.state.currentUserProfile && (
              <section className="mobile" id="mobile-options-consumer">
                <span className="review-count">
                  <Link to="/pathLink#review-section">61 Reviews</Link>
                </span>
                {!this.state.currentUserProfile && (
                  <button
                    onClick={
                      !this.props.user.verified
                        ? this.props.openSignUp
                        : this.openModal
                    }
                    className="ui-button"
                  >
                    Connect
                  </button>
                )}
              </section>
            )}
            <div style={{ height: '55px' }} />
            <Container className="user-container">
              <div className="ui equal width grid">
                <div
                  className="sixteen wide column consumerImg"
                  style={{ height: '3px' }}
                >
                  <img src={user.profilePic} alt="ale" />
                </div>
                <div className="column profile-header">
                  <div className="user-header title">{`${user.firstName} ${user.lastName}`}</div>
                </div>
                <div className="six wide column profile-header">
                  <div className="sub-header">{`Member since ${user.joined}`}</div>
                </div>
                <div className="three wide column connect desktop">
                  {!this.state.currentUserProfile && (
                    <button
                      onClick={
                        !this.props.user.verified
                          ? this.props.openSignUp
                          : this.openModal
                      }
                      className="ui-button"
                    >
                      Connect
                    </button>
                  )}
                </div>
                <div className="sixteen wide column">
                  <div>
                    {user.city && (
                      <div className="sub-header">
                        <FontAwesomeIcon
                          style={{ marginRight: '8px' }}
                          icon={faMapMarkerAlt}
                        />
                        {`${user.city}, ${user.state}`}
                      </div>
                    )}
                  </div>
                </div>
                <div className="sixteen wide column">
                  <p className="content">{`${user.bio || ''}`}</p>
                </div>

                <div className="title two column favorites">
                  {`${user.firstName} ${user.lastName}'s Personal Favorites`}
                </div>
                <div className="four column">
                  <Link
                    to={{
                      pathname: '/PersonalFavorites',
                      state: {
                        user,
                      },
                    }}
                  >
                    <div className="title button">See All</div>
                  </Link>
                </div>
                <div className="faves">
                  {favorites.length > 0 ? (
                    favorites.map(
                      (business, index) =>
                        index < 5 && (
                          <div className="fave">
                            <Link
                              to={{
                                pathname: `/business/${business.name}`,
                                state: {
                                  business,
                                },
                              }}
                            >
                              <img src={business.profilePic} alt="fav1" />
                            </Link>
                          </div>
                        ),
                    )
                  ) : (
                    <div>
                      <p className="content">
                        This user does not have any favorite businesses yet.
                      </p>
                    </div>
                  )}
                </div>

                {'_id' in business && (
                  <div className="myBusiness">
                    <div className="sixteen wide column">
                      <div className="title" style={{ marginBottom: '2em' }}>
                        My Business
                      </div>
                    </div>
                    <HorizontalCard
                      businessCard={userBusiness}
                      {...this.props}
                    />
                  </div>
                )}
              </div>
            </Container>
            <Container>
              <Reviews reviews={reviews} reviewCount={61}>
                <div
                  className="ui two column grid"
                  id="review-section"
                  style={{ marginBottom: '3%' }}
                >
                  <div className="row">
                    <div className="column">
                      <div className="sub-header reviews">
                        {' '}
                        From Consumers (57)
                      </div>
                    </div>
                    <div className="column">
                      <div className="sub-header button reviews">
                        {' '}
                        From Businesses (4)
                      </div>
                    </div>
                  </div>
                </div>
              </Reviews>
              <div className="row load-more">
                <div className="column">
                  <button className="ui-button">Load More</button>
                </div>
              </div>
            </Container>
            <div>
              {this.state.viewModal && <Backdrop />}
              {this.state.viewModal && (
                <ConnectionRequest
                  {...this.props}
                  closeModalHandler={this.closeModalHandler}
                  userProfile={user}
                />
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

ConsumerProfile.propTypes = {
  user: PropTypes.any,
  location: PropTypes.arrayOf({
    state: PropTypes.arrayOf({
      user: PropTypes.any,
    }),
  }),
  match: PropTypes.arrayOf({
    params: PropTypes.arrayOf({
      user: PropTypes.any,
    }),
  }),
  business: PropTypes.any,
  favorites: PropTypes.arrayOf({
    favoriteBusinesses: PropTypes.arrayOf({
      keys: PropTypes.any,
    }),
  }),
  userActions: PropTypes.arrayOf({
    updateUserPhotoAction: PropTypes.any,
  }),
  openSignUp: PropTypes.any,
};

export default connect(mapStateToProps)(withRouter(ConsumerProfile));
