import React, { Fragment } from 'react';
import DreamSearchResult from './DreamSearchResult';

const SearchResultsContainer = (props) => {
  const {
    results,
    sendRequest,
    history,
    userBusinessId,
    showMessage,
    popUpMessage,
    requestToId,
    success,
  } = props;
  return (
    <div>
      {results.map((result) => (
        <Fragment>
          <DreamSearchResult
            business={result}
            sendRequest={sendRequest}
            history={history}
            userBusinessId={userBusinessId}
          />
          {showMessage &&
            popUpMessage.length > 0 &&
            requestToId === result._id &&
            (!success ? (
              <div
                style={{
                  color: 'red',
                  paddingBottom: '5px',
                  fontSize: '1.1em',
                }}
              >
                {popUpMessage}
              </div>
            ) : (
              <div
                style={{
                  color: '#00BE6E',
                  paddingBottom: '5px',
                  fontSize: '1.25em',
                }}
              >
                {popUpMessage}
              </div>
            ))}
        </Fragment>
      ))}
    </div>
  );
};

export default SearchResultsContainer;
