/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Picker } from 'emoji-mart';
import '../Reviews.css';

const styles = {
  getEmojiButton: {
    border: 'none',
    margin: 0,
    cursor: 'pointer',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: -194,
    right: 83,
    marginLeft: '200px',
  },
};

export default class ConsumerReview extends Component {
  state = {
    showEmojis: false,
    choice: '',
    description: '',
  };

  formSubmit = (e) => {
    e.preventDefault();
    const err = this.validate();
    if (!err) {
      this.setState({
        errorText: '',
      });
    } else {
      this.setState({
        errorText: 'These fields cannot be empty',
      });
    }
  };

  textInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState({
      description: this.state.description + emoji,
    });
  };

  showEmojis = () => {
    this.setState(
      {
        showEmojis: true,
      },
      () => document.addEventListener('click', this.closeMenu),
    );
  };

  attachmentUploadHandler = (e) => {
    this.setState({
      attachment: e.target.files[0],
    });
  };

  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false,
        },
        () => document.removeEventListener('click', this.closeMenu),
      );
    }
  };

  yesBox = () => {
    this.setState(
      {
        choice: '',
      },
      () => {
        this.setState({
          choice: 'Yes',
          no: false,
          neutral: false,
          yes: true,
        });
      },
    );
  };

  neutralBox = () => {
    this.setState(
      {
        choice: '',
      },
      () => {
        this.setState({
          choice: 'Neutral',
          no: false,
          neutral: true,
          yes: false,
        });
      },
    );
  };

  noBox = () => {
    this.setState(
      {
        choice: '',
      },
      () => {
        this.setState({
          choice: 'No',
          no: true,
          neutral: false,
          yes: false,
        });
      },
    );
  };

  validate = () => {
    let isError = false;
    if (!this.state.choice.length) {
      isError = true;
      this.setState({
        choiceError: true,
      });
    } else {
      this.setState({
        choiceError: false,
      });
    }

    if (!this.state.description.length) {
      isError = true;
      this.setState({
        descriptionError: true,
      });
    } else {
      this.setState({
        descriptionError: false,
      });
    }

    return isError;
  };

  render() {
    return (
      <div className="messages-right">
        <div className="Review-Form">
          <Link to="/messages">
            <p className="Review-Form-back">Back to conversation</p>
          </Link>
          <h1>Write a review</h1>
          <h4 className="review-error-text">{this.state.errorText}</h4>
          <div className="recommend-choice">
            <h5>Would you recommend this customer?</h5>
            <div className="recommend-choice-flex">
              <div
                className={
                  this.state.yes
                    ? ' recommend-button-true'
                    : 'recommend-button-false'
                }
                onClick={this.yesBox}
                onKeyDown={this.yesBox}
              >
                <p>Yes</p>
              </div>
              <div
                className={
                  this.state.neutral
                    ? ' recommend-button-true'
                    : 'recommend-button-false'
                }
                onClick={this.neutralBox}
                onKeyDown={this.neutralBox}
              >
                <p>Neutral</p>
              </div>
              <div
                className={
                  this.state.no
                    ? ' recommend-button-true'
                    : 'recommend-button-false'
                }
                onClick={this.noBox}
                onKeyDown={this.noBox}
              >
                <p>No</p>
              </div>
            </div>
          </div>
          <div className="review-type">
            <h5>Your Review</h5>
            <textarea
              id="story"
              placeholder="How did it go?"
              value={this.state.description}
              name="description"
              onChange={this.textInput}
            ></textarea>
            <section className="attachment-container">
              <label
                className="delete-upload-buttons"
                htmlFor="attachment-upload"
              >
                <FontAwesomeIcon
                  icon={faPaperclip}
                  id="attachment-icon"
                  style={{ marginRight: '1em' }}
                />
              </label>
              <input
                id="attachment-upload"
                type="file"
                onChange={(e) => this.attachmentUploadHandler(e)}
              ></input>
              {this.state.showEmojis ? (
                <div>
                  <span
                    style={styles.emojiPicker}
                    ref={(el) => (this.emojiPicker = el)}
                  >
                    <Picker onSelect={this.addEmoji} emojiTooltip={true} />
                  </span>
                  <span
                    onClick={this.showEmojis}
                    onKeyDown={this.showEmojis}
                    style={{ cursor: 'pointer' }}
                  >
                    {String.fromCodePoint(0x1f60a)}
                  </span>
                </div>
              ) : (
                <span
                  onClick={this.showEmojis}
                  onKeyDown={this.showEmojis}
                  style={{ cursor: 'pointer' }}
                >
                  {String.fromCodePoint(0x1f60a)}
                </span>
              )}
            </section>
            <div
              onClick={this.formSubmit}
              onKeyDown={this.formSubmit}
              className="Review-Submit"
            >
              <p>Submit</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
