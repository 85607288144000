import React, { Component } from 'react';
import ReviewsAboutMeView from './ReviewsAboutMeView';
import reviewsAboutMeDummyData from './dummyData/reviewsAboutMeDummyData';
import './MyReviewsPage.css';

export default class ReviewsAboutMe extends Component {
  state = {
    reviewData: [],
  };

  componentDidMount() {
    this.setState({
      reviewData: reviewsAboutMeDummyData,
    });
  }

  render() {
    return (
      <div>
        {this.state.reviewData.map((review) => (
          <ReviewsAboutMeView review={review} />
        ))}
      </div>
    );
  }
}
