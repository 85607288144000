import React from 'react';
import { Link } from 'react-router-dom';
import '../Reviews.css';

// const styles = {
//   getEmojiButton: {
//     border: 'none',
//     margin: 0,
//     cursor: 'pointer',
//   },
//   emojiPicker: {
//     position: 'absolute',
//     bottom: -194,
//     right: 83,
//     marginLeft: '200px',
//   },
// };

export default function ConsumerReviewView() {
  return (
    <div className="messages-right">
      <div className="Review-Form">
        <Link to="/messages">
          <p className="Review-Form-back">Back to conversation</p>
        </Link>
        <h1>Blades of Glory</h1>

        <div className="recommend-choice">
          <h5>Would you recommend this customer?</h5>
          <div className="recommend-choice-flex">
            <div className="recommend-button-false">
              <p>Yes</p>
            </div>
            <div className="recommend-button-false">
              <p>Neutral</p>
            </div>
            <div className="recommend-button-true">
              <p>No</p>
            </div>
          </div>
        </div>
        <div className="review-type">
          <h5>Your Review</h5>
          <p>
            Great to work with. Direct, down to earth, and an all around nice
            person. It was a pleasure working on your backyard!
          </p>
        </div>
      </div>
    </div>
  );
}
