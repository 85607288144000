import { style } from 'glamor';
import styled from 'styled-components';

export const Banner = styled.div`
  background-image: ${(props) => `url(${props.src}) no-repeat top center`};
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
`;

export const Main = styled.div`
  font-family: 'Karla', sans-serif;
`;

export const ProfilePic = styled.img`
  height: '10px';
  width: '8px';
  margin-left: 5%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const Row = styled.div`
  display: flex;
  flex-direction; row;
  font-size: 18px;
  font-family: 'Karla', sans-serif;
`;

export const Dollar = styled.div`
  margin-right: 13%;
  fontsize: 23px;
  color: ${(props) => (props.color ? '#D3D3D3' : 'black')};
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Karla', sans-serif;
`;

export const Tag = styled.div`
  font-size: 20px;
  color: #43b02a;
  margin-right: 3%;
  font-family: 'Karla', sans-serif;
`;

export const WorksWithPic = styled.img`
  margin-right: 3%;
  padding-right: 6%;
  height: 60px;
  width: 140px;
  margin-top: 1%;
`;

export const RoundPic = styled.img`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 1.7%;
`;

export const BoldText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2.5%;
  font-family: 'Karla', sans-serif;
`;

export const SmallText = styled.div`
  font-size: 13px;
  color: #999;
  font-family: 'Karla', sans-serif;
`;

export const Line = styled.div`
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  color: black;
`;

export const Button = styled.button`
  background-color: #00be6e;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  width: 160px;
  font-family: 'Karla', sans-serif;
  border-radius: 4px;
`;

export const InstaPic = styled.img`
  height: 115px;
  width: 115px;
  margin-right: 10%;
  margin-bottom: 5%;
`;

export const Text = styled.div`
  font-family: 'Karla', sans-serif;
`;

export const SquarePic = styled.img`
  margin: 5% auto;
  width: 50%;
`;

export const ResourceBanner = styled.div`
  background-color: #00BF6F;
  width: 100%;
`;

export const WhiteBoldText = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Karla', sans-serif;
  width: 40%;
  margin: 0 auto;
  text-align: center;
  padding-top: 3%;
  padding-bottom: 2%;
`;

export const ResourceButton = styled.button`
  background-color: white;
  border: none;
  color: #00be6e;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 20px auto;
  cursor: pointer;
  width: 160px;
  font-family: 'Karla', sans-serif;
  border-radius: 4px;
`;

export const FernContainer = styled.div`
  text-align: center;
  font-size: 22px
`;
