import React from 'react';
import { Parallax } from 'react-parallax';
import { Image, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ParallaxConsumer = props => (
  <div>
    <Parallax
      blur={{ min: -10, max: 10 }}
      bgImage={props.bgImage}
      bgImageAlt="profile pic"
      strength={200}
    >
      <Container>
        <Image src={props.src} size={props.size} centered />
        <h1 style={{ textAlign: 'center', paddingTop: '80px' }}>{props.title}</h1>
        <p style={{ textAlign: 'center', paddingBottom: '80px' }}>{props.text}</p>
        <div style={{ textAlign: 'center' }}>{ props.search }</div>
        <div style={ props.height } />
      </Container>
    </Parallax>
  </div>
);

ParallaxConsumer.propTypes = {
  bgImage: PropTypes.any,
  src: PropTypes.any,
  size: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.any,
  search: PropTypes.any,
  height: PropTypes.object,
};

export default ParallaxConsumer;
