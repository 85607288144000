/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import qs from 'query-string';
import SignUpFormFields from './SignUpFormFields';
import {
  renderInput,
  required,
  passwordFormatCheck,
} from '../utils/formValidations';
import { signupUser, socketServices } from '../../../services';
import { notificationConstructor, getSearchQueryProp } from '../../../util';
class SignUpForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '', //! !getSearchQueryProp({}) ? this.props.queryUrlParams.newUserEmail : '',
    password: '',
    signedUp: false,
    message: '',
    success: true,
  };

  componentDidMount() {
    const { location } = this.props;
    const referralEmail = getSearchQueryProp({
      queryString: location.search,
      queryProperty: 'newUserEmail',
    });
    !!referralEmail && this.setState({ email: referralEmail });
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user.emailSent !== user.emailSent) {
      if (user.emailSent) {
        this.setState({
          signedUp: true,
        });
      }
    }
  }

  renderForm = () => {
    const { handleInput } = this;

    return (
      <div>
        <Field
          name={SignUpFormFields[0].name}
          component={renderInput}
          type={SignUpFormFields[0].type}
          placeholder={SignUpFormFields[0].placeholder}
          validate={[required]}
          onChange={handleInput}
        />
        <Field
          name={SignUpFormFields[1].name}
          component={renderInput}
          type={SignUpFormFields[1].type}
          placeholder={SignUpFormFields[1].placeholder}
          validate={[required]}
          onChange={handleInput}
        />
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Input
            name={'email'}
            fluid
            value={this.state.email}
            required={true}
            placeholder={'Email'}
            type={'email'}
            onChange={handleInput}
          />
        </div>
        <Field
          name={SignUpFormFields[3].name}
          component={renderInput}
          type={SignUpFormFields[3].type}
          placeholder={'Password'}
          validate={[required, passwordFormatCheck]}
          onChange={handleInput}
        />
        <div style={{ fontWeight: 'bold', marginBottom: '5%' }}>
          * Password must be 8-15 characters with at least 1 lowercase, 1
          uppercase 1 number and 1 special character
        </div>
      </div>
    );
  };

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      message: '',
    });
  };

  submit = (event) => {
    event.preventDefault();
    const { location } = this.props;
    const { email, password, firstName, lastName } = this.state;
    const queryUrlParams = qs.parse(location.search) || {};
    const newUser = {
      email,
      password,
      firstName,
      lastName,
      ...queryUrlParams,
    };
    this.setState(
      {
        message: 'Processing...',
        success: true,
      },
      () => {
        signupUser(newUser)
          .then((result) => {
            this.setState(
              {
                message: result.message || '',
                signedUp: result.success,
                success: result.success,
              },
              () => {
                const signupSocketBody = {
                  ...queryUrlParams,
                  email,
                  notification: {},
                };
                if (queryUrlParams.referralId) {
                  const notificationFrom = {
                    ...result.createdUser,
                    type: 'consumer',
                  };
                  const notificationTo = {
                    ...result.referredByUser,
                    type: 'consumer',
                  };
                  signupSocketBody.notification = notificationConstructor(
                    notificationFrom,
                    notificationTo,
                    'referral fulfilled',
                  );
                }
                socketServices.emitNewSignUp(signupSocketBody);
              },
            );
          })
          .catch((err) => {
            console.error({ err });
            this.setState({
              message: err.message,
              success: false,
            });
          });
      },
    );
  };

  render() {
    const { pristine, submitting } = this.props;
    const { signedUp, message, success } = this.state;
    const { submit, renderForm } = this;

    return (
      <div>
        {!signedUp ? (
          <form
            onSubmit={submit}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {renderForm()}
            {message.length > 0 && (
              <div
                style={{
                  color: `${success ? '#00be6e' : 'red'}`,
                  paddingBottom: '5px',
                }}
              >
                {message}
              </div>
            )}
            <Button
              positive
              type="submit"
              disabled={pristine || submitting}
              fluid
            >
              Sign Up
            </Button>
          </form>
        ) : (
          <div>Congratulations! Please check your email to verify.</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

// eslint-disable-next-line
SignUpForm = reduxForm({
  form: 'signup', // a unique identifier for this form
})(SignUpForm);

export default connect(mapStateToProps)(SignUpForm);
