/* eslint-disable  no-case-declarations */
import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_FAILED,
  SEND_CONNECTION_REQUEST,
  SEND_CONNECTION_REQUEST_SUCCESS,
  SEND_CONNECTION_REQUEST_FAILED,
  UPDATE_LAST_MESSAGE,
  UPDATE_LAST_MESSAGE_SUCCESS,
  UPDATE_LAST_MESSAGE_FAILED,
  UPDATE_CONNECTION,
  UPDATE_CONNECTION_SUCCESS,
  UPDATE_CONNECTION_FAILED,
  QUOTE_UPDATE,
  QUOTE_UPDATE_SUCCESS,
  QUOTE_UPDATE_FAILED,
  RESPOND_CONNECT_REQUEST,
  RESPOND_CONNECT_REQUEST_SUCCESS,
  RESPOND_CONNECT_REQUEST_FAILED,
  EDIT_PENDING_REQUEST,
  EDIT_PENDING_REQUEST_SUCCESS,
  EDIT_PENDING_REQUEST_FAILED,
  DELETE_REQUEST,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_FAILED,
  CREATE_C2C_CONNECTION,
  CREATE_C2C_CONNECTION_SUCCESS,
  CREATE_C2C_CONNECTION_FAILED,
  MARK_QUOTE_READ,
  MARK_QUOTE_READ_SUCCESS,
  MARK_QUOTE_READ_FAILED,
  UPDATE_CONNECTIONS_ORDER,
  SET_SELECTED_CONNECTION,
  UPDATE_CONNECTIONS_REQUEST,
  UPDATE_CONNECTIONS_REQUEST_FAILED,
  LOGOUT_DONE,
} from '../actions/types';
import { sortByTimestamp } from '../util/date';
import { setToLocalStorage } from '../util/storage';

const connections = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONNECTIONS:
      return {
        ...state,
        fetchingConnections: true,
      };
    case FETCH_CONNECTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload.connectionRequests,
        selectedConnection: action.payload.selectedConnection === null
          ? state.selectedConnection
          : action.payload.selectedConnection,
        fetchingConnections: false,
      };
    case FETCH_CONNECTIONS_FAILED:
      return {
        ...state,
        error: true,
        fetchingConnections: false,
      };
    case SEND_CONNECTION_REQUEST:
      return {
        ...state,
        message: 'Sending Request...',
        success: true,
        sendConnectionRequest: true,
      };
    case SEND_CONNECTION_REQUEST_SUCCESS:
      return {
        ...state,
        message: 'Your request has been sent!',
        success: true,
        sendConnectionRequest: false,
      };
    case SEND_CONNECTION_REQUEST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        success: false,
        sendConnectionRequest: false,
      };
    case UPDATE_LAST_MESSAGE:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_LAST_MESSAGE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case UPDATE_LAST_MESSAGE_FAILED:
      return {
        ...state,
        ...action.payload,
        updating: false,
      };
    case UPDATE_CONNECTION:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_CONNECTION_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case UPDATE_CONNECTION_FAILED:
      return {
        ...state,
        udating: false,
      };
    case QUOTE_UPDATE:
      return {
        ...state,
        updatingQuote: true,
      };
    case QUOTE_UPDATE_SUCCESS:
      return {
        ...state,
        updateQuoteMessage: action.payload.message,
        updatingQuote: false,
      };
    case QUOTE_UPDATE_FAILED:
      return {
        ...state,
        quoteErrorMessage: action.payload.message,
        updatingQuote: false,
      };
    case RESPOND_CONNECT_REQUEST:
      return {
        ...state,
        responding: true,
      };
    case RESPOND_CONNECT_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload.connections,
        responding: false,
      };
    case RESPOND_CONNECT_REQUEST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        responding: false,
      };
    case EDIT_PENDING_REQUEST:
      return {
        ...state,
        editingRequest: true,
      };
    case EDIT_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        message: 'Saved!',
        editingRequest: false,
      };
    case EDIT_PENDING_REQUEST_FAILED:
      return {
        ...state,
        message: action.payload.message,
        editingRequest: false,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        deleting: false,
      };
    case DELETE_REQUEST_FAILED:
      return {
        ...state,
        message: action.payload.message,
      };
    case CREATE_C2C_CONNECTION:
      return {
        ...state,
        creatingC2C: true,
      };
    case CREATE_C2C_CONNECTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        message:
          'Message sent! Head to your inbox to continue the conversation!',
        success: true,
        creatingC2C: false,
      };
    case CREATE_C2C_CONNECTION_FAILED:
      return {
        ...state,
        message:
          'Error sending this message. If the error persists, please contact support.',
        creatingC2C: false,
      };
    case MARK_QUOTE_READ:
      return {
        ...state,
        markingRead: true,
      };
    case MARK_QUOTE_READ_SUCCESS:
      return {
        ...state,
        ...action.payload.connections,
        markingRead: false,
      };
    case MARK_QUOTE_READ_FAILED:
      return {
        ...state,
        message: action.payload.message,
        markingRead: false,
      };
    case UPDATE_CONNECTIONS_ORDER:
      const { currentChat, type, updateOrder, updateCount } = action.payload;
      const connectionsList = { ...state };

      connectionsList[type].forEach((connection, index) => {
        if (
          connection.type === currentChat.type &&
          connection._id === currentChat._id
        ) {
          connectionsList[type][index] = currentChat;
        }
      });
      if (updateOrder) {
        connectionsList[type] = sortByTimestamp(connectionsList[type]);
      }

      if (updateCount) {
        if (type === 'business') {
          connectionsList.unreadBusinessMessagesCount += 1;
        } else {
          connectionsList.unreadPersonalMessagesCount += 1;
        }
      }

      return {
        ...state,
        ...connectionsList,
      };
    case SET_SELECTED_CONNECTION:
      setToLocalStorage(
        'selectedConnection',
        JSON.stringify(action.payload.selectedConnection),
      );
      return {
        ...state,
        selectedConnection: { ...action.payload.selectedConnection },
      };
    case UPDATE_CONNECTIONS_REQUEST:
      return {
        ...state,
        ...action.payload.connections,
        message: action.payload.message,
        success: true,
      };
    case UPDATE_CONNECTIONS_REQUEST_FAILED:
      return {
        ...state,
        success: false,
        message: action.payload.message,
      };
    case LOGOUT_DONE:
      return {
        all: [],
        personal: [],
        business: [],
        received: [],
        sent: [],
        declined: [],
        archived: {
          personal: [],
          business: [],
        },
        selectedConnection: {
          connection: {},
          userProfile: {},
          otherProfile: {},
          category: '',
        },
      };
    default:
      return state;
  }
};

export default connections;
