/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Header, Footer, FooterMobile, PrivateRoute } from './components';

import { getSearchQueryProp } from './util';
import {
  BusinessProfile,
  ConsumerProfile,
  Contact,
  EditBusiness,
  EditProfile,
  Home,
  InboxPage,
  LearningPage,
  Messages,
  MyReviewsPage,
  Notifications,
  PersonalFavorites,
  ReferralPage,
  SearchResults,
  Subscription,
  Team,
} from './views';

class Router extends Component {
  state = {
    openModal: false,
    formType: 'signUp',
    openSubscriptionModal: false,
  };

  componentDidMount = () => {
    const { user, location } = this.props;
    if (user._id) {
      if (!user.verified) {
        this.setState({
          openModal: true,
        });
      } else {
        this.setState({
          openModal: false,
        });
      }
    } else {
      const queryReferralId = getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'referralId',
      });
      !!queryReferralId && this.openSignUpModal();
    }
  };

  openSignUpModal = (e) => {
    e && e.preventDefault();
    this.setState(
      {
        openModal: false,
      },
      () => {
        this.setState({
          openModal: true,
        });
      },
    );
  };

  openSubscriptionModal = (e) => {
    e.preventDefault();
    this.setState(
      {
        openSubscriptionModal: false,
      },
      () => {
        this.setState({
          openSubscriptionModal: true,
        });
      },
    );
  };

  render() {
    const { user, userActions, business, loggedIn, location } = this.props;
    const resetPasswordAllowed =
      location.pathname === '/reset_password' &&
      getSearchQueryProp({
        queryString: location.search,
        queryProperty: 'userId',
      });

    return (
      <div>
        <Header
          {...this.props}
          openModal={this.state.openModal}
          openSubscriptionModal={this.state.openSubscriptionModal}
          openSignUp={this.openSignUpModal}
          formType={this.state.formType}
        />
        <div className='app-body'>
          <div className='mobile-top-padding'>
            <Route
              exact
              path='/'
              render={() => (
                <Home openSignUp={this.openSignUpModal} {...this.props} />
              )}
            />
            <Route path='/team' component={Team} />
            <Route
              path={'/business/:business'}
              render={(props) => (
                <BusinessProfile
                  {...props}
                  openModal={this.state.openModal}
                  openSignUp={this.openSignUpModal}
                  {...this.props}
                />
              )}
            />
            <Route
              path={'/user/:user'}
              render={(props) => (
                <ConsumerProfile
                  {...props}
                  {...this.props}
                  openModal={this.state.openModal}
                  openSignUp={this.openSignUpModal}
                  userActions={userActions}
                  user={user}
                  business={business}
                />
              )}
            />
            <Route
              path='/search'
              render={(props) => (
                <SearchResults
                  {...props}
                  {...this.props}
                  openSignUp={this.openSignUpModal}
                />
              )}
            />
            <Route
              path='/PersonalFavorites'
              render={(props) => (
                <PersonalFavorites
                  {...this.props}
                  {...props}
                  user={user}
                  myBusiness={business}
                />
              )}
            />
            <Route
              path='/contact'
              render={(props) => (
                <Contact
                  {...this.props}
                  {...props}
                  user={user}
                  myBusiness={business}
                />
              )}
            />
            <Route
              path='/subscriptions'
              render={(props) => (
                <Subscription
                  {...this.props}
                  {...props}
                  user={user}
                  openSignUp={this.openSignUpModal}
                  formType={this.state.formType}
                />
              )}
            />
            <Route
              path='/learning'
              component={() => (
                <LearningPage user={user} openSignUp={this.openSignUpModal} />
              )}
            />
            <Route
              path='/MyReviewsPage'
              render={(props) => (
                <MyReviewsPage
                  {...this.props}
                  {...props}
                  user={user}
                  business={business}
                />
              )}
            />
            <PrivateRoute
              path='/edit_profile'
              component={EditProfile}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/change_password'
              component={EditProfile}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/reset_password'
              component={EditProfile}
              isAuth={resetPasswordAllowed}
              resetPassword={true}
              {...this.props}
            />
            <PrivateRoute
              path='/edit_business'
              component={EditBusiness}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/business_verification'
              component={EditBusiness}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/my_dream_team'
              component={EditBusiness}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/messages'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/quoteform'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/quoteview'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/consumer_review'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/business_review'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/edit_review'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/business_review_form'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/consumer_review_form'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/edit_review_form'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/consumer_review_view'
              component={Messages}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/inboxpage'
              component={InboxPage}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/notifications'
              component={Notifications}
              isAuth={loggedIn}
              {...this.props}
            />
            <PrivateRoute
              path='/referrals'
              component={ReferralPage}
              isAuth={loggedIn}
              {...this.props}
            />

            {isMobile ? (
              this.props.history.location.pathname === '/Messages' ||
              this.props.history.location.pathname === '/InboxPage' ? (
                ''
              ) : (
                <FooterMobile />
              )
            ) : (
              <Footer />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  business: state.business,
  dreamTeamRequests: state.dreamTeamRequests,
  connections: state.connections,
});

export default connect(mapStateToProps)(Router);
