import axios from 'axios';
import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_FAILED,
  SEND_CONNECTION_REQUEST,
  UPDATE_LAST_MESSAGE,
  UPDATE_LAST_MESSAGE_SUCCESS,
  UPDATE_LAST_MESSAGE_FAILED,
  UPDATE_CONNECTION,
  UPDATE_CONNECTION_SUCCESS,
  UPDATE_CONNECTION_FAILED,
  QUOTE_UPDATE,
  QUOTE_UPDATE_SUCCESS,
  QUOTE_UPDATE_FAILED,
  RESPOND_CONNECT_REQUEST_FAILED,
  EDIT_PENDING_REQUEST,
  EDIT_PENDING_REQUEST_SUCCESS,
  EDIT_PENDING_REQUEST_FAILED,
  DELETE_REQUEST,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_FAILED,
  CREATE_C2C_CONNECTION_SUCCESS,
  MARK_QUOTE_READ,
  MARK_QUOTE_READ_FAILED,
  UPDATE_CONNECTIONS_ORDER,
  SET_SELECTED_CONNECTION,
  UPDATE_CONNECTIONS_REQUEST,
  UPDATE_CONNECTIONS_REQUEST_FAILED,
  SEND_CONNECTION_REQUEST_SUCCESS,
} from './types';

import {
  sortByTimestamp,
  getFromLocalStorage,
  unreadMessagesCount,
  getHeaderOptions,
} from '../util';

export const fetchConnections = (user) => async(dispatch) => {
  dispatch({ type: FETCH_CONNECTIONS });
  const fetchLocal = await getFromLocalStorage('selectedConnection');
  const selectedConnection =
    fetchLocal === null ? null : JSON.parse(fetchLocal);
  const options = await getHeaderOptions();
  axios
    .get(
      `/connections/request?userId=${user._id}&businessId=${user.business?._id}`,
      { headers: { ...options } },
    )
    .then(async(res) => {
      const c2cRes = await axios.get(`/c2c?userId=${user._id}`, {
        headers: { ...options },
      });
      res.data.connectionRequests.personal = sortByTimestamp([
        ...res.data.connectionRequests.personal,
        ...c2cRes.data.c2cConnections,
      ]);
      res.data.connectionRequests.unreadPersonalMessagesCount = unreadMessagesCount(
        res.data.connectionRequests.personal,
        'personal',
        user,
      );
      res.data.connectionRequests.unreadBusinessMessagesCount = unreadMessagesCount(
        res.data.connectionRequests.business,
        'business',
      );
      dispatch({
        type: FETCH_CONNECTIONS_SUCCESS,
        payload: { ...res.data, selectedConnection },
      });
    })
    .catch((err) => {
      dispatch({ type: FETCH_CONNECTIONS_FAILED, payload: err });
    });
};

export const sendConnectionRequest = () => async(dispatch) => {
  dispatch({ type: SEND_CONNECTION_REQUEST });
};

export const connectRequestSent = () => async(dispatch) => {
  dispatch({ type: SEND_CONNECTION_REQUEST_SUCCESS });
};

export const updateLastMessage = (
  lastMessage,
  updateCountColumn,
  category,
  type,
  _id,
) => async(dispatch) => {
  dispatch({ type: UPDATE_LAST_MESSAGE });
  const options = await getHeaderOptions();
  const connectionType = category === 'B2C' ? 'connections' : 'c2c';
  axios
    .put(
      `/${connectionType}/lastMessage`,
      {
        lastMessage,
        updateCountColumn,
        _id,
      },
      { ...options },
    )
    .then((res) => {
      dispatch({
        type: UPDATE_LAST_MESSAGE_SUCCESS,
        payload: {
          currentChat: res.data.updatedConnection,
          updateOrder: true,
          updateCount: false,
          type,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_LAST_MESSAGE_FAILED, payload: err });
    });
};

export const updateConnection = (updatedConnection) => async(dispatch) => {
  dispatch({ type: UPDATE_CONNECTION });
  const options = await getHeaderOptions();
  const connectionRoute =
    updateConnection.type === 'B2C' ? 'connections' : 'c2c';
  axios
    .put(`/${connectionRoute}/update`, { updatedConnection }, { ...options })
    .then((res) => {
      res.data.success
        ? dispatch({ type: UPDATE_CONNECTION_SUCCESS })
        : dispatch({ type: UPDATE_CONNECTION_FAILED });
    })
    .catch((err) => {
      dispatch({ type: UPDATE_CONNECTION_FAILED, payload: { err } });
    });
};

export const quoteUpdate = (_id, quote, status, quoteMessage) => async(
  dispatch,
) => {
  dispatch({ type: QUOTE_UPDATE });
  const options = await getHeaderOptions();
  axios
    .put(
      '/connections/quote',
      {
        _id,
        quote,
        status,
        quoteMessage,
      },
      { ...options },
    )
    .then(() => {
      dispatch({ type: QUOTE_UPDATE_SUCCESS });
    })
    .catch((err) => {
      dispatch({ type: QUOTE_UPDATE_FAILED, payload: err });
    });
};

export const respondConnectRequestFail = (message) => async(dispatch) => {
  dispatch({
    type: RESPOND_CONNECT_REQUEST_FAILED,
    payload: { message },
  });
};

export const editPendingConnection = (_id, updates, connections) => async(
  dispatch,
) => {
  dispatch({ type: EDIT_PENDING_REQUEST });
  // update axios call
  const res = await axios.put('/connections/editPending', { _id, updates });
  res.success
    ? dispatch({ type: EDIT_PENDING_REQUEST_SUCCESS, payload: connections })
    : dispatch({ type: EDIT_PENDING_REQUEST_FAILED, payload: res });
};

export const deleteConnection = (_id, connections) => async(dispatch) => {
  dispatch({ type: DELETE_REQUEST });
  const res = await axios.delete(`/connections?_id=${_id}`);
  res.success
    ? dispatch({ type: DELETE_REQUEST_SUCCESS, payload: connections })
    : dispatch({ type: DELETE_REQUEST_FAILED, payload: res });
};

export const createC2CConnection = (connections) => async(dispatch) => {
  dispatch({ type: CREATE_C2C_CONNECTION_SUCCESS, payload: connections });
};

export const markQuoteRead = (connection) => async(dispatch) => {
  dispatch({ type: MARK_QUOTE_READ });
  const options = await getHeaderOptions();
  const res = await axios.put(
    '/connections/markQuoteRead',
    { connection },
    { ...options },
  );
  res.data.success
    ? dispatch({
        type: SET_SELECTED_CONNECTION,
        payload: { selectedConnection: connection },
      })
    : dispatch({ type: MARK_QUOTE_READ_FAILED, payload: res.data });
};

export const updateConnections = (
  currentChat,
  type,
  updateOrder,
  updateCount,
) => async(dispatch) => {
  dispatch({
    type: UPDATE_CONNECTIONS_ORDER,
    payload: { currentChat, type, updateOrder, updateCount },
  });
};

export const setSelectedConnection = (selectedConnection) => async(
  dispatch,
) => {
  dispatch({
    type: SET_SELECTED_CONNECTION,
    payload: { selectedConnection },
  });
};

export const updateConnectionsFromRequest = (
  connections,
  message,
  success,
) => async(dispatch) => {
  success
    ? dispatch({
        type: UPDATE_CONNECTIONS_REQUEST,
        payload: { connections, message },
      })
    : dispatch({
        type: UPDATE_CONNECTIONS_REQUEST_FAILED,
        payload: { message },
      });
};
