import React from 'react';
import LoginForm from '../forms/LoginForm';

const loginModal = (props) => (
  <div style={{ textAlign: 'center' }}>
    <h1>Welcome Back!</h1>
    <div
      className="fb-login-button"
      data-width=""
      data-size="medium"
      data-button-type="login_with"
      data-auto-logout-link="false"
      data-use-continue-as="false"
    ></div>
    <LoginForm />
    <div
      className="forgot-password"
      onClick={props.openRetrievePasswordModal}
      onKeyDown={props.openRetrievePasswordModal}
    >
      Forgot password?
    </div>
    <div
      className="links"
      onClick={props.openSignUp}
      onKeyDown={props.openSignUp}
    >
      New to Rhiz? Register here.
    </div>
  </div>
);

export default loginModal;
