// this file is for functions making api requests that are not redux actions
import 'whatwg-fetch';
import axios from 'axios';
import { setAccessToken, getRefreshToken, getHeaderOptions } from '../util';

// getting google map api key to use on business profile
export const getGoogleMapKey = () =>
  new Promise((resolve, reject) => {
    axios
      .get('/thirdParty/googleMapKey')
      .then((key) => {
        resolve(key);
      })
      .catch((err) => {
        console.warn('error: ', err);
        reject(err);
      });
  });

export const fetchMessages = async(connectionId, type) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .get(`/messages?connectionId=${connectionId}&type=${type}`, {
        headers: { ...options },
      })
      .then((res) => {
        resolve(res.data.messages);
      })
      .catch((err) => {
        console.warn('Error fetching messages: ', err);
        reject(err);
      });
  });

};

export const sendContactEmail = (name, email, type, message) =>
  new Promise((resolve, reject) => {
    axios
      .post('/users/contactEmail', {
        name,
        email,
        type,
        message,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.warn('error sending contact email: ', err);
        reject(err);
      });
  });

export const fetchProfileById = (id, type) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/${type}?id=${id}`)
      .then((res) => {
        resolve(res.data.profile);
      })
      .catch((err) => {
        console.warn('error fetching profile: ', err);
        reject(err);
      });
  });

export const fetchProfileByUsername = (username, type) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/${type}/${username}`)
      .then((res) => {
        resolve(res.data.profile);
      })
      .catch((err) => {
        console.warn('error fetching profile: ', err);
        reject(err);
      });
  });

export const updateProfile = async(profileType, updatedProfile) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .put(`/${profileType}/`, { updatedProfile }, { headers: { ...options } })
      .then(({ data }) => {
        console.warn('data', data);
        resolve(data);
      })
      .catch((err) => {
        console.warn('Error updating profile: ', err);
        reject(err);
      });
  });

};

export const getNotifications = async(userId, businessId = null) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .get(`/notifications?userId=${userId}&businessId=${businessId}`, {
        headers: { ...options },
      })
      .then(({ data }) => {
        resolve(data.notifications);
      })
      .catch((err) => {
        console.warn('Error fetching notifications: ', err);
        reject();
      });
  });
};

export const updateConnection = async(_id, updates, type) => {
  const connectType = type === 'B2C' ? 'connections' : 'c2c';
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/${connectType}/update`,
        { _id, updates },
        { headers: { ...options } },
      )
      .then(({ data }) => {
        resolve(data.success);
      })
      .catch((err) => {
        console.warn('Error updating last notification check: ', err);
        reject();
      });
  });
};

export const fetchFavorites = (userId, businessId) =>
new Promise((resolve, reject) => {
  const favoritesRoute = businessId
    ? `/favorites/all?userId=${userId}&businessId=${businessId}`
    : `/favorites/businesses?userId=${userId}`;
    axios
      .get(favoritesRoute)
      .then(({ data }) => {
        resolve(data.favoritesIds);
      })
      .catch((err) => {
        console.warn('Error fetching favorites: ', err);
        reject(err);
      });
  });

export const getBatchOfProfiles = (profileType, idList) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/${profileType}/group?idList=${idList}`)
      .then(({ data }) => {
        resolve(data.profiles);
      })
      .catch((err) => {
        console.warn('Error fetching batch of profiles: ', err);
        reject(err);
      });
  });

export const getQuotes = async(connectionId, viewerType) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .get(`/quotes?connectionId=${connectionId}&viewerType=${viewerType}`, {
        headers: { ...options },
      })
      .then(({ data }) => {
        resolve(data.quotes);
      })
      .catch((err) => {
        console.warn('Error fetching quotes: ', err);
        reject({ sent: {}, saved: {}, rejected: [] });
      });
  });
};

export const getReferrals = async(userId) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .get(`/referrals?userId=${userId}`, { headers: { ...options } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.warn('Error fetching referrals: ', err);
        reject(err);
      });
  });
};

export const getProfileByCategory = async({ field, value, type }) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .get(`/${type}/category?field=${field}&value=${value}`, {
        headers: { ...options },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.warn('Error fetching profile by category: ', err);
        reject(err);
      });
  });
};

export const sendReferrals = async(referrals) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .post('/referrals', { referrals }, { headers: { ...options } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.warn('error sending referrals: ', err);
        reject(err);
      });
  });
};

export const redeemReferral = async(referral) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .put('/referrals', { referral }, { headers: { ...options } })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.warn('Error redeeming referral: ', err);
        reject(err);
      });
  });
};

export const deleteReferral = async(referralId) => {
  const options = await getHeaderOptions();
  return new Promise((resolve, reject) => {
    axios
      .delete(`/referrals?referralId=${referralId}`, {
        headers: { ...options },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        console.warn('Error deleting referral: ', err);
        reject(err);
      });
  });
};

export const requestNewAccessToken = async() => {
  const refreshToken = await getRefreshToken();
  const options = {
    headers: {
      Authorization: refreshToken,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/new_token', {}, { ...options, withCredentials: true })
      .then(({ data }) => {
        setAccessToken(data.accessToken);
        resolve(data.accessToken);
      })
      .catch((err) => {
        console.warn('Error creating new access token: ', err);
        reject(err);
      });
  });
};

// class ApiError extends Error {
//   constructor(message, status) {
//     super(message);

//     this.status = status;

//     Error.captureStackTrace(this, ApiError);
//   }
// }

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const api = async(options) => {
  const { path, method, headers, body } = options;

  let json;
  try {
    const res = await fetch(`/${path}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...headers,
      },
      method,
      ...(method !== GET &&
        method !== DELETE && { body: JSON.stringify(body) }),
    });

    json = await res.json();

    if (!res.ok) {
      console.warn(`Error in api call to => ${path}`, json.message, res);
    }
  } catch (error) {
    console.error(`Error in api call: ${error.message} ${error.status}`);
  }
  return json;
};
