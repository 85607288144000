import socketIOClient from 'socket.io-client';

// const socketUrl = process.env.REACT_APP_DOCKER_ENV
// ? '//server:5000'
// : '//localhost:5000';

// const socket = socketIOClient(socketUrl, { transports: ['websocket'], upgrade: false });
const socket = socketIOClient();

export const socketServices = {
  emitUserConnect(userConnected, businessConnected) {
    socket.emit('user connected', { userConnected, businessConnected });
  },

  emitBusinessConnect(businessConnected) {
    socket.emit('business connected', businessConnected);
  },

  emitLogout() {
    socket.emit('logout');
  },

  emitNewSignUp(signupBody) {
    socket.emit('new signup', signupBody);
  },

  emitChatEnter(chatName, selectedConnection) {
    socket.emit('chat entered', { chatName, selectedConnection });
  },

  emitChatSwitch(chatName, selectedConnection) {
    socket.emit('chat switch', { chatName, selectedConnection });
  },

  emitChatLeft(connection) {
    socket.emit('left chat', connection);
  },

  emitMessage(message, currentChat, connection, updateCountColumn) {
    socket.emit('message', {
      message,
      currentChat,
      connection,
      updateCountColumn,
    });
  },

  emitQuote({
    savedQuote,
    sentQuote,
    currentChat,
    status,
    notification = {},
    createNewSavedQuote,
    createNewSentQuote,
    connection,
  }) {
    socket.emit('quote update', {
      savedQuote,
      sentQuote,
      currentChat,
      status,
      notification,
      connection,
      createNewSentQuote,
      createNewSavedQuote,
    });
  },

  emitQuoteRespond(quote, connection, currentChat, status, notification) {
    socket.emit('quote response', {
      quote,
      connection,
      currentChat,
      status,
      notification,
    });
  },

  emitEnterQuoteFormView(enteredQuoteForm) {
    socket.emit('enter sent quote form view', { enteredQuoteForm });
  },

  emitLeaveQuoteFormView(leftQuoteForm) {
    socket.emit('left sent quote from view', { leftQuoteForm });
  },

  emitConnectRequest(request, notification) {
    socket.emit('connect request', { request, notification });
  },

  emitConnectRequestResponse(connection, notification) {
    socket.emit('connect request decision', { connection, notification });
  },

  emitConnectRequestEdit(connection, notification) {
    socket.emit('connect request edit', { connection, notification });
  },

  emitCancelConnectRequest(connection, notification) {
    socket.emit('connect request cancel', { connection, notification });
  },

  emitDeleteConnectRequest(connection, deleter, notification) {
    socket.emit('connect request delete', {
      connection,
      deleter,
      notification,
    });
  },

  emitC2CConnectRequest(connection, userId, notification, context) {
    socket.emit('c2c connect message', { connection, userId, notification });
    context.setState({
      requestMessage: 'Sending...',
      success: true,
      modal: true,
    });
  },

  emitDtRequest(request, notification, context) {
    socket.emit('dream team request', { request, notification });
    context.setState({
      popUpMessage: 'Sending...',
      showMessage: true,
      requestToId: request.requestToId,
    });
  },

  emitDtRequestResponse(request, notification) {
    socket.emit('dream team request response', { request, notification });
  },

  emitDtRequestCancel(request, notification) {
    socket.emit('cancel dream team request', { request, notification });
  },

  emitDtAdd(request, updatedBusiness, addedBy, notification) {
    socket.emit('dream team add', {
      request,
      updatedBusiness,
      addedBy,
      notification,
    });
  },

  emitDtRemove(request, updatedBusiness, removedBy, notification) {
    socket.emit('dream team remove', {
      request,
      updatedBusiness,
      removedBy,
      notification,
    });
  },

  emitDtRevoke(request, revokedBy, notification) {
    socket.emit('dream team request revoke', {
      request,
      revokedBy,
      notification,
    });
  },

  emitAddToFavorites(favedBusiness, notification, context) {
    socket.emit('favoriting business', { favedBusiness, notification });
    !context.props.fromFavorites &&
      context.setState((prevState) => ({
        favorite: !prevState.favorite,
      }));
  },

  emitRemoveFromFavorites(unFavedBusiness, notification, context) {
    socket.emit('unfavoriting business', { unFavedBusiness, notification });
    !context.props.fromFavorites &&
      context.setState((prevState) => ({
        favorite: !prevState.favorite,
      }));
  },

  removeListeners() {
    socket.removeAllListeners();
  },

  listenMessage(context) {
    socket.on('message', (message) => {
      const {
        user,
        business,
        connections: { selectedConnection },
      } = context.props;
      const receivedMessage =
        (user._id !== message.sender._id ||
          message.sender.type !== 'consumer') &&
        (business._id !== message.sender._id ||
          message.sender.type !== 'business');
      receivedMessage &&
        context.updateReadCount(selectedConnection, false, true);
      context.setState({
        messages: [...context.state.messages, message],
      });
    });

    socket.on('quote', ({ sentQuote, savedQuote, respondedQuote, action }) => {
      const { quotes } = context.state;
      const quoteSent = action === 'sent';
      const quoteRejected = action === 'rejected';
      const quoteSaved = action === 'saved';
      const quoteApproved = action === 'approved';

      if (quoteSent) {
        quotes.sent = sentQuote;
        quotes.saved = savedQuote;
      } else if (quoteRejected) {
        quotes.sent = respondedQuote;
        quotes.rejected = [respondedQuote, ...quotes.rejected];
      } else if (quoteSaved) {
        quotes.saved = savedQuote;
      } else if (quoteApproved) {
        quotes.sent = respondedQuote;
      } else {
        quotes.rejected.splice(0, 1);
        quotes.sent = respondedQuote;
      }

      context.setState(() => ({
        quotes,
      }));
    });

    socket.on('quote received consumer', ({ quote }) => {
      const { quotes } = context.state;
      const updatedQuotes = { ...quotes, sent: quote };
      context.setState({
        quote: updatedQuotes,
      });
    });
  },

  removeMessageListeners() {
    socket.removeListener('message');
    socket.removeListener('quote');
    socket.removeListener('quote received consumer');
  },

  appComponentListeners(props, notificationAlert) {
    socket.on('user online status change', ({ users, businesses }) => {
      const { socketsActions } = props;
      socketsActions.setSockets({ users, businesses });
    });

    socket.on('disconnect', () => {
      const { user, business } = props;
      user._id &&
        socket.emit('user connected', {
          userConnected: user,
          businessConnected: business,
        });
    });

    socket.on('message received', (currentChat) => {
      const {
        business,
        connectionsActions,
        connections: { selectedConnection },
      } = props;
      const connectionsListType =
        currentChat.type === 'B2C'
          ? business._id === currentChat.businessId
            ? 'business'
            : 'personal'
          : 'personal';
      connectionsActions.updateConnections(
        currentChat,
        connectionsListType,
        true,
        true,
      );
      const receivedWhileSelected =
        currentChat._id === selectedConnection.connection?._id &&
        currentChat.type === selectedConnection.connection?.type;
      if (receivedWhileSelected) {
        selectedConnection.connection = currentChat;
        connectionsActions.setSelectedConnection(selectedConnection);
      }
    });

    socket.on('message sent', (currentChat) => {
      const {
        business,
        connectionsActions,
        connections: { selectedConnection },
      } = props;
      const connectionsListType =
        currentChat.type === 'B2C'
          ? business._id === currentChat.businessId
            ? 'business'
            : 'personal'
          : 'personal';
      connectionsActions.updateConnections(
        currentChat,
        connectionsListType,
        true,
        false,
      );
      const receivedWhileSelected =
        currentChat._id === selectedConnection.connection?._id &&
        currentChat.type === selectedConnection.connection?.type;
      if (receivedWhileSelected) {
        selectedConnection.connection = currentChat;
        connectionsActions.setSelectedConnection(selectedConnection);
      }
    });

    socket.on('connect request received', (newRequest) => {
      const newConnectionsList = { ...props.connections };
      newConnectionsList.received.unshift(newRequest);
      props.connectionsActions.connectRequestSent();
      props.connectionsActions.updateConnectionsFromRequest(
        newConnectionsList,
        '',
        true,
      );
    });

    socket.on('request response business', ({ newRequest }) => {
      const { connections, connectionsActions, business } = props;
      const updatedConnections = { ...connections };
      const pendingIndex = updatedConnections.received.findIndex(
        (connection) => newRequest._id === connection._id,
      );
      updatedConnections.received.splice(pendingIndex, 1);
      if (newRequest.status === 'accepted') {
        updatedConnections.business.unshift(newRequest);
      } else {
        updatedConnections.declined.unshift(newRequest);
      }
      connectionsActions.updateConnectionsFromRequest(
        updatedConnections,
        '',
        true,
      );
      if (newRequest.status === 'accepted') {
        props.history.push({
          pathname: '/Messages',
          state: {
            connection: newRequest,
            otherProfile: newRequest.users[newRequest.consumerId],
            userProfile: business,
            category: 'business',
          },
        });
      }
    });

    socket.on('request response fail', ({ message }) => {
      props.connectionsActions.respondConnectRequestFail(message);
    });

    socket.on('request response consumer', ({ newRequest }) => {
      const { connectionsActions } = props;
      const updatedConnections = { ...props.connections };
      const pendingIndex = updatedConnections.sent.findIndex(
        (connection) => newRequest._id === connection._id,
      );
      updatedConnections.sent.splice(pendingIndex, 1);
      newRequest.status === 'accepted'
        ? updatedConnections.personal.unshift(newRequest)
        : updatedConnections.declined.unshift(newRequest);
      connectionsActions.updateConnectionsFromRequest(
        updatedConnections,
        '',
        true,
      );
    });

    socket.on('request edit business', ({ connectRequest }) => {
      const updatedConnections = { ...props.connections };
      const udpatedIndex = updatedConnections.received.findIndex(
        (connection) => connection._id === connectRequest._id,
      );
      updatedConnections.received[udpatedIndex] = connectRequest;
      props.connectionsActions.updateConnectionsFromRequest(updatedConnections);
    });

    socket.on('request canceled', ({ canceledRequest, type }) => {
      const updatedConnections = { ...props.connections };
      const removeIndex = updatedConnections[type].findIndex(
        (connection) => connection._id === canceledRequest,
      );
      updatedConnections[type].splice(removeIndex, 1);
      props.connectionsActions.updateConnectionsFromRequest(updatedConnections);
    });

    socket.on('request deleted', ({ deletedRequest }) => {
      const { connections } = props;
      const updatedConnections = { ...connections };
      const removeIndex = updatedConnections.declined.findIndex(
        (connection) => connection._id === deletedRequest._id,
      );
      updatedConnections.declined.splice(removeIndex, 1);
      props.connectionsActions.updateConnectionsFromRequest(updatedConnections);
    });

    socket.on('c2c connect message', ({ connection, sent, exists }) => {
      const updatedConnections = { ...props.connections };
      if (exists) {
        const prevIndex = updatedConnections.personal.findIndex(
          (chat) => chat._id === connection._id && chat.type === connection.type,
        );
        updatedConnections.personal.splice(prevIndex, 1);
      }
      updatedConnections.personal.unshift(connection);
      !sent && updatedConnections.unreadPersonalMessagesCount++;
      if (sent) {
        props.connectionsActions.createC2CConnection(updatedConnections);
      } else {
        props.connectionsActions.updateConnectionsFromRequest(
          updatedConnections,
          '',
          true,
        );
      }
    });

    socket.on('dream team request received', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsPendingReceived.unshift(request);
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('dream team response requestBy', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsPendingSent = newDTRequests.requestsPendingSent.filter(
        (dtRequest) => dtRequest._id !== request._id,
      );
      request.status === 'approved' &&
        newDTRequests.requestsAccepted.unshift(request);
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('dream team change', ({ request, changedBy }) => {
      const { dreamTeamRequests, dreamTeamActions } = props;
      const newDTRequests = { ...dreamTeamRequests };
      const inCurrentDreamTeam =
        (changedBy === 'requestTo' && request.inRequestByDreamTeam) ||
        (changedBy === 'requestBy' && request.inRequestToDreamTeam);
      const searchList = inCurrentDreamTeam ? 'dreamTeam' : 'requestsAccepted';
      const prevIndex = newDTRequests[searchList].findIndex(
        (req) => request._id === req._id,
      );
      newDTRequests[searchList][prevIndex] = request;
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('revoked dream team request', ({ request, revokedBy }) => {
      const { dreamTeamRequests, dreamTeamActions } = props;
      const newDTRequests = { ...dreamTeamRequests };
      const inCurrentDreamTeam =
        (revokedBy === 'requestTo' && request.inRequestByDreamTeam) ||
        (revokedBy === 'requestBy' && request.inRequestToDreamTeam);
      const filterList = inCurrentDreamTeam ? 'dreamTeam' : 'requestsAccepted';
      newDTRequests[filterList] = newDTRequests[filterList].filter(
        (req) => req._id !== request._id,
      );
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('cancel dream team request received', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsPendingReceived = newDTRequests.requestsPendingReceived.filter(
        (req) => req._id !== request._id,
      );
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    // when a user favorites/unfavorites a business
    socket.on('user favoriting change', ({ businessId, action }) => {
      const { favorites, favoritesActions } = props;
      const newFavorites = { ...favorites };
      if (action === 'favorited') {
        newFavorites.favoriteBusinesses.set(businessId, true);
      } else {
        newFavorites.favoriteBusinesses.delete(businessId);
      }
      favoritesActions.updateFavorites(newFavorites);
    });

    // when a business is favorited/unfavorited
    socket.on('business favoriting change', ({ userId, action }) => {
      const { business, favorites, businessActions, favoritesActions } = props;
      const newFavorites = { ...favorites };
      const newBusiness = { ...business };
      if (action === 'favorited') {
        newFavorites.favoritedBy.set(userId, true);
      } else {
        newFavorites.favoritedBy.delete(userId);
      }
      newBusiness.favoritesCounts = newFavorites.favoritedBy.size;
      favoritesActions.updateFavorites(newFavorites);
      businessActions.updateBusiness(newBusiness);
    });

    socket.on('favorites change failed', ({ message }) => {
      props.userActions.updateUserFavorites({}, false, message);
    });

    socket.on('notification alert', ({ newNotification }) => {
      const updatedNotificatons = { ...props.notifications };
      updatedNotificatons.list.unshift(newNotification);
      updatedNotificatons.unreadCount += 1;
      props.notificationsActions.setNotifications(updatedNotificatons);
      notificationAlert(newNotification);
    });
  },

  listenDtPage(context) {
    socket.on('dream team request sent', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = context.props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsPendingSent.unshift(request);
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
      context.setState({
        popUpMessage: 'Request sent!',
        success: true,
      });
    });

    socket.on('dream team request failed', ({ message }) => {
      context.setState({
        popUpMessage: message,
        success: false,
      });
    });

    socket.on('dream team response requestTo', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = context.props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsPendingReceived = newDTRequests.requestsPendingReceived.filter(
        (dtRequest) => dtRequest._id !== request._id,
      );
      request.status === 'approved' &&
        newDTRequests.requestsAccepted.unshift(request);
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('dream team response fail', ({ message }) => {
      const { dreamTeamActions } = context.props;
      dreamTeamActions.sendDreamTeamRequest({}, false, message);
    });

    socket.on('dream team add', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = context.props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsAccepted = newDTRequests.requestsAccepted.filter(
        (req) => req._id !== request._id,
      );
      newDTRequests.dreamTeam.push(request);
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('dream team remove', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = context.props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.dreamTeam = newDTRequests.dreamTeam.filter(
        (req) => req._id !== request._id,
      );
      newDTRequests.requestsAccepted.push(request);
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('dream team request revoker', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = context.props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsAccepted = newDTRequests.requestsAccepted.filter(
        (req) => req._id !== request._id,
      );
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });

    socket.on('cancel dream team request sent', ({ request }) => {
      const { dreamTeamRequests, dreamTeamActions } = context.props;
      const newDTRequests = { ...dreamTeamRequests };
      newDTRequests.requestsPendingSent = newDTRequests.requestsPendingSent.filter(
        (req) => req._id !== request._id,
      );
      dreamTeamActions.setDreamTeamRequests(newDTRequests);
    });
  },

  removeDtListeners() {
    socket.removeListener('dream team request sent');
    socket.removeListener('dream team request failed');
    socket.removeListener('dream team response requestTo');
    socket.removeListener('dream team response failed');
    socket.removeListener('dream team add');
    socket.removeListener('dream team remove');
    socket.removeListener('dream team request revoke');
    socket.removeListener('cancel dream team request sent');
  },

  listenQuoteForm(context) {
    socket.on('quote update', ({ message }) => {
      context.notifySuccess(message);
      context.setState({
        message,
        success: true,
      });
    });

    socket.on('quote update fail', ({ message }) => {
      context.notifyError(message);
      context.setState({
        message:
          'There was an error performing this action. Please contact support if error persists.',
        success: false,
      });
    });
  },

  removeQuoteFormListeners() {
    socket.removeListener('quote update');
    socket.removeListener('quote update fail');
  },

  listenQuoteFormView(context) {
    socket.on('quote response', () => {
      context.setState({
        responded: true,
      });
    });

    const viewingSentQuote =
      context.props.location.state &&
      context.props.location.state.viewType === 'sent';
    viewingSentQuote &&
      socket.on('quote received', ({ quote }) => {
        context.setState({
          quote,
        });
      });

    socket.on('quote response fail', () => {
      context.setState({
        message:
          'There was an error performing this action. Please contact support if error persists.',
        success: false,
        showMessage: true,
      });
    });
  },

  removeQuoteFormViewListeners() {
    socket.removeListener('quote response');
    socket.removeListener('quote response fail');
    socket.removeListener('quote received');
  },

  listenConnectRequestSent(context) {
    socket.on('connect request success', ({ newRequest, message }) => {
      const newConnectionsList = { ...context.props.connections };
      newConnectionsList.sent.unshift(newRequest);
      context.props.connectionsActions.updateConnectionsFromRequest(
        newConnectionsList,
        message,
        true,
      );
      context.setState({
        requestMessage: message,
        success: true,
        showMessage: true,
        modal: true,
      });
    });

    socket.on('connect request failed', ({ message }) => {
      context.setState({
        requestMessage: message,
        success: false,
        showMessage: true,
        modal: false,
      });
    });
  },

  removeConnectRequestSentListeners() {
    socket.removeListener('connect request sent');
    socket.removeListener('connect request failed');
  },

  listenConnectRequestEditConsumer(context) {
    socket.on('request edit consumer', ({ connectRequest }) => {
      const updatedConnections = { ...context.props.connections };
      const udpatedIndex = updatedConnections.sent.findIndex(
        (connection) => connection._id === connectRequest._id,
      );
      updatedConnections.sent[udpatedIndex] = connectRequest;
      context.props.connectionsActions.updateConnectionsFromRequest(
        updatedConnections,
      );
      context.setState({
        message: 'Changes Saved!',
        success: true,
        showMessage: true,
        editsMade: false,
      });
    });

    socket.on('request edit failed', () => {
      context.setState({
        message:
          'Error updating this request. Please contact support if error persists.',
        success: false,
        showMessage: true,
      });
    });
  },

  removeConnectRequestEditListeners() {
    socket.removeListener('request edit consumer');
    socket.removeListener('request edit failed');
  },

  listenBusinessProfile(currentBusiness, setBusiness) {
    socket.on('business online status change', ({ businessId, status }) => {
      if (businessId === currentBusiness._id) {
        setBusiness({ ...currentBusiness, online: status });
      }
    });
  },

  removeBusinessProfileListeners() {
    socket.removeListener('business online status change');
  },

  listenReferralPage(sentReferrals, setSentReferrals) {
    socket.on('referral fulfilled', ({ updatedRef }) => {
      const updatedSentReferrals = sentReferrals.map((referral) =>
        updatedRef._id === referral._id ? updatedRef : referral,
      );
      setSentReferrals([...updatedSentReferrals]);
    });
  },

  removeReferralPageListeners() {
    socket.removeListener('referral fulfilled');
  },
};
