/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Prompt } from 'react-router-dom';
import Modal from '../Subscription/Modal';
import { states } from '../EditBusiness/constants';

// Need to limit what kind of files are uploadable

export default class Form extends Component {
  state = {
    firstName: this.props.user.firstName || '',
    lastName: this.props.user.lastName || '',
    // email: this.props.user.email || '',
    phone: this.props.user.phone || '',
    street: this.props.user.street || '',
    city: this.props.user.city || '',
    state: this.props.user.state || '',
    zipCode: this.props.user.zipCode || '',
    idFile: null,
    isBlocking: false,
    // pictureChanged: false,
    saveConfirmation: '',
    showMessage: false,
    updating: false,
    modal: false,
  };

  modal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  componentDidUpdate = () => {
    const { user } = this.props;
    const { saveConfirmation, showMessage, updating } = this.state;
    if (user.message && user.message !== saveConfirmation && showMessage) {
      if (!user.updatingUser && !updating) {
        this.setState({
          saveConfirmation: user.message,
        });
      } else {
        this.setState({
          saveConfirmation: user.message,
          updating: false,
        });
      }
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showMessage: false,
      saveConfirmation: '',
      isBlocking: true,
    });
  };

  stateSelect = (event) => {
    this.setState({
      state: event.target.textContent,
      showMessage: false,
      saveConfirmation: '',
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.props.updateProfilePic(reader.result);
    };
    file && reader.readAsDataURL(file);
  };

  // upload identification
  idUploadHandler = (e) => {
    this.setState({
      idFile: e.target.files[0],
      showMessage: false,
      saveConfirmation: '',
    });
  };

  deleteImgHandler = () => {
    const profilePicEmpty = this.props.user.profilePic === null;
    !profilePicEmpty && this.props.updateProfilePic(null);
  };

  // prompt user to save changes
  blockingHandler = (e) => {
    this.setState({
      isBlocking: e.target.value !== this.state.isBlocking,
    });
  };

  // form submit
  onSubmit = (e) => {
    e.preventDefault();
    const updates = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zipCode: this.state.zipCode,
      bio: this.state.bio,
    };
    this.setState(
      {
        isBlocking: false,
        showMessage: true,
        updating: true,
      },
      () => {
        this.props.onSubmit(updates);
      },
    );
  };

  render() {
    const { profilePic } = this.props.user;
    const {
      firstName,
      lastName,
      phone,
      street,
      city,
      state,
      zipCode,
      bio,
      isBlocking,
      saveConfirmation,
    } = this.state;
    const defaultPic =
      'https://res.cloudinary.com/rhiz/image/upload/v1574201745/Default/Profile_Picture_New_Hair_2_j0yuuu.png';
    const buttonStyle = {
      // using for now, could not get classname in subcription.css to apply
      padding: '10px 25px',
      backgroundColor: '#00BF6F',
      color: '#fff',
      border: 'none',
      borderRadius: '3px',
      marginTop: '1em',
    };
    return (
      <div className="form-container">
        <div className="profile-picture-container">
          <img
            id="profile-picture"
            src={profilePic || defaultPic}
            alt="profile pic"
          />
          <div className="delete-upload-container">
            <button
              className="delete-upload-buttons"
              onClick={this.deleteImgHandler}
            >
              delete
            </button>
            <span>or</span>
            <label className="delete-upload-buttons" htmlFor="img-upload">
              upload
            </label>
            <input
              id="img-upload"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => this.handleImageChange(e)}
            ></input>
          </div>
        </div>
        <form
          id="edit-profile-form"
          onChange={(e) => this.blockingHandler(e)}
          onSubmit={(e) => this.onSubmit(e)}
        >
          <div className="first-name">
            <label htmlFor="firstName">FIRST NAME</label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => this.handleChange(e)}
              id="firstName"
              required
            />
          </div>
          <div className="last-name">
            <label htmlFor="lastName">LAST NAME</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => this.handleChange(e)}
              id="lastName"
              required
            />
          </div>
          {/* <div className="email">
            <label for="email">EMAIL</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => this.handleChange(e)}
              id="email"
              required
            />
          </div> */}
          <div className="phone">
            <label htmlFor="phone">PHONE</label>
            <input
              type="text"
              name="phone"
              placeholder="XXX-XXX-XXXX"
              value={phone}
              onChange={(e) => this.handleChange(e)}
              id="phone"
              required
            />
          </div>
          <div className="street">
            <label htmlFor="street">STREET</label>
            <input
              type="text"
              name="street"
              placeholder="Street"
              value={street}
              onChange={(e) => this.handleChange(e)}
              id="street"
              required
            />
          </div>
          <div className="city">
            <label htmlFor="city">CITY</label>
            <input
              type="text"
              name="city"
              placeholder="City"
              value={city}
              onChange={(e) => this.handleChange(e)}
              id="city"
              required
            />
          </div>
          <div className="state">
            <label htmlFor="state">STATE</label>
            <Dropdown
              placeholder="Select a state"
              fluid
              search
              onChange={this.stateSelect}
              value={state}
              style={{
                width: '100%',
                background: 'transparent',
                borderBottom: '1px solid gray',
                padding: '1em',
              }}
              options={states}
            />
          </div>
          <div className="zip-code">
            <label htmlFor="zipCode">ZIP CODE</label>
            <input
              type="text"
              name="zipCode"
              placeholder="Zip Code"
              value={zipCode}
              onChange={(e) => this.handleChange(e)}
              id="zipCode"
              required
            />
          </div>
          <div className="brief-bio">
            <label htmlFor="bio">BRIEF BIO</label>
            <textarea
              name="bio"
              placeholder="Write a little about yourself"
              value={bio}
              onChange={(e) => this.handleChange(e)}
              id="briefBio"
              rows="5"
              cols="50"
            />
          </div>
          <section className="form-bottom">
            <button type="button" onClick={this.modal} id="facebook-button">
              <img
                className="logo"
                src={require('../../assets/images/facebook-inverse.png')}
                alt="facebook inverse logo"
              />
              Connect Facebook
            </button>
            <label htmlFor="upload-identification">UPLOAD IDENTIFICATION</label>
            <input
              type="file"
              id="upload-identification"
              onChange={(e) => this.idUploadHandler(e)}
            />
            <section className="edit-save-buttons">
              <button type="submit" id="save-button">
                Save Changes
              </button>
              {saveConfirmation.length > 0 &&
                (!this.props.user.success ? (
                  <div
                    style={{ color: 'red', fontSize: '1.1em', marginTop: '2%' }}
                  >
                    {saveConfirmation}
                  </div>
                ) : (
                  <div
                    style={{
                      color: '#00BE6E',
                      fontSize: '1.25em',
                      marginTop: '2%',
                    }}
                  >
                    {saveConfirmation}
                  </div>
                ))}
            </section>
          </section>
        </form>
        <Modal show={this.state.modal} onClose={() => this.modal()}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>Coming Soon!</h1>
            <section style={{ position: 'absolute', bottom: 20 }}>
              <button
                style={buttonStyle}
                onClick={() => {
                  this.modal();
                }}
              >
                Close
              </button>
            </section>
          </div>
        </Modal>
        <Prompt
          when={isBlocking}
          message="If you leave before saving, your changes will be lost."
        />
      </div>
    );
  }
}
