export const mainCats = [
  {
    text: 'Landscaping',
    value: 'Landscaping',
  },
  {
    text: 'Construction',
    value: 'Construction',
  },
  {
    text: 'Solar',
    value: 'Solar',
  },
  {
    text: 'Plumbing',
    value: 'Plumbing',
  },
  {
    text: 'HVAC',
    value: 'HVAC',
  },
  {
    text: 'Home Tech',
    value: 'Home Tech',
  },
  {
    text: 'Handyperson',
    value: 'handyperson',
  },
  {
    text: 'Painting',
    value: 'Painting',
  },
  {
    text: 'Flooring',
    value: 'flooring',
  },
  {
    text: 'Masonry',
    value: 'Masonry',
  },
  {
    text: 'Interior Design',
    value: 'Interior Design',
  },
  {
    text: 'Carpentry',
    value: 'Carpentry',
  },
  {
    text: 'Electrical',
    value: 'Electrical',
  },
  {
    text: 'Architecture',
    value: 'Architecture',
  },
  {
    text: 'Pools',
    value: 'Pools',
  },
  {
    text: 'Garden Centers',
    value: 'Garden Centers',
  },
  {
    text: 'Roofing',
    value: 'Roofing',
  },
  {
    text: 'General Contracting',
    value: 'General Contracting',
  },
  {
    text: 'Healthcare Services',
    value: 'Healthcare Services',
  },
  {
    text: 'Artistic Services',
    value: 'Artistic Services',
  },
  {
    text: 'Fitness/Sports Services',
    value: 'Fitness/Sports Services',
  },
  {
    text: 'Homecare Services',
    value: 'Homecare Services',
  },
  {
    text: 'Retail',
    value: 'Retail',
  },
  {
    text: 'Services',
    value: 'Services',
  },
  {
    text: 'Restaurant',
    value: 'Restaurant',
  },
  {
    text: 'Trainer',
    value: 'trainer',
  },
  {
    text: 'Personal',
    value: 'personal',
  },
  {
    text: 'Product',
    value: 'product',
  },
];
