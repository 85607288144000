export const notificationText = (notification) => {
  const receiver = notification.notificationTo.name;
  switch (notification.notificationType) {
    case 'dream team request':
      return ' sent you a dream team request.';
    case 'dream team accept':
      return ' accepted your dream team request';
    case 'dream team decline':
      return ' declined your dream team request';
    case 'dream team add':
      return ` added ${receiver} to their dream team.`;
    case 'dream team remove':
      return ` removed ${receiver} from their dream team.`;
    case 'dream team cancel':
      return ` canceled their dream team request to ${receiver}`;
    case 'dream team revoke':
      return ' revoked their approval to be in your dream team.';
    case 'favorited':
      return ` added ${receiver} to their Personal Favorites.`;
    case 'unfavorited':
      return ` removed ${receiver} from their Personal Favorites.`;
    case 'c2cConnect started':
      return ' started a conversation with you!';
    case 'connect request received':
      return ` wants to connect with ${receiver}`;
    case 'connect request accept':
      return ' accepted your connection request!';
    case 'connect request decline':
      return ' declined your connection request.';
    case 'connect request edit':
      return ' edited their connection request.';
    case 'connect request cancel':
      return ' canceled their connection request.';
    case 'quote sent':
      return ' sent you a quote!';
    case 'quote update':
      return ' updated their quote!';
    case 'quote accepted':
      return ' accepted your quote!';
    case 'quote declined':
      return ' declined your quote.';
    case 'quote pending':
      return ' revoked their quote decision';
    case 'job completion request':
      return ' requests that the job is completed.';
    case 'job completion accept':
      return ' accepts the job completion.';
    case 'job completion decline':
      return ' declines the job completion.';
    case 'invoice sent':
      return ' sent you an invoice.';
    case 'review sent':
      return ' left you a review.';
    case 'referral fulfilled':
      return ' used your referral!';
    default:
      return '';
  }
};

export const unReadNotifCount = (notifications, userLastCheck) => {
  let count = 0;
  for (let i = 0; i < notifications.length; i++) {
    const isAfterLastCheck = notifications[i].timestamp >= userLastCheck;
    if (!userLastCheck || isAfterLastCheck) {
      count += 1;
    }
  }
  return count;
};

export const notificationConstructor = (
  creator,
  receiver,
  notificationType,
  data = {},
) => ({
  notificationFrom: {
    _id: creator._id,
    name: creator?.firstName
      ? `${creator.firstName} ${creator.lastName}`
      : creator.name,
    username: creator.username,
    profilePic: creator.profilePic,
    type: creator.type,
  },
  notificationTo: {
    _id: receiver._id,
    name: receiver?.firstName
      ? `${receiver.firstName} ${receiver.lastName}`
      : receiver.name,
    username: receiver.username,
    profilePic: receiver.profilePic,
    type: receiver.type,
  },
  timestamp: Date.now(),
  notificationType,
  data,
});
