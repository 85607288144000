import React from 'react';
import Checkmark from './checkmark';
import { Link } from 'react-router-dom';

const SuccessModal = ({ closeModal }) => (
  <div className="connect-success">
    <Checkmark />
    <h1>Success!</h1>
    <h3>Hooray! Your message has been sent.</h3>
    <div className="connect-success-buttons">
      <Link to="/InboxPage">
        <p className="connect-success-button-message">Go to my messages</p>
      </Link>
      <button onClick={closeModal} className="connect-success-button-close">
        Close window
      </button>
    </div>
  </div>
);

export default SuccessModal;
