/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import './Notifications.css';
import {
  notificationText,
  findConnection,
  timeagoFormatter,
  timeagoFormatterMobile,
} from '../../util';
import { isMobile } from 'react-device-detect';

class NotificationDropDownList extends Component {
  state = {
    text: '',
  };

  // different click options based on notification type
  renderClickOptions = (notification) => {
    if (
      notification.notificationType.includes('connect request') &&
      notification.notificationType !== 'connect request accept'
    ) {
      return (
        <Link to={'/InboxPage'}>
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
            }}
          >
            View In Inbox
          </button>
        </Link>
      );
    } else if (
      notification.notificationType.includes('connect request') ||
      notification.notificationType.includes('quote') ||
      notification.notificationType === 'c2cConnect started'
    ) {
      const { connections } = this.props;
      const pathname = notification.notificationType.includes('quote')
        ? '/QuoteView'
        : '/Messages';
      const category =
        notification.notificationTo.type === 'business'
          ? 'business'
          : 'personal';
      const connection = findConnection(
        notification.data._id,
        notification.data.type,
        connections[category],
      );

      return (
        <Link
          to={{
            pathname,
            state: {
              connection,
              category,
              userProfile: notification.notificationTo,
              otherProfile: notification.notificationFrom,
              viewType: 'sent',
              viewer: category === 'business' ? 'business' : 'consumer',
            },
          }}
        >
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
            }}
          >
            View Conversation
          </button>
        </Link>
      );
    } else if (notification.notificationType.includes('dream team')) {
      return (
        <Link to={'/my_dream_team'}>
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
            }}
          >
            View Dream Team Page
          </button>
        </Link>
      );
    } else if (notification.notificationType.includes('favorited')) {
      return (
        <Link
          to={{
            pathname: `/user/${notification.notificationFrom.username}`,
            state: { user: notification.notificationFrom },
          }}
        >
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
            }}
          >
            View User&apos;s Profile
          </button>
        </Link>
      );
    } else if (notification.notificationType.includes('referral')) {
      return (
        <Link to="/Referral">
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
            }}
          >
            Go Redeem It
          </button>
        </Link>
      );
    }
  };

  render() {
    const { notificationFrom, timestamp } = this.props.notification;
    const { notifcationPage } = this.props;
    const profileType =
      notificationFrom.type === 'consumer' ? 'user' : 'business';
    const namePath = `/${profileType}/${notificationFrom.username}`;
    return (
      <div style={{ fontSize: '14px', padding: '5px' }}>
        <Dropdown.Item>
          <section style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={notificationFrom.profilePic}
              style={{ width: '50px', marginRight: '1em' }}
              alt="notification from profile pic"
            />
            <div className="notfication-text-option">
              <div className="notification-name-text">
                <p>
                  <Link
                    to={{
                      pathname: namePath,
                      state: {
                        [profileType]: { ...notificationFrom },
                      },
                    }}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        marginRight: '3px',
                        fontSize: '14px',
                        marginLeft: '0px',
                        lineHeight: '20px',
                      }}
                    >
                      {notificationFrom.name}
                    </span>
                  </Link>
                  {notificationText(this.props.notification)}
                </p>
              </div>
              <div style={{ marginTop: '.7em' }}>
                <div>
                  {this.renderClickOptions(this.props.notification)}
                  {/* {type !== 'Job Complete' &&
                    <button
                      style={{ marginRight: '2em', border: 'none',
                      background: '#fff', color: '#00BE6E' }}
                    >
                      View
                  </button>
                  }

                  {type === 'Connection' &&
                    <button
                      style={{ border: 'none', background: '#fff',
                      color: '#999999' }}
                    >
                      Decline
                  </button>
                  }
                  {type === "Job Complete" &&
                    <div>
                      <button
                        style={{ marginRight: '2em', border: 'none',
                        background: '#fff', color: '#00BE6E' }}
                      >
                        Confirm and pay
                      </button>
                      <button
                        style={{ marginRight: '2em', border: 'none',
                        background: '#fff', color: '#C11614' }}
                      >
                        Decline
                    </button>
                      <button
                        style={{ marginRight: '2em', border: 'none',
                        background: '#fff', color: '#999999' }}
                      >
                        View messages
                    </button>
                    </div>
                  } */}
                </div>
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <TimeAgo
                date={timestamp}
                formatter={isMobile ? timeagoFormatterMobile : timeagoFormatter}
                minPeriod={30}
                style={{
                  marginLeft: '10px',
                  border: 'none',
                  backgroundColor: '#fff',
                  color: '#999999',
                  position: 'relative',
                  bottom: '14.5px',
                }}
              />

              {/* <button style={{border:'none', backgroundColor: '#fff',
              marginLeft: '5em', color: '#DCDCDC'}}>Today</button> */}
            </div>
          </section>
          <hr
            className={
              notifcationPage
                ? 'notfication-hr-page'
                : 'notfication-hr-dropdown'
            }
          />
        </Dropdown.Item>
      </div>
    );
  }
}

export default NotificationDropDownList;
