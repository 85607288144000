import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';
import { timeagoFormatter } from '../util';

const DreamTeamDropdown = ({
  request,
  businessName,
  profilePic,
  respond,
  date,
}) => (
  <div style={{ width: '100%' }}>
    <Dropdown.Item>
      <section>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            maxWidth: '100%',
          }}
        >
          <img src={profilePic} style={{ width: '40px', float: 'left' }} alt="profilePic" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              flexGrow: '2',
              wordWrap: 'break-word',
              marginTop: '2%',
              marginBottom: '2%',
              paddingLeft: '12%',
            }}
          >
            <Link
              to={`/business/${
                request.businesses[request.requestById].username
              }`}
            >
              <span
                style={{
                  fontSize: '15px',
                  color: 'black',
                  textDecoration: 'underline',
                }}
              >
                {businessName}
              </span>
            </Link>
            <p style={{ fontSize: '15px', color: '#C0C0C0', marginTop: '2%' }}>
              wants to feature you on their Business Profile.
            </p>
          </div>
          <div
            style={{
              // marginLeft: 'auto'
              marginTop: '2%',
              float: 'right',
            }}
          >
            <TimeAgo
              date={date}
              formatter={timeagoFormatter}
              minPeriod={30}
              style={{
                border: 'none',
                backgroundColor: '#fff',
                marginLeft: '5em',
                color: '#DCDCDC',
                fontSize: '14px',
              }}
            />
          </div>
        </div>
        <div>
          <button
            style={{
              marginRight: '2em',
              border: 'none',
              background: '#fff',
              color: '#00BE6E',
              fontSize: '14px',
            }}
            onClick={() => respond(request, 'approved')}
          >
            Accept
          </button>
          <button
            style={{
              border: 'none',
              background: '#fff',
              color: '	#DCDCDC',
              fontSize: '14px',
            }}
            onClick={() => respond(request, 'denied')}
          >
            Decline
          </button>
        </div>
      </section>
      <hr
        style={{
          borderWidth: '0.05em',
          marginTop: '1em',
          marginBottom: '1em',
          height: '1px',
          background: '#DCDCDC',
        }}
      />
    </Dropdown.Item>
  </div>
);

export default DreamTeamDropdown;
