/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Picker } from 'emoji-mart';
import ScrollToBottom from 'react-scroll-to-bottom';
import MessageList from './MessageList';
import { isMobile } from 'react-device-detect';
import MessagesUserContentMobile from './MessagesUserContentMoble';
import AnimateHeight from 'react-animate-height';
import { fetchMessages, updateConnection } from '../../services';

const styles = {
  getEmojiButton: {
    border: 'none',
    margin: 0,
    cursor: 'pointer',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: -60,
    right: 0,
    // marginLeft: "200px"
  },
};

class MessagesUserMessages extends Component {
  state = {
    description: '',
    messages: [],
    loaded: true,
    currentChat: '',
    details: false,
    height: 60,
    connections: [],
  };

  heightSmall = () => {
    this.setState({
      height: 60,
    });
  };
  heightExtended = () => {
    this.setState({
      height: 425,
    });
  };

  detailsDropdown = () => {
    this.setState((prevState) => ({
      details: !prevState.details,
    }));
  };

  componentDidMount = () => {
    const { connections } = this.props;
    const { selectedConnection } = connections;
    // const respondingToQuote = connections.hasOwnProperty('updatingQuote')
    // && connections.updatingQuote === true;
    this.updateReadCount(selectedConnection, false, true);
    // socketServices.listenMessage(this);
    // socketServices.emitChatEnter(`${selectedConnection.connection.type}_${
    // selectedConnection.connection._id}`, selectedConnection);
  };

  componentWillUnmount() {
    const {
      connections: { selectedConnection },
    } = this.props;
    this.updateReadCount(selectedConnection, true, true);
    // socketServices.emitChatLeft(`${selectedConnection.connection.type}_${
    // selectedConnection.connection._id}`)
  }

  componentDidUpdate = (prevProps) => {
    const { connections } = this.props;
    const { selectedConnection } = connections;
    const newConnectionChat =
      prevProps.connections.selectedConnection.connection._id !==
        selectedConnection.connection._id ||
      selectedConnection.connection.type !==
        prevProps.connections.selectedConnection.connection.type;
    if (newConnectionChat) {
      this.updateReadCount(
        prevProps.connections.selectedConnection,
        true,
        false,
      );
      this.updateReadCount(selectedConnection, false, true);
    }
  };

  getMessages = (_id, type, connection = {}) => {
    fetchMessages(_id, type).then((messages) => {
      // safety net for initial acceptance and description isn't created as message in time
      const newMessages =
        messages.length > 0 ? messages : [connection.lastMessage];
      this.setState({
        messages: newMessages,
        currentChat: `${connection.type}_${connection._id}`,
        loaded: true,
      });
    });
  };

  updateReadCount = async(updatedSelected, updateDb, callUpdateSelect) => {
    const { user, connections, connectionsActions } = this.props;
    const updatedConnections = { ...connections };

    let needUpdate = false;
    const countUpdate = {};
    if (updatedSelected.category === 'business') {
      needUpdate = updatedSelected.connection.unreadMessagesBusiness > 0;
      updatedConnections.unreadBusinessMessagesCount -=
        updatedSelected.connection.unreadMessagesBusiness;
      countUpdate.unreadMessagesBusiness = 0;
    } else if (updatedSelected.connection.type === 'B2C') {
      needUpdate = updatedSelected.connection.unreadMessagesConsumer > 0;
      updatedConnections.unreadPersonalMessagesCount -=
        updatedSelected.connection.unreadMessagesConsumer;
      countUpdate.unreadMessagesConsumer = 0;
    } else if (user._id === updatedSelected.connection.user1_id) {
      needUpdate = updatedSelected.connection.unreadMessagesUser1 > 0;
      updatedConnections.unreadPersonalMessagesCount -=
        updatedSelected.connection.unreadMessagesUser1;
      countUpdate.unreadMessagesUser1 = 0;
    } else {
      needUpdate = updatedSelected.connection.unreadMessagesUser2 > 0;
      updatedConnections.unreadPersonalMessagesCount -=
        updatedSelected.connection.unreadMessagesUser2;
      countUpdate.unreadMessagesUser2 = 0;
    }
    updatedConnections.selectedConnection.connection = callUpdateSelect
      ? { ...updatedSelected.connection, ...countUpdate }
      : { ...updatedConnections.selectedConnection.connection };
    const success =
      (updateDb &&
        (await updateConnection(
          updatedSelected.connection._id,
          countUpdate,
          updatedSelected.connection.type,
        ))) ||
      !updateDb;
    if (needUpdate) {
      const selectedIndex = updatedConnections[
        updatedSelected.category
      ].findIndex(
        (connection) =>
          connection._id === updatedSelected.connection._id &&
          connection.type === updatedSelected.connection.type,
      );
      updatedConnections[updatedSelected.category][selectedIndex] =
        updatedConnections.selectedConnection.connection;
      connectionsActions.updateConnectionsFromRequest(
        updatedConnections,
        '',
        true,
      );
      success && connectionsActions.setSelectedConnection(updatedSelected);
    }
    // else if (!this.state.loaded) {
    //   this.getMessages(updatedConnections.selectedConnection.connection._id,
    // updatedConnections.selectedConnection.connection.type,
    // updatedConnections.selectedConnection.connection)
    // }
  };

  textInput = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState({
      description: this.state.description + emoji,
    });
  };

  showEmojis = () => {
    this.setState(
      {
        showEmojis: true,
      },
      () => document.addEventListener('click', this.closeMenu),
    );
  };

  attachmentUploadHandler = (e) => {
    this.setState({
      attachment: e.target.files[0],
    });
  };

  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false,
        },
        () => document.removeEventListener('click', this.closeMenu),
      );
    }
  };

  // using on press enter key since no send button yet
  sendMessage = async(e) => {
    const {
      connections: { selectedConnection },
    } = this.props;
    const { description } = this.state;
    if (e.key === 'Enter' && description.trim().length > 0 && !e.shiftKey) {
      e.preventDefault();
      // send message
      const connection = { ...selectedConnection.connection };
      const message = {
        sender: {
          _id: selectedConnection.userProfile._id,
          username: selectedConnection.userProfile.username,
          type:
            selectedConnection.userProfile._id ===
            selectedConnection.connection.businessId
              ? 'business'
              : 'consumer',
        },
        text: description,
        timestamp: Date.now(),
        receiver: {
          _id: selectedConnection.otherProfile._id,
          type:
            selectedConnection.otherProfile._id ===
            selectedConnection.connection.businessId
              ? 'business'
              : 'consumer',
          username: selectedConnection.otherProfile.username,
        },
        connection: {
          _id: selectedConnection.connection._id,
          type: selectedConnection.connection.type,
        },
      };
      connection.lastMessage = message;
      let updateCountColumn = '';
      if (selectedConnection.category === 'business') {
        updateCountColumn = 'unreadMessagesConsumer';
      } else if (connection.type === 'B2C') {
        updateCountColumn = 'unreadMessagesBusiness';
      } else if (selectedConnection.userProfile._id === connection.user1_id) {
        updateCountColumn = 'unreadMessagesUser2';
      } else {
        updateCountColumn = 'unreadMessagesUser1';
      }
      this.setState(
        {
          description: '',
        },
        () => {
          const currentChat = `${connection.type}_${connection._id}`;
          this.props.send(message, updateCountColumn, currentChat, connection);
          const textArea = document.querySelector('textarea');
          textArea.selectionStart = textArea.selectionEnd = 0;
        },
      );
    }
  };

  messageSentByUser = (message) => {
    const {
      connections: { selectedConnection },
    } = this.props;
    const sameId = selectedConnection.userProfile._id === message.sender._id;
    const sameSenderType =
      (selectedConnection.category === 'personal' &&
        message.sender.type === 'consumer') ||
      (selectedConnection.category === 'business' &&
        message.sender.type === 'business');
    return sameId && sameSenderType;
  };

  messageIsOnlyEmojis = (message) => {
    const ranges = [
      '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
      '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
      '\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
      ' ', // Also allow spaces
    ].join('|');

    const removeEmoji = (str) => str.replace(new RegExp(ranges, 'g'), '');

    return !removeEmoji(message).length;
  };

  determineMessageClassName = (sentByUser, allEmojis) => {
    let className = '';
    className = sentByUser ? 'User-Own-Message' : 'User-Recipient-Message';
    className = allEmojis ? className + ' Emoji-Message' : className;
    return className;
  };

  render() {
    const { loaded } = this.state;
    const {
      messages,
      connections: { selectedConnection },
    } = this.props;
    const { name, profilePic } = selectedConnection.otherProfile;

    return (
      <div>
        {isMobile && (
          <div>
            <AnimateHeight
              className="animate-height"
              duration={300}
              height={this.state.height}
            >
              {this.state.height === 60 ? (
                <div className="mobile mobile-messages-topbar">
                  <span
                    onClick={this.props.showDrawer}
                    onKeyDown={this.props.showDrawer}
                    className="inbox-link"
                    role="presentation"
                  >
                    ← Messages
                  </span>
                  <div className="mobile-messages-topbar-img">
                    <img src={profilePic} alt="profile pic" />
                    <span>{name}</span>
                  </div>
                  <span
                    onClick={() => {
                      this.detailsDropdown();
                      this.heightExtended();
                    }}
                    onKeyDown={() => {
                      this.detailsDropdown();
                      this.heightExtended();
                    }}
                    className="details"
                  >
                    Details
                  </span>
                </div>
              ) : (
                <div className="mobile mobile-messages-topbar-details">
                  <span
                    className="inbox-link"
                    onClick={this.props.showDrawer}
                    onKeyDown={this.props.showDrawer}
                  >
                    ←Messages
                  </span>
                  <div className="mobile-messages-topbar-details-img">
                    <MessagesUserContentMobile
                      {...this.props}
                      approvedDate={this.props.approvedDate}
                      completeDate={this.props.completeDate}
                      consumerProfile={this.props.consumerProfile}
                      paid={this.props.paid}
                      quoteSentDate={this.props.quoteSentDate}
                      expiredDate={this.props.expiredDate}
                      denyDate={this.props.denyDate}
                      pending={this.props.pending}
                      busninessReviewSent={this.props.busninessReviewSent}
                      reviewSent={this.props.reviewSent}
                    />
                  </div>

                  <span
                    onClick={() => {
                      this.detailsDropdown();
                      this.heightSmall();
                    }}
                    onKeyDown={() => {
                      this.detailsDropdown();
                      this.heightSmall();
                    }}
                    className="details"
                  >
                    X
                  </span>
                </div>
              )}
            </AnimateHeight>
          </div>
        )}

        {loaded && (
          <div className="Messages-User-Messages">
            <div
              className={
                this.details ? 'messages-scroll-details' : 'messages-scroll'
              }
            >
              <ScrollToBottom className="chat-box">
                {loaded &&
                  (messages.length > 0 ? (
                    messages.map((message) => {
                      const sentByUser = this.messageSentByUser(message);
                      const allEmojis = this.messageIsOnlyEmojis(message.text);
                      const className = this.determineMessageClassName(
                        sentByUser,
                        allEmojis,
                      );
                      return (
                        <MessageList message={message} className={className} />
                      );
                    })
                  ) : (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '2em',
                        marginTop: '50%',
                        textAlign: 'center',
                      }}
                    >
                      There was an error loading the messages for this
                      connection. Please try refreshing and if the error
                      persists contact support.
                    </div>
                  ))}
              </ScrollToBottom>
            </div>
            <div className="Messages-Text-Box">
              <textarea
                id="story"
                value={this.state.description}
                name="description"
                onChange={this.textInput}
                onKeyDown={this.sendMessage}
              ></textarea>
              <section className="attachment-container">
                <label
                  className="delete-upload-buttons"
                  htmlFor="attachment-upload"
                >
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    id="attachment-icon"
                    style={{ marginRight: '1em' }}
                  />
                </label>
                <input
                  id="attachment-upload"
                  type="file"
                  onChange={(e) => this.attachmentUploadHandler(e)}
                ></input>
                {this.state.showEmojis ? (
                  <div>
                    <span
                      style={styles.emojiPicker}
                      ref={(el) => (this.emojiPicker = el)}
                    >
                      <Picker onSelect={this.addEmoji} emojiTooltip={true} />
                    </span>
                    <span
                      onClick={this.showEmojis}
                      onKeyDown={this.showEmojis}
                      style={{ cursor: 'pointer' }}
                    >
                      {String.fromCodePoint(0x1f60a)}
                    </span>
                  </div>
                ) : (
                  <span
                    onClick={this.showEmojis}
                    onKeyDown={this.showEmojis}
                    style={{ cursor: 'pointer' }}
                  >
                    {String.fromCodePoint(0x1f60a)}
                  </span>
                )}
              </section>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(withRouter(MessagesUserMessages));
