// dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { SocialIcon } from 'react-social-icons';
import commaNumber from 'comma-number';
import { Link } from 'react-router-dom';
import dateFnsFormat from 'date-fns/format';

// components
import Sidebar from './subcomponents/Sidebar';
import Reviews from './subcomponents/Reviews';
import BusinessMapContainer from './subcomponents/BusinessMapContainer';
import BackgroundBanner from './subcomponents/BackgroundBanner';
import {
  Container,
  Row,
  Tag,
  WorksWithPic,
  RoundPic,
  BoldText,
  SmallText,
  Text,
  ResourceBanner,
  WhiteBoldText,
  ResourceButton,
} from './BusinessProfileStyles/index';
import BreakLine from './subcomponents/BreakLine';
import ConnectModal from './ConnectModal/ConnectModal';
import Backdrop from './ConnectModal/Backdrop/Backdrop';
import { Loading, PricePoint } from '../../components';
import './businessProfile.css';

// assets
import {
  businessBlades,
  // reviewsBlades,
  instaPicsBlades,
} from './BusinessInfo/BladesOfGlory';

// utils
import { getDate, notificationConstructor } from '../../util';
import { socketServices } from '../../services';
// import { fetchProfileById, fetchProfileByUsername } from '../../services/apiRequests';

const BusinessProfile = (props) => {
  const [business, setBusiness] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [urgency, setUrgency] = useState('Just Curious');
  const [mutualFriends, setMutual] = useState(0);
  const [totalFriends, setTotal] = useState(0);
  const [reviewCount, setReviewCount] = useState('');
  const [score, setScore] = useState(0);
  const [price, setPrice] = useState(0);
  const [instaPic, setInsta] = useState([]);
  const [userReviews, setReviews] = useState([]);
  const [startDate, setDate] = useState(
    dateFnsFormat(new Date(), 'MM-dd-yyyy'),
  );
  const [visibleReviewCount, setVisReviewCount] = useState(4);
  const [connecting, setConnection] = useState(false);
  const [render, setRender] = useState(false);
  const [favorite, setfavorite] = useState('');

  useEffect(() => {
    const fetchBusiness = () => {
      loaded && setLoaded(false);
      if (
        props.match.params.business === props.business.username ||
        (props.location.state &&
          props.location.state.business._id === props.business._id)
      ) {
        setBusiness(props.business);
        setLoaded(true);
        socketServices.listenBusinessProfile(props.business, setBusiness);
      } else {
        // for any other businesses
        const requestUri =
          props.location.state && props.location.state.business
            ? `/businesses?id=${props.location.state.business._id}`
            : `/businesses/${props.match.params.business}`;
        axios
          .get(requestUri)
          .then(({ data }) => {
            setBusiness(data.profile);
            setLoaded(true);
            socketServices.listenBusinessProfile(data.profile, setBusiness);
          })
          .catch((err) => {
            console.error('Error fetching business: ', err);
          });
      }
      // use blades of glory data for businesses coming from db for now
      // these features aren't available yet for actual businesses
      setPrice(businessBlades.price);
      setInsta(instaPicsBlades);
      setMutual(businessBlades.mutualFriends);
      setTotal(businessBlades.totalFriends);
      // setReviewCount(businessBlades.reviewCount);
      // setReviews(reviewsBlades);
      setScore(businessBlades.rhizScore);
    };

    const favoriteCheck = () => {
      const { user, favorites } = props;
      if (user._id) {
        setfavorite(favorites.favoriteBusinesses.get(business._id));
      } else {
        setfavorite(false);
      }
    };

    window.scrollTo(0, 0);
    fetchBusiness();
    setRender(true);
    favoriteCheck();

    return () => socketServices.removeBusinessProfileListeners();
  }, [props.location.pathname]);

  useEffect(() => {
    loaded && socketServices.listenBusinessProfile(business, setBusiness);
  }, [loaded]);

  // updating urgency
  const updateUrgency = (event) => {
    // get new selection
    setUrgency(event.target.value);
  };

  const updateStartDate = (date) => {
    const formattedDate = getDate(date, 'MMDDYYYY');
    setDate(formattedDate);
  };

  // Connection Request
  const sendRequest = (request) => {
    const notification = notificationConstructor(
      { ...props.user, type: 'consumer' },
      { ...business, type: 'business' },
      'connect request received',
    );
    request.date = Date.now();
    socketServices.emitConnectRequest(request, notification);
  };

  // Close modal
  const closeModalHandler = () => {
    const isUserBusinessProfile = props.business._id === business._id;
    if (!isUserBusinessProfile) {
      setConnection(!connecting);
    }
  };

  // load up more reviews
  const loadMore = () => {
    const newVisReviewCount = visibleReviewCount + 4;
    setVisReviewCount(newVisReviewCount);
  };

  return (
    <div>
      {loaded ? (
        <div>
          <BackgroundBanner
            banner={
              render && !business.headerPic
                ? businessBlades.headerPic
                : business.headerPic
            }
            name={business.name}
            description={business.mainCategory}
            profilePic={
              render && !business.profilePic
                ? businessBlades.profilePic
                : business.profilePic
            }
          />

          <Row>
            <Container
              className="business-profile-left"
              style={{ marginLeft: '5%', marginRight: '5%' }}
            >
              {/* <img src={profilePic} className="business-photo"/>       */}
              {/* <Row style={{ marginBottom: '2.5%', alignItems: 'center' }}>
                <SocialIcon network="facebook" className="fb-icon" />
                <span className="content">
                  {mutualFriends} of your Facebook friends and{' '}
                  {commaNumber(totalFriends)} people have liked {business.name}
                </span>
              </Row> */}

              {props.business._id !== business._id && (
                <section className="mobile" id="mobile-options">
                  <span className="review-count">Rating:</span>
                  <span className="rhiz-score">90</span>
                  <span className="rhiz-score-base">/100</span>
                  <button className="ui-button" onClick={closeModalHandler}>
                    Connect
                  </button>
                </section>
              )}

              <Row style={{ marginBottom: '5%', width: '100%' }}>
                {Array.isArray(business.subCategories) &&
                  business.subCategories.map((tag, i) => (
                    <Tag key={i}>{tag}</Tag>
                  ))}
                <PricePoint
                  price={price}
                  style={{ marginLeft: 'auto' }}
                  size="large"
                />
              </Row>
              <Row style={{ marginBottom: '1.5%' }}>
                <Text className="content-title">Works With</Text>
              </Row>
              <Row style={{ marginBottom: '1.5%' }}>
                <Text className="content">
                  {Object.values(business.dreamTeam).length > 0
                    ? 'See our recomended partners'
                    : 'This business has not listed any recommended partners yet.'}
                </Text>
              </Row>
              <Row style={{ marginBottom: '3.5%', flexWrap: 'wrap' }}>
                {Object.values(business.dreamTeam).length > 0 &&
                  Object.values(business.dreamTeam).map((member, i) => (
                    <Link
                      to={{
                        pathname: `/business/${member.username}`,
                        state: {
                          business: member,
                        },
                      }}
                    >
                      <WorksWithPic
                        className="workswith-image"
                        src={member.profilePic}
                        key={i}
                      />
                    </Link>
                  ))}
              </Row>
              <Row style={{ marginBottom: '2%' }}>
                <Text className="content-title">About Us</Text>
              </Row>
              <Row style={{ marginBottom: '5%', width: '100%' }}>
                <Text className="content">{business.about}</Text>
              </Row>
              {!!business.website && (
                <>
                  <Row style={{ marginBottom: '2%' }}>
                    <Text className="content-title">Website</Text>
                  </Row>
                  <Row style={{ marginBottom: '5%', width: '100%' }}>
                    <a href={business.website} target='_blank' rel='noreferrer'>{business.website}</a>
                  </Row>
                </>
              )}
              <Row style={{ marginBottom: '7%' }}>
                <Container style={{ marginRight: '2%' }}>
                  <Link
                    to={{
                      pathname:
                      `/user/${business.user.firstName}_${business.user.lastName}_${business._id}`,
                      state: {
                        user: business.user,
                      },
                    }}
                  >
                    <RoundPic src={business.user.profilePic} />
                  </Link>
                </Container>
                <Container style={{ marginTop: '1.2%' }}>
                  <BoldText>{`${business.user.firstName} ${business.user.lastName}`}</BoldText>
                  <SmallText>
                    {business.user.title || 'Business Contact'}
                  </SmallText>
                </Container>
              </Row>
              <Row className="content-title">Location</Row>
              <Row style={{ marginBottom: '3%' }}>
                <img
                  src="https://img.icons8.com/material/24/000000/marker.png"
                  alt="location"
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '0.25%',
                    marginTop: '',
                  }}
                />
                <Text className="content address">{`${business.street}, ${
                  business.city
                }, ${business.state || ''} ${business.zip}`}</Text>
              </Row>
              <BusinessMapContainer
                address={`${business.street || ''}, ${business.city || ''}, ${
                  business.state || ''
                } ${business.zip || ''}`}
              />
            </Container>
            <Container>
              <Sidebar
                score={score}
                urgency={urgency}
                instaPics={instaPic}
                changeUrgency={updateUrgency}
                connectPress={closeModalHandler}
                getDate={updateStartDate}
                date={startDate}
                favorite={favorite}
                viewedBusiness={business}
                openModal={props.openModal}
                user={props.user}
                {...props}
              />
            </Container>
          </Row>
          <Container style={{ marginLeft: '5%' }}>
            <BreakLine color="#D3D3D3" width="60%" margin="50%" />
          </Container>
          <Container style={{ marginLeft: '5%', marginTop: '5%' }}>
            <Reviews reviews={userReviews} reviewCount={reviewCount} onShowMore={loadMore}/>
          </Container>

          <ResourceBanner>
                <WhiteBoldText>
                  Need advice, motivation or tools?
                  <ResourceButton>
                    <Link style={{ color: '#00be6e' }} to={{
                      pathname: '/learning',
                    }}>
                      Resources
                    </Link>
                  </ResourceButton>
                </WhiteBoldText>
          </ResourceBanner>
        </div>
      ) : (
        <Loading />
      )}
      {connecting && <Backdrop />}
      {connecting && (
        <ConnectModal
          {...props}
          businessReq={business}
          user={props.user}
          closeModalHandler={closeModalHandler}
          date={startDate}
          urgency={urgency}
          updateUrgency={updateUrgency}
          sendRequest={sendRequest}
          userBusinessProfile={business._id === props.business._id}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(BusinessProfile);
