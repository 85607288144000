import React from 'react';

export default function FilterSVG() {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      aria-labelledby="filterIconTitle"
      stroke="#7A7A7A"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="miter"
      fill="none"
      color="#2329D6"
    >
      <title id="filterIconTitle">Filter</title>
      <path d="M10 12.261L4.028 3.972h16L14 12.329V17l-4 3z" />
    </svg>
  );
}
