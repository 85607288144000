import jwtDecode from 'jwt-decode';
import { requestNewAccessToken } from '../services';

let accessToken = '';

export const setAccessToken = (s) => {
  accessToken = s;
};

export const getAccessToken = () => accessToken;

export const isTokenValidOrUndefined = () => {
  const token = getAccessToken();
  if (!token) {
    return false;
  }

  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const getHeaderOptions = async() => {
  const valid = isTokenValidOrUndefined();
  const token = valid ? getAccessToken() : await requestNewAccessToken();

  return {
    Authorization: token,
  };
};
