/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import DreamTeamDropdown from './DreamTeamDropdown';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Image, Dropdown, Button } from 'semantic-ui-react';
import ModalForm from './register-login';
import rhizImg from '../assets/images/rhiz.png';
import R from '../assets/images/Rhiz R.png';
import Bell from './Bell';
import Envelope from './Envelope';
import Leaf from '../assets/images/leaf.png';
import { Notifications } from '../views/Notifications';
import { socketServices } from '../services';
import { notificationConstructor } from '../util';
import { isBrowser } from 'react-device-detect';

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function(p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !window.safari ||
      (window.safari !== 'undefined' && window.safari.pushNotification),
  )
    ? 'safari-icon'
    : '';

class Header extends Component {
  state = {
    activeItem: 'home',
    open: false,
    formType: this.props.formType,
    renderDropdown: false,
    showNotifDropdown: false,
  };

  componentDidUpdate(prevProps) {
    const { openModal } = this.props;

    if (openModal !== prevProps.openModal) {
      this.setState({
        open: this.props.openModal,
        formType: this.props.formType,
      });
    }
  }

  dreamTeamDropdownHandler = () => {
    const { dreamTeamRequests, business } = this.props;
    return dreamTeamRequests.requestsPendingReceived.length > 0 ? (
      dreamTeamRequests.requestsPendingReceived.map((notification) => {
        const { businesses, requestById } = notification;
        return (
          <DreamTeamDropdown
            request={notification}
            businessName={businesses[requestById].name}
            profilePic={businesses[requestById].profilePic}
            respond={this.dreamTeamRequestResponse}
            date={notification.date}
          />
        );
      })
    ) : (
      <Dropdown.Item>
        {business._id
          ? 'No new requests'
          : 'Hit "Add Business" above to start building your Dream Team!'}
      </Dropdown.Item>
    );
  };

  dreamTeamRequestResponse = (request, response) => {
    const { business } = this.props;
    const updatedRequest = {
      ...request,
      status: response,
    };
    let notificationType = 'dream team decline';
    if (response === 'approved') {
      updatedRequest.inRequestedDreamTeam = false;
      updatedRequest.inRequesterDreamTeam = false;
      notificationType = 'dream team accept';
    }
    const notification = notificationConstructor(
      business,
      request.businesses[request.requestById],
      notificationType,
    );
    socketServices.emitDtRequestResponse(updatedRequest, notification);
  };

  notificationDropDownHandler = () => (
    <Notifications dropDown={true} {...this.props} />
  );

  openLoginModal = () => {
    this.setState(
      {
        open: false,
        formType: 'login',
      },
      () => {
        this.setState({
          open: true,
        });
      },
    );
  };

  openSubscriptionModal = () => {
    this.setState(
      {
        open: false,
        formType: 'subscription',
      },
      () => {
        this.setState({
          open: true,
        });
      },
    );
  };

  viewSubscription = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        this.props.history.push('/subscription');
      },
    );
  };

  handleLogout = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        socketServices.emitLogout();
        this.props.userActions.logoutUser();
      },
    );
    this.props.history.push('/');
  };

  renderLogin() {
    const { user, business } = this.props;
    return (
      <div>
        <div className="dropdown-header-mobile">
          <Dropdown
            item
            text={user.firstName}
            icon={'chevron down'}
            className={'header login'}
            style={{ marginTop: '8px' }}
          >
            <Dropdown.Menu>
              <Link
                to={{
                  pathname: `/user/${user.username}`,
                  state: {
                    user,
                  },
                }}
              >
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  View my profile
                </Dropdown.Item>
              </Link>
              <Link to="/edit_profile">
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  Edit my profile
                </Dropdown.Item>
              </Link>
              {this.props.user.business && (
                <Link
                  to={{
                    pathname: `/business/${business.username}`,
                    state: {
                      user,
                      business,
                    },
                  }}
                >
                  <Dropdown.Item
                    style={{
                      fontSize: '1.2rem',
                      right: '.25rem',
                    }}
                  >
                    View my business
                  </Dropdown.Item>
                </Link>
              )}
              {user.business && (
                <Link to="/edit_business">
                  {' '}
                  <Dropdown.Item
                    style={{
                      fontSize: '1.2rem',
                      right: '.25rem',
                    }}
                    href="/edit_business"
                  >
                    Edit my business
                  </Dropdown.Item>
                </Link>
              )}
              <Link
                to={{
                  pathname: '/PersonalFavorites',
                  state: {
                    user,
                  },
                }}
              >
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  My Favorites
                </Dropdown.Item>
              </Link>
              <Link to="/MyReviewsPage/BusinessesIReviewed">
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  My Reviews
                </Dropdown.Item>
              </Link>
              {/* <Dropdown.Item onClick={this.openSubscriptionModal} style={{
                fontSize: '1.2rem',
                right: '.25rem',
              }}>My Subscription</Dropdown.Item> */}
              <Link to="/referrals">
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  My Referrals
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                style={{
                  fontSize: '1.2rem',
                  right: '.25rem',
                }}
                onClick={this.handleLogout}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="dropdown-header">
          <Dropdown
            item
            text={`Welcome ${user.firstName} ${user.lastName}!`}
            icon={'chevron down'}
            className={'header login'}
            style={{ marginTop: '13px' }}
          >
            <Dropdown.Menu>
              <Link
                to={{
                  pathname: `/user/${user.username}`,
                  state: {
                    user,
                  },
                }}
              >
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  View my profile
                </Dropdown.Item>
              </Link>
              <Link to="/edit_profile">
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  Edit my profile
                </Dropdown.Item>
              </Link>
              {this.props.user.business && (
                <Link
                  to={{
                    pathname: `/business/${business.username}`,
                    state: {
                      user,
                      business,
                    },
                  }}
                >
                  <Dropdown.Item
                    style={{
                      fontSize: '1.2rem',
                      right: '.25rem',
                    }}
                  >
                    View my business
                  </Dropdown.Item>
                </Link>
              )}
              {this.props.user.business && (
                <Link to="/edit_business">
                  {' '}
                  <Dropdown.Item
                    style={{
                      fontSize: '1.2rem',
                      right: '.25rem',
                    }}
                  >
                    Edit my business
                  </Dropdown.Item>
                </Link>
              )}
              <Link
                to={{
                  pathname: '/PersonalFavorites',
                  state: { user },
                }}
              >
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  My Favorites
                </Dropdown.Item>
              </Link>
              <Link to="/MyReviewsPage/BusinessesIReviewed">
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  My Reviews
                </Dropdown.Item>
              </Link>
              {/* <a><Dropdown.Item onClick={this.openSubscriptionModal} style={{
                fontSize: '1.2rem',
                right: '.25rem',
              }}>My Subscription</Dropdown.Item></a> */}
              <Link to="/referrals">
                <Dropdown.Item
                  style={{
                    fontSize: '1.2rem',
                    right: '.25rem',
                  }}
                >
                  My Referrals
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                onClick={this.handleLogout}
                style={{
                  fontSize: '1.2rem',
                  right: '.25rem',
                }}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {this.state.formType === 'subscription' && (
            <div>
              <ModalForm
                formType={this.state.formType}
                openModal={this.state.open}
                user={this.props.user}
                viewSubscription={this.viewSubscription}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { showNotifDropdown } = this.state;
    const {
      user,
      business,
      dreamTeamRequests,
      notifications,
      connections,
      location,
    } = this.props;
    const route = business._id
      ? `/business/${business.username}`
      : '/subscriptions';
    const buttonText = business._id
      ? 'My Business'
      : 'Add Business';
    const pendingDTRequests =
      business._id &&
      dreamTeamRequests.requestsPendingReceived.length > 0;
    const unreadNotifications =
      user._id && notifications.unreadCount > 0;
    const unreadMessagesCount =
      user._id &&
      connections.unreadBusinessMessagesCount +
        connections.unreadPersonalMessagesCount;
    const resetPasswordScreen = location.pathname === '/reset_password';


    return (
      <Menu secondary className="header">
        <Link to="/">
          <Menu.Item name="home">
            <Image
              className="rhiz-header-logo"
              src={rhizImg}
              size="small"
              verticalAlign="middle"
            />
          </Menu.Item>
        </Link>
        <Link to="/">
          <Menu.Item name="home">
            <Image
              className="rhiz-header-logo-R"
              src={R}
              size="small"
              verticalAlign="middle"
            />
          </Menu.Item>
        </Link>
        <Menu.Menu position="right">
          {!resetPasswordScreen && !user.loggedOut && user.verified && (
            <Link
              to={{
                pathname: `${route}`,
                state: {
                  user,
                  business,
                },
              }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                className={
                  !business._id
                    ? ' my-business-button-false'
                    : 'my-business-button'
                }
              >
                {buttonText}
              </Button>
            </Link>
          )}
          {!resetPasswordScreen && !user.loggedOut && user.verified && (
            <Menu.Item>
              <div id="header-flex">
                <div id="leaf-svg" className={isSafari}>
                  {pendingDTRequests && (
                    <p className="icon-count-leaf">
                      {dreamTeamRequests.requestsPendingReceived.length}
                    </p>
                  )}
                  <Dropdown
                    item
                    icon={<img src={Leaf} alt="leaft" />}
                    className={
                      business._id
                        ? ' dream-team-dropdown'
                        : 'dream-team-dropdown-false'
                    }
                    style={{ marginTop: '5%' }}
                  >
                    <Dropdown.Menu
                      style={{ right: '0', left: 'auto', marginTop: '8%' }}
                    >
                      {dreamTeamRequests.dreamTeam &&
                        this.dreamTeamDropdownHandler()}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <Link to="/InboxPage">
                  <div id="envelope-svg">
                    {unreadMessagesCount > 0 && (
                      <p className="icon-count-message">
                        {unreadMessagesCount}
                      </p>
                    )}
                    <Envelope width="28px" height="30px" />
                  </div>
                </Link>

                {isBrowser ? (
                  <div
                    id={
                      unreadNotifications ? 'bell-svg-count-true' : 'bell-svg'
                    }
                    className={isSafari}
                  >
                    {/* <Bell width='28px' height='30px' /> */}
                    {unreadNotifications && (
                      <p
                        className="icon-count-bell"
                        style={{ marginLeft: '-5px' }}
                      >
                        {notifications.unreadCount}
                      </p>
                    )}
                    <Dropdown
                      item
                      onClick={() =>
                        this.setState((prevState) => ({
                          showNotifDropdown: !prevState.showNotifDropdown,
                        }))
                      }
                      icon={<Bell width="28px" height="30px" />}
                      className=" dream-team-dropdown"
                    >
                      <Dropdown.Menu style={{ right: '0', left: 'auto' }}>
                        {showNotifDropdown &&
                          this.notificationDropDownHandler()}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div
                    id={
                      unreadNotifications ? 'bell-svg-count-true' : 'bell-svg'
                    }
                    className={isSafari}
                  >
                    <Link to="/Notifications">
                      {unreadNotifications && (
                        <p
                          className="icon-count-bell"
                          style={{ marginLeft: '-5px' }}
                        >
                          {notifications.unreadCount}
                        </p>
                      )}
                      <Bell width="28px" height="30px" />
                    </Link>
                  </div>
                )}
              </div>
            </Menu.Item>
          )}

          {!resetPasswordScreen &&
            !user.loggedOut &&
            user.verified &&
            this.renderLogin()}
          {/* Work in progress for header */}
          {/* <Tooltip message={'Messages'} position={'top'}>
            <div className="envelope-svg">
              <Link to='/messages' >

                <Envelope width='28px' height='30px' />
              </Link>
            </div>
          </Tooltip>

          <Tooltip message={'Dream Team requests'} position={'top'}>
            <Dropdown
              item
              icon={<img src={Leaf} />}
              className={business._id ? ' dream-team-dropdown' : 'dream-team-dropdown-false'}
            >
              <Dropdown.Menu style={{ right: '0', left: 'auto' }}>
                {this.dreamTeamDropdownHandler()}
              </Dropdown.Menu>
            </Dropdown>
          </Tooltip>

          <Tooltip message={'Notifications'} position={'top'}>
            <div className="bell-svg">
              <Bell width='28px' height='30px' />
            </div>
          </Tooltip> */}
        </Menu.Menu>

        {/* {!user._id && <Modal />} */}
        {user.loggedOut && (
          <div style={{ marginLeft: '5%', marginTop: '1.8%' }}>
            <Button className="nav-button" onClick={this.openLoginModal}>
              Log In
            </Button>
            <Button
              className="nav-button"
              id="signup-button"
              onClick={this.props.openSignUp}
            >
              Sign Up
            </Button>
            {/* <div> */}
            <ModalForm
              formType={this.state.formType}
              openModal={this.state.open}
              user={this.props.user}
              viewSubscription={this.viewSubscription}
              location={location}
            />
            {/* </div> */}
          </div>
        )}
      </Menu>
    );
  }
}

function mapStateToProps({ user, business, dreamTeamRequests, notifications }) {
  return { user, business, dreamTeamRequests, notifications };
}

export default connect(mapStateToProps)(withRouter(Header));
