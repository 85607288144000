import React from 'react';
import SignUpForm from '../forms/SignUpForm';

const SignupModal = (props) => (
  <div style={{ textAlign: 'center' }}>
    <h1>Welcome to Rhiz!</h1>
    <div
      className="fb-login-button"
      data-width=""
      data-size="medium"
      data-button-type="login_with"
      data-auto-logout-link="false"
      data-use-continue-as="false"
    ></div>
    <SignUpForm location={props.location} />
    <div
      className="links"
      onClick={props.openLogin}
      onKeyDown={props.openLogin}
    >
      Already have an account? Log in.
    </div>
  </div>
);

export default SignupModal;
