import React, { Component } from 'react';
import BusinessesReviewedView from './BusinessesReviewsView';
import businessesReviewedDummyData from './dummyData/businessesReviewedDummyData';
import './MyReviewsPage.css';

export default class BusinessesReviewed extends Component {

  state = {
    businessesData: [],
  }

  componentDidMount() {
    this.setState({
      businessesData: businessesReviewedDummyData,
    });
  }

  render() {
    return (
      <div>
        {this.state.businessesData.map(review => (
          <BusinessesReviewedView review ={review } />
        ),
        )}


      </div>
    );
  }

}
