import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import linkImage from '../../assets/images/learningPage/link.png';

const LearningCard = ({ img, name, desc, link }) => (
  <Card>
    <Image src={img} wrapped ui={false} alt='card img' />

    <Card.Content>
      <Card.Header id='card-name'>{name}</Card.Header>
      <Card.Description id='class-desc'>
        {desc}
      </Card.Description>
    </Card.Content>

    <Card.Content extra>
      <a id='card-desc-link' href={link} target='blank'>
        Click here <Image src={linkImage} />
      </a>
    </Card.Content>
  </Card>
);

export default LearningCard;
