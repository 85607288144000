/* eslint-disable max-len */
const reviewsAboutMeDummyData = [
  {
    img:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1557460077/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/BoG_fn8uip.png',
    businessName: 'Blades of Glory',
    recommendation: 'Yes',
    review: 'Amazing person to work with!',
  },
  {
    img:
      'https://res.cloudinary.com/rhiz/image/upload/c_thumb,w_200,g_face/v1559065260/Businesses%20%28profile%2C%20lists%2C%20search%29/Garden%20of%20Eva/GoE_h83gwf.png',
    businessName: 'Garden of Eva',
    recommendation: 'Yes',
    review:
      "Alessandra McClane was very thorough and attentive. I'm not the best on computers so when we connected, their workers were great about calling me directly",
  },
  {
    img:
      'https://res.cloudinary.com/rhiz/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1557460077/Businesses%20%28profile%2C%20lists%2C%20search%29/Blades%20of%20Glory/BoG_fn8uip.png',
    businessName: 'Blades of Glory',
    recommendation: 'Yes',
    review:
      "Alessandra McClane was one of the nicest people I've met in a while. My yard was a beast but they did something magical and now the kids play outside all the time",
  },
];

export default reviewsAboutMeDummyData;
