import {
  FETCH_BUSINESS,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_FAILED,
  ADD_BUSINESS,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_FAILED,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_FAILED,
  LOGOUT_DONE,
  SET_BUSINESS,
  UPDATE_BUSINESS_PHOTO,
  UPDATE_BUSINESS_PHOTO_SUCCESS,
  UPDATE_BUSINESS_PHOTO_FAILED,
} from '../actions/types';

const business = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BUSINESS:
      return {
        ...state,
        fetchingBusiness: true,
      };
    case FETCH_BUSINESS_SUCCESS:
      return {
        ...action.payload.profile,
        fetchingBusiness: false,
      };
    case FETCH_BUSINESS_FAILED:
      return {
        ...state,
        fetchBusinessMessage: action.payload.message,
        fetchingBusiness: false,
      };
    case ADD_BUSINESS:
      return {
        ...state,
        addingBusiness: true,
      };
    case ADD_BUSINESS_SUCCESS:
      return {
        ...state,
        ...action.payload.business,
        addingBusiness: false,
        createBusinessSuccess: true,
      };
    case ADD_BUSINESS_FAILED:
      return {
        ...state,
        addingBusinessMessage: action.payload.message,
        addingBusiness: false,
      };
    case UPDATE_BUSINESS:
      return {
        ...state,
        updatingBusiness: true,
        success: true,
        message: 'Saving please wait...',
      };
    case UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        ...action.payload.business,
        message: action.payload.message,
        updatingBusiness: false,
      };
    case UPDATE_BUSINESS_FAILED:
      return {
        ...state,
        updateBusinessMessage: action.payload,
        success: false,
        updatingBusiness: false,
      };
    case UPDATE_BUSINESS_PHOTO:
      return {
        ...state,
        updatingBusinessPhoto: true,
      };
    case UPDATE_BUSINESS_PHOTO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updatingBusinessPhoto: false,
      };
    case UPDATE_BUSINESS_PHOTO_FAILED:
      return {
        ...state,
        updatingBusinessPhoto: false,
        updatingPhotoErrorMessage: action.payload.message,
      };
    case SET_BUSINESS:
      return {
        ...state,
        ...action.payload.business,
      };
    case LOGOUT_DONE:
      return {};
    default:
      return state;
  }
};

export default business;
