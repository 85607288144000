// dependencies
import React from 'react';
import commaNumber from 'comma-number';

// import "../../ConsumerProfile/consumerProfile.css";

// components
import {
  Container,
  Row,
  RoundPic,
  BoldText,
  SmallText,
} from '../BusinessProfileStyles';

const Reviews = (props) => {
  const { reviews, reviewCount } = props;

  return (
    <div className="user-container">
      <Row className="title">
        <div>Reviews - Coming Soon</div>
      </Row>
      <Container>{props.children}</Container>
      <Container style={{ marginBottom: '10%', marginLeft: '.5em' }}>
        <Row style={{ flexWrap: 'wrap' }}>
          {reviews.map((review, i) => (
            <Container className="review" style={{ marginRight: '3%' }} key={i}>
              <Row style={{ marginBottom: '3%' }}>
                <RoundPic src={review.src} style={{ marginRight: '3%' }} />
                <Container style={{ marginTop: '2%' }}>
                  <BoldText>{review.name}</BoldText>
                  <SmallText>{review.datePosted}</SmallText>
                </Container>
              </Row>
              <div className="review-body">{review.review}</div>
            </Container>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Reviews;
