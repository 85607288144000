import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Inbox from '../common/Inbox';

export default class QuoteRecieved extends Component {
  render() {
    const {
      connection,
      profile,
      userProfile,
      type,
      otherIndex,
      allIndex,
    } = this.props;
    const { businessId } = connection;
    return (
      <div className="Quote-Form-Container">
        <div className="Quote-recieved">
          <p
            onClick={this.props.backToConversation}
            onKeyDown={this.props.backToConversation}
            role="presentation"
          >
            Back to conversation
          </p>
          <div className="Quote-recieved-flex">
            <Inbox />
            <h3>{connection.users[businessId].name} sent you a new quote</h3>
            <Link
              to={{
                pathname: '/QuoteView',
                state: {
                  connection,
                  profile,
                  userProfile,
                  type,
                  otherIndex,
                  allIndex,
                  viewType: 'sent',
                  viewer: 'consumer',
                },
              }}
            >
              <Button color="green">View Quote</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
