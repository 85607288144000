/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperclip,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Picker } from 'emoji-mart';
import Attachment from '../../InboxPage/ConnectRequestModal/Attachment';
import { SuccessModal } from '../../../components';
import 'react-day-picker/lib/style.css';
import 'emoji-mart/css/emoji-mart.css';
import './ConnectModal.css';
import { getDate, formatDate } from '../../../util';
import { socketServices } from '../../../services';
import { Dropdown } from 'semantic-ui-react';

const urgencyOptions = [
  {
    key: 'Just Curious',
    text: 'Just Curious',
    value: 'Just Curious',
  },
  {
    key: 'Ready to go',
    text: 'Ready to go',
    value: 'Ready to go',
  },
  {
    key: 'Emergency',
    text: 'Emergency',
    value: 'Emergency',
  },
];

const styles = {
  getEmojiButton: {
    border: 'none',
    margin: 0,
    cursor: 'pointer',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: -194,
    right: 83,
    marginLeft: '200px',
  },
};

export default class ConnectModal extends Component {
  state = {
    date: this.props.date,
    urgency: this.props.urgency || 'Just Curious',
    description: '',
    zipcode: this.props.user.zipCode || '',
    attachments: [],
    showEmojis: false,
    selectedOption: this.props.urgency,
    requestMessage: '',
    showMessage: false,
    success: false,
    modal: false,
  };

  constructor(props) {
    super(props);
    this.dateElRef = React.createRef();
    this.descriptionElRef = React.createRef();
  }

  componentDidMount = () => {
    socketServices.listenConnectRequestSent(this);
  };

  componentWillUnmount = () => {
    socketServices.removeConnectRequestSentListeners();
  };

  // update date state
  changeDateHandler = (date) => {
    this.setState({
      date,
      requestMessage: '',
      showMessage: false,
      success: true,
    });
  };

  // update urgency
  changeUrgencyHandler = (e) => {
    this.setState({
      selectedOption: e.target.value,
      requestMessage: '',
      showMessage: false,
      success: true,
    });
    // this.props.updateUrgency(e)
  };

  changeUrgencyDropdownHandler = (e) => {
    this.setState({
      selectedOption: e.value,
      requestMessage: '',
      showMessage: false,
      success: true,
    });
  };

  descriptionHandler = (e) => {
    this.setState({
      description: e.target.value,
      requestMessage: '',
      showMessage: false,
      success: true,
    });
  };

  zipInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      requestMessage: '',
      showMessage: false,
      success: true,
    });
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState({
      description: this.state.description + emoji,
      requestMessage: '',
      showMessage: false,
    });
  };

  showEmojis = () => {
    this.setState(
      {
        showEmojis: true,
      },
      () => document.addEventListener('click', this.closeMenu),
    );
  };

  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false,
        },
        () => document.removeEventListener('click', this.closeMenu),
      );
    }
  };

  // upload files
  attachmentUploadHandler = (e) => {
    const { attachments } = this.state;
    const reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      file = {
        ...file,
        path: reader.result,
      };
      file && attachments.push(file);
      this.setState({
        attachments,
        requestMessage: '',
        showMessage: false,
        success: true,
      });
    };
    file && reader.readAsDataURL(file);
  };

  removeImage = (i) => {
    const { attachments } = this.state;
    attachments.splice(i, 1);
    this.setState({
      attachments,
      requestMessage: '',
      showMessage: false,
      success: true,
    });
  };

  sendConnectionRequest = (e) => {
    e.preventDefault();
    const { businessReq, user } = this.props;
    const startDate = getDate(this.state.date, 'MMDDYYYY');
    const description = this.descriptionElRef.current.value;
    const { selectedOption: urgency, attachments, zipcode } = this.state;
    if (Date.now() < this.state.date) {
      const request = {
        consumerId: user._id,
        businessId: businessReq._id,
        users: {
          [user._id]: {
            _id: user._id,
            profilePic: user.profilePic,
            name: `${user.firstName} ${user.lastName}`,
            username: user.username,
            email: user.email,
            online: user.online,
          },
          [businessReq._id]: {
            _id: businessReq._id,
            profilePic: businessReq.profilePic,
            name: businessReq.name,
            email: businessReq.email,
            username: businessReq.username,
            online: businessReq.online,
          },
        },
        startDate,
        urgency,
        attachments,
        description,
        zipcode,
        type: 'B2C',
      };
      this.setState(
        {
          showMessage: true,
          success: true,
          requestMessage: 'Sending...',
        },
        () => {
          this.props.sendRequest(request);
        },
      );
    } else {
      this.setState({
        success: false,
        requestMessage: 'The start date you chose has already passed.',
      });
    }
  };

  render() {
    const { date, businessReq, businessName } = this.props;
    const placeholderMessage =
      `Don't be shy! Tell ${businessName} a little about yourself and your project.`;
    const { requestMessage } = this.state;

    return (
      <div className="connect-modal">
        {this.state.modal ? (
          <SuccessModal closeModal={this.props.closeModalHandler} />
        ) : (
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              id="close-modal-button"
              style={{
                marginRight: '1em',
                marginTop: '1em',
                cursor: 'pointer',
              }}
              onClick={(e) => this.props.closeModalHandler(e)}
            />
            <form id="connect-modal-form" onSubmit={this.sendConnectionRequest}>
              <section className="connect-modal-left">
                <div className="connect-request-business-photo">
                  <img
                    id="profile-pic"
                    src={businessReq.profilePic}
                    alt={businessReq.name}
                  />
                </div>
                <h4>{businessReq.name}</h4>
                <div className="connect-zip">
                  <p>SERVICE AREA ZIP</p>
                  <input
                    type="text"
                    name="zipcode"
                    row="5"
                    value={this.state.zipcode}
                    onChange={this.zipInput}
                  />
                </div>

                <label htmlFor="start-date">START DATE</label>
                <DayPickerInput
                  onDayChange={this.changeDateHandler}
                  formatDate={formatDate}
                  format="MM-dd-yyyy"
                  placeholder={date}
                  dayPickerProps={{
                    disabledDays: {
                      before: new Date(),
                    },
                  }}
                  style={{
                    width: '100%',
                    marginBottom: '5%',
                  }}
                />
                <label htmlFor="form-check">URGENCY</label>
                <Dropdown
                  className="connect-mobile-urgency-dropdown"
                  value={this.state.selectedOption}
                  fluid
                  selection
                  options={urgencyOptions}
                  onChange={(e, d) => this.changeUrgencyDropdownHandler(d)}
                />

                <div id="form-check">
                  <label className="radio-option-container">
                    <p>Just curious</p>
                    <input
                      type="radio"
                      id="just-curious"
                      value="Just Curious"
                      className="connect-modal-radio-button"
                      checked={this.state.selectedOption === 'Just Curious'}
                      onChange={this.changeUrgencyHandler}
                    />
                    <span className="checkmark-connect"></span>
                  </label>

                  <label className="radio-option-container">
                    <p>Ready to go</p>
                    <input
                      type="radio"
                      id="ready-to-go"
                      value="Ready to go"
                      className="connect-modal-radio-button"
                      checked={this.state.selectedOption === 'Ready to go'}
                      onChange={this.changeUrgencyHandler}
                    />
                    <span className="checkmark-connect"></span>
                  </label>

                  <label className="radio-option-container">
                    <p>Emergency</p>
                    <input
                      type="radio"
                      id="emergency"
                      value="Emergency"
                      className="connect-modal-radio-button"
                      checked={this.state.selectedOption === 'Emergency'}
                      onChange={this.changeUrgencyHandler}
                    />
                    <span className="checkmark-connect"></span>
                  </label>
                </div>
              </section>
              <section className="connect-modal-right">
                <h1>Send a connect request</h1>
                <label htmlFor="story">Tell us your story: </label>
                <textarea
                  id="story"
                  ref={this.descriptionElRef}
                  row="5"
                  placeholder={placeholderMessage}
                  value={this.state.description}
                  onChange={(e) => this.descriptionHandler(e)}
                ></textarea>
                <section className="attachment-container">
                  <label
                    className="delete-upload-buttons"
                    htmlFor="attachment-upload"
                  >
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      id="attachment-icon"
                      style={{ marginRight: '1em' }}
                    />
                  </label>
                  <input
                    id="attachment-upload"
                    type="file"
                    onChange={(e) => this.attachmentUploadHandler(e)}
                  ></input>
                  {this.state.showEmojis ? (
                    <div>
                      <span
                        style={styles.emojiPicker}
                        ref={(el) => (this.emojiPicker = el)}
                      >
                        <Picker onSelect={this.addEmoji} emojiTooltip={true} />
                      </span>
                      <span
                        onClick={this.showEmojis}
                        onKeyDown={this.showEmojis}
                        style={{ cursor: 'pointer' }}
                      >
                        {String.fromCodePoint(0x1f60a)}
                      </span>
                    </div>
                  ) : (
                    <span
                      onClick={this.showEmojis}
                      onKeyDown={this.showEmojis}
                      style={{ cursor: 'pointer' }}
                    >
                      {String.fromCodePoint(0x1f60a)}
                    </span>
                  )}
                </section>
                <div className="connect-modal-attachments">
                  {this.state.attachments.map((file, i) => {
                    const image = <img src={file.path} alt={`attachment_${i}`} />;
                    return (
                      <div style={{ display: 'flex', marginRight: '4%' }}>
                        <Attachment image={image} />
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="sm"
                          id="add-business"
                          style={{ cursor: 'pointer', marginLeft: '2%' }}
                          onClick={() => this.removeImage(i)}
                        />
                      </div>
                    );
                  })}
                </div>
                <button id="send-connect-button">Send Connect Request</button>
                {requestMessage.length > 0 &&
                  (!this.state.success ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '1.1em',
                        marginTop: '2%',
                      }}
                    >
                      {requestMessage}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: '#00BE6E',
                        fontSize: '1.25em',
                        marginTop: '2%',
                      }}
                    >
                      {requestMessage}
                    </div>
                  ))}
              </section>
            </form>
          </div>
        )}
      </div>
    );
  }
}
