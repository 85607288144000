import { api, GET, POST, PUT } from './apiRequests';
import { getHeaderOptions } from '../util';

export const getBusinessById = (id) =>
  api({
    path: `businesses?id=${id}`,
    method: GET,
  });

export const getBusinessByUsername = (username) =>
  api({
    path: `businesses/${username}`,
    method: GET,
  });

export const createBusiness = (newBusiness, headerOptions) =>
  api({
    path: 'businesses',
    method: POST,
    body: { ...newBusiness },
    headers: { ...headerOptions },
  });

export const editBusinessProfile = (id, updates, nameChanged, websiteChanged, headerOptions) =>
  api({
    path: 'businesses',
    method: PUT,
    body: { id, updates, nameChanged, websiteChanged },
    headers: { ...headerOptions },
  });

export const uploadBusinessPhoto = (id, photoUpdate, headerOptions) =>
  api({
    path: `businesses/photo/${id}`,
    method: PUT,
    body: photoUpdate,
    headers: { ...headerOptions },
  });

export const searchBusinessByName = async(name) => {
  const headerOptions = await getHeaderOptions();
  return api({
    path: 'businesses/searchDt',
    method: POST,
    body: { name },
    headers: { ...headerOptions },
  });
};

export const searchBusinessByCatAndZip = (category, zip) =>
  api({
    path: `businesses/search?category=${category}&zipCode=${zip}`,
    method: GET,
  });

export const removeUserFromRevokedBusinessDT = async(userId, revokedId) => {
  const headerOptions = await getHeaderOptions();
  return api({
    path: 'businesses/revoke/dreamTeam',
    method: PUT,
    body: {
      userId,
      revokedId,
    },
    headers: { ...headerOptions },
  });
};
