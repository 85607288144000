import React from 'react';

const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
  padding: 50,
  zIndex: 1,
  cursor: 'pointer',
};

const modalStyle = {
  position: 'fixed',
  top: '20vh',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  borderRadius: 5,
  width: '30%',
  height: '35%',
  margin: '0 auto',
  padding: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '2em',
  zIndex: 2,
  color: 'black',
};

const footerStyle = {
  position: 'absolute',
  bottom: 20,
};

const buttonStyle = {
  padding: '10px 25px',
  backgroundColor: '#00BF6F',
  color: '#fff',
  border: 'none',
  borderRadius: '3px',
  marginTop: '1em',
  fontSize: '16px',
};

const ComingSoonModal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div>
      <div
        onClick={onClose}
        onKeyDown={onClose}
        style={backdropStyle}
      ></div>
      <section style={modalStyle}>
        {children}
        <section style={footerStyle}>
          <button
            style={buttonStyle}
            onClick={onClose}
          >
            Close
          </button>
        </section>
      </section>
    </div>
  );
};

export default ComingSoonModal;
