import React from 'react';

const EmptyResult = ({ text }) => (
  <div style={{ marginTop: '5%' }}>
    <section
      style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
    >
      {text}
    </section>
    <hr
      style={{
        borderWidth: '0.05em',
        marginTop: '1em',
        marginBottom: '1em',
        height: '1px',
        background: '#DCDCDC',
      }}
    />
  </div>
);

export default EmptyResult;
