/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { NavLink, Route, withRouter } from 'react-router-dom';

// components
import {
  BusinessDetails,
  DreamTeam,
  Verification,
} from './EditBusinessSubcomponents';

// styled components
import { Container, Row, BoldText } from './Styles';
import BreakLine from '../BusinessProfile/subcomponents/BreakLine';
import './Styles/editBusiness.css';
class EditBusinessProfile extends Component {
  state = {
    editing: this.props.category || 'Business Details',
    business: '',
    completePct: '25%',
  };

  componentDidMount = () => {
    // calculate profile completeness percentage
    window.scrollTo(0, 0);
  };

  viewProfile = () => {
    const { history, business } = this.props;
    history.push({
      pathname: `/business/${business.username}`,
      state: {
        business,
      },
    });
  };

  // for any input fields
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // switching between edit and view profile
  viewEditSwitch = () => {
    const { selected } = this.state;
    this.setState({
      selected: selected === 'edit' ? 'view' : 'edit',
    });
  };

  renderEditCategoryView = () => {
    const { editing, completePct } = this.state;
    switch (editing) {
      case 'Business Details':
        return <BusinessDetails progress={completePct} />;
      case 'Dream Team':
        return <DreamTeam progress={completePct} />;
      case 'Verification':
        return <Verification progress={completePct} />;
      default:
    }
  };

  // rendering based on which category is selected,
  // selected cat gets green text, others get grey with click handler
  editCategoriesRender = () => {
    const categories = [
      { name: 'Business Details', path: '/edit_business' },
      { name: 'Verification', path: '/business_verification' },
      { name: 'Dream Team', path: '/my_dream_team' },
    ]; // using array so can map the categories
    return (
      <Container style={{ marginTop: '5%' }}>
        {categories.map(
          (category) => (
            // return category.name === editing ?
            <NavLink
              to={category.path}
              key={category.name}
              className="sidebar-nav-link"
              activeClassName="sidebar-nav-active"
              activeStyle={{ color: '#00BE6E' }}
            >
              {category.name}
            </NavLink>
          ),
          // : <NavLink to={category.path} key={category.name}
          //    className="sidebar-nav-link" activeClassName="sidebar-nav-active">
          //   <RegText style={{ color: '#999', marginBottom: '4%' }}>{category.name}</RegText>
          // </NavLink>
        )}
      </Container>
    );
  };

  changeEditCategory = (event) => {
    this.setState({
      editing: event.target.innerHTML,
    });
  };

  render() {
    const { editCategoriesRender } = this;
    const { completePct } = this.state;
    return (
      <div>
        <Row style={{ marginTop: '40px' }}>
          <Container className="sidebar desktop">
            <Container>
              <BoldText>Edit my business</BoldText>
              {/* will make this next line link to user business profile */}
              <div
                className="sidebar-nav-link"
                activeClassName="sidebar-nav-active"
                activeStyle={{ color: '#00BE6E' }}
                style={{ marginBottom: '10%', cursor: 'pointer' }}
                onClick={this.viewProfile}
                onKeyDown={this.viewProfile}
              >
                View business
              </div>
              {/* <BreakLine color='black' width='30%' margin='28%' height='2px' /> */}
            </Container>
            <BreakLine color="black" width="10%" margin="5em" height="2px" />
            {editCategoriesRender()}
          </Container>
          <Container className="edit-business-form">
            <Route
              path="/edit_business"
              render={() => <BusinessDetails {...this.props} />}
            />
            <Route
              path="/business_verification"
              render={() => (
                <Verification {...this.props} progress={completePct} />
              )}
            />
            <Route
              path="/my_dream_team"
              render={() => (
                <DreamTeam {...this.props} progress={completePct} />
              )}
            />
          </Container>
        </Row>
        <section className="mobile" id="mobile-options-edit">
          <span className="active link">
            <NavLink to="/edit_business">Details</NavLink>
          </span>
          <span className="link">
            <NavLink to="/business_verification">Verification</NavLink>{' '}
          </span>
          <span className="link">
            <NavLink to="/my_dream_team">Dream Team</NavLink>{' '}
          </span>
          <span
            className="link"
            onClick={this.viewProfile}
            onKeyDown={this.viewProfile}
          >
            View profile
          </span>
        </section>
      </div>
    );
  }
}

export default withRouter(EditBusinessProfile);
