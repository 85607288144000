import React from 'react';
import Tag from './Tag';

const SortAndTags = ({ length, tags, deleteTagHandler, clearSearch }) => (
  <div className="sort-tag-main-container">
    <section className="tag-container">
      <h1>{length} Listings Found</h1>
      <div className="tags-flex">
        {tags.map((tag, index) => (
          <Tag key={index} tag={tag} deleteTagHandler={deleteTagHandler} />
        ))}
      </div>
    </section>
    <section className="sort-container">
      <button id="clear-search" onClick={clearSearch}>
        Clear Search
      </button>
      <select>
        <option value="proximity">Sort by Proximity(Coming Soon)</option>
        <option value="availability">Sort by Availability(Coming Soon)</option>
        <option value="Community Trust Match">
          Sort by Community Trust Match(Coming Soon)
        </option>
      </select>
    </section>
  </div>
);

export default SortAndTags;
