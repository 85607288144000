import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  LOGIN_STARTED,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_DONE,
  UPDATE_USER,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_FAILED,
  VERIFY_USER_SUCCESS,
  UPDATE_USER_FAVORITES,
  UPDATE_USER_FAVORITES_SUCCESS,
  UPDATE_USER_FAVORITES_FAILED,
  UPDATE_USER_NOTIFICATIONS_CHECK,
  UPDATE_USER_NOTIFICATIONS_CHECK_SUCCESS,
  UPDATE_USER_NOTIFICATIONS_CHECK_FAILED,
  SET_USER,
  UPDATE_USER_PHOTO,
  UPDATE_USER_PHOTO_SUCCESS,
  UPDATE_USER_PHOTO_FAILED,
  UPDATE_REDEEM_COUNT,
  UPDATE_REDEEM_COUNT_SUCCESS,
  UPDATE_REDEEM_COUNT_FAILED,
} from '../actions/types';

import { setRefreshToken, setAccessToken, removeRefreshToken } from '../util';

const user = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_USER_SUCCESS:
      action.payload?.accessToken &&
        setAccessToken(action.payload.accessToken);
      action.payload?.refreshToken &&
        setRefreshToken(action.payload.refreshToken);
      return {
        ...action.payload.user,
        loggedOut: false,
        fetching: false,
      };
    case FETCH_USER_FAILED:
      return {
        ...state,
        fetchMessage: action.payload.message,
        fetching: false,
        loggedOut: true,
      };
    case LOGIN_STARTED:
      return {
        ...state,
        loginMessage: 'Checking...',
        checking: true,
        fetching: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loginMessage: action.payload.message,
        fetching: false,
        checking: false,
      };
    case LOGIN_SUCCESS:
      setRefreshToken(action.payload.refreshToken);
      setAccessToken(action.payload.accessToken);
      return {
        ...state,
        ...action.payload.user,
        loggedOut: false,
        fetching: false,
        checking: false,
      };
    case LOGOUT:
      return {
        ...state,
        killing: true,
      };
    case LOGOUT_DONE:
      removeRefreshToken();
      setAccessToken('');
      return {
        loggedOut: true,
        favorites: {},
      };
    case UPDATE_USER:
      return {
        ...state,
        message: 'Saving, please wait...',
        success: true,
        updatingUser: true,
      };
    case UPDATE_FAILED:
      return {
        ...state,
        message: action.payload.message,
        updatingUser: false,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        success: true,
        message: action.payload.message,
        updatingUser: false,
      };
    case CHANGE_PASSWORD:
      if (state.changePasswordSuccess) {
        delete state.changePasswordSuccess;
      }

      return {
        ...state,
        checkingPassword: true,
        changePasswordMessage: 'Updating...',
      };
    case CHANGE_PASSWORD_SUCCESS:
      action.payload?.refreshToken &&
        setRefreshToken(action.payload.refreshToken);

      return {
        ...state,
        changePasswordSuccess: true,
        changePasswordMessage: action.payload.message,
        checkingPassword: false,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordMessage: action.payload.message,
        changePasswordSuccess: false,
        checkingPassword: false,
      };
    case VERIFY_USER:
      return {
        ...state,
        verifyingUser: true,
      };
    case VERIFY_USER_SUCCESS:
      setRefreshToken(action.payload.refreshToken);
      setAccessToken(action.payload.accessToken);
      return {
        ...state,
        ...action.payload.user,
        verified: true,
        verifyingUser: false,
        fetching: false,
      };
    case VERIFY_USER_FAILED:
      return {
        ...state,
        verifyingUser: false,
        verifyingErrorMessage: action.payload.message,
      };
    case UPDATE_USER_FAVORITES:
      return {
        ...state,
        favoriting: true,
      };
    case UPDATE_USER_FAVORITES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        favoriting: false,
      };
    case UPDATE_USER_FAVORITES_FAILED:
      return {
        ...state,
        message: action.payload.message,
        favoriting: false,
      };
    case UPDATE_USER_NOTIFICATIONS_CHECK:
      return {
        ...state,
        updatingLastCheck: true,
      };
    case UPDATE_USER_NOTIFICATIONS_CHECK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updatingLastCheck: false,
      };
    case UPDATE_USER_NOTIFICATIONS_CHECK_FAILED:
      return {
        ...state,
        errorMessage: 'Error updating last notifications check.',
        updatingLastCheck: false,
      };
    case UPDATE_REDEEM_COUNT:
      return {
        ...state,
        redeeming: true,
      };
    case UPDATE_REDEEM_COUNT_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        redeeming: false,
        redeemError: false,
      };
    case UPDATE_REDEEM_COUNT_FAILED:
      return {
        ...state,
        redeeming: false,
        redeemError: true,
      };
    case SET_USER:
      return {
        ...state,
        ...action.payload.user,
      };
    case UPDATE_USER_PHOTO:
      return {
        ...state,
        updatingPhoto: true,
      };
    case UPDATE_USER_PHOTO_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        updatingPhoto: false,
      };
    case UPDATE_USER_PHOTO_FAILED:
      return {
        ...state,
        updatingPhoto: false,
        message: action.payload.err.message,
      };
    default:
      return state;
  }
};

export default user;
