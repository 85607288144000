export default [
  {
    name: 'Jacob Metzinger',
    position: 'Lead Developer',
    src: 'https://res.cloudinary.com/rhiz/image/upload/v1566327340/Team%20Pics/jacob_fczppl.jpg',
    alt: 'Jacob',
    linkedin: 'https://www.linkedin.com/in/jacobmetzinger/',
  },

  // {
  //   name: "Geoff Knoth",
  //   position: "Co-Founder/Marketing",
  //   src: "https://res.cloudinary.com/rhiz/image/upload/v1566327340/Team%20Pics/geoff_nnoelo.jpg",
  //   alt: "Geoff",
  //   linkedin: "https://www.linkedin.com/in/geoff-knoth/"
  // },
  // {
  //   name: "Dennis Gonzalez",
  //   position: "Co-Founder/Operations",
  //   src: "https://res.cloudinary.com/rhiz/image/upload/v1566327340/Team%20Pics/dennis_aw7tro.jpg",
  //   alt: "Dennis",
  //   linkedin: "https://www.linkedin.com/in/dennis-gonzalez-rhiz/"
  // },
  {
    name: 'Michelle Pederson',
    position: 'UI/UX Lead Architect',
    src: 'https://res.cloudinary.com/rhiz/image/upload/v1566327340/Team%20Pics/michelle_r5hldt.jpg',
    alt: 'Michelle',
    linkedin: 'https://www.linkedin.com/in/michellepederson/',
  },
  {
    name: 'Stuart Campbell',
    position: 'Co-Founder/CEO',
    src: 'https://res.cloudinary.com/rhiz/image/upload/v1566327340/Team%20Pics/stu_jgcj7w.jpg',
    alt: 'Stuart',
    linkedin: 'https://www.linkedin.com/in/rhizaboveit/',
    twitter: 'https://twitter.com/RhizAboveIt',
  },
  {
    name: 'David Benavidez',
    position: 'Front-End Developer',
    src: 'https://res.cloudinary.com/rhiz/image/upload/v1566836267/Team%20Pics/david_ycdy8p.jpg',
    alt: 'David',
    linkedin: 'https://www.linkedin.com/in/david-benavidez/',
  },
  {
    name: 'Kanek Perez',
    position: 'Front-End Developer',
    src: 'https://res.cloudinary.com/ddcumb6ki/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,b_rgb:262c35/v1612056277/IMG_0639_Edited2_wzlzec.jpg',
    alt: 'David',
    linkedin: 'https://www.linkedin.com/in/kanek-perez-a0b569157/',
  },
];
