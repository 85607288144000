export const subCatSuggestions = {
  Landscaping: {
    values: {
      Permaculture: true,
      'Native Plants': true,
      Lawncare: true,
      Design: true,
      Maintenance: true,
      'Water Smart': true,
    },
    dropdown: [
      {
        key: 'Permaculture',
        text: 'Permaculture',
        value: 'Permaculture',
      },
      {
        key: 'Native Plants',
        text: 'Native Plants',
        value: 'Native Plants',
      },
      {
        key: 'Lawncare',
        text: 'Lawncare',
        value: 'Lawncare',
      },
      {
        key: 'Design',
        text: 'Design',
        value: 'Design',
      },
      {
        key: 'Maintenance',
        text: 'Maintenance',
        value: 'Maintenance',
      },
      {
        key: 'Water Smart',
        text: 'Water Smart',
        value: 'Water Smart',
      },
    ],
  },
  Construction: {
    values: { Design: true, Decks: true, Pools: true },
    dropdown: [
      {
        text: 'Design',
        value: 'Design',
      },
      {
        text: 'Decks',
        value: 'Decks',
      },
      {
        text: 'Pools',
        value: 'Pools',
      },
    ],
  },
  Solar: {
    values: { Design: true, Installation: true, Maintenance: true },
    dropdown: [
      {
        text: 'Design',
        value: 'Design',
      },
      {
        text: 'Installation',
        value: 'Installation',
      },
      {
        text: 'Maintenance',
        value: 'Maintenance',
      },
    ],
  },
  Plumbing: {
    values: { 'Eco-Friendly': true, 'Reverse Osmosis Systems': true },
    dropdown: [
      {
        text: 'Eco-Friendly',
        value: 'Eco-Friendly',
      },
      {
        text: 'Reverse Osmosis Systems',
        value: 'Reverse Osmosis Systems',
      },
    ],
  },
  HVAC: {
    values: { 'Smart Homes': true, Solar: true },
    dropdown: [
      {
        text: 'Smart Homes',
        value: 'Smart Homes',
      },
      {
        text: 'Solar',
        value: 'Solar',
      },
    ],
  },
  'Home Tech': {
    values: { 'Smart Home': true, 'Audio/Video': true, Security: true },
    dropdown: [
      {
        text: 'Smart Home',
        value: 'Smart Home',
      },
      {
        text: 'Audio/Video',
        value: 'Audio/Video',
      },
      {
        text: 'Security',
        value: 'Security',
      },
    ],
  },
  Painting: {
    values: { 'Low VOC': true },
    dropdown: [
      {
        text: 'Low VOC',
        value: 'Low VOC',
      },
    ],
  },
  Masonry: {
    values: {
      Tile: true,
      Bathrooms: true,
      Kitchens: true,
      Stone: true,
      Brick: true,
    },
    dropdown: [
      {
        text: 'Tile',
        value: 'Tile',
      },
      {
        text: 'Bathrooms',
        value: 'Bathrooms',
      },
      {
        text: 'Kitchens',
        value: 'Kitchens',
      },
      {
        text: 'Stone',
        value: 'Stone',
      },
      {
        text: 'Brick',
        value: 'Brick',
      },
    ],
  },
  'Interior Design': {
    values: { Kitchens: true, Contracting: true },
    dropdown: [
      {
        text: 'Kitchens',
        value: 'Kitchens',
      },
      {
        text: 'Contracting',
        value: 'Contracting',
      },
    ],
  },
  Carpentry: {
    values: { Design: true, Fencing: true, Decks: true },
    dropdown: [
      {
        text: 'Design',
        value: 'Design',
      },
      {
        text: 'Fencing',
        value: 'Fencing',
      },
      {
        text: 'Decks',
        value: 'Decks',
      },
    ],
  },
  Electrical: {
    values: { 'Smart Homes': true },
    dropdown: [
      {
        text: 'Smart Homes',
        value: 'Smart Homes',
      },
    ],
  },
  Architecture: {
    values: { Design: true, Contracting: true, Permitting: true },
    dropdown: [
      {
        text: 'Design',
        value: 'Design',
      },
      {
        text: 'Contracting',
        value: 'Contracting',
      },
      {
        text: 'Permitting',
        value: 'Permitting',
      },
    ],
  },
  Cleaning: {
    values: { 'Eco-Friendly': true },
    dropdown: [
      {
        text: 'Eco-Friendly',
        value: 'Eco-Friendly',
      },
    ],
  },
  Pools: {
    values: { Design: true, Construction: true, Maintenance: true },
    dropdown: [
      {
        text: 'Design',
        value: 'Design',
      },
      {
        text: 'Construction',
        value: 'Construction',
      },
      {
        text: 'Maintenance',
        value: 'Maintenance',
      },
    ],
  },
  'Garden Centers': {
    values: { 'Native Plantes': true, Edibles: true },
    dropdown: [
      {
        text: 'Native Plants',
        value: 'Native Plants',
      },
      {
        text: 'Edibles',
        value: 'Edibles',
      },
    ],
  },
  Roofing: {
    values: { Solar: true, Metal: true, Asphalt: true, 'Green Roofs': true },
    dropdown: [
      {
        text: 'Solar',
        value: 'Solar',
      },
      {
        text: 'Metal',
        value: 'Metal',
      },
      {
        text: 'Ashphalt',
        value: 'Ashphalt',
      },
      {
        text: 'Green Roofs',
        value: 'Green Roofs',
      },
    ],
  },
  'Healthcare Services': {
    values: {
      'Physical Therapy': true,
      'Art Therapy': true,
      'Mental Health': true,
      Eldercare: true,
      'Midwifery/Doula': true,
      'Personal Coaching': true,
    },
    dropdown: [
      {
        text: 'Physical Therapy',
        value: 'Physical Therapy',
      },
      {
        text: 'Art Therapy',
        value: 'Art Therapy',
      },
      {
        text: 'Mental Health',
        value: 'Mental Health',
      },
      {
        text: 'Eldercare',
        value: 'Eldercare',
      },
      {
        text: 'Midwifery/Doula',
        value: 'Midwifery/Doula',
      },
      {
        text: 'Personal Coaching',
        value: 'Personal Coaching',
      },
    ],
  },
  'Artistic Services': {
    values: {
      'Art Instruction': true,
      'Design Instruction': true,
      'Artist for Hire': true,
      'Performance Art': true,
    },
    dropdown: [
      {
        text: 'Art Instruction',
        value: 'Art Instruction',
      },
      {
        text: 'Design Instruction',
        value: 'Design Instruction',
      },
      {
        text: 'Artist for Hire',
        value: 'Artist for Hire',
      },
      {
        text: 'Performance Art',
        value: 'Performance Art',
      },
    ],
  },
  'Fitness/Sports Services': {
    values: {
      'Fitness Instructor': true,
      'Yoga Instructor': true,
      'New-Age Instructor': true,
      'Sports Performance Instructor': true,
    },
    dropdown: [
      {
        text: 'Fitness Instructor',
        value: 'Fitness Instructor',
      },
      {
        text: 'Yoga Instructor',
        value: 'Yoga Instructor',
      },
      {
        text: 'New-Age Instructor',
        value: 'New-Age Instructor',
      },
      {
        text: 'Sports Performance Instructor',
        value: 'Sports Performance Instructor',
      },
    ],
  },
  'Homecare Services': {
    values: {
      'Cleaning Services': true,
      'Damage Repair/Restoration': true,
      'Contaminant Remediation': true,
    },
    dropdown: [
      {
        text: 'Cleaning Services',
        value: 'Cleaning Services',
      },
      {
        text: 'Damage Repair/Restoration',
        value: 'Damage Repair/Restoration',
      },
      {
        text: 'Contaminant Remediation',
        value: 'Contaminant Remediation',
      },
    ],
  },
  'General Contracting': {
    values: {
      Construction: true,
      Remodeling: true,
      'Home Automation': true,
      Commercial: true,
    },
    dropdown: [
      {
        text: 'Construction',
        value: 'Construction',
      },
      {
        text: 'Remodeling',
        value: 'Remodeling',
      },
      {
        text: 'Home Automation',
        value: 'Home Automation',
      },
      {
        text: 'Commercial',
        value: 'Commercial',
      },
    ],
  },
  Restaurant: {
    values: {
      Dinner: true,
      'Food Truck': true,
      Takeout: true,
      Delivery: true,
      Gourmet: true,
      'Farm To Table': true,
      Homestyle: true,
      'Soul Food': true,
      Barbeque: true,
      Japanese: true,
      Fusion: true,
      Chinese: true,
      Mexican: true,
      Indian: true,
      Authentic: true,
      French: true,
      Thai: true,
      Greek: true,
      Italian: true,
      German: true,
      American: true,
      Coffee: true,
    },
    dropdown: [
      {
        text: 'Diner',
        value: 'Diner',
      },
      {
        text: 'Food Truck',
        value: 'Food Truck',
      },
      {
        text: 'Takeout',
        value: 'Takeout',
      },
      {
        text: 'Delivery',
        value: 'Delivery',
      },
      {
        text: 'Gourmet',
        value: 'Gourmet',
      },
      {
        text: 'Farm To Table',
        value: 'Farm To Table',
      },
      {
        text: 'Homestyle',
        value: 'Homestyle',
      },
      {
        text: 'Soul Food',
        value: 'Soul Food',
      },
      {
        text: 'Barbeque',
        value: 'Barbeque',
      },
      {
        text: 'Japanese',
        value: 'Japanese',
      },
      {
        text: 'Fusion',
        value: 'Fusion',
      },
      {
        text: 'Chinese',
        value: 'Chinese',
      },
      {
        text: 'Mexican',
        value: 'Mexican',
      },
      {
        text: 'Indian',
        value: 'Indian',
      },
      {
        text: 'Authentic',
        value: 'Authentic',
      },
      {
        text: 'French',
        value: 'French',
      },
      {
        text: 'Thai',
        value: 'Thai',
      },
      {
        text: 'Greek',
        value: 'Greek',
      },
      {
        text: 'Italian',
        value: 'Italian',
      },
      {
        text: 'German',
        value: 'German',
      },
      {
        text: 'American',
        value: 'American',
      },
      {
        text: 'Coffee',
        value: 'Coffee',
      },
    ],
  },
  Retail: {
    values: {
      Cannabis: true,
      Antiques: true,
      Collectibles: true,
      Toys: true,
      Souvenirs: true,
      Clothing: true,
      Outdoor: true,
      Automotive: true,
      Hardware: true,
      Jewlery: true,
      'Wine/Beer': true,
      Alcohol: true,
      Coffee: true,
      Games: true,
      Sports: true,
      'Home Décor': true,
      Electrical: true,
      Plumbing: true,
      Technology: true,
      Books: true,
    },
    dropdown: [
      {
        text: 'Cannabis',
        value: 'Cannabis',
      },
      {
        text: 'Antiques',
        value: 'Antiques',
      },
      {
        text: 'Collectibles',
        value: 'Collectibles',
      },
      {
        text: 'Toys',
        value: 'Toys',
      },
      {
        text: 'Souvenirs',
        value: 'Souvenirs',
      },
      {
        text: 'Clothing',
        value: 'Clothing',
      },
      {
        text: 'Outdoor',
        value: 'Outdoor',
      },
      {
        text: 'Automotive',
        value: 'Automotive',
      },
      {
        text: 'Hardware',
        value: 'Hardware',
      },
      {
        text: 'Jewlery',
        value: 'Jewlery',
      },
      {
        text: 'Wine/Beer',
        value: 'Wine/Beer',
      },
      {
        text: 'Alcohol',
        value: 'Alcohol',
      },
      {
        text: 'Coffee',
        value: 'Coffee',
      },
      {
        text: 'Games',
        value: 'Games',
      },
      {
        text: 'Sports',
        value: 'Sports',
      },
      {
        text: 'Home Décor',
        value: 'Home Décor',
      },
      {
        text: 'Electrical',
        value: 'Electrical',
      },
      {
        text: 'Plumbing',
        value: 'Plubming',
      },
      {
        text: 'Technology',
        value: 'Technology',
      },
      {
        text: 'Books',
        value: 'Books',
      },
    ],
  },
  Services: {
    values: {
      Insurance: true,
      Mortgages: true,
      'Real Estate': true,
      Consultant: true,
      Architecture: true,
      Dietician: true,
    },
    dropdown: [
      {
        text: 'Insurance',
        value: 'Insurance',
      },
      {
        text: 'Mortgages',
        value: 'Mortgages',
      },
      {
        text: 'Real Estate',
        value: 'Real Estate',
      },
      {
        text: 'Consultant',
        value: 'Consultant',
      },
      {
        text: 'Architecture',
        value: 'Architecture',
      },
      {
        text: 'Dietician',
        value: 'Dietician',
      },
    ],
  },
  handyperson: {
    values: {
      installations: true,
      carpentry: true,
      homeMaintenance: true,
      basicElectrical: true,
    },
    dropdown: [
      {
        text: 'Installations',
        value: 'installations',
        key: 'installations',
      },
      {
        text: 'Carpentry',
        value: 'carpentry',
        key: 'carpentry',
      },
      {
        text: 'Home Maintenance',
        value: 'homeMaintenance',
        key: 'homeMaintenance',
      },
      {
        text: 'Basic Electrical',
        value: 'basicElectrical',
        key: 'basicElectrical',
      },
    ],
  },
  flooring: {
    value: {
      design: true,
      installation: true,
    },
    dropdown: [
      {
        text: 'Design',
        value: 'design',
        key: 'design',
      },
      {
        text: 'Installation',
        value: 'installation',
        key: 'installation',
      },
    ],
  },
  trainer: {
    values: {
      basketball: true,
      baseball: true,
      softball: true,
      tennis: true,
      volleyball: true,
      golf: true,
      trackAndField: true,
      wrestling: true,
      swimming: true,
      fitness: true,
      boxing: true,
      mma: true,
      karate: true,
      taekwondo: true,
      judo: true,
      jiuJitsu: true,
      yoga: true,
      pilates: true,
      cycling: true,
      rowing: true,
      sailing: true,
      education: true,
      barre: true,
      dance: true,
      ballet: true,
      zumba: true,
      tapDance: true,
    },
    dropdown: [
      {
        text: 'Basketball',
        value: 'basketball',
        key: 'basketball',
      },
      {
        text: 'Baseball',
        value: 'baseball',
        key: 'baseball',
      },
      {
        text: 'Softball',
        value: 'softball',
        key: 'softball',
      },
      {
        text: 'Tennis',
        value: 'tennis',
        key: 'tennis',
      },
      {
        text: 'Volleyball',
        value: 'volleyball',
        key: 'volleyball',
      },
      {
        text: 'Golf',
        value: 'golf',
        key: 'golf',
      },
      {
        text: 'Track and Field',
        value: 'trackAndField',
        key: 'trackAndField',
      },
      {
        text: 'Wrestling',
        value: 'wrestling',
        key: 'wrestling',
      },
      {
        text: 'Swimming',
        value: 'swimming',
        key: 'swimming',
      },
      {
        text: 'Fitness',
        value: 'fitness',
        key: 'fitness',
      },
      {
        text: 'Boxing',
        value: 'boxing',
        key: 'boxing',
      },
      {
        text: 'MMA',
        value: 'mma',
        key: 'mma',
      },
      {
        text: 'Karate',
        value: 'karate',
        key: 'karate',
      },
      {
        text: 'Taekwondo',
        value: 'taekwondo',
        key: 'taekwondo',
      },
      {
        text: 'Judo',
        value: 'judo',
        key: 'judo',
      },
      {
        text: 'Jiu-jitsu',
        value: 'jiuJitsu',
        key: 'jiuJitsu',
      },
      {
        text: 'Yoga',
        value: 'yoga',
        key: 'yoga',
      },
      {
        text: 'Pilates',
        value: 'pilates',
        key: 'pilates',
      },
      {
        text: 'Cycling',
        value: 'cycling',
        key: 'cycling',
      },
      {
        text: 'Rowing',
        value: 'rowing',
        key: 'rowing',
      },
      {
        text: 'Sailing',
        value: 'sailing',
        key: 'sailing',
      },
      {
        text: 'Education',
        value: 'education',
        key: 'education',
      },
      {
        text: 'Barre',
        value: 'barre',
        key: 'barre',
      },
      {
        text: 'Dance',
        value: 'dance',
        key: 'dance',
      },
      {
        text: 'Ballet',
        value: 'ballet',
        key: 'ballet',
      },
      {
        text: 'Zumba',
        value: 'zumba',
        key: 'zumba',
      },
      {
        text: 'Tap Dance',
        value: 'tapDance',
        key: 'tapDance',
      },
    ],
  },
  personal: {
    values: {
      tanning: true,
      tattoo: true,
      massage: true,
      barber: true,
      hairstylist: true,
    },
    dropdown: [
      {
        text: 'Tanning',
        value: 'tanning',
        key: 'tanning',
      },
      {
        text: 'Tattoo',
        value: 'tattoo',
        key: 'tattoo',
      },
      {
        text: 'Massage',
        value: 'massage',
        key: 'massage',
      },
      {
        text: 'Barber',
        value: 'barber',
        key: 'barber',
      },
      {
        text: 'Hairstylist',
        value: 'hairstylist',
        key: 'hairstylist',
      },
    ],
  },
  product: {
    values: {
      carpeting: true,
      windows: true,
      flooring: true,
      hvac: true,
      plumbing: true,
      construction: true,
      landscaping: true,
      pools: true,
      cabinetry: true,
      decor: true,
      lighting: true,
      paint: true,
      entertainment: true,
      homeTheatre: true,
      homeAutomation: true,
      security: true,
      survival: true,
      appliance: true,
      automotive: true,
      electrical: true,
      roofing: true,
      furniture: true,
    },
    dropdown: [
      {
        text: 'Carpeting',
        value: 'carpeting',
        key: 'carpeting',
      },
      {
        text: 'Windows',
        value: 'windows',
        key: 'windows',
      },
      {
        text: 'Flooring',
        value: 'flooring',
        key: 'flooring',
      },
      {
        text: 'HVAC',
        value: 'hvac',
        key: 'hvac',
      },
      {
        text: 'Plumbing',
        value: 'plumbing',
        key: 'plumbing',
      },
      {
        text: 'Construction',
        value: 'construction',
        key: 'construction',
      },
      {
        text: 'Landscaping',
        value: 'landscaping',
        key: 'landscaping',
      },
      {
        text: 'Pools',
        value: 'pools',
        key: 'pools',
      },
      {
        text: 'Cabinetry',
        value: 'cabinetry',
        key: 'cabinetry',
      },
      {
        text: 'Decor',
        value: 'decor',
        key: 'decor',
      },
      {
        text: 'Lighting',
        value: 'lighting',
        key: 'lighting',
      },
      {
        text: 'Paint',
        value: 'paint',
        key: 'paint',
      },
      {
        text: 'Entertainment',
        value: 'entertainment',
        key: 'entertainment',
      },
      {
        text: 'Home Theatre',
        value: 'homeTheatre',
        key: 'homeTheatre',
      },
      {
        text: 'Home Automation',
        value: 'homeAutomation',
        key: 'homeAutomation',
      },
      {
        text: 'Security',
        value: 'security',
        key: 'security',
      },
      {
        text: 'Survival',
        value: 'survival',
        key: 'survival',
      },
      {
        text: 'Appliance',
        value: 'appliance',
        key: 'appliance',
      },
      {
        text: 'Automotive',
        value: 'automotive',
        key: 'automotive',
      },
      {
        text: 'Electrical',
        value: 'electrical',
        key: 'electrical',
      },
      {
        text: 'Roofing',
        value: 'roofing',
        key: 'roofing',
      },
      {
        text: 'Furniture',
        value: 'furniture',
        key: 'furniture',
      },
    ],
  },
};
