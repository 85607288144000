import React from 'react';
import ModalImage from 'react-modal-image';
import './Modal.css';

const Attachment = (props) =>
  // const closeLightbox = () => {
  //   this.state.open = true;
  // };

   (
    <ModalImage
      small={props.image.props.src}
      large={props.image.props.src}
      // alt={props.image.props.src}
    />
  )
;

export default Attachment;
