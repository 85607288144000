import React from 'react';

const Envelope = ({ height, width }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    aria-labelledby="envelopeIconTitle"
    stroke="#C6C6C6"
    strokeWidth="1.5"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#C6C6C6"
  >
    {/* <title id="envelopeIconTitle">Envelope</title> */}
    <rect width="20" height="14" x="2" y="5" />
    <path strokeLinecap="round" d="M2 5l10 9 10-9" />
    <path strokeLinecap="round" d="M2 19l6.825-7.8" />
    <path strokeLinecap="round" d="M22 19l-6.844-7.822" />
  </svg>
);

export default Envelope;
