import React from 'react';
import { Parallax } from 'react-parallax';
import { Image, Container } from 'semantic-ui-react';

const ParallaxImage = (props) => (
  <div>
    <Parallax
      bgImage={props.bgImage}
      bgImageAlt="profile pic"
      style={props.style}
      strength={0}
    >
      <Container>
        <Image src={props.src} size={props.size} centered />
        <h1 className="homepage-title" style={{ marginTop: '8%' }}>
          {props.title}
        </h1>
        <div style={{ textAlign: 'center' }}>{props.search}</div>
        <div style={props.height} />
      </Container>
    </Parallax>
  </div>
);
export default ParallaxImage;
