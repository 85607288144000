import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Karla', sans-serif;
`;

export const Row = styled.div`
  display: flex;
  flex-direction; row;
  font-family: 'Karla', sans-serif;
`;

export const RegText = styled.div`
  font-size: 14px;
  font-family: 'Karla', sans-serif;
  margin-bottom: 5%;
  cursor: pointer;
  &:hover {
    color: #00be6e;
  }
`;

export const BoldText = styled.div`
  font-size: 14px;
  font-family: 'Karla', sans-serif;
  font-weight: bold;
  margin-bottom: 5%
`;

export const BusinessDetailsInput = styled.input`
  border: 0;
  outline: 0;
  background: transparent;
  width: 75%;
  margin-left: 1%;
  margin-bottom: 1%;
`;

export const PhotoText = styled.div`
  border: 0;
  outline: 0;
  background: transparent;
  width: 75%;
  margin-left: 1%;
  margin-bottom: 1%;
`;

export const BusinessAboutField = styled.textarea`
  border: 0;
  outline: 0;
  background: transparent;
  width: 75%;
  color: #999;
  margin-left: 1%;
`;

export const InputLabels = styled.label`
  font-size: 14px;
  font-family: 'Karla', sans-serif;
  font-weight: bold;
  margin-bottom: 2%;
  color: #000000;
`;

export const ProgressBar = styled.div`
  width: 75%;
  background-color: #e3e3e3;
`;

export const ProgressBarFill = styled.div`
  background-color: #00be6e;
  width:  ${(props) => props.width}
  height: 30px;
  display: table-cell;
  vertical-align: middle;
`;

export const Text = styled.div`
  font-family: 'Karla', sans-serif;
`;

export const WorksWithPic = styled.img`
  margin-right: 1.5%;
  height: 60px;
  width: 60px;
  
`;

export const DeleteButton = styled.button`
  color: #00BE6E;
  border: none;
  background-color: #fff;
  display: inline-block;
  cursor: pointer;
`;

export const UploadLabel = styled.label`
  color: #00BE6E;
  border: none;
  background-color: #fff;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
`;

export const UploadInput = styled.input`
  border: none;
`;

export const DeleteUploadContainer = styled.div`
  margin: 0 5px;
`;
