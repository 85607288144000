import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Feed } from 'semantic-ui-react';
import styled from 'styled-components';
import TimeAgo from 'react-timeago';
import 'semantic-ui-css/semantic.min.css';
import { timeagoFormatter } from '../../util';

class Messages extends Component {
  truncate = (input) => input.substring(0, 20) + '...';

  determineMessageSender = (message) => {
    const { user, business, category } = this.props; // eslint-disable-line
    const messageHasSenderProp = !!message.sender;
    if (messageHasSenderProp) {
      if (category === 'personal') {
        return message.sender._id === user._id;
      }
      return message.sender._id === business._id;
    }
    if (category === 'personal') {
      return message.userId === user._id;
    }
    return message.userId === business._id;
  };

  determineUnreadMessageCount = () => {
    const { connection, user, category } = this.props; // eslint-disable-line
    if (category === 'business') {
      return connection.unreadMessagesBusiness;
    }
    if (connection.type === 'B2C') {
      return connection.unreadMessagesConsumer;
    } else if (user._id === connection.user1_id) {
      return connection.unreadMessagesUser1;
    }
    return connection.unreadMessagesUser2;
  };

  render() {
    const { connection, otherProfile, category, user, business } = this.props;
    const unreadCount = this.determineUnreadMessageCount();

    return (
      <Link
        to={{
          pathname: '/Messages',
          state: {
            connection,
            otherProfile,
            category,
            userProfile: category === 'business' ? business : user,
          },
        }}
      >
        <div className="Messaged-PeopleList">
          <Feed>
            <Feed.Event>
              <Feed.Label image={otherProfile.profilePic} />
              <Feed.Content>
                <FeedUser>{otherProfile.name}</FeedUser>
                <Feed.Date
                  className="Feed-Date"
                  content={
                    <TimeAgo
                      date={connection.lastMessage.timestamp}
                      minPeriod={30}
                      formatter={timeagoFormatter}
                      style={{ border: 'none', color: '#959595' }}
                    />
                  }
                />
                <FeedUser>{`${
                  this.determineMessageSender(connection.lastMessage)
                    ? 'Me: '
                    : ''
                }${this.truncate(connection.lastMessage.text)}`}</FeedUser>
              </Feed.Content>
            </Feed.Event>
          </Feed>

          {/* Todo: show unread count somewhere in this component.
          Conditional render using unreadCount var */}
          {unreadCount > 0 && (
            <p className="new-messages-sidebar">{unreadCount}</p>
          )}
        </div>
      </Link>
    );
  }
}

const FeedUser = styled.p`
  font-weight: 5px;
  color: #7a7a7a;
`;

const mapStateToProps = (state) => ({
  connections: state.connections,
});

export default connect(mapStateToProps)(Messages);
