import {
  FETCH_BUSINESS,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_FAILED,
  ADD_BUSINESS,
  ADD_BUSINESS_SUCCESS,
  ADD_BUSINESS_FAILED,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_FAILED,
  SET_BUSINESS,
  UPDATE_BUSINESS_PHOTO,
  UPDATE_BUSINESS_PHOTO_SUCCESS,
  UPDATE_BUSINESS_PHOTO_FAILED,
} from './types';
import { getHeaderOptions } from '../util';
import {
  getBusinessById,
  createBusiness,
  editBusinessProfile,
  uploadBusinessPhoto,
} from '../services';

export const fetchBusiness = (id) => async(dispatch) => {
  dispatch({ type: FETCH_BUSINESS });
  const fetchResults = await getBusinessById(id);

  if (fetchResults.success) {
    dispatch({ type: FETCH_BUSINESS_SUCCESS, payload: fetchResults });
  } else {
    dispatch({ type: FETCH_BUSINESS_FAILED, payload: fetchResults });
  }
};

export const addBusiness = (businessInfo) => async(dispatch) => {
  dispatch({ type: ADD_BUSINESS });
  const options = await getHeaderOptions();
  const createBusinessResult = await createBusiness(businessInfo, options);

  if (createBusinessResult.success) {
    dispatch({ type: ADD_BUSINESS_SUCCESS, payload: createBusinessResult });
  } else {
    dispatch({ type: ADD_BUSINESS_FAILED, payload: createBusinessResult });
  }
};

export const editBusiness = (id, updates, nameChanged, websiteChanged) => async(dispatch) => {
  dispatch({ type: UPDATE_BUSINESS });
  const options = await getHeaderOptions();
  const results = await editBusinessProfile(id, updates, nameChanged, websiteChanged, options);

  if (results.success) {
    dispatch({ type: UPDATE_BUSINESS_SUCCESS, payload: results });
  } else {
    dispatch({ type: UPDATE_BUSINESS_FAILED, payload: results });
  }
};

const updateBusinessPhotoSuccess = (business) => async(dispatch) =>
  dispatch({
    type: UPDATE_BUSINESS_PHOTO_SUCCESS,
    payload: business,
  });

const updateBusinessPhotoFailed = (message) => async(dispatch) =>
  dispatch({
    type: UPDATE_BUSINESS_PHOTO_FAILED,
    payload: { message },
  });

export const updatePhotoAction = (id, photoUpdates) => async(dispatch) => {
  dispatch({ type: UPDATE_BUSINESS_PHOTO });
  const options = await getHeaderOptions();
  const updateResults = await uploadBusinessPhoto(id, photoUpdates, options);

  if (updateResults.success) {
    dispatch(updateBusinessPhotoSuccess(updateResults.business));
  } else {
    dispatch(updateBusinessPhotoFailed(updateResults.message));
  }
};

export const updateBusiness = (business) => async(dispatch) => {
  dispatch({
    type: SET_BUSINESS,
    payload: { business },
  });
};
