import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction; row;
  font-size: 18px;
  font-family: 'Karla', sans-serif;
`;

export const Dollar = styled.div`
  margin-right: 1%;
  fontsize: 23px;
  color: ${(props) => (props.color ? '#D3D3D3' : 'black')};
  font-weight: bold;
`;
