/* eslint-disable no-invalid-this */
import React, { Component } from 'react';

// styled components
import { Container, ProgressBar, ProgressBarFill } from '../Styles';

class ProfileProgressBar extends Component {
  state = {
    message: '',
  };

  componentDidMount = () => {
    this.constructProgressBarMesage(this.props.progress);
  };

  constructProgressBarMesage = (progress) => {
    let message = '';
    switch (progress) {
      case '0%':
        message = '0% - Start filling out informaton about your business';
        break;
      case '25%':
        message = '25% - Great start!';
        break;
      case '50%':
        message = '50% - Halfway there!';
        break;
      case '75%':
        message = '75% - Nearly there!';
        break;
      case '100%':
        message = '100% - Congratulations!';
        break;
      default:
    }
    this.setState({
      message,
    });
  };

  render() {
    const { progress } = this.props;
    const { message } = this.state;
    return (
      <Container className="profile-progress-bar">
        <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
          Business Profile Completeness
        </div>
        <ProgressBar>
          <ProgressBarFill width={progress}>
            <div style={{ marginLeft: '5%', color: 'white' }}>{message}</div>
          </ProgressBarFill>
        </ProgressBar>
      </Container>
    );
  }
}

export default ProfileProgressBar;
