import React from 'react';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';

const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;

  > input {
    display: none;
  }
`;

// const Slider = styled.span`
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #00bf6f;
//   transition: 0.4s;
//   border-radius: 15px;

//   &:before {
//     position: absolute;
//     content: '';
//     height: 15px;
//     width: 15px;
//     background-color: white;
//     transition: 0.2s;
//     border-radius: 50%;
//   }
// `;

// const SliderInput = styled.input`
//   &:checked + ${Slider} {
//     background-color: #00bf6f;
//     &:before {
//       transform: translateX(15px);
//       background-color: white;
//     }
//   }
// `;

const MonthlyPlan = styled.span`
  color: #000;
  font-size: 1em;
  margin-right: 0.7em;
  font-weight: bold;
  font-family: inherit;
  color: #a5a5a5;
  ${({ active }) =>
    !active &&
    `
color: #000;
`}
`;

const YearlyPlan = styled.span`
  color: #000;
  font-size: 1em;
  margin-left: 20px;
  font-weight: bold;
  font-family: inherit;
  color: #a5a5a5;
  ${({ active }) =>
    active &&
    `
color: #000;

`}
`;

const SubscriptionMonthlyPrice = styled.span`
  display: none;
  text-align: center;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  ${({ active }) =>
    active &&
    `
display: block;

`}
`;

const SubscriptionYearlyPrice = styled.span`
  display: none;
  text-align: center;
  margin-bottom: 25px;
  ${({ active }) =>
    active &&
    `
display: block;

`}
`;

export default class ToggleSwitch extends React.Component {
  constructor() {
    super();

    this.state = {
      checked: false,
    };

    this.onToggleSwitchChange = this.onToggleSwitchChange.bind(this);
  }

  onToggleSwitchChange() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  render() {
    return (
      <div>
        <SubscriptionMonthlyPrice active={!this.state.checked && '!isChecked'}>
          <sup style={{ fontSize: '120%' }}>$</sup>
          <span className="subscription-monthly-amount"></span>
          <sup style={{ fontSize: '120%' }}>/mo</sup>
        </SubscriptionMonthlyPrice>
        {isBrowser && (
          <div>
            <SubscriptionYearlyPrice active={this.state.checked && 'isChecked'}>
              <sup style={{ fontSize: '120%' }}>$</sup>
              <span className="subscription-yearly-amount"></span>
              <sup style={{ fontSize: '120%' }}>/mo</sup>
            </SubscriptionYearlyPrice>
            <MonthlyPlan active={this.state.checked && 'isChecked'}>
              Billed monthly
            </MonthlyPlan>
            <Container>
              <div className="ToggleSwitch ToggleSwitch__rounded">
                <div className="ToggleSwitch__wrapper">
                  <div
                    className={`Slider ${this.state.checked && 'isChecked'}`}
                    onClick={this.onToggleSwitchChange}
                    onKeyDown={this.onToggleSwitchChange}
                  ></div>
                </div>
              </div>
            </Container>
            <YearlyPlan active={this.state.checked && 'isChecked'}>
              Billed yearly
            </YearlyPlan>
          </div>
        )}
      </div>
    );
  }
}
