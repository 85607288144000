import React, { Component } from 'react';

export default class MessageList extends Component {


  render() {
    return (
      <div>
        <p className={this.props.className}>
          {this.props.message.text}
        </p>
      </div>
    );
  }
}
