import React from 'react';
import BusinessImages from './BusinessImages';
import BusinessInfo from './BusinessInfo';
import './horizontalCard.css';
import { isBrowser, isMobile } from 'react-device-detect';

const HorizontalCard = (props) => (
  <div>
    {isMobile && (
      <div className="horizontal-card-container-mobile">
        <BusinessImages
          mainImage={props.businessCard[0].profilePic}
          subImages={props.businessCard[3]}
        />
        <BusinessInfo
          openModal={props.openModal}
          openSignUp={props.openSignUp}
          user={props.user}
          businessInfo={props.businessCard}
          {...props}
        />
      </div>
    )}

    {isBrowser && (
      <div className="horizontal-card-container">
        <BusinessImages
          mainImage={props.businessCard[0].profilePic}
          subImages={props.businessCard[3]}
        />
        <BusinessInfo
          openModal={props.openModal}
          openSignUp={props.openSignUp}
          user={props.user}
          businessInfo={props.businessCard}
          {...props}
        />
      </div>
    )}
  </div>
);

export default HorizontalCard;
