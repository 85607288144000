import React from 'react';
import { Link } from 'react-router-dom';

const DreamSearchResult = ({
  business,
  sendRequest,
  userBusinessId,
}) => (
  <div style={{ marginTop: '5%' }}>
    <section style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={business.profilePic}
        alt="business profile pic"
        style={{ width: '40px', marginRight: '1em' }}
      />
      <div>
        <p
          style={{
            marginBottom: '0.5em',
            color: '#C0C0C0',
            fontSize: '1.25em',
          }}
        >
          {business.name}
        </p>
        <div>
          {userBusinessId !== business._id && (
            <button
              style={{
                marginRight: '2em',
                border: 'none',
                background: '#fff',
                color: '#00BE6E',
              }}
              onClick={() => sendRequest(business)}
            >
              Request to Add
            </button>
          )}
          <button
            style={{ border: 'none', background: '#fff', color: '	#DCDCDC' }}
          >
            <Link to={{
              pathname: `/business/${business.username}`,
              state: {
                business,
              },
            }}>
            {userBusinessId !== business._id
              ? 'View Profile'
              : 'View Your Profile'}
            </Link>
          </button>
        </div>
      </div>
    </section>
    <hr
      style={{
        borderWidth: '0.05em',
        marginTop: '1em',
        marginBottom: '1em',
        height: '1px',
        background: '#DCDCDC',
      }}
    />
  </div>
);

export default DreamSearchResult;
